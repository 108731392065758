import React, { useRef, useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import MultiSelect from "react-multi-select-component";
import "./MultiselectTeamDropdown.scss";

const MultiselectTeamDropdown: React.FC<any> = (props) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    // eslint-disable-next-line
  }, [props.value]);
  useEffect(() => {
    // eslint-disable-next-line
    let tempTeams = props.options.map((item: any) => {
      return { label: item[props.uniqueLabel], value: item[props.uniqueId] };
    });
    setOptions(tempTeams);
  }, [props.options]);

  return (
    <React.Fragment>
      <div style={{ marginBottom: "10px" }}>
        <MultiSelect
          overrideStrings={{
            selectSomeItems:
              props.overrideStrings.selectSomeItems || "Select...",
            allItemsAreSelected:
              props.overrideStrings.allItemsAreSelected ||
              "All items are selected",
            selectAll: props.overrideStrings.selectAll || "Select All Items",
            search: props.overrideStrings.search || "Search",
            clearSearch: "Clear Search",
          }}
          filterOptions={(options, filter) => {
            if (!filter) {
              return options;
            }
            const re = new RegExp(filter, "i");
            return options.filter(({ label }) => label && label.match(re));
          }}
          className={props.className || ""}
          options={options}
          value={props.value}
          onChange={props.onChange}
          labelledBy={"Select"}
          ArrowRenderer={props.ArrowRenderer}
        />
      </div>
    </React.Fragment>
  );
};

export default React.memo(MultiselectTeamDropdown);
