import React, { Fragment } from "react";
import PageLoader from "../pageLoader/pageLoader";

const DataLoadingInProgress = () => {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          padding: "20px",
          marginTop: "64px",
          paddingTop: "0px",
        }}
      >
        Loading...
      </div>
      <PageLoader customStyle={{ marginTop: "-35px" }}></PageLoader>
    </div>
  );
};
export default DataLoadingInProgress;
