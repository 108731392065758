import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useAppContext } from "../../../AppContext/App.context";
import useLocalStorage from "../../../hooks/localStorage";
import useApiService from "../../../services/api.service";
import { templateApiService } from "../../../services/ApiServices/templateApi.service";
import headerDropdownService from "../../../services/headerDropdown.service";
import MaterialAutocomplete from "../../MaterialAutocomplete/MaterialAutocomplete";
import { useTranslation } from "react-i18next";
let increment = 0;

const TemplateListDropdown = () => {
  const [appData]: any = useAppContext();
  const { t, i18n } = useTranslation([
    "Common",
    "Supervisor",
    "Jobfilter",
    "ImportJobs",
  ]);
  const access_token = appData.loginData.access_token;
  const ApiService = useApiService();
  const [templateList, setTemplateList]: any = useState();
  const [selectedTemplateList, setSelectedTemplateList]: any = useState();
  let [templateListData, setTemplateListData]: any = useState([]);
  const isTemplateSet = useLocalStorage.getItem("selectedTemplateForJob");
  const onTemplateChange = (event: any, isReset?: any, templateData?: any) => {
    increment = ++increment;
    if (isReset == true) {
      const templateChangeSendToJobListCount = {
        type: "jobStatusCount",
        isStatusChange: true,
      };
      headerDropdownService.emit(templateChangeSendToJobListCount);
    }

    const dropdownData = {
      type: "templateList",
      data: { tag_id: event },
    };
    let tempArray4 = [];
    tempArray4.push(event);
    if (event.length == 0) {
      useLocalStorage.removeItem("selectedTemplateForJob");
    } else {
      useLocalStorage.setItem("selectedTemplateForJob", event);
    }
    headerDropdownService.emit(dropdownData);
    if (templateData) {
      templateListData = templateData;
    }
    let tempArray: any = [];
    for (let k = 0; k < templateListData.length; k++) {
      for (let i = 0; i < event.length; i++) {
        if (templateListData[k].template_id == event[i]) {
          tempArray.push(templateListData[k]);
        }
      }
    }
    const dropdownData1 = {
      type: "templateListData",
      data: { data: tempArray },
      isReset: isReset,
    };
    headerDropdownService.emit(dropdownData1);
  };
  const getTemplateList = async () => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams = {
      skip: 0,
      limit: 1000,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    await ApiService.get(templateApiService.getTemplateList(reqData))
      .then((res) => {
        let tempArray: any = [],
          tempArray2: any = [],
          templateFields = res?.data?.data;
        let selectedTemplateList: any = [];

        if (templateFields) {
          for (let i = 0; i < templateFields.length; i++) {
            tempArray.push({
              name: templateFields[i].name,
              id: templateFields[i].template_id,
            });

            for (let k = 0; k < templateFields[i].data.length; k++) {
              tempArray2.push({
                template_id: templateFields[i].template_id,
                template_name: templateFields[i].name,
                field_name: templateFields[i].data[k].name,
              });
            }
            if (isTemplateSet) {
              for (let y = 0; y < isTemplateSet.length; y++) {
                if (templateFields[i].template_id == isTemplateSet[y]) {
                  selectedTemplateList.push({
                    name: templateFields[i].name,
                    id: templateFields[i].template_id,
                  });
                }
              }
            }
          }
        }
        const dropdownData1 = {
          type: "templateListDataApiHit",
          data: { data: tempArray2 },
        };
        headerDropdownService.emit(dropdownData1);
        setTemplateListData(tempArray2);
        setTemplateList(tempArray);
        setSelectedTemplateList(selectedTemplateList);
        onTemplateChange(isTemplateSet, false, tempArray2);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getTemplateList();
  }, []);

  return (
    <Fragment>
      {templateList?.length > 0 ? (
        <div style={{ display: "flex", minWidth: "170px" }}>
          <MaterialAutocomplete
            isMultiple={true}
            freeSolo={false}
            disablePortal={false}
            size="medium"
            disableClearable
            id={"tags-outlined"}
            noOptionsText={"No Template"}
            options={templateList}
            labelName="name"
            getOptionSelected={(option: any, value: any) =>
              option.id === value.id
            }
            value={selectedTemplateList ? selectedTemplateList : []}
            onChange={(event: any, newValue: any) => {
              let templateIdArray: any = [];
              if (newValue) {
                for (let i = 0; i < newValue.length; i++) {
                  templateIdArray.push(newValue[i].id);
                }
                onTemplateChange(templateIdArray, true);
              }
              setSelectedTemplateList(newValue);
            }}
            filterSelectedOptions
            textFieldLabel={t("ImportJobs:Select Template")}
            textFieldPlaceholder={""}
            textFieldMargin={"normal"}
            textFieldFullWidth={true}
          />
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default TemplateListDropdown;
