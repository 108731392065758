import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { themeColor } from "../../../constants/themeColor";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      margin: "0px 1.4rem",
    },
    muiTabRoot: {
      color: "#000",

      fontSize: "15px",
      fontWeight: 600,
      // textTransform: "capitalize",
    },
    rootCard: {
      minWidth: "206px",
      margin: "15px",
      borderRadius: "12px",
      height: "106px",
      marginTop: "0px",
      marginBottom: "1.4rem",
      boxShadow: "0 17px 24px 0 rgba(0, 126, 191, 0.26)",
    },
    constraintsTooltip: {
      display: "inline-flex",
      marginLeft: "0.1rem",
      cursor: "pointer",
    },
    activeInactive: {
      paddingBottom: "10px !important",
      paddingTop: "0px",
    },
    tooltipIcon: {
      height: "16px",
    },
    headerTitle: {
      fontSize: "18px",
      fontWeight: 600,
      letterSpacing: "0.08px",
      // whiteSpace: "nowrap",
      // overflow: "hidden",
      // textOverflow: "ellipsis",
      // maxWidth: "95%",
    },
    headerTitleSurge: {
      fontSize: "14px",
      fontWeight: "normal",
      letterSpacing: "0.01px",
    },
    cardContentRoot: {
      padding: "8px 16px",
      paddingBottom: "8px !important",
    },
    headerTitleRoot: {
      padding: "11px 16px 0px",
    },
    cardContent: {
      fontWeight: "bold",
      overflow: "hidden",
      letterSpacing: "0.1px",
      padding: "5px 16px 8px",
    },
    cardContentSurge: {
      fontSize: "17px",
      fontWeight: "bold",
      overflow: "hidden",
      letterSpacing: "0.1px",
      padding: "5px 16px 8px",
    },
    listHeading: {
      fontSize: theme.typography.pxToRem(17),
      fontWeight: theme.typography.fontWeightRegular,
      paddingLeft: "1.9rem",
      marginBottom: "0.6rem",
      paddingTop: "1.6rem",
    },
    subHeading: {
      fontWeight: 800,
      fontSize: "24px",
    },
    heading: {
      fontWeight: 800,
      fontSize: "24px",
      letterSpacing: "0.17px",
    },
    accordionHead: {
      fontWeight: 700,
    },
    constraints: {
      width: "230px",
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
      display: "flex",
    },
    tagInputField: {
      marginTop: "0px",
      marginBottom: "10px",
    },
    input: {
      width: "1rem",
    },
    surgeGridItem: {
      marginTop: "10px",
    },
    submitButtons: {
      textAlign: "center",
      height: "7rem",
    },
    templateText: {
      maxWidth: "54%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    subheadingsCard: {
      backgroundColor: themeColor.defaultBackgroundColor,
      boxShadow: "0 3px 4px 0 rgba(0, 126, 191, 0.26)",
    },
    button: {
      marginRight: "10px",
      marginTop: "2.5rem",
      padding: "0.45rem 1rem",
      minWidth: "120px",
    },
    baseDistance: {
      marginRight: "1rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginBottom: "0.5rem",
    },
    switch: {
      marginTop: "22px",
      marginBottom: "1rem",
    },
    checkBoxStyle: {
      padding: "9px 9px 9px 0 !important",
    },
    constantsRadioAlign: {
      // marginLeft : "0.5rem",
    },
    ruleNameClass: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: themeColor.primary.main,
        },
        "&:hover fieldset": {
          borderColor: themeColor.primary.main,
        },
      },
    },
    tabs: {
      // color: "#000 !important",
      marginLeft: "20px",
    },

    "MuiTab-root": {
      minWidth: "0px !important",
      fontSize: "15px",
      fontWeight: 600,
      textTransform: "capitalize",
      color: "#000",
    },
    tabsIndicator: {
      marginLeft: "10px",
      width: "53.409px !important",
      height: "3px",
    },
    enpIndicator: {
      height: "3px",
    },
    earningListView: {
      minHeight: "84vh",
    },
    earningTabBody: {
      backgroundColor: "#fff",
      borderRadius: "20px",
      boxShadow: "0 17px 24px 0 rgba(0, 126, 191, 0.26)",
      margin: "1.25rem 0rem",
      padding: "1.4rem 1.8rem",
    },
    tabInputLabel: {
      color: "#171725",
      padding: "0.2rem 0rem",
    },
    constraintsItem: {
      marginTop: "0.8rem",
    },
    removeTabSpace: {
      marginLeft: "0px",
      paddingLeft: "0px",
      marginRight: "3rem",
      paddingRight: "0px",
    },
    showEllipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    tabBody: {
      backgroundColor: "#fff",
      borderRadius: "20px",
      boxShadow: "0 17px 24px 0 rgba(0, 126, 191, 0.26)",
      margin: "1.25rem 1rem",
      padding: "1.4rem 1.8rem",

      minHeight: "calc(95vh - 215px)",
    },
  })
);
