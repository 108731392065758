import axios from "axios";
import { useAppContext } from "../../AppContext/App.context";
// const [appData, dispatch]: any = useAppContext();

var mapBoxKey: any;
var hereMapKey: any;
class HereMapsFunctions {
  static Heremaps = async (searchtext: any) => {
    try {
      var loginData = JSON.parse(localStorage.getItem("loginData") || "{}");
      hereMapKey = loginData?.map_configuration?.here?.dashboard;
      const res: any = await axios.get(`https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=${hereMapKey}&searchtext=${searchtext}
  `);
      const value: any = [];
      res.data.Response.View[0].Result.map((item: any, index: any) => {
        const data: any = {};
        data.description = item.Location.Address.Label;
        data.lat = item.Location.DisplayPosition.Latitude;
        data.lng = item.Location.DisplayPosition.Longitude;

        value.push(data);
      });
      return value;
    } catch (error) {
      return [];
    }
  };
  static getLocationFromLatLong = async (lat: any, long: any) => {
    try {
      var hereMapKey: any;
      var loginData = JSON.parse(localStorage.getItem("loginData") || "{}");
      hereMapKey = loginData?.map_configuration?.here?.dashboard;
      const res: any = await axios.get(`https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json?apiKey=${hereMapKey}&mode=retrieveAddresses&prox=${lat},${long}
        `);
      let formatted_address;
      if (res) {
        formatted_address =
          res.data.Response.View[0].Result[0].Location.Address.Label;
        return formatted_address;
      }
    } catch (error) {
      return error;
    }
  };
}

export class MapBoxFunction {
  static Mapbox = async (searchtext: any) => {
    try {
      // var hereMapKey: any;
      var loginData = JSON.parse(localStorage.getItem("loginData") || "{}");
      mapBoxKey = loginData?.map_configuration?.mapbox?.dashboard;
      const res: any = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchtext}.json?access_token=${mapBoxKey}&autocomplete=true
      `);
      const value: any = [];
      console.log(value);
      console.log(res);
      res.data.features.map((item: any) => {
        const data: any = {};
        data.description = item.place_name;
        data.lat = item.center[1];
        data.lng = item.center[0];

        value.push(data);
      });

      return value;
    } catch (error) {
      return [];
    }
  };

  static getLocationFromLatLong = async (lat: any, lng: any) => {
    try {
      var loginData = JSON.parse(localStorage.getItem("loginData") || "{}");
      mapBoxKey = loginData?.map_configuration?.mapbox?.dashboard;
      const res: any = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapBoxKey}
        `);
      let formatted_address;
      if (res) {
        formatted_address = res.data.features[0].place_name;
        return formatted_address;
      }
    } catch (error) {
      return error;
    }
  };
}
export default HereMapsFunctions;
