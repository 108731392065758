import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./CreateJobMap.scss";
import lMap from "../../utils/leaflet/map";
import GoogleAutocompleteService from "../../utils/googleAutocompleteService";
import { jobMarkers, jobStatusData } from "../../constants/jobStatusData";
import DashboardMapMarkers from "../../utils/dashboardMarkers";
import useLocalStorage from "../../hooks/localStorage";
import { MapBoxFunction } from "../Autocomplete/AutocompleteServices";
import HereMapsFunctions from "../Autocomplete/AutocompleteServices";
import { mapTypesEnum } from "../../enum/MapType.enum";
import { useAppContext } from "../../AppContext/App.context";

const CreateJobMap = forwardRef((props: any, ref: any) => {
  // const { control, register } = useForm();
  const loginData = useLocalStorage.getItem("loginData");
  const [open, setOpen] = useState(false);
  const [markerLatlng, setMarkerLatlng] = useState({});
  const [appData]: any = useAppContext();

  let marker: any = useRef(null);
  var myMap: any = useRef(null);
  let c = 2;
  const mapType = Number(appData.loginData.map_config?.map_type);

  useEffect(() => {
    initMap();
  }, []);
  useEffect(() => {
    if (props.marker && (props.marker.lat || props.marker.lng)) {
      setMarkerLatlng(props.marker);
      setMapCenter(props.marker);
      updateMarkerPosition(props.marker);
    }
  }, [props.marker]);
  const updateMarkerPosition = (markerPosition: any) => {
    if (!marker.current) {
      createMarker(markerPosition);
    }
    if (
      markerPosition &&
      !isNaN(markerPosition.lat) &&
      !isNaN(markerPosition.lng)
    ) {
      marker.current.setLatLng([markerPosition.lat, markerPosition.lng]);
    }
  };
  const createMarker = (markerPosition: any) => {
    let template = "";
    // Unassigned markers for job_type
    let color = jobStatusData[0].color;
    if (props.taskType === 0) {
      template = DashboardMapMarkers.getPickupIcon(color);
    } else if (props.taskType === 1) {
      template = DashboardMapMarkers.getDeliveryIcon(color);
    } else if (props.taskType === 2) {
      template = DashboardMapMarkers.getServiceIcon(color);
    }

    marker.current = new lMap.markers({
      map: myMap.current.map,
      lat: markerPosition["lat"] || 0,
      lng: markerPosition["lng"] || 0,
      draggable: true,
      // icon: `${window.location.origin + jobMarkers[props.taskType][0]["icon"]}`,
      icon: `${
        "data:image/svg+xml;charset=UTF-8, " + encodeURIComponent(template)
      }`,
      params: [],
    });
    marker.current.marker.on("dragend", (event: any) => {
      var position = marker.current.marker.getLatLng();
      getAddressFromLatlng(position);
    });
  };
  const setMapCenter = (marker: any) => {
    // if(!marker.lat || !marker.lng){
    //     return;
    // }
    //    console.log(c)
    myMap.current.setCenter([marker?.lat, marker?.lng]);
  };
  const initMap = () => {
    let lat = Number(loginData.latitude);
    let lng = Number(loginData.longitude);
    c = 3;
    myMap.current = new lMap.map({
      eleId: "createJobMap" + props.mapId,
      zoom: 13,
      center: lat && lng ? [lat, lng] : "",
      //  template: '<create-job-button-on-map/>',
    });
  };

  useImperativeHandle(ref, () => ({
    handleOpen: () => {
      setOpen(true);
    },
  }));
  const getAddressFromLatlng = async (position: any) => {
    if (mapType === mapTypesEnum.GoogleMap || !mapType) {
      const address = await GoogleAutocompleteService.getGeolocation(
        new google.maps.LatLng(position.lat, position.lng)
      );
      console.log("-----position", position.lat, position.lng);
      setMarkerLocation(address, {
        latitude: position.lat,
        longitude: position.lng,
      });
    } else if (mapType === mapTypesEnum.HereMap) {
      const address = await HereMapsFunctions.getLocationFromLatLong(
        position.lat,
        position.lng
      );
      setMarkerLocation(address, {
        latitude: position.lat,
        longitude: position.lng,
      });
    } else if (mapType === mapTypesEnum.MapBox) {
      const address = await MapBoxFunction.getLocationFromLatLong(
        position.lat,
        position.lng
      );
      console.log("address", address);
      setMarkerLocation(address, {
        latitude: position.lat,
        longitude: position.lng,
      });
    }
  };
  const setMarkerLocation = (address: any, latLng: any) => {
    props.setAddressFromMarker({ address, latLng });
    console.log(address, latLng);
  };

  return (
    <Fragment>
      <div id={"createJobMap" + props.mapId} className="CreateJobMap"></div>
    </Fragment>
  );
});

export default CreateJobMap;
