import React from "react";
import classes from "./PageHeading.module.scss";
const PageHeading: React.FC<any> = (props) => {
  return (
    <React.Fragment>
      <div className={classes["PageTitleContainer"]} style={props.customStyle}>
        {props.title}
      </div>
    </React.Fragment>
  );
};

export default PageHeading;
