import React, { useRef, Fragment, Suspense, useEffect } from "react";
// import Header from "../Header/Header";
import "./TaskDetailTabs.scss";
import DateTimeFormatter from "../../../utils/DateFormatter";
import { stringify } from "querystring";
import TemplateImages from "./TemplateImages";
import templateMethodService from "../../TemplatesCommonMethods/TemplateMethod.service";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import MusicVideoIcon from "@material-ui/icons/MusicVideo";

const TemplateInfo = (props: any) => {
  const templateDetail = props?.taskDetail?.template || props?.template;
  const imageSliderRef: any = useRef();
  if (
    !props.taskDetail?.template &&
    !props.taskDetail?.template_data &&
    !templateDetail
  ) {
    return null;
  }

  const templateFieldData =
    props.taskDetail && props.taskDetail.template_data
      ? props.taskDetail.template_data
      : templateMethodService.parseTempalteDataForVisible(templateDetail.data);
  const ImageViewInHtml = (props: any) => {
    return props.imgArray
      ? props.imgArray.map((val: any, index: number) => (
          <img
            onClick={() => imageSliderRef.current.handleOpen(index)}
            className={"task-detail-tabs__template-data__images"}
            src={val}
            alt="image"
          />
        ))
      : null;
  };

  const DocumentViewInHtml = (props: any) => {
    const getDisplayName = (imageUrl: string) => {
      let fileName: any = imageUrl.split("/");
      fileName = fileName[fileName.length - 1].toString();
      if (fileName.length > 10) {
        return `${fileName.slice(0, 10)}...`;
      } else {
        return fileName;
      }
    };
    return props.docsArray
      ? props.docsArray.map((val: any, index: number) => {
          return (
            <div
              className={"task-detail-tabs__info__documentWithLabel"}
              style={{ cursor: "pointer" }}
            >
              <InsertDriveFileIcon
                style={{ fontSize: "2,2rem" }}
                color="primary"
                onClick={() => window.open(val)}
              ></InsertDriveFileIcon>
              {getDisplayName(val)}
            </div>
          );
        })
      : null;
  };
  const RecordingViewInHtml = (props: any) => {
    return props.recordingArray
      ? props.recordingArray.map((val: any, index: number) => {
          return (
            <div className={"task-detail-tabs__info__documentWithLabel"}>
              {val ? (
                <MusicVideoIcon
                  style={{ fontSize: "20px" }}
                  onClick={() => window.open(val)}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })
      : null;
  };
  const showFieldValue = (item: any) => {
    switch (item.data_type) {
      case 13:
        return (
          <div>
            <a target="_blank" href={item.value}>
              {item.value}
            </a>
          </div>
        );

      case 4:
        return (
          <div>
            <ImageViewInHtml imgArray={item.value}></ImageViewInHtml>
            <Suspense fallback={<h3>Loading&hellip;</h3>}>
              <TemplateImages
                ref={imageSliderRef}
                imageArray={item.value}
              ></TemplateImages>
            </Suspense>
          </div>
        );
      case 6:
        return (
          <div>
            <DocumentViewInHtml docsArray={item.value}></DocumentViewInHtml>
          </div>
        );
      case 11:
        return (
          <div>
            {item.value ? item.country_code : ""} {item.value}
          </div>
        );
      case 8: {
        let list: any = [];

        item.value &&
          item.value.map((element: any, index: any) => {
            if (index === item.value.length - 1) {
              list.push(element);
            } else {
              list.push(element);
              list.push(", ");
            }
          });
        return list;
      }
      case 10:
        return <RecordingViewInHtml recordingArray={[item.value]} />;
      default:
        return item.value;
    }
  };
  return (
    <Fragment>
      <div className={"task-detail-tabs__info__basic-detail"}>
        <b style={{ marginBottom: "10px" }}> {templateDetail.name}</b>
      </div>
      <div style={{ marginTop: "-19px" }}>
        {templateFieldData.map((item: any, index: number) => (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {item.name}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {showFieldValue(item)}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};
export default TemplateInfo;
