import React, { useEffect, useState, Fragment, useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import MaterialAutocomplete from "../MaterialAutocomplete/MaterialAutocomplete";
import CommonEndpoints from "../../services/commonApi.service";
import useApiService from "../../services/api.service";
import { useAppContext } from "../../AppContext/App.context";
import { makeStyles } from "@material-ui/core/styles";
import headerDropdownService from "../../services/headerDropdown.service";
import "../Dropdown/Dropdown.modules.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
    border: "solid 1px #dcdcdc",
  },
  accordionWrapper: {
    boxShadow: "none",
  },
  padding0: {
    padding: "0px",
  },
  width100: {
    width: "100%",
  },
  expanded: {
    height: "56px",
    borderRadius: "10px",
    paddingLeft: "20px",
    display: "flex",
    paddingRight: "20px",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    border: "solid 1px #dcdcdc",
    "&.Mui-expanded": {
      border: "solid 1px #3b7cff",
      "&.MuiAccordionSummary-expandIcon": {
        color: "#3b7cff",
      },
    },
  },
  tagInputField: {
    marginTop: "0px",
    marginBottom: "0px",
  },
}));

const ResourceListDropdown = (props: any) => {
  const [resourceList, setResourceList]: any = useState([]);
  const [selectResourceId, setResourceId]: any = useState("");
  const [appData]: any = useAppContext();
  const access_token = appData.loginData.access_token;
  const ApiService = useApiService();
  const classes = useStyles();

  // const onResourceChange = (event: any) => {
  //  let newResourceId = '';
  //   if(event && event['resource_id'] ){
  //     newResourceId = event['resource_id']

  //   }
  //   setResourceId(event);

  //   const dropdownData = {
  //     type: "resourceList",
  //     location:props.location,
  //     data: { "resourceId": newResourceId }
  //   }
  //   headerDropdownService.emit(dropdownData)
  // }
  const getResourceList = async (resourceFiltering: any) => {
    // return;
    let headers = {
      access_token: access_token,
    };
    let qureyParams: any = {
      limit: 1000,
      get_user_details: true,
      get_team_details: true,
      is_active: true,
      resource_type: 1,
      skip: 0,
    };
    if (props?.resourceType) {
      qureyParams.resource_type = props?.resourceType;
    }
    if (props.dataForResourceFiltering.teamId) {
      qureyParams.team_id = props.dataForResourceFiltering.teamId;
    }
    if (
      props.dataForResourceFiltering.selectedTags &&
      props.dataForResourceFiltering.selectedTags.length > 0
    ) {
      const tempTags = props.dataForResourceFiltering.selectedTags.map(
        (tag: any) => {
          return tag.tag_id;
        }
      );
      qureyParams.tags = tempTags;
      qureyParams.get_tag_details = true;
    }
    if (props.dataForResourceFiltering.geofence) {
      if (
        props.dataForResourceFiltering.locationForGetResourceList &&
        props.dataForResourceFiltering.locationForGetResourceList.lat
      ) {
        qureyParams.check_geofence = props.dataForResourceFiltering.geofence;
        qureyParams.point =
          props.dataForResourceFiltering.locationForGetResourceList.lat +
          " " +
          props.dataForResourceFiltering.locationForGetResourceList.lng;
      }
    }

    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      CommonEndpoints.getResourceList(reqData)
    );
    //setApiHitsCount(++apiHitsCount)

    // setResourceId(props.dataForResourceFiltering.resourceId || 0);

    setResourceListDropdown(res.data.data);
  };
  useEffect(() => {
    //  setShown(true)
    getResourceList(props.dataForResourceFiltering);
  }, [props.dataForResourceFiltering]);

  // useEffect(()=>{
  //   setResourceId(props.selectedResourceId?props.selectedResourceId.toString():0)
  // },[props.selectedResourceId])

  const findResourceByResourceId = (resourceId: any, resourceList: any) => {
    const totalResource = resourceList.length;
    for (let i = 0; i < totalResource; i++) {
      if (resourceList[i]["resource_id"] === resourceId) {
        return resourceList[i];
      }
    }
    return null;
  };
  const setResourceListDropdown = (data: any) => {
    if (!data || !data.length) {
      data = [{ resource_id: 0, name: "No Agent Found" }];
      setResourceId({ resource_id: 0, name: "No Agent Found" });
      props.onChange(null, { resource_id: 0, name: "No Agent Found" });
      setResourceList(data);
      return;
    }
    let arr: any = data;

    if (props.showAllResource) {
      arr.unshift({ resource_id: 0, name: "All Resources" });
      setResourceId({ resource_id: 0, name: "All Resources" });
    } else {
      if (props.dataForResourceFiltering.resourceId) {
        const resourceObj = findResourceByResourceId(
          props.dataForResourceFiltering.resourceId,
          arr
        );
        // if(resourceObj)
        setResourceId(resourceObj, arr);
        if (!resourceObj) {
          props.onChange(null, { resource_id: 0, name: "No Agent Found" });
        } else {
          props.onChange(null, {
            resource_id: resourceObj?.resource_id || resourceObj?.id,
            name:
              resourceObj?.name ||
              resourceObj?.res_name ||
              resourceObj?.asset_id,
          });
        }
      } else {
        setResourceId(null);
      }
    }
    let tempArray: any = [];
    for (let index = 0; index < arr.length; index++) {
      tempArray.push({
        name:
          arr[index]?.name ||
          arr[index]?.res_name ||
          arr[index]?.asset_id ||
          arr[index]?.licence_plate,
        resource_id: arr[index]?.resource_id || arr[index]?.id,
      });
    }
    setResourceList(tempArray);
    // if(props.showAllResource){
    //   setResourceId("0");
    // }
  };
  const renderResourceList = () => {
    return (
      resourceList.length > 0 &&
      resourceList.map((resource: any, index: any) => {
        return (
          <MenuItem value={resource.resource_id}> {resource.name}</MenuItem>
        );
      })
    );
  };
  return (
    <Fragment>
      <FormControl
        variant="outlined"
        fullWidth={true}
        style={{ marginBottom: "10px", display: "flex", flexDirection: "row" }}
      >
        {/* {resourceList.length > 0?  */}
        <MaterialAutocomplete
          isMultiple={false}
          name={props.name}
          freeSolo={true}
          value={selectResourceId}
          disablePortal={props.disablePortal === false ? false : true}
          disableClearable={false}
          noOptionsText={"No agent found"}
          inputProps={props.inputProps}
          variant={props.variant}
          size="medium"
          id={"resource-outlined"}
          options={resourceList || []}
          labelName="name"
          onChange={(event: any, newValue: any) => {
            setResourceId(newValue);
            props.onChange(event, newValue);
          }}
          filterSelectedOptions
          onKeyDown={() => {}}
          textFieldClassName={classes.tagInputField}
          textFieldLabel={props.labelName}
          textFieldPlaceholder={"Agent"}
          textFieldMargin={"normal"}
          textFieldFullWidth={true}
        />

        {/* :null} */}
      </FormControl>
    </Fragment>
  );
};
export default ResourceListDropdown;
