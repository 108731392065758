import { Backdrop, Fade, makeStyles } from "@material-ui/core";
import React, {
  Fragment,
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from "react";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";
import Btn from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { useForm } from "react-hook-form";
import { ValidationService } from "../../utils/Validation";
import useToast from "../../components/Toast/hooks/useToast";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import { extensionService } from "./Extensions.endpoints";
import InfoIcon from "@material-ui/icons/Info";
import useLoader from "../../hooks/useLoader";
import DateTimePickerInput from "../../components/TemplatesCommonMethods/DateTimePickerInput";
import { TemplateDataTypeEnum } from "../../enum/Template.enum";
import DateFormatter from "../../utils/DateFormatter";
import DateTimeFormatter from "../../utils/DateFormatter";
import moment from "moment";
import Tooltip from "../../components/Tooltip/Tooltip";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  textField: {
    marginTop: "4px!important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "300px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
    marginBottom: "-15px!important",
  },
}));
let extensionPrice: any = 0;
const RecurringJobs = forwardRef((props: any, ref: any) => {
  // console.log(props, "uiuoiuiooui");
  extensionPrice = props.price;
  const { t, i18n } = useTranslation(["Extension"]);
  const [open, setOpen] = React.useState(false);
  const [operation, setOperation]: any = useState("add");
  const toaster = useToast();
  const ApiService = useApiService();
  const loader: any = useLoader();
  const configFormDetails: any = useRef();
  const [step, updateStep] = useState(1);
  const [time, setTime]: any = useState();
  const [id, setId]: any = useState();
  const [defaultCapacity, setDefaultCapacity]: any = useState();
  const [appData]: any = useAppContext();
  const [configData, setConfigData]: any = useState();
  const { register, handleSubmit, reset, errors } = useForm();
  const [previousTime, setPreviousTime] = useState();
  const obj = {
    time: {
      required: ValidationService.requiredValidator(),
    },
  };
  const classes = useStyles();
  useImperativeHandle(ref, () => ({
    handleOpen(operation?: any, configData?: any) {
      updateStep(1);

      if (operation === 1) {
        setOperation("edit");
        if (configData) {
          resetConfigData(configData);
          setConfigData(configData);
          setTime(configData.time);
          setPreviousTime(configData.time);
        } else if (props.config) {
          resetConfigData(props.config);
          setConfigData(props.config);
          setTime(props.config.time);
          setPreviousTime(props.config.time);
        }
        setOpen(true);
      } else {
        resetForm();
        setOperation("add");
        setOpen(true);
      }
    },
  }));
  const resetForm = () => {
    reset({
      time: "",
    });
    setTime("");
  };
  const onConfirm = () => {
    submitData(configFormDetails.current.value);
  };
  const resetConfigData = (data: any) => {
    reset({
      time: data.time,
    });
  };
  const openConfirmation = (data: any) => {
    let updateValue: any = false;

    if (data.time != configData.time) {
      updateValue = true;
    }
    if (!updateValue) {
      toaster.addToast({
        message: "Please update a value",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    configFormDetails.current.value = data;
    updateStep(2);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const submitData = (data: any) => {
    if (!time) {
      toaster.addToast({
        message: t("Team:Time cannot be empty"),
        timeout: 3000,
        type: "error",
      });
      return;
    }

    let recurringData: any = {};
    const headers = {
      access_token: appData.loginData.access_token,
    };
    let convertedTime;
    if (time == previousTime) {
      convertedTime = time;
    } else {
      convertedTime = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
        time,
        "HH:mm"
      );
    }

    let hello = moment(time).format("HH:MM");
    if (operation != "edit") {
      recurringData = {
        time: convertedTime,
      };
    } else {
      recurringData = {
        time: convertedTime,
      };
    }

    const reqData = {
      reqBody: recurringData,
      headers: headers,
    };

    loader.showLoader();
    return ApiService[operation === "edit" ? "put" : "post"](
      extensionService.createRecurring(reqData, operation === "edit" ? 1 : 0)
    )
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        handleClose();
        props.handleRecurringJobActivation();
        props.fetcRecurringDetail(true);
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} ref={configFormDetails}>
            <form onSubmit={handleSubmit(submitData)}>
              <h2 id="modal-title">
                {/* {(operation != "edit" ? "Add " : "Edit ") + "Details"} */}
                Configure
              </h2>
              <div style={{ textAlign: "left", paddingBottom: "12px" }}>
                <label style={{ textAlign: "left" }}>
                  Select Job Creation Time
                </label>
                <Tooltip
                  content={
                    "The recurring job will be created every day at this time."
                  }
                  direction={"top"}
                  style={{
                    backgroundColor: "rgba(97, 97, 97, 0.92)",
                    marginTop: "-10px",
                  }}
                  wrapperStyle={{
                    margin: "-5px 0px 0px 0px",
                    // marginRight:"px"
                    // width: "-webkit-fill-available",
                  }}
                  // hideOnClick={true}
                  // className={classes.tooltip}
                >
                  <InfoIcon />
                </Tooltip>
              </div>
              <DateTimePickerInput
                type={TemplateDataTypeEnum.TIME}
                refValue={register(obj.time)}
                label={"Select Job Creation Time"}
                value={operation == "edit" ? props?.config?.time : ""}
                onChange={(data: any) => {
                  setTime(data);
                }}
              ></DateTimePickerInput>
              <div>
                <Btn
                  className={classes.btn}
                  type={"Submit"}
                  // onClick={onConfirm}
                  btnText={t("Common:Submit")}
                >
                  {" "}
                </Btn>
                <Btn
                  onClick={() => {
                    setOpen(false);
                  }}
                  color={"default"}
                  type={"button"}
                  btnText={t("Common:Cancel")}
                >
                  {" "}
                </Btn>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default RecurringJobs;
