const DashboardMapMarkers = {
  getPickupIcon(color: String) {
    let template = [
      '<svg xmlns="http://www.w3.org/2000/svg" width="82" height="75" viewBox="0 0 82 75">',
      "<defs>",
      '<filter id="jp6j1j4uza" width="150%" height="122.8%" x="-25%" y="-11.4%" filterUnits="objectBoundingBox">',
      '<feGaussianBlur in="SourceGraphic" stdDeviation="2"/>',
      "</filter>",
      "</defs>",
      '<g fill="none" fill-rule="evenodd">',
      '<g fill-rule="nonzero">',
      '<g transform="translate(-758.000000, -336.000000) translate(760.673813, 338.000000)">',
      '<path fill="#000" d="M54.805 40.594c0 11.608-8.45 31.6-12 31.6-3.551 0-12-19.992-12-31.6s5.372-21.017 12-21.017c6.627 0 12 9.41 12 21.017z" filter="url(#jp6j1j4uza)" opacity=".3" transform="translate(42.804584, 45.885483) rotate(72.000000) translate(-42.804584, -45.885483)"/>',
      `<path fill="${color}" stroke="#FFF" stroke-width="2" d="M21.018-1c6.08 0 11.584 2.464 15.569 6.449 3.984 3.984 6.449 9.489 6.449 15.569 0 5.646-3.362 13.239-7.634 19.662-4.904 7.372-10.956 12.938-14.384 12.938-3.428 0-9.48-5.566-14.384-12.938C2.362 34.257-1 26.664-1 21.018c0-6.08 2.464-11.585 6.449-15.57C9.433 1.465 14.938-1 21.018-1z"/>`,
      '<circle cx="21" cy="21" r="17" fill="#FFF"/>',
      `<path fill="${color}" d="M19.85 31v-6.942h2.21c.954 0 1.872-.104 2.756-.312.884-.208 1.664-.537 2.34-.988.676-.45 1.214-1.044 1.612-1.781.399-.737.598-1.642.598-2.717 0-1.057-.186-1.946-.559-2.665-.372-.72-.884-1.3-1.534-1.742-.65-.442-1.412-.763-2.288-.962-.875-.2-1.815-.299-2.82-.299h-6.76V31h4.445zm1.872-10.478H19.85V16.18h1.95c.399 0 .785.026 1.157.078.373.052.707.156 1.001.312.295.156.529.373.702.65.174.277.26.633.26 1.066 0 .433-.086.793-.26 1.079-.173.286-.411.516-.715.689-.303.173-.645.295-1.027.364-.381.07-.78.104-1.196.104z"/>`,
      "</g>",
      "</g>",
      "</g>",
      "</svg>",
    ].join("\n");
    return template;
  },
  getDeliveryIcon(color: String) {
    let template = [
      '<svg xmlns="http://www.w3.org/2000/svg" width="82" height="75" viewBox="0 0 82 75">',
      "<defs>",
      '<filter id="ycfm13xkpa" width="150%" height="122.8%" x="-25%" y="-11.4%" filterUnits="objectBoundingBox">',
      '<feGaussianBlur in="SourceGraphic" stdDeviation="2"/>',
      "</filter>",
      "</defs>",
      '<g fill="none" fill-rule="evenodd">',
      '<g fill-rule="nonzero">',
      '<g transform="translate(-714.000000, -608.000000) translate(716.673813, 610.000000)">',
      '<path fill="#000" d="M54.805 40.594c0 11.608-8.45 31.6-12 31.6-3.551 0-12-19.992-12-31.6s5.372-21.017 12-21.017c6.627 0 12 9.41 12 21.017z" filter="url(#ycfm13xkpa)" opacity=".3" transform="translate(42.804584, 45.885483) rotate(72.000000) translate(-42.804584, -45.885483)"/>',
      `<path fill="${color}" stroke="#FFF" stroke-width="2" d="M21.018-1c6.08 0 11.584 2.464 15.569 6.449 3.984 3.984 6.449 9.489 6.449 15.569 0 5.646-3.362 13.239-7.634 19.662-4.904 7.372-10.956 12.938-14.384 12.938-3.428 0-9.48-5.566-14.384-12.938C2.362 34.257-1 26.664-1 21.018c0-6.08 2.464-11.585 6.449-15.57C9.433 1.465 14.938-1 21.018-1z"/>`,
      '<circle cx="21" cy="21" r="17" fill="#FFF"/>',
      `<path fill="${color}" d="M20.526 30c1.266 0 2.514-.178 3.744-.533 1.23-.355 2.336-.91 3.315-1.664.98-.754 1.768-1.716 2.366-2.886.598-1.17.897-2.561.897-4.173 0-1.733-.303-3.19-.91-4.368-.606-1.179-1.404-2.119-2.392-2.821s-2.114-1.205-3.38-1.508c-1.265-.303-2.548-.455-3.848-.455h-6.656V30h6.864zm-.416-3.848h-2.106V15.388h2.21c.763 0 1.504.087 2.223.26.72.173 1.36.464 1.924.871.564.407 1.014.953 1.352 1.638.338.685.507 1.547.507 2.587 0 1.023-.169 1.88-.507 2.574-.338.693-.788 1.248-1.352 1.664-.563.416-1.213.715-1.95.897-.736.182-1.503.273-2.3.273z"/>`,
      "</g>",
      "</g>",
      "</g>",
      "</svg>",
    ].join("\n");
    return template;
  },
  getServiceIcon(color: String) {
    let template = [
      '<svg xmlns="http://www.w3.org/2000/svg" width="82" height="75" viewBox="0 0 82 75">',
      "<defs>",
      '<filter id="9d5smzra6a" width="150%" height="122.8%" x="-25%" y="-11.4%" filterUnits="objectBoundingBox">',
      '<feGaussianBlur in="SourceGraphic" stdDeviation="2"/>',
      "</filter>",
      "</defs>",
      '<g fill="none" fill-rule="evenodd">',
      '<g fill-rule="nonzero">',
      '<g transform="translate(-880.000000, -336.000000) translate(882.673813, 338.000000)">',
      '<path fill="#000" d="M54.805 40.594c0 11.608-8.45 31.6-12 31.6-3.551 0-12-19.992-12-31.6s5.372-21.017 12-21.017c6.627 0 12 9.41 12 21.017z" filter="url(#9d5smzra6a)" opacity=".3" transform="translate(42.804584, 45.885483) rotate(72.000000) translate(-42.804584, -45.885483)"/>',
      `<path fill="${color}" stroke="#FFF" stroke-width="2" d="M21.018-1c6.08 0 11.584 2.464 15.569 6.449 3.984 3.984 6.449 9.489 6.449 15.569 0 5.646-3.362 13.239-7.634 19.662-4.904 7.372-10.956 12.938-14.384 12.938-3.428 0-9.48-5.566-14.384-12.938C2.362 34.257-1 26.664-1 21.018c0-6.08 2.464-11.585 6.449-15.57C9.433 1.465 14.938-1 21.018-1z"/>`,
      '<circle cx="21" cy="21" r="17" fill="#FFF"/>',
      `<path fill="${color}" d="M21.097 30.468c.901 0 1.777-.121 2.626-.364.85-.243 1.603-.615 2.262-1.118.659-.503 1.183-1.14 1.573-1.911.39-.771.585-1.686.585-2.743 0-.919-.182-1.686-.546-2.301-.364-.615-.823-1.131-1.378-1.547-.555-.416-1.17-.75-1.846-1.001-.676-.251-1.326-.481-1.95-.689-.433-.139-.823-.273-1.17-.403s-.646-.273-.897-.429c-.251-.156-.442-.338-.572-.546-.13-.208-.195-.468-.195-.78 0-.364.082-.663.247-.897.165-.234.368-.425.611-.572.243-.147.516-.247.819-.299.303-.052.602-.078.897-.078.572 0 1.166.143 1.781.429.615.286 1.114.672 1.495 1.157l2.834-2.99c-.815-.745-1.764-1.309-2.847-1.69s-2.145-.572-3.185-.572c-.867 0-1.725.108-2.574.325-.85.217-1.612.559-2.288 1.027-.676.468-1.222 1.066-1.638 1.794-.416.728-.624 1.603-.624 2.626 0 .832.134 1.538.403 2.119.269.58.624 1.075 1.066 1.482.442.407.945.741 1.508 1.001.563.26 1.14.485 1.729.676.607.19 1.148.368 1.625.533.477.165.875.347 1.196.546.32.2.568.42.741.663.173.243.26.537.26.884 0 .364-.07.676-.208.936-.139.26-.325.468-.559.624-.234.156-.507.269-.819.338-.312.07-.633.104-.962.104-.728 0-1.46-.186-2.197-.559-.737-.373-1.33-.836-1.781-1.391l-2.912 2.964c.815.85 1.837 1.504 3.068 1.963 1.23.46 2.505.689 3.822.689z"/>`,
      "</g>",
      "</g>",
      "</g>",
      "</svg>",
    ].join("\n");
    return template;
  },
  getDefaultMarker(color: String) {
    let template = [
      '<svg xmlns="http://www.w3.org/2000/svg" width="82" height="75" viewBox="0 0 82 75">',
      "<defs>",
      '<filter id="f9lenwbqna" width="150%" height="122.8%" x="-25%" y="-11.4%" filterUnits="objectBoundingBox">',
      '<feGaussianBlur in="SourceGraphic" stdDeviation="2"/>',
      "</filter>",
      "</defs>",
      '<g fill="none" fill-rule="evenodd">',
      '<g fill-rule="nonzero">',
      '<g transform="translate(-758.000000, -336.000000) translate(760.673813, 338.000000)">',
      '<path fill="#000" d="M54.805 40.594c0 11.608-8.45 31.6-12 31.6-3.551 0-12-19.992-12-31.6s5.372-21.017 12-21.017c6.627 0 12 9.41 12 21.017z" filter="url(#f9lenwbqna)" opacity=".3" transform="translate(42.804584, 45.885483) rotate(72.000000) translate(-42.804584, -45.885483)"/>',
      `<path fill="${color}" stroke="#FFF" stroke-width="2" d="M21.018-1c6.08 0 11.584 2.464 15.569 6.449 3.984 3.984 6.449 9.489 6.449 15.569 0 5.646-3.362 13.239-7.634 19.662-4.904 7.372-10.956 12.938-14.384 12.938-3.428 0-9.48-5.566-14.384-12.938C2.362 34.257-1 26.664-1 21.018c0-6.08 2.464-11.585 6.449-15.57C9.433 1.465 14.938-1 21.018-1z"/>`,
      '<circle cx="21" cy="21" r="17" fill="#FFF"/>',
      "</g>",
      "</g>",
      "</g>",
      "</svg>",
    ].join("\n");
    return template;
  },
  getDefaultMarkerWithoutShadow(color: String) {
    let template = [
      '<svg xmlns="http://www.w3.org/2000/svg" width="82" height="75" viewBox="0 0 82 75">',
      "<defs>",
      '<filter id="f9lenwbqna" width="150%" height="122.8%" x="-25%" y="-11.4%" filterUnits="objectBoundingBox">',
      '<feGaussianBlur in="SourceGraphic" stdDeviation="2"/>',
      "</filter>",
      "</defs>",
      '<g fill="none" fill-rule="evenodd">',
      '<g fill-rule="nonzero">',
      '<g transform="translate(-758.000000, -336.000000) translate(760.673813, 338.000000)">',
      `<path fill="${color}" stroke="#FFF" stroke-width="2" d="M21.018-1c6.08 0 11.584 2.464 15.569 6.449 3.984 3.984 6.449 9.489 6.449 15.569 0 5.646-3.362 13.239-7.634 19.662-4.904 7.372-10.956 12.938-14.384 12.938-3.428 0-9.48-5.566-14.384-12.938C2.362 34.257-1 26.664-1 21.018c0-6.08 2.464-11.585 6.449-15.57C9.433 1.465 14.938-1 21.018-1z"/>`,
      '<circle cx="21" cy="21" r="17" fill="#FFF"/>',
      "</g>",
      "</g>",
      "</g>",
      "</svg>",
    ].join("\n");
    return template;
  },
  getResourceIcon(color: string) {
    let template = [
      '<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">',
      "<defs>",
      '<filter x="-21.4%" y="-21.4%" width="142.9%" height="142.9%" filterUnits="objectBoundingBox" id="a">',
      '<feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>',
      '<feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>',
      '<feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>',
      '<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1"/>',
      "</filter>",
      '<circle id="b" cx="23" cy="50" r="7"/>',
      "</defs>",
      '<g transform="translate(-14 -41)" fill-rule="nonzero" fill="none">',
      '<use fill="#000" filter="url(#a)" xlink:href="#b"/>',
      `<circle stroke="#FFF" stroke-width="2" stroke-linejoin="square" fill="${color}" fill-rule="evenodd" cx="23" cy="50" r="6"/>`,
      "</g>",
      "</svg>",
    ].join("\n");
    return template;
  },
  getSelectedPickupIcon(color: String) {
    let template = [
      `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="83.06" height="76.955" viewBox="0 0 83.06 76.955">
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#007ebf"/>
          <stop offset="0.286" stop-color="#007fbf"/>
          <stop offset="0.344" stop-color="#0087ab"/>
          <stop offset="0.406" stop-color="#008f97"/>
          <stop offset="0.472" stop-color="#009585"/>
          <stop offset="0.54" stop-color="#009c74"/>
          <stop offset="0.613" stop-color="#22a164"/>
          <stop offset="0.689" stop-color="#32a657"/>
          <stop offset="0.773" stop-color="#3caa4e"/>
          <stop offset="0.868" stop-color="#40ac4a"/>
          <stop offset="1" stop-color="#41ad49"/>
        </linearGradient>
      </defs>
      <g id="P" transform="translate(8414.534 -257)">
        <path id="Path_963" data-name="Path 963" d="M54.805,40.594c0,11.608-8.45,31.6-12,31.6s-12-19.992-12-31.6,5.372-21.017,12-21.017S54.805,28.987,54.805,40.594Z" transform="translate(-8339.317 249.996) rotate(72)" opacity="0.3"/>
        <path id="Path_964" data-name="Path 964" d="M21.018-1A22.018,22.018,0,0,1,43.036,21.018c0,5.646-3.362,13.239-7.634,19.662-4.9,7.372-10.956,12.938-14.384,12.938S11.538,48.052,6.634,40.68C2.362,34.257-1,26.664-1,21.018A22.017,22.017,0,0,1,21.018-1Z" transform="translate(-8412.534 259)" stroke="#fff" stroke-width="2" fill="url(#linear-gradient)"/>
        <circle id="Ellipse_294" data-name="Ellipse 294" cx="17" cy="17" r="17" transform="translate(-8408.534 263)" fill="#fff"/>
        <path id="Path_970" data-name="Path 970" d="M5.435-6.919v5.67H1.5V-18.212H7.484a9.869,9.869,0,0,1,3.079.425,5.91,5.91,0,0,1,2.119,1.17,4.562,4.562,0,0,1,1.228,1.758,5.96,5.96,0,0,1,.4,2.189,6.435,6.435,0,0,1-.407,2.328,4.673,4.673,0,0,1-1.246,1.816,5.87,5.87,0,0,1-2.125,1.182,9.739,9.739,0,0,1-3.044.425Zm0-2.969H7.484A2.887,2.887,0,0,0,9.7-10.622a2.913,2.913,0,0,0,.675-2.049A3.064,3.064,0,0,0,10.2-13.73a2.156,2.156,0,0,0-.53-.821,2.382,2.382,0,0,0-.9-.53,3.965,3.965,0,0,0-1.286-.186H5.435Z" transform="translate(-8398.42 289.731)" fill="url(#linear-gradient)"/>
      </g>
    </svg>`,
    ].join("\n");
    return template;
  },
  getSelectedDeliveryIcon(color: String) {
    let template = [
      `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="83.06" height="76.955" viewBox="0 0 83.06 76.955">
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#007ebf"/>
          <stop offset="0.286" stop-color="#007fbf"/>
          <stop offset="0.344" stop-color="#0087ab"/>
          <stop offset="0.406" stop-color="#008f97"/>
          <stop offset="0.472" stop-color="#009585"/>
          <stop offset="0.54" stop-color="#009c74"/>
          <stop offset="0.613" stop-color="#22a164"/>
          <stop offset="0.689" stop-color="#32a657"/>
          <stop offset="0.773" stop-color="#3caa4e"/>
          <stop offset="0.868" stop-color="#40ac4a"/>
          <stop offset="1" stop-color="#41ad49"/>
        </linearGradient>
      </defs>
      <g id="D" transform="translate(8320.534 -251.129)">
        <path id="Path_966" data-name="Path 966" d="M54.805,40.594c0,11.608-8.45,31.6-12,31.6s-12-19.992-12-31.6,5.372-21.017,12-21.017S54.805,28.987,54.805,40.594Z" transform="translate(-8245.317 244.125) rotate(72)" opacity="0.3"/>
        <path id="Path_967" data-name="Path 967" d="M21.018-1A22.018,22.018,0,0,1,43.036,21.018c0,5.646-3.362,13.239-7.634,19.662-4.9,7.372-10.956,12.938-14.384,12.938S11.538,48.052,6.634,40.68C2.362,34.257-1,26.664-1,21.018A22.017,22.017,0,0,1,21.018-1Z" transform="translate(-8318.534 253.129)" stroke="#fff" stroke-width="2" fill="url(#linear-gradient)"/>
        <circle id="Ellipse_295" data-name="Ellipse 295" cx="17" cy="17" r="17" transform="translate(-8314.534 257.129)" fill="#fff"/>
        <path id="Path_969" data-name="Path 969" d="M16.891-9.737a8.9,8.9,0,0,1-.635,3.394A7.94,7.94,0,0,1,14.47-3.654a8.105,8.105,0,0,1-2.777,1.77,9.826,9.826,0,0,1-3.6.635H1.5V-18.212H8.09a9.746,9.746,0,0,1,3.6.64A8.222,8.222,0,0,1,14.47-15.8a7.878,7.878,0,0,1,1.787,2.684A8.863,8.863,0,0,1,16.891-9.737Zm-4.028,0a7.39,7.39,0,0,0-.326-2.27,4.85,4.85,0,0,0-.937-1.717,4.07,4.07,0,0,0-1.5-1.083,5.079,5.079,0,0,0-2.014-.378H5.458V-4.277H8.09A5.079,5.079,0,0,0,10.1-4.655a4.07,4.07,0,0,0,1.5-1.083,4.85,4.85,0,0,0,.937-1.717A7.432,7.432,0,0,0,12.863-9.737Z" transform="translate(-8304.959 283.86)" fill="url(#linear-gradient)"/>
      </g>
    </svg>`,
    ].join("\n");
    return template;
  },
  getSelectedServiceIcon(color: String) {
    let template = [
      `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="83.06" height="76.955" viewBox="0 0 83.06 76.955">
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#007ebf"/>
          <stop offset="0.286" stop-color="#007fbf"/>
          <stop offset="0.344" stop-color="#0087ab"/>
          <stop offset="0.406" stop-color="#008f97"/>
          <stop offset="0.472" stop-color="#009585"/>
          <stop offset="0.54" stop-color="#009c74"/>
          <stop offset="0.613" stop-color="#22a164"/>
          <stop offset="0.689" stop-color="#32a657"/>
          <stop offset="0.773" stop-color="#3caa4e"/>
          <stop offset="0.868" stop-color="#40ac4a"/>
          <stop offset="1" stop-color="#41ad49"/>
        </linearGradient>
      </defs>
      <g id="S" transform="translate(8414.534 -257)">
        <path id="Path_963" data-name="Path 963" d="M54.805,40.594c0,11.608-8.45,31.6-12,31.6s-12-19.992-12-31.6,5.372-21.017,12-21.017S54.805,28.987,54.805,40.594Z" transform="translate(-8339.317 249.996) rotate(72)" opacity="0.3"/>
        <path id="Path_964" data-name="Path 964" d="M21.018-1A22.018,22.018,0,0,1,43.036,21.018c0,5.646-3.362,13.239-7.634,19.662-4.9,7.372-10.956,12.938-14.384,12.938S11.538,48.052,6.634,40.68C2.362,34.257-1,26.664-1,21.018A22.017,22.017,0,0,1,21.018-1Z" transform="translate(-8412.534 259)" stroke="#fff" stroke-width="2" fill="url(#linear-gradient)"/>
        <circle id="Ellipse_294" data-name="Ellipse 294" cx="17" cy="17" r="17" transform="translate(-8408.534 263)" fill="#fff"/>
        <path id="Path_1002" data-name="Path 1002" d="M11.65-14.512a1.587,1.587,0,0,1-.394.45.881.881,0,0,1-.531.15,1.3,1.3,0,0,1-.619-.181q-.331-.181-.75-.406a6.224,6.224,0,0,0-.956-.406,3.826,3.826,0,0,0-1.225-.181,2.636,2.636,0,0,0-1.769.506,1.722,1.722,0,0,0-.581,1.369,1.261,1.261,0,0,0,.35.913,3.017,3.017,0,0,0,.919.625,9.848,9.848,0,0,0,1.3.481q.731.219,1.494.488a13.359,13.359,0,0,1,1.494.631,4.981,4.981,0,0,1,1.3.925A4.317,4.317,0,0,1,12.6-7.781a4.867,4.867,0,0,1,.35,1.944,6.334,6.334,0,0,1-.437,2.362,5.532,5.532,0,0,1-1.269,1.919A5.889,5.889,0,0,1,9.194-.269,7.67,7.67,0,0,1,6.425.2,8.875,8.875,0,0,1,4.694.025,10.281,10.281,0,0,1,2.988-.469a9.9,9.9,0,0,1-1.55-.756A6.586,6.586,0,0,1,.175-2.2l1.25-1.975a1.017,1.017,0,0,1,.388-.362,1.1,1.1,0,0,1,.538-.138,1.423,1.423,0,0,1,.756.238q.381.237.863.525a7.019,7.019,0,0,0,1.106.525A4.148,4.148,0,0,0,6.55-3.15a2.808,2.808,0,0,0,1.787-.506,1.928,1.928,0,0,0,.637-1.606A1.518,1.518,0,0,0,8.625-6.3a2.7,2.7,0,0,0-.919-.663,8.465,8.465,0,0,0-1.294-.463q-.725-.2-1.488-.444a10.883,10.883,0,0,1-1.488-.6,4.694,4.694,0,0,1-1.294-.938,4.387,4.387,0,0,1-.919-1.45A5.757,5.757,0,0,1,.875-13a5.087,5.087,0,0,1,.413-2A5.163,5.163,0,0,1,2.5-16.738a5.962,5.962,0,0,1,1.963-1.219,7.248,7.248,0,0,1,2.663-.456,9.984,9.984,0,0,1,1.631.131,8.576,8.576,0,0,1,1.506.388,8.272,8.272,0,0,1,1.331.613,6.138,6.138,0,0,1,1.106.806Z" transform="translate(-8398.466 289)" fill="url(#linear-gradient)"/>
      </g>
    </svg>`,
    ].join("\n");
    return template;
  },
};
export default DashboardMapMarkers;
