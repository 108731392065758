import * as actionTypes from "./actionTypes";

export const onLoginResponse = (data: any) => {
  return {
    type: actionTypes.LOGIN_RESPONSE,
    data: { ...data },
  };
};

export const loginViaAccessToken = (data: any) => {
  return {
    type: actionTypes.LOGIN_VIA_ACCESS_TOKEN,
    data: { ...data },
  };
};
export const onPreLoginData = (data: any) => {
  return {
    type: actionTypes.PRE_LOGIN_DATA,
    data: { ...data },
  };
};
export const onLogout = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};

export const onLoader = (data: any) => {
  return {
    type: actionTypes.LOADER,
    data: { ...data },
  };
};
export const onDropdownChangeHeader = (data: any) => {
  return {
    type: actionTypes.ON_DROPDOWN_CHANGE_HEADER,
    data: { ...data },
  };
};
