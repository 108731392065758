import {
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, {
  Fragment,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Btn from "../../../components/Button/Button";
import { extensionService } from "../Extensions.endpoints";
import useToast from "../../../components/Toast/hooks/useToast";
import useApiService from "../../../services/api.service";
import useLoader from "../../../hooks/useLoader";
import { useAppContext } from "../../../AppContext/App.context";
import { SettingsInputAntenna } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "500px",
    outline: "none",
    borderRadius: "10px",
  },
  btn: {
    margin: theme.spacing(2),
    marginLeft: "0px",
  },
  dropdown: {
    textAlign: "left",
  },
}));

const AgentWalletConfig: any = forwardRef((props: any, ref: any) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(["Wallet"]);
  const [operation, setOperation]: any = useState("add");
  const toaster = useToast();
  const ApiService = useApiService();
  const loader: any = useLoader();
  const [appData]: any = useAppContext();
  const [priority, setpriority] = useState<any>(`1`);
  const [open, setOpen] = React.useState(false);
  const [teamList, setTeamList]: any = useState([]);
  const [walletConfigData, setWalletConfigData]: any = useState();
  const [includeEarning, setIncludeEarning]: any = useState(true);
  const [multiplePaymentMethod, setMultiplePaymentMethod]: any = useState(
    false
  );

  useImperativeHandle(ref, () => ({
    handleOpen(opr: any) {
      // getTeamList();
      if (opr === 1) {
        setOperation("edit");
        setOpen(true);
        getWalletConfigData();
      } else {
        // resetform();
        setOperation("add");
        setOpen(true);
      }
    },
  }));

  const resetWalletConfigData = (walletConfigData: any) => {
    setpriority(`${walletConfigData?.priority}`);
    if (walletConfigData) {
      // setlunchBreak(true);
      setIncludeEarning(walletConfigData?.include_earning ? true : false);
      setMultiplePaymentMethod(
        walletConfigData?.multiple_payment_method ? true : false
      );
    }

    // if (walletConfigData?.multiple_payment_method) {
    //   setMultiplePaymentMethod(walletConfigData?.multiple_payment_method);
    // }

    // props.roConfigData(data)
    setOpen(true);
  };

  const handleClose = () => {
    setTeamList([]);
    setOpen(false);
    // resetform();
  };
  const submitAgentWallet = () => {
    const request: any = {
      priority: Number(priority),
    };

    let agentWalletConfig: any = {
      include_earning: includeEarning,
      multiple_payment_method: multiplePaymentMethod,
    };
    if (operation === "edit") {
      agentWalletConfig["id"] = walletConfigData?.id;
    }

    const headers = {
      access_token: appData.loginData.access_token,
    };

    const reqData = {
      reqBody: agentWalletConfig,
      headers: headers,
    };

    loader.showLoader();
    return ApiService[operation === "edit" ? "put" : "post"](
      extensionService.agentWalletConfig(reqData, operation === "edit" ? 1 : 0)
    )
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        handleClose();
        props.handleAgentWallet();
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  const getWalletConfigData = async () => {
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const request: any = {};
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    return ApiService["get"](extensionService.getWalletconfig(reqData))
      .then((res: any) => {
        setWalletConfigData(res.data[0]);
        resetWalletConfigData(res.data[0]);
        console.log(res, "responce....");
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  return (
    <div>
      <Fragment>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          className={classes.modal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h2 id="modal-title">
                {/* {operation === "add" ? "Add Details" : "Update Details"} */}
                {t("Wallet:Configure")}
              </h2>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  {t("Wallet:Do you want to include agent earning in wallet?")}
                  <RadioGroup
                    className="NewRoute__ro-priority__radio-group"
                    name={"priority"}
                    onChange={(evt: any) => {
                      setIncludeEarning(
                        evt.target.value === "false" ? false : true
                      );
                    }}
                    value={includeEarning}
                    row
                    style={{ marginTop: "8px" }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="primary" />}
                      label={t("Wallet:Yes")}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label={t("Wallet:No")}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  {t("Wallet:Do you have multiple payment method in postpaid?")}
                  <RadioGroup
                    className="NewRoute__ro-priority__radio-group"
                    name={"priority"}
                    onChange={(evt: any) => {
                      setMultiplePaymentMethod(
                        evt.target.value === "false" ? false : true
                      );
                    }}
                    value={multiplePaymentMethod}
                    row
                    style={{ marginTop: "8px" }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="primary" />}
                      label={t("Wallet:Yes")}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label={t("Wallet:No")}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>

              <div style={{}}>
                <Btn
                  className={classes.btn}
                  type={"Submit"}
                  btnText={
                    t("Wallet:Submit") // operation === "add" ? t("Common:Submit") : t("Common:Update")
                  }
                  onClick={submitAgentWallet}
                >
                  {" "}
                </Btn>
                <Btn
                  onClick={() => {
                    setOpen(false);
                  }}
                  color={"default"}
                  type={"button"}
                  btnText={t("Wallet:Cancel")}
                >
                  {" "}
                </Btn>
              </div>
            </div>
          </Fade>
        </Modal>
      </Fragment>
    </div>
  );
});

export default AgentWalletConfig;
