const ChatEndpoints = {
  createConversation(reqData: any) {
    return {
      url: "chat/chat/createConversation",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getChatList(reqData: any) {
    return {
      url: "chat/chat/conversationList",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getConversation(reqData: any) {
    return {
      url: "chat/chat/conversationHistory",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  closeChat(reqData: any) {
    return {
      url: "chat/chat/closeConversation",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  conversationStatus(reqData:any){
    return {
      url:"chat/chat/conversation/status",
      data:reqData["reqBody"],
      headers:reqData["headers"],
    };
  },
};

export default ChatEndpoints;
