import React, {
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Btn from "../../../components/Button/Button";
import PageHeading from "../../../components/PageHeading/PageHeading";
import Input from "../../../components/Input/Input";
import Autocomplete from "../../../components/Autocomplete/Autocomplete";
import { Controller, useForm } from "react-hook-form";
import { ValidationService } from "../../../utils/Validation";
import { useAppContext } from "../../../AppContext/App.context";
import useLoader from "../../../hooks/useLoader";
import useToast from "../../../components/Toast/hooks/useToast";
import { extensionService } from "../Extensions.endpoints";
import useApiService from "../../../services/api.service";
import InfoIcon from "@material-ui/icons/Info";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import DateTimeFormatter from "../../../utils/DateFormatter";
import { extensionList } from "../../../constants/extensionList";
import DateTimePickerInput from "../../../components/TemplatesCommonMethods/DateTimePickerInput";
import AutoRoutingConfig from "./AutoRoutingConfig";
import CommonEndpoints from "../../../services/commonApi.service";
import Tooltip from "../../../components/Tooltip/Tooltip";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "500px",
    outline: "none",
    borderRadius: "10px",
  },
  btn: {
    margin: theme.spacing(2),
    marginLeft: "0px",
  },
  dropdown: {
    textAlign: "left",
  },
}));
const RouteOptimisationConfig = forwardRef((props: any, ref: any) => {
  // console.log("config data -> ", props?.roConfigData);
  const { t, i18n } = useTranslation(["Extension"]);
  const ApiService = useApiService();
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const [appData]: any = useAppContext();
  const loader: any = useLoader();
  const [key, setkey]: any = useState("");
  const toaster = useToast();
  const [configData, setConfigData]: any = useState();
  const [operation, setOperation]: any = useState();
  const [breaktime, setBreaktime]: any = useState();
  const [lunchBreak, setlunchBreak]: any = useState(false);
  const [priority, setpriority] = useState<any>(`1`);
  const [breakDuration, setbreakDuration]: any = useState();
  const [teamList, setTeamList]: any = useState([]);
  const [otherReqItems, setOtherReqItems] = useState<any>();
  const [autoRoutingData, setAutoRoutingData]: any = useState({
    shouldDisplay: false,
    data: {},
  });

  useImperativeHandle(ref, () => ({
    handleOpen(opr: any) {
      getTeamList();
      if (opr === 1) {
        setOperation("edit");
        resetConfigData(props.roConfigData);
        setConfigData(props.roConfigData);
      } else {
        resetform();
        setOperation("add");
        setOpen(true);
      }
    },
  }));

  const resetConfigData = (data: any) => {
    setpriority(`${data?.priority}`);
    if (data?.break_time) {
      // setlunchBreak(true);
      setBreaktime(data?.break_time);
    }

    if (data?.break_duration) {
      setbreakDuration(data?.break_duration);
    }
    if (data?.routific_key) {
      setkey(data.routific_key);
    }
    if (data.auto_routing) {
      getTeamList();
    }
    // props.roConfigData(data)
    setOpen(true);
  };

  const getTeamList = async () => {
    let headers = {
      access_token: appData.loginData.access_token,
    };
    let qureyParams = {
      limit: 1000,
      skip: 0,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(CommonEndpoints.getTeamList(reqData));
    setTeamList(res.data.data);
  };

  const resetform = () => {
    setConfigData({});
    setOperation("");
    setbreakDuration("");
    setkey("");
    setBreaktime("");
    setAutoRoutingData({ data: {}, shouldDisplay: false });
    setpriority(`1`);
    setlunchBreak(false);
  };

  const handleClose = () => {
    setTeamList([]);
    setOpen(false);
    resetform();
  };

  const validateInput = (
    message: string,
    fieldName: any,
    otherDefaultData: any,
    baseObj: any
  ) => {
    if (
      otherDefaultData?.data[fieldName] ||
      (otherDefaultData?.data[fieldName] === 0 && fieldName === "job_type")
    ) {
      baseObj[fieldName] = parseInt(otherDefaultData?.data[fieldName]);
      return true;
    } else {
      toaster.addToast({
        message: t(`Extension:${message}`),
        timeout: 3000,
        type: "error",
      });
      return false;
    }
  };

  const submitROconfig = (data: any) => {
    if (priority < 0) {
      toaster.addToast({
        message: t("Extension:Please select Route Optimization Priority"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (priority == 1 && !key) {
      toaster.addToast({
        message: t("Team:Please enter Routing Key"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    const request: any = {
      priority: Number(priority),
    };
    if (priority == 1) {
      request.routific_key = key;
    }

    if (breaktime && lunchBreak) {
      request.break_time = DateTimeFormatter.convert12HoursFormatTo24Format(
        breaktime
      );
    } else {
      request.break_time = "";
    }
    if (breakDuration && lunchBreak) {
      request.break_duration = Number(breakDuration);
    } else {
      request.break_duration = 0;
    }

    if (operation === "edit") {
      request.configuration_id = configData.configuration_id;
    } else {
      request.extension_id = extensionList.routeOptimization.id;
      request.price = Number(props.price);
    }
    let validationRespose: any = false;

    if (request.priority == 0) {
      const inputFields = [
        {
          message: "Job type is required",
          fieldName: "job_type",
          otherReqItems,
          request,
        },
        {
          message: "Start location is required",
          fieldName: "start_location",
          otherReqItems,
          request,
        },
        {
          message: "End location is required",
          fieldName: "end_location",
          otherReqItems,
          request,
        },
        {
          message: "Min job to route is required",
          fieldName: "min_jobs_to_route",
          otherReqItems,
          request,
        },
      ];

      if (autoRoutingData.data?.auto_routing) {
        for (let i = 0; i < inputFields.length; i++) {
          validationRespose = validateInput(
            inputFields[i].message,
            inputFields[i].fieldName,
            otherReqItems,
            request
          );
          if (!validationRespose) {
            break;
          }
        }
      } else {
        request.auto_routing = false;
        validationRespose = true;
      }
    }

    if (
      !validationRespose &&
      autoRoutingData.shouldDisplay &&
      request.priority === 0
    )
      return;

    if (otherReqItems?.data?.team_id) {
      request.team_id = otherReqItems?.data.team_id;
    }

    const headers = {
      access_token: appData.loginData.access_token,
    };

    const finalObj = { ...otherReqItems?.data, ...request };

    const {
      auto_routing,
      job_type,
      min_jobs_to_route,
      start_location,
      team_id,
      end_location,
      routific_key,
      break_duration,
      break_time,
      configuration_id,
      capacity,
      extension_id,
      price,
    } = finalObj;

    const x = {
      priority: parseInt(finalObj.priority),
      auto_routing: auto_routing ? true : false,
      min_jobs_to_route: parseInt(min_jobs_to_route),
      capacity: parseInt(capacity),
    };

    if (x.min_jobs_to_route < 10 && x.priority == 0 && x.auto_routing) {
      toaster.addToast({
        message: t(
          `Extension: Min job to route should be greater than or equal to 10`
        ),
        timeout: 3000,
        type: "error",
      });
      return;
    }

    if (x.min_jobs_to_route > 30 && x.priority == 0 && x.auto_routing) {
      toaster.addToast({
        message: t(
          `Extension: Min job to route should be less than or equal to 30`
        ),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    let reqParam: any = { auto_routing, ...request };

    if (operation === "add") {
      if (x.priority == 0) {
        if (!auto_routing) {
          reqParam = {
            auto_routing: false,
            priority: x.priority,
            extension_id,
            price,
          };
        } else {
          reqParam = {
            job_type,
            price,
            start_location,
            team_id: team_id ? team_id.team_id || team_id : null,
            extension_id,
            end_location,
            ...x,
          };
        }
      } else {
        reqParam = {
          priority: x.priority,
          auto_routing: false,
          routific_key,
          price,
          extension_id,
          break_duration,
          break_time,
        };
      }
    } else {
      if (x.priority == 0) {
        if (!auto_routing) {
          reqParam = {
            auto_routing: false,
            configuration_id,
            priority: x.priority,
          };
        } else {
          // // console.log()
          reqParam = {
            job_type,
            start_location,
            configuration_id,
            team_id: team_id ? team_id.team_id || team_id : null,
            end_location,
            ...x,
          };
          // console.log(reqParam);
        }
      } else {
        reqParam = {
          priority: x.priority,
          auto_routing: false,
          routific_key,
          break_duration,
          break_time,
          configuration_id,
        };
      }
    }

    const reqData = {
      reqBody: reqParam,
      headers: headers,
    };
    // return;
    loader.showLoader();
    return ApiService[operation === "add" ? "post" : "put"](
      extensionService.ROconfig(reqData, operation === "add" ? 0 : 1)
    )
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        handleClose();
        localStorage.setItem("firstTimeRoActivation", "1");
        props.handleROActivation();
        return;
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
        return;
      });
  };
  const obj = {
    break_time: {
      required: ValidationService.requiredValidator(),
    },
    break_duration: {
      // required: ValidationService.requiredValidator(),
    },
    priority: {},
  };

  useEffect(() => {
    if (props.roConfigData?.break_duration && props.roConfigData?.break_time) {
      setlunchBreak(true);
    }
  }, [props.roConfigData]);

  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="modal-title">
              {operation === "add" ? "Add Details" : "Update Details"}
            </h2>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "left" }}>
                Route Optimization Criteria
                <RadioGroup
                  className="NewRoute__ro-priority__radio-group"
                  name={"priority"}
                  onChange={(evt: any) => {
                    setpriority(evt.target.value);
                    if (evt.target.value == 1) {
                      setlunchBreak(false);
                    } else {
                      setlunchBreak(true);
                    }
                  }}
                  value={priority}
                  row
                  style={{ marginTop: "8px" }}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Time"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label="Distance"
                  />
                </RadioGroup>
              </Grid>
              {priority == 1 && (
                <Grid
                  item
                  xs={12}
                  style={{ display: "inline-flex", alignItems: "center" }}
                >
                  <Grid item xs={9}>
                    <Input
                      label="Routing Key*"
                      type="text"
                      value={key}
                      onchange={(e: any) => setkey(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "initial" }}>
                    {" "}
                    <Tooltip
                      direction={"left"}
                      style={{
                        width: "200px",
                        "white-space": "break-spaces",
                        backgroundColor: "rgba(97, 97, 97, 0.92)",
                        marginBottom: "-8px",
                        textAlign: "left",
                      }}
                      content={`Please get in touch with the support team to get this key`}
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
              {priority == 1 && (
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "left",
                    marginLeft: "-15px",
                    marginTop: "-10px",
                  }}
                >
                  <FormControlLabel
                    value={lunchBreak}
                    control={
                      <Checkbox
                        color="primary"
                        checked={lunchBreak}
                        onChange={(e: any) => {
                          setlunchBreak(e.target.checked);
                        }}
                      />
                    }
                    label="Lunch Break"
                    labelPlacement="start"
                  />
                </Grid>
              )}

              {lunchBreak && priority == 1 && (
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{ marginLeft: "0px" }}
                >
                  <Grid item xs={6}>
                    <DateTimePickerInput
                      type={5}
                      name={"break_time"}
                      label={"Break Time*"}
                      value={breaktime || ""}
                      onChange={(data: any, format: string) => {
                        if (data) {
                          setBreaktime(
                            DateTimeFormatter.changeOnlyFormatInSameTimeZone(
                              data,
                              format
                            )
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      type="text"
                      onkeypress={() => {}}
                      id="break_duration"
                      value={breakDuration}
                      name="break_duration"
                      onchange={(e: any) => {
                        setbreakDuration(e.target.value);
                      }}
                      label={t("Extension:Break Duration") + "*"}
                    />
                  </Grid>
                </Grid>
              )}

              {priority == 0 && (
                <AutoRoutingConfig
                  teamlist={teamList}
                  operation={operation}
                  getTeams={getTeamList}
                  setOtherReqItems={setOtherReqItems}
                  otherReqItems={otherReqItems}
                  autoRoutingData={autoRoutingData}
                  setAutoRoutingData={setAutoRoutingData}
                  defaultData={props?.roConfigData}
                />
              )}
            </Grid>

            <div>
              <Btn
                className={classes.btn}
                type={"Submit"}
                btnText={
                  operation === "add" ? t("Common:Submit") : t("Common:Update")
                }
                onClick={submitROconfig}
              >
                {" "}
              </Btn>
              <Btn
                onClick={() => {
                  setOpen(false);
                }}
                color={"default"}
                type={"button"}
                btnText={t("Common:Cancel")}
              >
                {" "}
              </Btn>
            </div>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default RouteOptimisationConfig;
