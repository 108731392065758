import React, { useEffect, useState, Fragment, useRef } from "react";

import { useAppContext } from "../../AppContext/App.context";

import useApiService from "../../services/api.service";
import useLoader from "../../hooks/useLoader";
import CommonEndpoints from "../../services/commonApi.service";
import { TaskDetail as TaskDetailEndpoint } from "./TaskDetail.endpoints";
import classes from "./TaskDetail.module.scss";
import TaskDetailMap from "./TaskDetailMap";
import TaskDetailDataView from "./TaskDetailDataView";
import { extensionList } from "../../constants/extensionList";
import useLocalStorage from "../../hooks/localStorage";
// import Header from "../Header/Header";
const TaskDetail = (props: any) => {
  const ApiService = useApiService();
  const [appData]: any = useAppContext();
  const loader: any = useLoader();
  const access_token = appData.loginData.access_token;
  const [activeTab, setActiveTab] = useState();
  const [polyline, setPolyline]: any = useState();
  let activatedExtension: any = useLocalStorage.getItem("activatedExtension");

  const [taskDetail, setTaskDetail]: any = useState();

  useEffect(() => {
    loader.showLoader();
    getTaskDetailFromTaskId(props.taskId);
  }, [props.taskId]);

  const getTaskDetailFromTaskId = async (taskId: number) => {
    setPolyline([]);
    const headers = {
      access_token: access_token,
    };

    const qureyParams = {
      task_id: taskId,
      // limit: 100,
      // skip: 0,
      fetch_geofence_details: true,
      fetch_team_details: true,
      fetch_resource_details: true,
      fetch_template_details: true,
      fetch_task_history: true,
      fetch_earning_details: true,
      fetch_pricing_details: true,
      fetch_transaction_details: true,
      ignore_assignment_history: true,
      fetch_subscription_details: true,
      fetch_tags_details: true,
      // fetch_location_history: true,
      fetch_order_details: true,
    };
    // if (activatedExtension.includes(extensionList.customerSubscription.id)) {
    //   Object.assign(qureyParams, {  });
    // }
    const reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      CommonEndpoints.getTaskDetails(reqData)
    );
    setTaskDetail({
      ...res.data.data,
      tags: props?.tagsBarcodeData?.tags || res?.data?.data?.tags || "",
      barcode: props?.tagsBarcodeData?.barcode || "",
      assignmentStatus: props?.tagsBarcodeData?.assignmentStatus,
    });

    await getPolyline(taskId);
    loader.hideLoader();
  };
  const getPolyline = async (taskId: any) => {
    const headers = {
      access_token: access_token,
    };

    const qureyParams = {
      task_id: taskId,
    };
    const reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    await ApiService.get(TaskDetailEndpoint.getPolyline(reqData))
      .then((res: any) => {
        setPolyline(res.data.legs);
      })
      .catch((err: any) => {});
  };

  const getSelectedTab = (data: any) => {
    setActiveTab(data);
  };
  return (
    <Fragment>
      {taskDetail ? (
        <div className={classes["task-detail"]}>
          <div className={classes["task-detail__map-view"]}>
            <TaskDetailMap
              taskDetail={taskDetail}
              selectedTab={activeTab}
              polyline={polyline}
            />
          </div>
          <div className={classes["task-detail__data-view"]}>
            {/* {taskDetail ? ( */}
            <TaskDetailDataView
              openTaskDetailForSelectedTask={getTaskDetailFromTaskId}
              onCloseTaskDetail={props.onCloseTaskDetail}
              taskDetail={taskDetail}
              selectedTab={getSelectedTab}
            ></TaskDetailDataView>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
export default TaskDetail;
