import React, { Suspense, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import useToast from "../../components/Toast/hooks/useToast";
import { extensionList } from "../../constants/extensionList";
import Btn from "../../components/Button/Button";
import { extensionService } from "./Extensions.endpoints";
import AlertDialog from "../../components/Dialog/Dialog";
import CommonEndpoints from "../../services/commonApi.service";
import { Subject } from "rxjs";
import TwilioDetails from "./TwilioDetails";
import OTPVerification from "./OTPVerification/OTPVerification";
import RouteOptimisationConfig from "./RouteOptimisation/RouteOptimisationConfig";
import TwilioMasking from "./TwilioMasking";
import StripeConnect from "./StripeConnect";
import AgentReferralDetails from "./AgentreferralDetails";
import CapacityManagement from "./CapacityManagement";
import RecurringJobs from "./RecurringJobs";
import AgentVerification from "../Resources/List/AgentVerification";
import IdentityVerfication from "./IdentityVerfication";
import SosConfiguration from "./SOSConfiguration";

import SettingsIcon from "@material-ui/icons/Settings";
import configSettings from "../../assets/configSettings.png";
import useLocalStorage from "../../hooks/localStorage";
import AgentWalletConfig from "./AgentWallet/AgentWalletConfig";
const useStyles = makeStyles((theme) => ({}));

const subject = new Subject();
export const extensionSubject = {
  subscribe: (update: any) => subject.subscribe(update),
  unsubscribe: () => subject.unsubscribe(),
  emit: (extensionData: any) => {
    subject.next(extensionData);
  },
};
let capacityRemoveId: any, sosRemoveId: any, configDataByApi: any;
const ExtensionActionButton = (props: any) => {
  const { t, i18n } = useTranslation([
    "Common",
    "Geofence",
    "Extensions",
    "Wallet",
  ]);
  const [appData]: any = useAppContext();
  const ApiService = useApiService();
  let params: any = useParams();
  const toaster = useToast();
  const enableShowMoreDescription = () => {};
  // let ;;
  // Refs
  const twilioDetailsRef: any = useRef();
  const agentReferralRef: any = useRef();
  const OTPVerificationRef: any = useRef();
  const twilioMaskingRef: any = useRef();
  const stripeConnectRef: any = useRef();
  const capacityManagementRef: any = useRef();
  const sosConfigurationRef: any = useRef();
  const identityVerficationRef: any = useRef();

  const routeOptimizationRef: any = useRef();
  const agentWalletRef: any = useRef();
  const deactivateExtensionRef: any = useRef();
  const activateExtensionRef: any = useRef();
  const recurringJobRef: any = useRef();

  // Constants
  const access_token = appData.loginData.access_token;
  let extensionType: any = props.extensionType;
  const [showConfig, setshowConfig] = useState(props.showConfig);
  let [extension, setExtension] = useState(props.extension);
  const [isActivated, setIsActivated] = useState(props.isActivated);
  let [roConfigData, setRoConfigData]: any = useState(props.roConfigData);
  let [agentWalletConfigData, setAgentWalletConfigData]: any = useState(
    props.agentWalletConfig
  );
  console.log(agentWalletConfigData, "agentWalletConfigData");

  const [agentReferralReqId, setAgentReferralReqId] = useState(null);
  // const[capacityRemoveId,setCapacityRemoveId]:any =useState();
  useEffect(() => {
    if (!props.extension) {
      return;
    }
    if (props.configData?.id) {
      setAgentReferralReqId(props.configData?.id);
    }

    if (roConfigData === "" && extension.is_active === 1) {
      switch (extension.extension_id) {
        case extensionList.routeOptimization.id:
          {
            getConfigData();
          }
          break;
        case extensionList.agentReferral.id:
          {
            getAgentReferralExtensionDetails(19);
          }
          break;
      }
    }
    // if (params.extensionId) {
    //   switch (parseInt(params.extensionId)) {
    //     case 19:
    //       getAgentReferralExtensionDetails(params.extensionId);
    //       break;

    //     default:
    //       break;
    //   }
    // }
  }, []);

  const getAgentReferralExtensionDetails = (id: any) => {
    let header: any = {
      access_token: access_token,
    };
    let request: any = {
      id: 1,
      extension_id: id,
    };
    let reqData = {
      reqBody: {},
      headers: header,
    };

    // isActivated

    ApiService.get(extensionService.getAgentReferralExtentionDetails(reqData))
      .then(async function (response: any) {
        setAgentReferralReqId(response.data[0].id);
        // setAgentReferralResponseData(response.data[0]);
        // setshowConfig(true);
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  // Events
  const clickOnExtensionAction = (config?: any) => {
    if (!isActivated) {
      switch (extension.extension_id) {
        case extensionList.twilio.id:
          twilioDetailsRef.current.handleOpen();
          return;
        case extensionList.stripeConnect.id: {
          stripeConnectRef.current.handleOpen();
          return;
        }
        case extensionList.recurring.id: {
          recurringJobRef.current.handleOpen();
          return;
        }
        case extensionList.identityVerification.id: {
          identityVerficationRef.current.handleOpen();
          return;
        }
        case extensionList.capacityManagement?.id: {
          capacityManagementRef.current.handleOpen();
          return;
        }
        case extensionList.saveOurSouls?.id: {
          sosConfigurationRef.current.handleOpen();
          return;
        }
        case extensionList.agentReferral.id: {
          agentReferralRef.current.showPrefillData(null);
          agentReferralRef.current.handleOpen();
          return;
        }
        case extensionList.routeOptimization.id: {
          routeOptimizationRef.current.handleOpen();
          return;
        }
        case extensionList.agentWallet.id: {
          agentWalletRef.current.handleOpen();
          return;
        }
        case extensionList.twilioMasking.id: {
          twilioMaskingRef.current.handleOpen();
          return;
        }
        case extensionList.OTPVerification.id: {
          OTPVerificationRef.current.handleOpen();
          return;
        }
        default:
          activateExtensionRef.current.handleClickOpen();
      }
    } else if (config == true) {
      switch (extension.extension_id) {
        case extensionList.twilio.id:
          twilioDetailsRef.current.handleOpen(1, configDataByApi);
          return;
        case extensionList.agentReferral.id:
          agentReferralRef.current.showPrefillData(
            props.configData ? props.configData : configDataByApi
          );
          agentReferralRef.current.handleOpen(1, configDataByApi);
          return;
        case extensionList.stripeConnect.id: {
          stripeConnectRef.current.handleOpen(1, configDataByApi);
          return;
        }
        case extensionList.recurring.id: {
          recurringJobRef.current.handleOpen(1, configDataByApi);
          return;
        }
        case extensionList.capacityManagement.id: {
          capacityManagementRef.current.handleOpen(1, configDataByApi);
          return;
        }
        case extensionList.saveOurSouls?.id: {
          sosConfigurationRef.current.handleOpen(1);
          return;
        }
        case extensionList.identityVerification.id: {
          identityVerficationRef.current.handleOpen(1, configDataByApi);
          return;
        }
        case extensionList.routeOptimization.id: {
          routeOptimizationRef.current.handleOpen(1, configDataByApi);
          return;
        }
        case extensionList.agentWallet.id: {
          agentWalletRef.current.handleOpen(1, configDataByApi);
          return;
        }
        case extensionList.twilioMasking.id: {
          twilioMaskingRef.current.handleOpen(1, configDataByApi);
          return;
        }
        case extensionList.OTPVerification.id: {
          OTPVerificationRef.current.handleOpen(1, configDataByApi);
          return;
        }
        default:
      }
    } else {
      deactivateExtensionRef.current.handleClickOpen();
    }
  };
  // HubSpot - Get In Touch
  const hubspotDeal = async (description: any) => {
    let header: any = {
      access_token: appData.loginData.access_token,
    };
    let phone = "";
    let request: any = {
      name: appData.loginData.name,
      email: appData.loginData.email,
      phone: Number(phone),
      description: description,
      // user_id: appData.loginData.user_id,
    };
    let reqData = {
      reqBody: request,
      headers: header,
    };
    await ApiService.post(extensionService.hubspotDeal(reqData))
      .then(function (response: any) {
        toaster.addToast({
          message: t("Extensions:We will contact you shortly!"),
          timeout: 3000,
          type: "success",
        });
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  // Config Data

  // Activated Extension
  const getActivatedExtension = (token?: any) => {
    let header: any = {
      access_token: appData.loginData.access_token,
    };
    let request: any = {
      limit: 1000,
      skip: 0,
      user_id: appData.loginData.user_id,
    };
    let reqData = {
      reqBody: request,
      headers: header,
    };
    let data: any = [];
    ApiService.get(CommonEndpoints.activatedExtensionList(reqData))
      .then(function (response: any) {
        response.data.map((item: any) => {
          data.push(item.extension_id);
        });
        localStorage.setItem("activatedExtension", JSON.stringify(data));
      })
      .catch((err: any) => {});
    if (props.hideConfig === true) {
      props.sendStatusBackToParent(true);
    }
  };
  // Discard Deafult
  const onActivateDiscard = () => {
    activateExtensionRef.current.handleClose();
  };
  // Twilio Activate
  const handleTwilioMaskingActivation = () => {
    setshowConfig(true);
    getActivatedExtension();
    setIsActivated(true);
  };
  const handleFaceVerificationActivation = () => {
    getActivatedExtension();
    setIsActivated(true);
    setshowConfig(true);
    extensionSubject.emit({
      action: "activated",
      id: extension.extension_id,
    });
  };
  const handleCapacityManagementActivation = () => {
    getActivatedExtension();
    setIsActivated(true);
    setshowConfig(true);
    extensionSubject.emit({
      action: "activated",
      id: extension.extension_id,
    });
  };
  const handleSosSubscription = () => {
    getActivatedExtension();
    setIsActivated(true);
    setshowConfig(true);
    extensionSubject.emit({
      action: "activated",
      id: extension.extension_id,
    });
  };
  const handleRecurringJobActivation = () => {
    getActivatedExtension();
    setIsActivated(true);
    setshowConfig(true);
    extensionSubject.emit({
      action: "activated",
      id: extension.extension_id,
    });
  };
  const handleTwilioActivation = () => {
    getActivatedExtension();
    setIsActivated(true);
  };
  const handleAgentReferralExtention = () => {
    if (props.getAgentReferralExtensionDetails) {
      props.getAgentReferralExtensionDetails(extension.extension_id);
    }
    extensionSubject.emit({
      action: "activated",
      id: extension.extension_id,
    });
    getActivatedExtension();
    setIsActivated(true);
    setshowConfig(true);
  };
  const handleOTPVerification = () => {
    if (!appData.loginData.workflow_type) {
      setshowConfig(true);
    }

    getActivatedExtension();
    setIsActivated(true);
  };
  // RO Activate
  const handleROactivation = () => {
    getActivatedExtension();
    getConfigData();
  };

  const getWalletConfigData = async () => {
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const request: any = {};
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    return ApiService["get"](extensionService.getWalletconfig(reqData))
      .then((res: any) => {
        setAgentWalletConfigData(res.data[0]);
        setshowConfig(true);
        setIsActivated(true);
        console.log(res, "responce....");
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  // Agent Wallet Activate
  const handleAgentWallet = () => {
    getActivatedExtension();
    extensionSubject.emit({
      action: "activated",
      id: extension.extension_id,
    });
    getWalletConfigData();
  };

  const getConfigData = async () => {
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const request: any = {};
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    return ApiService["get"](extensionService.getROconfig(reqData))
      .then((res: any) => {
        setRoConfigData(res.data[0]);
        setshowConfig(true);
        setIsActivated(true);
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  // Stripe Connect
  const handleStripeConnectActivation = () => {
    getActivatedExtension();
    setIsActivated(true);
    setshowConfig(true);
    extensionSubject.emit({
      action: "activated",
      id: extension.extension_id,
    });
  };

  // Deafult Activate
  const onActivateConfirm = () => {
    let header: any = {
      access_token: access_token,
    };
    let request: any = {
      extension_id: extension.extension_id,
    };
    request.price = extension.price;
    let reqData = {
      reqBody: request,
      headers: header,
    };
    ApiService.post(extensionService.activateExtension(reqData, 0))
      .then(function (response: any) {
        toaster.addToast({
          message: response.message,
          timeout: 3000,
          type: "success",
        });
        setIsActivated(true);
        extensionSubject.emit({
          action: "activated",
          id: extension.extension_id,
        });
        // if(!props.hideConfig){
        activateExtensionRef.current.handleClose();
        // }
        getActivatedExtension();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  // Default Deactivate
  const onDeactivateConfirm = async () => {
    let header: any = {
      access_token: access_token,
    };

    let request: any = {
      extension_id: extension.extension_id,
    };

    let reqData = {
      reqBody: request,
      headers: header,
    };
    switch (extension.extension_id) {
      case extensionList.twilio.id:
        deactivateTwilio(reqData);
        return;
      case extensionList.stripeConnect.id:
        deactivateStripeconnect({ headers: header });
        return;
      case extensionList.capacityManagement.id:
        let request1: any = {
          extension_id: extensionList.capacityManagement.id,
        };
        let reqData1 = {
          reqBody: request1,
          headers: header,
        };
        const data = await ApiService.get(
          extensionService.getExtensionsDetails(reqData1)
        );
        capacityRemoveId = data.data[0].config.id;
        deactivateCapacityManagement({
          headers: header,
          reqBody: { id: capacityRemoveId },
        });
        return;
      case extensionList.identityVerification.id:
        let requestBody: any = {
          extension_id: extensionList.identityVerification.id,
        };
        let requestData = {
          reqBody: requestBody,
          headers: header,
        };
        const extensionData = await ApiService.get(
          extensionService.getExtensionsDetails(requestData)
        );
        capacityRemoveId = extensionData.data[0].config.id;
        deactivateIdentityVerification({
          headers: header,
          reqBody: { id: capacityRemoveId },
        });
        return;
      case extensionList.saveOurSouls.id:
        let requestBody1: any = {
          extension_id: extensionList.saveOurSouls.id,
        };
        let requestData1 = {
          reqBody: requestBody1,
          headers: header,
        };
        const extensionData1 = await ApiService.get(
          extensionService.getExtensionsDetails(requestData1)
        );
        sosRemoveId = extensionData1.data[0].config.id;
        deactivateSosSubscription({
          headers: header,
          reqBody: { id: sosRemoveId },
        });
        return;
      case extensionList.agentWallet.id:
        deactivateAgentWallet({
          headers: header,
          reqBody: {
            //  configuration_id: agentWalletConfigData.configuration_id,
            id: extensionList.agentWallet.id,
          },
        });
        return;
      case extensionList.routeOptimization.id:
        deactivateRO({
          headers: header,
          reqBody: {
            configuration_id: roConfigData.configuration_id,
            extension_id: extensionList.routeOptimization.id,
          },
        });
        return;
      case extensionList.agentReferral.id:
        deactivateAgentReferral({
          headers: { ...reqData.headers },
          reqBody: {
            // ...reqData.reqBody,
            id: props.configData?.id || agentReferralReqId,
          },
        });
        return;
      default:
        deactivateDefaultExtension(reqData);
    }
  };
  // Discard Default
  const onDeactivateDiscard = () => {
    deactivateExtensionRef.current.handleClose();
  };
  // Twilio Deactivate
  const deactivateTwilio = (reqData: Object) => {
    ApiService.put(extensionService.deactivateTwilio(reqData))
      .then(function (response: any) {
        toaster.addToast({
          message: response.message,
          timeout: 3000,
          type: "success",
        });
        getActivatedExtension();
        setIsActivated(false);
        extensionSubject.emit({
          action: "deactivated",
          id: extension.extension_id,
        });
        deactivateExtensionRef.current.handleClose();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  // Stripe Deactivate
  const deactivateStripeconnect = (reqData: Object) => {
    ApiService.put(extensionService.deactivateStripeConnect(reqData))
      .then(async (response: any) => {
        toaster.addToast({
          message: response.message,
          timeout: 3000,
          type: "success",
        });
        getActivatedExtension();
        setIsActivated(false);
        await props.getExtensionDetailsFromProps(extension.extension_id);
        setshowConfig(false);
        extensionSubject.emit({
          action: "deactivated",
          id: extension.extension_id,
        });
        deactivateExtensionRef.current.handleClose();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const deactivateCapacityManagement = (reqData: Object) => {
    ApiService.put(extensionService.removeCapacityManagement(reqData))
      .then(async (response: any) => {
        toaster.addToast({
          message: response.message,
          timeout: 3000,
          type: "success",
        });
        getActivatedExtension();
        setIsActivated(false);
        await props.getExtensionDetailsFromProps(extension.extension_id);
        setshowConfig(false);
        extensionSubject.emit({
          action: "deactivated",
          id: extension.extension_id,
        });
        deactivateExtensionRef.current.handleClose();
        getActivatedExtension();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const deactivateSosSubscription = (reqData: Object) => {
    ApiService.put(extensionService.deactivateSosSubscription(reqData))
      .then(function (response: any) {
        toaster.addToast({
          message: response.message,
          timeout: 3000,
          type: "success",
        });
        getActivatedExtension();
        setIsActivated(false);
        extensionSubject.emit({
          action: "deactivated",
          id: extension.extension_id,
        });
        deactivateExtensionRef.current.handleClose();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const deactivateIdentityVerification = (reqData: Object) => {
    ApiService.put(extensionService.deactivateIdentityVerification(reqData))
      .then(function (response: any) {
        toaster.addToast({
          message: response.message,
          timeout: 3000,
          type: "success",
        });
        getActivatedExtension();
        setIsActivated(false);
        extensionSubject.emit({
          action: "deactivated",
          id: extension.extension_id,
        });
        deactivateExtensionRef.current.handleClose();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const deactivateAgentReferral = (reqData: Object) => {
    ApiService.put(extensionService.deactivateAgentReferral(reqData))
      .then(function (response: any) {
        toaster.addToast({
          message: response.message,
          timeout: 3000,
          type: "success",
        });
        getActivatedExtension();
        setIsActivated(false);
        extensionSubject.emit({
          action: "deactivated",
          id: extension.extension_id,
        });
        deactivateExtensionRef.current.handleClose();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  // RO Deactivate
  const deactivateRO = (reqData: Object) => {
    // const activatedExtension = useLocalStorage.getItem("activatedExtension");

    ApiService.put(extensionService.deactivateRO(reqData))
      .then(function (response: any) {
        toaster.addToast({
          message: response.message,
          timeout: 3000,
          type: "success",
        });
        getActivatedExtension();
        setIsActivated(false);
        extensionSubject.emit({
          action: "deactivated",
          id: extension.extension_id,
        });
        useLocalStorage.removeItem("filteredColumns");
        deactivateExtensionRef.current.handleClose();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  // Agent Wallet
  const deactivateAgentWallet = (reqData: Object) => {
    ApiService.put(extensionService.deactivateAgentWallet(reqData))
      .then(function (response: any) {
        toaster.addToast({
          message: response.message,
          timeout: 3000,
          type: "success",
        });
        getActivatedExtension();
        setIsActivated(false);
        extensionSubject.emit({
          action: "deactivated",
          id: extension.extension_id,
          // id: "34",
        });
        // useLocalStorage.removeItem("filteredColumns");
        deactivateExtensionRef.current.handleClose();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  // Deafault Deactivate
  const deactivateDefaultExtension = (reqData: Object) => {
    ApiService.put(extensionService.deactivateExtension(reqData))
      .then(function (response: any) {
        toaster.addToast({
          message: response.message,
          timeout: 3000,
          type: "success",
        });
        getActivatedExtension();
        setIsActivated(false);
        extensionSubject.emit({
          action: "deactivated",
          id: extension.extension_id,
        });
        deactivateExtensionRef.current.handleClose();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const getExtensionDetail = async (extensionId: any) => {
    let header: any = {
      access_token: access_token,
    };
    let request: any = {
      extension_id: extensionId,
    };
    let reqData = {
      reqBody: request,
      headers: header,
    };
    let response: any = await ApiService.get(
      extensionService.getExtensionsDetails(reqData)
    );
    // if(response.data[0].)
    configDataByApi = response?.data[0].config;
    clickOnExtensionAction(true);
    // setConfigDataByApi(response?.data[0].config);
  };
  return (
    <div style={{ display: "inline-flex" }}>
      {extensionType === 1 && props.isConfigIcon ? (
        <span
          onClick={() => {
            if (props.isComeFromFrontPage) {
              getExtensionDetail(extension.extension_id);
            } else {
              clickOnExtensionAction(true);
            }
          }}
          style={{ alignSelf: "center" }}
        >
          <img
            src={configSettings}
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            // onClick={() => clickOnExtensionAction(true)
            // }
          ></img>
          {/* <SettingsIcon
            onClick={() => clickOnExtensionAction(true)}
          ></SettingsIcon> */}
        </span>
      ) : (
        ""
      )}
      {extensionType === 1 &&
      showConfig &&
      isActivated &&
      props.hideConfig !== true ? (
        <Btn
          className={"extension__detailActionBtn"}
          onClick={() => clickOnExtensionAction(true)}
          color={"primary"}
          type={"button"}
          btnText={t("Wallet:Configure")}
        ></Btn>
      ) : (
        ""
      )}
      <div className={"extension__detailAction"}>
        {extensionType === 1 ? (
          <Btn
            className={"extension__detailActionBtn"}
            onClick={clickOnExtensionAction}
            color={isActivated ? "default" : "primary"}
            type={"button"}
            btnText={
              !isActivated
                ? t("Extensions:Activate")
                : t("Extensions:Deactivate")
            }
          ></Btn>
        ) : (
          <Btn
            className={"extension__detailActionBtn"}
            color={"primary"}
            type={"button"}
            onClick={() => {
              hubspotDeal({
                extension_id: extension.extension_id,
                extension_name: extension.name,
              });
            }}
            btnText={t("Extensions:Get In Touch")}
          ></Btn>
        )}
      </div>

      {/* Default Actions */}
      <Suspense fallback={<></>}>
        <AlertDialog
          ref={deactivateExtensionRef}
          description={t("Extensions:Are you sure you want to deactivate?")}
          title={t("Extensions:Deactivate Extension")}
          confirm={t("Common:Confirm")}
          discard={t("Common:Discard")}
          onConfirm={onDeactivateConfirm}
          onDiscard={onDeactivateDiscard}
        ></AlertDialog>
      </Suspense>
      <Suspense fallback={<></>}>
        <AlertDialog
          ref={activateExtensionRef}
          description={t(
            "Extensions:Are you sure you want to activate this extension?"
          )}
          title={t("Extensions:Activate Extension")}
          confirm={t("Common:Confirm")}
          discard={t("Common:Discard")}
          onConfirm={onActivateConfirm}
          onDiscard={onActivateDiscard}
        ></AlertDialog>
      </Suspense>
      {/* Twilio */}
      <Suspense fallback={<></>}>
        <TwilioDetails
          ref={twilioDetailsRef}
          price={extension.price}
          handleTwilioActivation={handleTwilioActivation}
        ></TwilioDetails>
      </Suspense>
      <Suspense fallback={<></>}>
        <AgentReferralDetails
          ref={agentReferralRef}
          price={extension.price}
          handleAgentReferralExtention={handleAgentReferralExtention}
        ></AgentReferralDetails>
      </Suspense>
      <Suspense fallback={<></>}>
        <OTPVerification
          ref={OTPVerificationRef}
          price={extension.price}
          handleOTPVerification={handleOTPVerification}
        ></OTPVerification>
      </Suspense>
      {/* RO */}
      <Suspense fallback={<></>}>
        <AgentWalletConfig
          agentWalletConfigData={agentWalletConfigData}
          ref={agentWalletRef}
          // price={extension.price}
          handleAgentWallet={handleAgentWallet}
        ></AgentWalletConfig>
      </Suspense>
      <Suspense fallback={<></>}>
        <RouteOptimisationConfig
          ref={routeOptimizationRef}
          price={extension.price}
          roConfigData={roConfigData}
          handleROActivation={handleROactivation}
        ></RouteOptimisationConfig>
        <TwilioMasking
          ref={twilioMaskingRef}
          price={extension.price}
          handleTwilioMaskingActivation={handleTwilioMaskingActivation}
        ></TwilioMasking>
      </Suspense>
      {/* Capacity Management */}
      <Suspense fallback={<></>}>
        <CapacityManagement
          ref={capacityManagementRef}
          price={extension.price}
          config={
            props.stripeConnectData ? props.stripeConnectData : configDataByApi
          }
          handleCapacityManagementActivation={() =>
            handleCapacityManagementActivation()
          }
          fetchCapacityDetail={(data: any) => {
            if (data) {
              props.getExtensionDetailsFromProps(extension.extension_id);
            }
          }}
        ></CapacityManagement>
      </Suspense>
      {/* SOS Configuration */}
      <Suspense fallback={<></>}>
        <SosConfiguration
          ref={sosConfigurationRef}
          price={extension.price}
          config={props.stripeConnectData}
          handleSosSubscription={() => handleSosSubscription()}
          fetchCapacityDetail={(data: any) => {
            if (data) {
              props.getExtensionDetailsFromProps(extension.extension_id);
            }
          }}
        ></SosConfiguration>
      </Suspense>
      {/* Identity Verification */}
      <Suspense fallback={<></>}>
        <IdentityVerfication
          ref={identityVerficationRef}
          price={extension.price}
          config={
            props.stripeConnectData ? props.stripeConnectData : configDataByApi
          }
          handleIdentityVerificationActivation={() =>
            handleFaceVerificationActivation()
          }
          fetchVerificationDetail={(data: any) => {
            if (data) {
              props.getExtensionDetailsFromProps(extension.extension_id);
            }
          }}
        ></IdentityVerfication>
      </Suspense>
      {/* Stripe Connect */}
      <Suspense fallback={<></>}>
        <StripeConnect
          ref={stripeConnectRef}
          price={extension.price}
          config={
            props.stripeConnectData ? props.stripeConnectData : configDataByApi
          }
          handleStripeConnectActivation={() => handleStripeConnectActivation()}
          fetchExtensionDetail={(data: any) => {
            if (data) {
              props.getExtensionDetailsFromProps(extension.extension_id);
            }
          }}
        ></StripeConnect>
      </Suspense>
      {/* Recurring Jobs */}
      <Suspense fallback={<></>}>
        <RecurringJobs
          ref={recurringJobRef}
          price={extension.price}
          config={
            props.stripeConnectData ? props.stripeConnectData : configDataByApi
          }
          handleRecurringJobActivation={() => handleRecurringJobActivation()}
          fetcRecurringDetail={(data: any) => {
            if (data) {
              props.getExtensionDetailsFromProps(extension.extension_id);
            }
          }}
        />
      </Suspense>
    </div>
  );
};

export default ExtensionActionButton;
