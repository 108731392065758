import React, { useEffect, useState, Fragment } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import classes from "../Header/Header.module.scss";
import classes from "../../assets/css/HeaderDropdown.module.scss";
import CommonEndpoints from "../../services/commonApi.service";
import useApiService from "../../services/api.service";
import { useAppContext } from "../../AppContext/App.context";
import useLocaStorage from "../../hooks/localStorage";
import headerDropdownService from "../../services/headerDropdown.service";
import TeamDropdownIcon from "../../assets/images/header/team_dropdown_icon.svg";

const TeamListDropdown = (props: any) => {
  const [teamList, setTeamList]: any = useState([]);
  const [selectedTeamId, setTeamId] = useState("");
  const [appData, dispatch]: any = useAppContext();
  const access_token = appData.loginData.access_token;
  const ApiService = useApiService();
  const localStorageObject = useLocaStorage;
  const Permission: any = localStorageObject.getItem("Permission");

  const onTeamChange = (event: any) => {
    setTeamId(event.target.value);
    const dropdownData = {
      type: "teamList",
      data: { teamId: event.target.value },
    };
    headerDropdownService.emit(dropdownData);
  };

  useEffect(() => {
    setTeamId("0");
  }, [props.stripeUserType]);

  const getTeamList = async () => {
    // return;
    let headers = {
      access_token: access_token,
    };
    let qureyParams = {
      limit: 1000,
      skip: 0,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(CommonEndpoints.getTeamList(reqData));
    if (res.data.data.length) {
      setTeamListDropdown(res.data.data);
    }
  };
  useEffect(() => {
    if (Permission && Permission["Read_Teams"]) {
      getTeamList();
    }
  }, [props.chatUserType]);

  useEffect(() => {
    //  setShown(true)
    if (Permission && Permission["Read_Teams"]) {
      getTeamList();
    }
  }, []);

  const setTeamListDropdown = (data: any) => {
    if (props.default) {
      let arr: any = data || [];
      arr.unshift({ team_id: 0, team_name: props.default });
      setTeamList(arr);
      setTeamId("0");
    } else {
      setTeamList(data);
      setTeamId(data[0]?.team_id);
      const dropdownData = {
        type: "teamList",
        data: { teamId: data[0]?.team_id },
      };
      headerDropdownService.emit(dropdownData);
    }
  };

  const renderTeamList = () => {
    return (
      teamList.length > 0 &&
      teamList.map((team: any, index: any) => {
        return <MenuItem value={team.team_id}> {team.team_name}</MenuItem>;
      })
    );
  };

  return (
    <Fragment>
      {teamList.length ? (
        <div style={{ display: "flex" }} className={classes.dropdownOuter}>
          <img
            src={TeamDropdownIcon}
            alt="team drpdown"
            className={classes["dropdown-pre-icon"]}
          ></img>
          <Select
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              getContentAnchorEl: null,
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            disableUnderline={true}
            value={selectedTeamId}
            onChange={onTeamChange}
          >
            {renderTeamList()}
          </Select>
        </div>
      ) : (
        <div></div>
      )}
    </Fragment>
  );
};
export default TeamListDropdown;
