export const extensionList: any = {
  bookingForm: {
    id: 6,
  },
  routeOptimization: {
    id: 2,
  },
  recurring: {
    id: 3,
  },
  customerApp: {
    id: 7,
  },
  twilio: {
    id: 5,
  },
  stripeConnect: {
    id: 9,
  },
  twilioMasking: {
    id: 10,
  },
  routeAnalytics: {
    id: 11,
  },
  agentSignup: {
    id: 13,
  },
  barcodeExtension: {
    id: 14,
  },
  OTPVerification: {
    id: 15,
  },
  merchantAnalytics: {
    id: 16,
  },
  broadcast: {
    id: 18,
  },
  agentReferral: {
    id: 19,
  },
  customReport: {
    id: 20,
  },
  scheduledPayments: {
    id: 21,
  },
  capacityManagement: {
    id: 22,
  },
  identityVerification: {
    id: 23,
  },
  eatHappyNowCustomReport: {
    id: 25,
  },
  customerSubscription: {
    id: 26,
  },
  customerPromos: {
    id: 27,
  },
  saveOurSouls: {
    id: 28,
  },
  tripAnalytics: {
    id: 30,
  },
  agentIncentive: {
    id: 31,
  },
  agentWallet: {
    id: 34,
  },
};
