const DashboardEndpoints = {
  getDashboard(reqData: any) {
    return {
      url: "main/dashboard/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getJobsList(reqData: any) {
    return {
      url: "main/job/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getReleaseNotes(reqData: any) {
    return {
      url: "main/notes/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  updateReleaseNotesStatus(reqData: any) {
    return {
      url: "main/notes/status",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default DashboardEndpoints;
