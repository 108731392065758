import React, {
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Btn from "../../components/Button/Button";
import PageHeading from "../../components/PageHeading/PageHeading";
import Input from "../../components/Input/Input";
import Autocomplete from "../../components/Autocomplete/Autocomplete";
import { useForm } from "react-hook-form";
import { ValidationService } from "../../utils/Validation";
import { useAppContext } from "../../AppContext/App.context";
import useLoader from "../../hooks/useLoader";
import useToast from "../../components/Toast/hooks/useToast";
import { extensionService } from "./Extensions.endpoints";
import useApiService from "../../services/api.service";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "500px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
  },
}));
const TwilioDetails = forwardRef((props: any, ref: any) => {
  const { t, i18n } = useTranslation(["Extension"]);
  const ApiService = useApiService();
  const [open, setOpen] = React.useState(false);
  const { register, setValue, handleSubmit, reset, errors } = useForm();
  const classes = useStyles();
  const [appData]: any = useAppContext();
  const [historyDetail, setHistoryDetail]: any = useState("");
  const [type, setType]: any = useState();
  const loader: any = useLoader();
  const toaster = useToast();

  useImperativeHandle(ref, () => ({
    handleOpen() {
      //initMap();
      setOpen(true);
      // setType(type);
      //setHistoryDetail(history);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };
  const submitTwilioDetails = (data: any) => {
    if (!data.phoneNumber.trim()) {
      toaster.addToast({
        message: t("Extension:Please enter valid Phone No./Custom Sender ID"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (!data.accountSID.trim()) {
      toaster.addToast({
        message: t("Team:Please enter valid Account SID"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (!data.authToken.trim()) {
      toaster.addToast({
        message: t("Team:Please enter valid AuthToken"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    let credentials: any = {
      fromNumber: data.phoneNumber,
      accountSid: data.accountSID,
      authToken: data.authToken,
    };
    const request: any = {
      price: props.price,
      credentials: credentials,
    };
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    loader.showLoader();
    return ApiService["post"](extensionService.twilioDetails(reqData))
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        handleClose();
        props.handleTwilioActivation();
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const obj = {
    phoneNumber: {
      required: ValidationService.requiredValidator(),
    },
    accountSID: {
      required: ValidationService.requiredValidator(),
    },
    authToken: {
      required: ValidationService.requiredValidator(),
    },
  };
  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form onSubmit={handleSubmit(submitTwilioDetails)}>
              <h2 id="modal-title">Add Details</h2>
              <Input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                refValue={register(obj.phoneNumber)}
                errors={errors}
                label={t("Extension:Phone No./Custom Sender ID") + "*"}
              ></Input>
              <Input
                type="text"
                id="accountSID"
                name="accountSID"
                refValue={register(obj.accountSID)}
                errors={errors}
                label={t("Extension:Account SID") + "*"}
              ></Input>
              <Input
                type="text"
                id="authToken"
                name="authToken"
                refValue={register(obj.authToken)}
                errors={errors}
                label={t("Extension:AuthToken") + "*"}
              ></Input>
              <div>
                <Btn
                  className={classes.btn}
                  type={"Submit"}
                  btnText={t("Common:Submit")}
                >
                  {" "}
                </Btn>
                <Btn
                  onClick={() => {
                    setOpen(false);
                  }}
                  color={"default"}
                  type={"button"}
                  btnText={t("Common:Cancel")}
                >
                  {" "}
                </Btn>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default TwilioDetails;
