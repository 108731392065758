import { routeConfig } from "../enum/routeConfig.enum";

const redirect = (loginData: any, returnOnlyRoute?: boolean) => {
  let route = "/dashboard/";
  switch (loginData?.dashboard_route) {
    // case 0:
    //      window.location.href = routeConfig[0];
    //     break;
    case 1:
      route = routeConfig[1];
      break;
    default:
      route = routeConfig[0];
      break;
  }
  if (!returnOnlyRoute) {
    window.location.href = route;
  } else {
    return route;
  }
};
export default redirect;
