import moment from "moment";
import DateTimeFormatter from "../../../utils/DateFormatter";
import useLocalStorage from "../../../hooks/localStorage";
import { useAppContext } from "../../../AppContext/App.context";
const tzlookup = require("tz-lookup");
// const [appData, dispatch]: any = useAppContext();

const setLastTimeOfDayInDate = (dateString: string) => {
  const newDate = new Date(dateString);
  newDate.setHours(23);
  newDate.setMinutes(59);
  newDate.setSeconds(59);
  return moment(newDate).format("YYYY-MM-DD HH:mm:ss");
};
//a simple date formatting function
function dateFormat(inputDate: any, format: any) {
  var loginData: any = localStorage.getItem("loginData");
  let dateTimeFormat = JSON.parse(loginData).datetime_format;

  let dateType = typeof inputDate;
  let date: any = "";
  //parse the input date
  if (
    dateTimeFormat == "DD-MM-YYYY HH:mm" ||
    dateTimeFormat == "DD-MM-YYYY hh:mm A"
  ) {
    if (dateType == "string") {
      const myArray = inputDate.split("-");
      const yearAndTimeArray = myArray[2].split(" ");
      inputDate =
        myArray[1] +
        "-" +
        myArray[0] +
        "-" +
        yearAndTimeArray[0] +
        " " +
        yearAndTimeArray[1];
    } else {
      date = new Date(inputDate);
    }
  }
  if (dateType == "string") {
    if (DateTimeFormatter.checkIfTimeInTz(inputDate)) {
      date = moment(inputDate).utc().format("YYYY-MM-DD");
      return date;
    } else {
      date = new Date(inputDate);
    }
  } else {
    date = new Date(inputDate);
  }
  //extract the parts of the date
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  //replace the month
  format = format.replace("MM", month.toString().padStart(2, "0"));

  //replace the year
  if (format.indexOf("yyyy") > -1) {
    format = format.replace("yyyy", year.toString());
  } else if (format.indexOf("yy") > -1) {
    format = format.replace("yy", year.toString().substr(2, 2));
  }

  //replace the day
  format = format.replace("dd", day.toString().padStart(2, "0"));

  return format;
}
const createJobService = {
  getRecurringBody(repeatData: any, isRecurringEdit: any) {
    repeatData.occurrences = repeatData.occurrences
      ? parseInt(repeatData.occurrences)
      : 0;
    repeatData.scheduleEndCriteria = repeatData.scheduleEndCriteria
      ? parseInt(repeatData.scheduleEndCriteria)
      : 0;
    let endOn: any = "";
    switch (parseInt(repeatData.cycle)) {
      case 0:
      case 7:
        if (repeatData.scheduleEndCriteria == 0) {
          endOn = dateFormat(repeatData.endDate, "yyyy-MM-dd");
          // endOn=DateTimeFormatter.convertDateTimeWithoutUTC(repeatData.endDate,"YYYY-MM-DD");
          // endOn = moment(repeatData.endDate).format("YYYY-MM-DD");
        } else {
          let occurTime = repeatData.occurrences;
          if (repeatData.cycle === 7) {
            occurTime = occurTime + occurTime - 1;
          }
          endOn = moment(repeatData.startOn)
            .add(occurTime - 1, "days")
            .format("YYYY-MM-DD");
        }
        break;
      case 1:
        let i = 0;
        if (repeatData.scheduleEndCriteria === 0) {
          endOn = dateFormat(repeatData.endDate, "yyyy-MM-dd");
          // endOn = moment(repeatData.endDate).utc().format("YYYY-MM-DD");
        } else {
          const currentDay = moment(repeatData.startOn).format("d");
          let tempSelectedDays: any = [];
          for (let dayId in repeatData.cycle_def) {
            let obj: any = {
              dayId: dayId,
              isSelected: 1,
            };
            if (dayId < currentDay) {
              const dayDiff = parseInt(currentDay) - parseInt(dayId);
              obj.newEndDate = moment(repeatData.startOn)
                .subtract(dayDiff, "days")
                .format("YYYY-MM-DD");
            } else {
              const dayDiff = parseInt(dayId) - parseInt(currentDay);
              obj.newEndDate = moment(repeatData.startOn)
                .add(dayDiff, "days")
                .format("YYYY-MM-DD");
            }
            if (repeatData.cycle_def[dayId] === true) {
              tempSelectedDays.push(obj);
            }
          }
          let occurTime = repeatData.occurrences;
          for (let j = 0; j < tempSelectedDays.length; j++) {
            if (tempSelectedDays[j].dayId >= currentDay) {
              endOn = tempSelectedDays[j].newEndDate;
              tempSelectedDays[j].newEndDate = endOn;
              i++;
              if (i === occurTime) {
                break;
              }
            }
          }
          for (; i < occurTime; ) {
            for (let day = 0; day < tempSelectedDays.length; day++) {
              if (i < occurTime) {
                endOn = moment(tempSelectedDays[day].newEndDate)
                  .add(7, "days")
                  .format("YYYY-MM-DD");
                tempSelectedDays[day].newEndDate = endOn;
                i++;
              }
            }
          }
        }
        break;
      case 2:
        if (repeatData.scheduleEndCriteria === 0) {
          endOn = dateFormat(repeatData.endDate, "yyyy-MM-dd");
        } else {
          endOn = moment(repeatData.startOn)
            .add(repeatData.occurrences - 1, "months")
            .format("YYYY-MM-DD");
        }
        break;
      case 3:
        if (repeatData.scheduleEndCriteria === 0) {
          endOn = dateFormat(repeatData.endDate, "yyyy-MM-dd");
        } else {
          endOn = moment(repeatData.startOn)
            .add(repeatData.occurrences - 1, "years")
            .format("YYYY-MM-DD");
        }
        break;
      case 4:
        if (repeatData.scheduleEndCriteria === 0) {
          endOn = dateFormat(repeatData.endDate, "yyyy-MM-dd");
        } else {
          endOn = moment(repeatData.startOn)
            .add((repeatData.occurrences - 1) * 14, "days")
            .format("YYYY-MM-DD");
        }
        break;
      case 5:
        if (repeatData.scheduleEndCriteria === 0) {
          endOn = dateFormat(repeatData.endDate, "yyyy-MM-dd");
        } else {
          endOn = moment(repeatData.startOn)
            .add((repeatData.occurrences - 1) * 3, "months")
            .format("YYYY-MM-DD");
        }
        break;
      case 6:
        if (repeatData.scheduleEndCriteria === 0) {
          endOn = dateFormat(repeatData.endDate, "yyyy-MM-dd");
        } else {
          endOn = moment(repeatData.startOn)
            .add((repeatData.occurrences - 1) * 6, "months")
            .format("YYYY-MM-DD");
        }
    }
    const differenceBetweenStartAndEndDate = moment(
      repeatData.lastEndDate
    ).diff(moment(repeatData.startOn), "days");
    endOn = setLastTimeOfDayInDate(endOn);
    let reqBody: any = {
      start_date: repeatData.startOn,
      name: repeatData.recurringName,
      end_date: endOn,
      cycle: repeatData.cycle,
      occurrences:
        repeatData.scheduleEndCriteria == 1
          ? parseInt(repeatData.occurrences)
          : 0,
      end_date_timezone: repeatData.last_date_timezone,
      start_date_timezone: repeatData.start_date_timezone,
    };
    if (isRecurringEdit) {
      reqBody["id"] = repeatData.id;
    }
    reqBody["last_task_date"] = setLastTimeOfDayInDate(
      moment(endOn)
        .add(differenceBetweenStartAndEndDate, "days")
        .format("YYYY-MM-DD")
    );
    if (repeatData.cycle === 1) {
      reqBody["cycle_def"] = repeatData.cycle_def;
    }

    return reqBody;
  },
  validateRecurringData(repeatData: any) {
    const validateEndDate = () => {
      if (!repeatData.endDate) {
        return "Please enter a valid end date for schedule";
      } else if (moment(repeatData.startOn).isAfter(repeatData.endDate)) {
        return "Repeat end date should be greater than task time";
      }
    };
    if (!repeatData.recurringName.trim()) {
      return "Please enter a valid schedule name";
    }
    if (repeatData.scheduleEndCriteria == 0) {
      return validateEndDate();
    } else {
      if (!repeatData.occurrences || repeatData.occurrences < 1) {
        return "Please enter a valid no of occurrences";
      }
    }
  },
  getStartAndEndTaskDateTimezone(taskDatesForSchedulingRule: any) {
    var min = taskDatesForSchedulingRule.reduce(function (a: any, b: any) {
      return a.local_datetime < b.local_datetime ? a : b;
    });
    var max = taskDatesForSchedulingRule.reduce(function (a: any, b: any) {
      return a.local_datetime > b.local_datetime ? a : b;
    });
    return {
      startOn: min.local_datetime,
      start_date_timezone: min.timezone,
      lastEndDate: max.local_datetime,
      last_date_timezone: max.timezone,
    };
  },
  getTimezoneForTask(taskDate: any, taskLocation: any, returnType?: string) {
    const lat = parseFloat(taskLocation.lat).toFixed(4);
    const lng = parseFloat(taskLocation.lng).toFixed(4);
    const timezoneString = tzlookup(lat, lng);
    if (returnType === "timezonestring") {
      return timezoneString;
    }
    let m = moment.utc(taskDate).tz(timezoneString);
    const offsetInMinutes = m.utcOffset();
    return offsetInMinutes;
  },
};
export default createJobService;
