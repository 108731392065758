import React, { useEffect, useState, Fragment } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import classes from "../Header/Header.module.scss";
import classes from "../../assets/css/HeaderDropdown.module.scss";
// import CommonEndpoints from "../../services/commonApi.service";
import CustomerListEndpoint from "./CustomerList.endpoints";
import useApiService from "../../services/api.service";
import { useAppContext } from "../../AppContext/App.context";
import useLocaStorage from "../../hooks/localStorage";
import headerDropdownService from "../../services/headerDropdown.service";
import TeamDropdownIcon from "../../assets/images/header/team_dropdown_icon.svg";

const CustomerListDropdown = (props: any) => {
  const [customerList, setCustomerList]: any = useState([]);
  const [selectedCustomerId, setCustomerId] = useState("");
  const [appData, dispatch]: any = useAppContext();
  const access_token = appData.loginData.access_token;
  const ApiService = useApiService();
  const localStorageObject = useLocaStorage;
  const Permission: any = localStorageObject.getItem("Permission");

  const onCustomerChange = (event: any) => {
    setCustomerId(event.target.value);
    sendData(event.target.value);
  };
  const sendData = (customer_id: any) => {
    const dropdownData = {
      type: "customerList",
      data: { customer_id: customer_id },
    };
    headerDropdownService.emit(dropdownData);
  };
  const getCustomerList = async () => {
    // return;
    let headers = {
      access_token: access_token,
    };
    let qureyParams = {
      limit: 1000,
      skip: 0,
      fetch_customer_ref: true,
    };

    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };

    const res: any = await ApiService.get(
      CustomerListEndpoint.getCustomerList(reqData)
    );
    if (res.data.data.length) {
      setCustomerListDropdown(res.data.data);
    }
  };

  useEffect(() => {
    //  setShown(true)

    getCustomerList();
  }, []);

  const setCustomerListDropdown = (data: any) => {
    let arr: any = data || [];

    setCustomerList(arr);
    setCustomerId(arr[0].customer_id);
    sendData(arr[0].customer_id);
  };

  const renderCustomerList = () => {
    return (
      customerList.length > 0 &&
      customerList.map((team: any, index: any) => {
        return <MenuItem value={team.customer_id}> {team.name}</MenuItem>;
      })
    );
  };

  return (
    <Fragment>
      {customerList.length ? (
        <div style={{ display: "flex" }} className={classes.dropdownOuter}>
          <img
            src={TeamDropdownIcon}
            alt="team drpdown"
            className={classes["dropdown-pre-icon"]}
          ></img>
          <Select
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              getContentAnchorEl: null,
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            disableUnderline={true}
            value={selectedCustomerId}
            onChange={onCustomerChange}
          >
            {renderCustomerList()}
          </Select>
        </div>
      ) : (
        <div></div>
      )}
    </Fragment>
  );
};
export default CustomerListDropdown;
