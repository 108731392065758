import { makeStyles } from "@material-ui/core";
import { themeColor } from "../../constants/themeColor";
const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
    border: "solid 1px #dcdcdc",
    height: `calc(100vh - 90px)`,
  },
  accordionWrapper: {
    boxShadow: "none",
  },
  padding0: {
    padding: "0px !important",
    marginTop: "20px",
  },
  width100: {
    width: "100%",
  },
  expanded: {
    // height: "56px",
    // borderRadius: "10px",
    // paddingLeft: "20px",
    // display: "flex",
    // paddingRight: "20px",
    // alignItems: "center",
    // border: "solid 1px #dcdcdc",
    // "&.Mui-expanded": {
    //   border: "solid 1px #3b7cff",
    //   "&.MuiAccordionSummary-expandIcon": {
    //     color: "#3b7cff",
    //   },
    // },
    border: "none",
    cursor:"default!important",
  },
  hideBorder: {
    "&.MuiExpansionPanel-root:before": {
      display: "none",
    },
  },
  tagInputField: {
    marginTop: "0px",
    marginBottom: "10px",
  },
  tabLabelClass: {
    color: `${themeColor.blackColor}`,
  },
  tabs: {
    // color: "#000 !important",
    marginLeft: "20px",
  },
  formInput: {
    marginBottom: "8px",
  },

  "MuiTab-root": {
    minWidth: "0px !important",
    fontSize: "15px",
    paddingLeft: "0px !important",
    color: "black !important",
    marginRight: "20px",
    opacity: "1.0 !important",
    // fontWeight: 600,
    textTransform: "capitalize",
  },
  "MuiAutocomplete-option": {
    background: "red",
  },
  tabsIndicator: {
    // marginLeft: "10px",
    // width: "53.409px !important",
    height: "3px",
  },
  "Mui-selected": {
    fontWeight: "bold",
  },
  activeTab: {
    fontWeight: "bold!important" as any,
    minWidth: "0px !important",
    fontSize: "15px",
    paddingLeft: "0px !important",
    color: "black !important",
    marginRight: "20px",
    // fontWeight: 600,
    textTransform: "capitalize",
  } as const,

  root: {
    borderRadius: "20px!important",
    boxShadow: "0 18px 18px 0 rgba(0, 126, 191, 0.26)",
    position: "relative",
    zIndex: 1,
    margin: "10px 0px 0 3px",
    cursor:"pointer!important"
  },
}));
export default useStyles;
