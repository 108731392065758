import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend"; // primary use cache
import HttpApi from "i18next-http-backend"; // fallback http load
import atTrimmer from "./i18nplugin";

i18next
  .use(initReactI18next)
  .use(Backend)
  .use(atTrimmer)
  .init({
    fallbackLng: "en",
    lng: "en",
    keySeparator: false,
    postProcess: ["atTrimmer"],
    backend: {
      backends: [
        LocalStorageBackend, // primary
        HttpApi, // fallback
      ],
      backendOptions: [
        {
          // prefix for stored languages
          prefix: "i18next_res_",

          // expiration
          expirationTime: 72460601000,

          // Version applied to all languages, can be overriden using the option versions
          defaultVersion: "1",

          // language versions
          versions: { ar: "1.5", en: "v1.2", fr: "v1.1" },

          // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
          store: window.localStorage,
        },
        {
          loadPath: "/locales/{{lng}}/{{ns}}.json", // xhr load path for my own fallback
        },
      ],
    },
  });

export default i18next;
