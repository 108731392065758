const Timezone = [
  {
    name: "(GMT+00:00) Default",
    value: "UTC",
  },
  {
    name: "(GMT-12:00) International Date Line West",
    value: "Etc/GMT+12",
  },
  {
    name: "	(GMT-11:00) Coordinated Universal Time-11",
    value: "Etc/GMT+11",
  },
  {
    name: "(GMT-03:00) Montevideo",
    value: "America/Montevideo",
  },
  {
    name: "(GMT-03:00) Cayenne, Fortaleza",
    value: "America/Cayenne",
  },
  {
    name: "(GMT-03:00) Brasilia",
    value: "America/Sao_Paul",
  },
  {
    name: "(GMT-03:00) Buenos Aires",
    value: "America/Buenos_Aires",
  },
  {
    name: "(GMT-03:30) Newfoundland",
    value: "America/St_Johns",
  },
  {
    name: "(GMT-04:00) Georgetown, La Paz, Manaus, San Juan",
    value: "America/La_Paz",
  },
  {
    name: "(GMT-04:00) Santiago",
    value: "America/Santiago",
  },
  {
    name: "(GMT-04:00) Cuiaba",
    value: "America/Cuiaba",
  },
  {
    name: "(GMT-04:00) Asuncion",
    value: "America/Asuncion",
  },
  {
    name: "(GMT-04:00) Atlantic Time (Canada)",
    value: "America/Halifax",
  },
  {
    name: "(GMT-04:30) Caracas",
    value: "America/Caracas",
  },
  {
    name: "(GMT-05:00) Indiana (East)",
    value: "America/Indianapolis",
  },
  {
    name: "(GMT-05:00) Eastern Time (US & Canada)",
    value: "America/New_York",
  },
  {
    name: "(GMT-05:00) Bogota, Lima, Quito",
    value: "America/Bogota",
  },
  {
    name: "(GMT-06:00) Saskatchewan",
    value: "America/Regina",
  },
  {
    name: "(GMT-06:00) Guadalajara,Mexico City, Monterrey",
    value: "America/Mexico_City",
  },
  {
    name: "(GMT-06:00) Central America",
    value: "America/Guatemala",
  },
  {
    name: "(GMT-06:00) Central Time (US & Canada)",
    value: "America/Chicago",
  },
  {
    name: "(GMT-07:00) Mountain Time (US & Canada)",
    value: "America/Denver",
  },
  {
    name: "(GMT-07:00) Arizona",
    value: "America/Phoenix",
  },
  {
    name: "(GMT-07:00) Chihuahua,Mazatlan",
    value: "America/Chihuahua",
  },
  {
    name: "(GMT-08:00) Pacific Time (US & Canada)",
    value: "America/Los_Angeles",
  },
  {
    name: "(GMT-08:00) Baja California",
    value: "America/Tijuana",
  },
  {
    name: "(GMT-09:00) Alaska",
    value: "America/Anchorage",
  },
  {
    name: "(GMT-09:30) Marquesas Islands",
    value: "Pacific/Marquesas",
  },
  {
    name: "(GMT-10:00) Hawaii",
    value: "Pacific/Honolulu",
  },
  {
    name: "(GMT-11:00) Samoa",
    value: "Pacific/Samoa",
  },
  { value: "America/Godthab", name: "(GMT-03:00) Greenland" },
  { value: "Etc/GMT+2", name: "(GMT-02:00) Coordinated Universal Time-02" },
  { value: "Etc/GMT+2", name: "(GMT-02:00) Mid-Atlantic" },
  { value: "Atlantic/Azores", name: "(GMT-01:00) Azores" },
  { value: "Atlantic/Cape_Verde", name: "(GMT-01:00) Cape Verde Is." },
  { value: "Africa/Casablanca", name: "(GMT+00:00) Casablanca" },
  { value: "Atlantic/Reykjavik", name: "(GMT+00:00) Monrovia,Reykjavik" },
  {
    value: "Europe/London",
    name: "(GMT+00:00) Greenwich Mean Time : Dublin,Edinburgh,Lisbon, London",
  },
  {
    value: "Europe/Warsaw",
    name: "(GMT+01:00) Sarajevo,Skopje, Warsaw, Zagreb",
  },
  { value: "Africa/Lagos", name: "(GMT+01:00) West Central Africa" },
  {
    value: "Europe/Budapest",
    name: "(GMT+01:00) Belgrade,Bratislava, Budapest, Ljubljana, Prague",
  },
  {
    value: "Europe/Paris",
    name: "(GMT+01:00) Brussels, Copenhagen,Madrid, Paris",
  },
  {
    value: "Europe/Berlin",
    name: "(GMT+01:00) Amsterdam,Berlin, Bern, Rome,Stockholm, Vienna",
  },
  { value: "Africa/Johannesburg", name: "(GMT+02:00) Harare, Pretoria" },
  { value: "Asia/Damascus", name: "(GMT+02:00) Damascus" },
  {
    value: "Europe/Kiev",
    name: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
  },
  { value: "Africa/Windhoek", name: "(GMT+02:00) Windhoek" },
  { value: "Europe/Minsk", name: "(GMT+02:00) Minsk" },
  { value: "Europe/Istanbu", name: "(GMT+02:00) Athens,Bucharest, Istanbul" },
  { value: "Asia/Amman", name: "(GMT+02:00) Amman" },
  { value: "Asia/Beirut", name: "(GMT+02:00) Beirut" },
  { value: "Asia/Jerusalem", name: "(GMT+02:00) Jerusalem" },
  { value: "Africa/Cairo", name: "(GMT+02:00) Cairo" },
  { value: "Asia/Riyadh", name: "(GMT+03:00) Kuwait, Riyadh" },
  {
    value: "Europe/Moscow",
    name: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
  },
  { value: "Asia/Baghdad", name: "(GMT+03:00) Baghdad" },
  { value: "Africa/Nairobi", name: "(GMT+03:00) Nairobi" },
  { value: "Asia/Tehran", name: "(GMT+03:30) Tehran" },
  { value: "Indian/Mauritius", name: "(GMT+04:00) Port Louis" },
  { value: "Asia/Tbilisi", name: "	(GMT+04:00) Tbilisi" },
  { value: "Asia/Baku", name: "(GMT+04:00) Baku" },
  { value: "Asia/Yerevan", name: "(GMT+04:00) Yerevan" },
  { value: "Asia/Dubai", name: "(GMT+04:00) Abu Dhabi, Muscat" },
  { value: "Asia/Kabul", name: "(GMT+04:30) Kabul" },
  { value: "Asia/Yekaterinburg", name: "(GMT+05:00) Yekaterinburg" },
  { value: "Asia/Karachi", name: "(GMT+05:00) Islamabad,Karachi" },
  { value: "Asia/Tashkent", name: "(GMT+05:00) Tashkent" },
  {
    value: "Asia/Calcutta",
    name: "(GMT+05:30) Chennai, Kolkata, Mumbai,New Delhi",
  },
  { value: "Asia/Colombo", name: "(GMT+05:30) Sri Jayawardenepura" },
  { value: "Asia/Katmandu", name: "(GMT+05:45) Kathmandu" },
  { value: "Asia/Dhaka", name: "(GMT+06:00) Dhaka" },
  { value: "Asia/Novosibirsk", name: "(GMT+06:00) Novosibirsk" },
  { value: "Asia/Almaty", name: "(GMT+06:00) Astana" },
  { value: "Asia/Rangoon", name: "(GMT+06:30) Yangon (Rangoon)" },
  { value: "Asia/Krasnoyarsk", name: "(GMT+07:00) Krasnoyarsk" },
  { value: "Asia/Bangkok", name: "(GMT+07:00) Bangkok, Hanoi,Jakarta" },
  { value: "Asia/Ulaanbaatar", name: "(GMT+08:00) Ulaanbaatar" },
  { value: "Australia/Perth", name: "(GMT+08:00) Perth" },
  { value: "Asia/Taipei", name: "(GMT+08:00) Taipei" },
  { value: "Asia/Singapore", name: "(GMT+08:00) Kuala Lumpur,Singapore" },
  {
    value: "Asia/Shanghai",
    name: "(GMT+08:00) Beijing,Chongqing,Hong Kong, Urumqi",
  },
  { value: "Asia/Irkutsk", name: "(GMT+08:00) Irkutsk" },
  { value: "Asia/Pyongyang", name: "(GMT+08:30) Pyongyang" },
  { value: "Australia/Eucla", name: "(GMT+08:30) Eucla" },
  { value: "Asia/Seoul", name: "(GMT+09:00) Seoul" },
  { value: "Asia/Tokyo", name: "(GMT+09:00) Osaka,Sapporo, Tokyo" },
  { value: "Asia/Yakutsk", name: "(GMT+09:00) Yakutsk" },
  { value: "Australia/Darwin", name: "(GMT+09:30) Darwin" },
  { value: "Australia/Adelaide", name: "(GMT+09:30) Adelaide" },
  { value: "Australia/Hobart", name: "(GMT+10:00) Hobart" },
  { value: "Asia/Vladivostok", name: "(GMT+10:00) Vladivostok" },
  { value: "Pacific/Port_Moresby", name: "(GMT+10:00) Guam, Port Moresby" },
  { value: "Australia/Brisbane", name: "(GMT+10:00) Brisbane" },
  { value: "Australia/Sydney", name: "(GMT+10:00) Canberra,Melbourne, Sydney" },
  { value: "Australia/LHI", name: "(GMT+10:30) Lord Howe Island" },
  {
    value: "Asia/Magadan",
    name: "	(GMT+11:00) Magadan, Solomon Is.,New Caledonia",
  },
  { value: "Pacific/Fiji", name: "(GMT+12:00) Fiji" },
  { value: "Asia/Kamchatka", name: "(GMT+12:00) Petropavlovsk-Kamchatsky" },
  { value: "Pacific/Auckland", name: "(GMT+12:00) Auckland, Wellington" },
  { value: "Etc/GMT-12", name: "(GMT+12:00) Coordinated Universal Time+12" },
  { value: "Pacific/Chatham", name: "(GMT+12:45) Chatham Islands" },
  {
    value: "Pacific/Enderbury",
    name: "(GMT+13:00) Phoenix Islands, Tokelau, Tonga",
  },
  { value: "Pacific/Kiritimati", name: "(GMT+14:00) Line Islands" },
];
export default Timezone;
