import L from "leaflet";
var l = 0;
(function () {
  L.LineUtil.PolylineDecorator = {
    computeAngle: function (a, b) {
      return (Math.atan2(b.y - a.y, b.x - a.x) * 180) / Math.PI + 90;
    },

    getPointPathPixelLength: function (pts) {
      var nbPts = pts.length;
      if (nbPts < 2) {
        return 0;
      }
      var dist = 0,
        prevPt = pts[0],
        pt;
      for (var i = 1; i < nbPts; i++) {
        dist += prevPt.distanceTo((pt = pts[i]));
        prevPt = pt;
      }
      return dist;
    },

    getPixelLength: function (pl, map) {
      var ll = pl instanceof L.Polyline ? pl.getLatLngs() : pl,
        nbPts = ll.length;
      if (nbPts < 2) {
        return 0;
      }
      var dist = 0,
        prevPt = map.project(ll[0]),
        pt;
      for (var i = 1; i < nbPts; i++) {
        dist += prevPt.distanceTo((pt = map.project(ll[i])));
        prevPt = pt;
      }
      return dist;
    },

    /**
     * path: array of L.LatLng
     * offsetRatio: the ratio of the total pixel length where the pattern will start
     * endOffsetRatio: the ratio of the total pixel length where the pattern will end
     * repeatRatio: the ratio of the total pixel length between two points of the pattern
     * map: the map, to access the current projection state
     */

    projectPatternOnPath: function (
      path,
      offsetRatio,
      endOffsetRatio,
      repeatRatio,
      map
    ) {
      var pathAsPoints = [],
        i;

      for (i = 0, l = path.length; i < l; i++) {
        pathAsPoints[i] = map.project(path[i]);
      }
      // project the pattern as pixel points
      var pattern = this.projectPatternOnPointPath(
        pathAsPoints,
        offsetRatio,
        endOffsetRatio,
        repeatRatio
      );
      if (!pattern) {
        return;
      }
      // and convert it to latlngs;
      for (i = 0, l = pattern.length; i < l; i++) {
        pattern[i].latLng = map.unproject(pattern[i].pt);
      }
      return pattern;
    },

    projectPatternOnPointPath: function (
      pts,
      offsetRatio,
      endOffsetRatio,
      repeatRatio
    ) {
      var positions = [];
      // 1. compute the absolute interval length in pixels
      var repeatIntervalLength =
        this.getPointPathPixelLength(pts) * repeatRatio;
      // 2. find the starting point by using the offsetRatio and find the last pixel using endOffsetRatio
      var previous = this.interpolateOnPointPath(pts, offsetRatio);
      if (!previous) {
        return;
      }
      var endOffsetPixels =
        endOffsetRatio > 0
          ? this.getPointPathPixelLength(pts) * endOffsetRatio
          : 0;

      positions.push(previous);
      if (repeatRatio > 0) {
        // 3. consider only the rest of the path, starting at the previous point
        var remainingPath = pts;
        remainingPath = remainingPath.slice(previous.predecessor);

        remainingPath[0] = previous.pt;
        var remainingLength = this.getPointPathPixelLength(remainingPath);

        // 4. project as a ratio of the remaining length,
        // and repeat while there is room for another point of the pattern

        while (repeatIntervalLength <= remainingLength - endOffsetPixels) {
          previous = this.interpolateOnPointPath(
            remainingPath,
            repeatIntervalLength / remainingLength
          );
          positions.push(previous);
          remainingPath = remainingPath.slice(previous.predecessor);
          remainingPath[0] = previous.pt;
          remainingLength = this.getPointPathPixelLength(remainingPath);
        }
      }
      return positions;
    },

    /**
     * pts: array of L.Point
     * ratio: the ratio of the total length where the point should be computed
     * Returns null if ll has less than 2 LatLng, or an object with the following properties:
     *    latLng: the LatLng of the interpolated point
     *    predecessor: the index of the previous vertex on the path
     *    heading: the heading of the path at this point, in degrees
     */
    interpolateOnPointPath: function (pts, ratio) {
      var nbVertices = pts.length;

      if (nbVertices < 2) {
        return null;
      }
      // easy limit cases: ratio negative/zero => first vertex
      if (ratio <= 0) {
        return {
          pt: pts[0],
          predecessor: 0,
          heading: this.computeAngle(pts[0], pts[1]),
        };
      }
      // ratio >=1 => last vertex
      if (ratio >= 1) {
        return {
          pt: pts[nbVertices - 1],
          predecessor: nbVertices - 1,
          heading: this.computeAngle(pts[nbVertices - 2], pts[nbVertices - 1]),
        };
      }
      // 1-segment-only path => direct linear interpolation
      if (nbVertices == 2) {
        return {
          pt: this.interpolateBetweenPoints(pts[0], pts[1], ratio),
          predecessor: 0,
          heading: this.computeAngle(pts[0], pts[1]),
        };
      }

      var pathLength = this.getPointPathPixelLength(pts);
      var a = pts[0],
        b = a,
        ratioA = 0,
        ratioB = 0,
        distB = 0;
      // follow the path segments until we find the one
      // on which the point must lie => [ab]
      var i = 1;
      for (; i < nbVertices && ratioB < ratio; i++) {
        a = b;
        ratioA = ratioB;
        b = pts[i];
        distB += a.distanceTo(b);
        ratioB = distB / pathLength;
      }

      // compute the ratio relative to the segment [ab]
      var segmentRatio = (ratio - ratioA) / (ratioB - ratioA);

      return {
        pt: this.interpolateBetweenPoints(a, b, segmentRatio),
        predecessor: i - 2,
        heading: this.computeAngle(a, b),
      };
    },

    /**
     * Finds the point which lies on the segment defined by points A and B,
     * at the given ratio of the distance from A to B, by linear interpolation.
     */
    interpolateBetweenPoints: function (ptA, ptB, ratio) {
      if (ptB.x != ptA.x) {
        return new L.Point(
          ptA.x * (1 - ratio) + ratio * ptB.x,
          ptA.y * (1 - ratio) + ratio * ptB.y
        );
      }
      // special case where points lie on the same vertical axis
      return new L.Point(ptA.x, ptA.y + (ptB.y - ptA.y) * ratio);
    },
  };

  L.PolylineDecorator = L.FeatureGroup.extend({
    options: {
      patterns: [],
    },

    initialize: function (paths, options) {
      L.FeatureGroup.prototype.initialize.call(this);
      L.Util.setOptions(this, options);
      this._map = null;
      this._initPaths(paths);
      this._initPatterns();
    },

    /**
     * Deals with all the different cases. p can be one of these types:
     * array of LatLng, array of 2-number arrays, Polyline, Polygon,
     * array of one of the previous.
     */
    _initPaths: function (p) {
      this._paths = [];
      var isPolygon = false;
      if (p instanceof L.Polyline) {
        this._initPath(p.getLatLngs(), p instanceof L.Polygon);
      } else if (L.Util.isArray(p) && p.length > 0) {
        if (p[0] instanceof L.Polyline) {
          for (var i = 0; i < p.length; i++) {
            this._initPath(p[i].getLatLngs(), p[i] instanceof L.Polygon);
          }
        } else {
          this._initPath(p);
        }
      }
    },

    _isCoordArray: function (ll) {
      return (
        L.Util.isArray(ll) &&
        ll.length > 0 &&
        (ll[0] instanceof L.LatLng ||
          (L.Util.isArray(ll[0]) &&
            ll[0].length == 2 &&
            typeof ll[0][0] === "number"))
      );
    },

    _initPath: function (path, isPolygon) {
      var latLngs;
      // It may still be an array of array of coordinates
      // (ex: polygon with rings)
      if (this._isCoordArray(path)) {
        latLngs = [path];
      } else {
        latLngs = path;
      }
      for (var i = 0; i < latLngs.length; i++) {
        // As of Leaflet >= v0.6, last polygon vertex (=first) isn't repeated.
        // Our algorithm needs it, so we add it back explicitly.
        if (isPolygon) {
          latLngs[i].push(latLngs[i][0]);
        }
        this._paths.push(latLngs[i]);
      }
    },

    _initPatterns: function () {
      this._isZoomDependant = false;
      this._patterns = [];
      var pattern;
      // parse pattern definitions and precompute some values
      for (var i = 0; i < this.options.patterns.length; i++) {
        pattern = this._parsePatternDef(this.options.patterns[i]);
        this._patterns.push(pattern);
        // determines if we have to recompute the pattern on each zoom change
        this._isZoomDependant =
          this._isZoomDependant ||
          pattern.isOffsetInPixels ||
          pattern.isEndOffsetInPixels ||
          pattern.isRepeatInPixels ||
          pattern.symbolFactory.isZoomDependant;
      }
    },

    /**
     * Changes the patterns used by this decorator
     * and redraws the new one.
     */
    setPatterns: function (patterns) {
      this.options.patterns = patterns;
      this._initPatterns();
      this._softRedraw();
    },

    /**
     * Changes the patterns used by this decorator
     * and redraws the new one.
     */
    setPaths: function (paths) {
      this._initPaths(paths);
      this.redraw();
    },

    /**
     * Parse the pattern definition
     */
    _parsePatternDef: function (patternDef, latLngs) {
      var pattern = {
        cache: [],
        symbolFactory: patternDef.symbol,
        isOffsetInPixels: false,
        isEndOffsetInPixels: false,
        isRepeatInPixels: false,
      };

      // Parse offset and repeat values, managing the two cases:
      // absolute (in pixels) or relative (in percentage of the polyline length)
      if (
        typeof patternDef.offset === "string" &&
        patternDef.offset.indexOf("%") != -1
      ) {
        pattern.offset = parseFloat(patternDef.offset) / 100;
      } else {
        pattern.offset = patternDef.offset ? parseFloat(patternDef.offset) : 0;
        pattern.isOffsetInPixels = pattern.offset > 0;
      }

      if (
        typeof patternDef.endOffset === "string" &&
        patternDef.endOffset.indexOf("%") != -1
      ) {
        pattern.endOffset = parseFloat(patternDef.endOffset) / 100;
      } else {
        pattern.endOffset = patternDef.endOffset
          ? parseFloat(patternDef.endOffset)
          : 0;
        pattern.isEndOffsetInPixels = pattern.endOffset > 0;
      }

      if (
        typeof patternDef.repeat === "string" &&
        patternDef.repeat.indexOf("%") != -1
      ) {
        pattern.repeat = parseFloat(patternDef.repeat) / 100;
      } else {
        pattern.repeat = parseFloat(patternDef.repeat);
        pattern.isRepeatInPixels = pattern.repeat > 0;
      }

      return pattern;
    },

    onAdd: function (map) {
      this._map = map;
      this._draw();
      // listen to zoom changes to redraw pixel-spaced patterns
      if (this._isZoomDependant) {
        this._map.on("zoomend", this._softRedraw, this);
      }
    },

    onRemove: function (map) {
      // remove optional map zoom listener
      this._map.off("zoomend", this._softRedraw, this);
      this._map = null;
      L.LayerGroup.prototype.onRemove.call(this, map);
    },

    /**
     * Returns an array of ILayers object
     */
    _buildSymbols: function (latLngs, symbolFactory, directionPoints) {
      var symbols = [];
      if (!directionPoints) {
        return symbols;
      }
      for (var i = 0, l = directionPoints.length; i < l; i++) {
        symbols.push(
          symbolFactory.buildSymbol(
            directionPoints[i],
            latLngs,
            this._map,
            i,
            l
          )
        );
      }
      return symbols;
    },

    _getCache: function (pattern, zoom, pathIndex) {
      var zoomCache = pattern.cache[zoom];
      if (typeof zoomCache === "undefined") {
        pattern.cache[zoom] = [];
        return null;
      }
      return zoomCache[pathIndex];
    },

    /**
     * Select pairs of LatLng and heading angle,
     * that define positions and directions of the symbols
     * on the path
     */
    _getDirectionPoints: function (pathIndex, pattern) {
      var zoom = this._map.getZoom();
      var dirPoints = this._getCache(pattern, zoom, pathIndex);
      if (dirPoints) {
        return dirPoints;
      }

      var offset,
        endOffset,
        repeat,
        pathPixelLength = null,
        latLngs = this._paths[pathIndex];
      if (pattern.isOffsetInPixels) {
        pathPixelLength = L.LineUtil.PolylineDecorator.getPixelLength(
          latLngs,
          this._map
        );
        offset = pattern.offset / pathPixelLength;
      } else {
        offset = pattern.offset;
      }
      if (pattern.isEndOffsetInPixels) {
        pathPixelLength =
          pathPixelLength !== null
            ? pathPixelLength
            : L.LineUtil.PolylineDecorator.getPixelLength(latLngs, this._map);
        endOffset = pattern.endOffset / pathPixelLength;
      } else {
        endOffset = pattern.endOffset;
      }
      if (pattern.isRepeatInPixels) {
        pathPixelLength =
          pathPixelLength !== null
            ? pathPixelLength
            : L.LineUtil.PolylineDecorator.getPixelLength(latLngs, this._map);
        repeat = pattern.repeat / pathPixelLength;
      } else {
        repeat = pattern.repeat;
      }
      dirPoints = L.LineUtil.PolylineDecorator.projectPatternOnPath(
        latLngs,
        offset,
        endOffset,
        repeat,
        this._map
      );
      // save in cache to avoid recomputing this
      pattern.cache[zoom][pathIndex] = dirPoints;

      return dirPoints;
    },

    /**
     * Public redraw, invalidating the cache.
     */
    redraw: function () {
      this._redraw(true);
    },

    /**
     * "Soft" redraw, called internally for example on zoom changes,
     * keeping the cache.
     */
    _softRedraw: function () {
      this._redraw(false);
    },

    _redraw: function (clearCache) {
      if (this._map === null) return;
      this.clearLayers();
      if (clearCache) {
        for (var i = 0; i < this._patterns.length; i++) {
          this._patterns[i].cache = [];
        }
      }
      this._draw();
    },

    /**
     * Draw a single pattern
     */
    _drawPattern: function (pattern) {
      var directionPoints, symbols;
      for (var i = 0; i < this._paths.length; i++) {
        directionPoints = this._getDirectionPoints(i, pattern);
        symbols = this._buildSymbols(
          this._paths[i],
          pattern.symbolFactory,
          directionPoints
        );
        for (var j = 0; j < symbols.length; j++) {
          this.addLayer(symbols[j]);
        }
      }
    },

    /**
     * Draw all patterns
     */
    _draw: function () {
      for (var i = 0; i < this._patterns.length; i++) {
        this._drawPattern(this._patterns[i]);
      }
    },
  });
  /*
   * Allows compact syntax to be used
   */
  L.polylineDecorator = function (paths, options) {
    return new L.PolylineDecorator(paths, options);
  };

  L.RotatedMarker = L.Marker.extend({
    options: {
      angle: 0,
    },

    statics: {
      TRANSFORM_ORIGIN: L.DomUtil.testProp([
        "transformOrigin",
        "WebkitTransformOrigin",
        "OTransformOrigin",
        "MozTransformOrigin",
        "msTransformOrigin",
      ]),
    },

    _initIcon: function () {
      L.Marker.prototype._initIcon.call(this);

      this._icon.style[
        L.RotatedMarker.TRANSFORM_ORIGIN
      ] = this._getTransformOrigin();
    },

    _getTransformOrigin: function () {
      var iconAnchor = this.options.icon.options.iconAnchor;

      if (!iconAnchor) {
        return "50% 50%";
      }

      return iconAnchor[0] + "px " + iconAnchor[1] + "px";
    },

    _setPos: function (pos) {
      L.Marker.prototype._setPos.call(this, pos);

      if (L.DomUtil.TRANSFORM) {
        // use the CSS transform rule if available
        this._icon.style[L.DomUtil.TRANSFORM] +=
          " rotate(" + this.options.angle + "deg)";
      } else if (L.Browser.ie) {
        // fallback for IE6, IE7, IE8
        var rad = this.options.angle * (Math.PI / 180),
          costheta = Math.cos(rad),
          sintheta = Math.sin(rad);
        this._icon.style.filter +=
          " progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=" +
          costheta +
          ", M12=" +
          -sintheta +
          ", M21=" +
          sintheta +
          ", M22=" +
          costheta +
          ")";
      }
    },

    setAngle: function (ang) {
      this.options.angle = ang;
    },
  });

  L.rotatedMarker = function (pos, options) {
    return new L.RotatedMarker(pos, options);
  };

  /**
   * Defines several classes of symbol factories,
   * to be used with L.PolylineDecorator
   */

  L.Symbol = L.Symbol || {};

  /**
   * A simple dash symbol, drawn as a Polyline.
   * Can also be used for dots, if 'pixelSize' option is given the 0 value.
   */
  L.Symbol.Dash = L.Class.extend({
    isZoomDependant: true,

    options: {
      pixelSize: 10,
      pathOptions: {},
    },

    initialize: function (options) {
      L.Util.setOptions(this, options);
      this.options.pathOptions.clickable = false;
    },

    buildSymbol: function (dirPoint, latLngs, map, index, total) {
      var opts = this.options,
        d2r = Math.PI / 180;

      // for a dot, nothing more to compute
      if (opts.pixelSize <= 1) {
        return new L.Polyline(
          [dirPoint.latLng, dirPoint.latLng],
          opts.pathOptions
        );
      }

      var midPoint = map.project(dirPoint.latLng);
      var angle = -(dirPoint.heading - 90) * d2r;
      var a = new L.Point(
        midPoint.x + (opts.pixelSize * Math.cos(angle + Math.PI)) / 2,
        midPoint.y + (opts.pixelSize * Math.sin(angle)) / 2
      );
      // compute second point by central symmetry to avoid unecessary cos/sin
      var b = midPoint.add(midPoint.subtract(a));
      return new L.Polyline(
        [map.unproject(a), map.unproject(b)],
        opts.pathOptions
      );
    },
  });

  L.Symbol.dash = function (options) {
    return new L.Symbol.Dash(options);
  };

  L.Symbol.ArrowHead = L.Class.extend({
    isZoomDependant: true,

    options: {
      polygon: true,
      pixelSize: 10,
      headAngle: 60,
      pathOptions: {
        stroke: false,
        weight: 2,
      },
    },

    initialize: function (options) {
      L.Util.setOptions(this, options);
      this.options.pathOptions.clickable = false;
    },

    buildSymbol: function (dirPoint, latLngs, map, index, total) {
      var opts = this.options;
      var path;
      if (opts.polygon) {
        path = new L.Polygon(
          this._buildArrowPath(dirPoint, map),
          opts.pathOptions
        );
      } else {
        path = new L.Polyline(
          this._buildArrowPath(dirPoint, map),
          opts.pathOptions
        );
      }
      return path;
    },

    _buildArrowPath: function (dirPoint, map) {
      var d2r = Math.PI / 180;
      var tipPoint = map.project(dirPoint.latLng);
      var direction = -(dirPoint.heading - 90) * d2r;
      var radianArrowAngle = (this.options.headAngle / 2) * d2r;

      var headAngle1 = direction + radianArrowAngle,
        headAngle2 = direction - radianArrowAngle;
      var arrowHead1 = new L.Point(
          tipPoint.x - this.options.pixelSize * Math.cos(headAngle1),
          tipPoint.y + this.options.pixelSize * Math.sin(headAngle1)
        ),
        arrowHead2 = new L.Point(
          tipPoint.x - this.options.pixelSize * Math.cos(headAngle2),
          tipPoint.y + this.options.pixelSize * Math.sin(headAngle2)
        );

      return [
        map.unproject(arrowHead1),
        dirPoint.latLng,
        map.unproject(arrowHead2),
      ];
    },
  });

  L.Symbol.arrowHead = function (options) {
    return new L.Symbol.ArrowHead(options);
  };

  L.Symbol.Marker = L.Class.extend({
    isZoomDependant: false,

    options: {
      markerOptions: {},
      rotate: false,
    },

    initialize: function (options) {
      L.Util.setOptions(this, options);
      this.options.markerOptions.clickable = false;
      this.options.markerOptions.draggable = false;
      this.isZoomDependant = L.Browser.ie && this.options.rotate;
    },

    buildSymbol: function (directionPoint, latLngs, map, index, total) {
      if (!this.options.rotate) {
        return new L.Marker(directionPoint.latLng, this.options.markerOptions);
      } else {
        this.options.markerOptions.angle =
          directionPoint.heading + (this.options.angleCorrection || 0);
        return new L.RotatedMarker(
          directionPoint.latLng,
          this.options.markerOptions
        );
      }
    },
  });

  L.Symbol.marker = function (options) {
    return new L.Symbol.Marker(options);
  };
})();
