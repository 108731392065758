const LoginDataViaAccessToken = {
  getLoginData(reqData: any) {
    const url = "auth/user/fetchAccountDetails";
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default LoginDataViaAccessToken;
