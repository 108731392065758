export const updateGoogleAPIKey = (key?: any) => {
  document
    .querySelectorAll('script[src^="https://maps.googleapis.com"]')
    .forEach(function (script) {
      script.remove();
    });
  // @ts-ignore
  if (window.google && window.google != undefined) delete window.google.maps;
  var newAPI = document.createElement("script");
  var GOOGLE_MAP_KEY = "";
  const tempLoginData: any = localStorage.getItem("loginData");
  const loginData: any = JSON.parse(tempLoginData);
  if (key) {
    GOOGLE_MAP_KEY = key;
  } else if (loginData) {
    GOOGLE_MAP_KEY = loginData?.map_config?.google
      ? loginData?.map_config?.google?.dashboard
      : loginData?.google_api_key
      ? loginData?.google_api_key
      : "AIzaSyBkRba8rlBqvQfp_4YBgow6fjfKUvMiPNw";
  }

  var lang = "en";
  newAPI.src = `https://maps.googleapis.com/maps/api/js?libraries=geometry,language,places,drawing&amp&key=${GOOGLE_MAP_KEY}`;
  //Callback for the Google Maps API src
  newAPI.async = false;
  // @ts-ignore
  document.querySelector("head").appendChild(newAPI);
  return true;
};
