export const countryList = [
  {
    name: "Australia",
    code: "AU",
    currencies: "AUD",
  },
  {
    name: "Austria",
    code: "AT",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },
  {
    name: "Belgium",
    code: "BE",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },
  {
    name: "Brazil",
    code: "BR",
    currencies: "BRL",
  },
  {
    name: "Canada",
    code: "CA",
    currencies: "CAD,USD",
  },
  {
    name: "Denmark",
    code: "DK",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },
  {
    name: "Finland",
    code: "FI",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },
  {
    name: "France",
    code: "FR",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },
  {
    name: "Germany",
    code: "DE",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },
  {
    name: "Hong Kong",
    code: "HK",
    currencies: "HKD",
  },
  {
    name: "Ireland",
    code: "IE",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },
  {
    name: "Italy",
    code: "IT",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },
  {
    name: "Japan",
    code: "JP",
    currencies: "JPY",
  },
  {
    name: "Luxembourg",
    code: "LU",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },
  {
    name: "Mexico",
    code: "MX",
    currencies: "MXN",
  },
  {
    name: "Netherlands",
    code: "NL",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },

  {
    name: "New Zealand",
    code: "NZ",
    currencies: "NZD",
  },

  {
    name: "Norway",
    code: "NO",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },

  {
    name: "Portugal",
    code: "PT",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },

  {
    name: "Singapore",
    code: "SG",
    currencies: "SGD",
  },

  {
    name: "Spain",
    code: "ES",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },

  {
    name: "Sweden",
    code: "SE",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },

  {
    name: "Switzerland",
    code: "CH",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },

  {
    name: "United Kingdom",
    code: "GB",
    currencies: "EUR,DKK,GBP,NOK,SEK,USD,CHF",
  },

  {
    name: "United States",
    code: "US",
    currencies: "USD",
  },
];
export const industryList = [
  { id: "1520", name: "General Services" },
  { id: "1711", name: "Heating, Plumbing, A/C" },
  { id: "1731", name: "Electrical Services" },
  { id: "1750", name: "Carpentry Services" },
  { id: "1761", name: "Roofing/Siding, Sheet Metal" },
  { id: "1771", name: "Concrete Work Services" },
  { id: "4111", name: "Commuter Transport, Ferries" },
  { id: "4119", name: "Ambulance Services" },
  { id: "4121", name: "Taxicabs/Limousines" },
  { id: "4131", name: "Bus Lines" },
  { id: "4215", name: "Courier Services" },
  {
    id: "4225",
    name:
      "Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage",
  },
  { id: "4411", name: "Cruise Lines" },
  { id: "4457", name: "Boat Rentals and Leases" },
  { id: "4511", name: "Airlines, Air Carriers" },
  { id: "4582", name: "Airports, Flying Fields" },
  { id: "4722", name: "Travel Agencies, Tour Operators" },
  { id: "4814", name: "Telecommunication Services" },
  { id: "4816", name: "Computer Network Services" },
  { id: "4829", name: "Wires, Money Orders" },
  { id: "4899", name: "Cable, Satellite, and Other Pay Television and Radio" },
  { id: "5039", name: "Construction Materials (Not Elsewhere Classified)" },
  { id: "5045", name: "Computers, Peripherals, and Software" },
  { id: "5046", name: "Commercial Equipment (Not Elsewhere Classified)" },
  { id: "5085", name: "Industrial Supplies (Not Elsewhere Classified)" },
  { id: "5099", name: "Durable Goods (Not Elsewhere Classified)" },
  { id: "5139", name: "Commercial Footwear" },
  {
    id: "5169",
    name: "Chemicals and Allied Products (Not Elsewhere Classified)",
  },
  { id: "5172", name: "Petroleum and Petroleum Products" },
  { id: "5192", name: "Books, Periodicals, and Newspapers" },
  { id: "5200", name: "Home Supply Warehouse Stores" },
  { id: "5231", name: "Glass, Paint, and Wallpaper Stores" },
  { id: "5251", name: "Hardware Stores" },
  { id: "5261", name: "Nurseries, Lawn and Garden Supply Stores" },
  { id: "5271", name: "Mobile Home Dealers" },
  { id: "5300", name: "Wholesale Clubs" },
  { id: "5311", name: "Department Stores" },
  { id: "5331", name: "Variety Stores" },
  { id: "5411", name: "Grocery Stores, Supermarkets" },
  { id: "5441", name: "Candy, Nut, and Confectionery Stores" },
  { id: "5451", name: "Dairy Products Stores" },
  { id: "5462", name: "Bakeries" },
  {
    id: "5511",
    name:
      "Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing",
  },
  {
    id: "5521",
    name:
      "Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing",
  },
  { id: "5531", name: "Auto and Home Supply Stores" },
  { id: "5532", name: "Automotive Tire Stores" },
  { id: "5533", name: "Automotive Parts and Accessories Stores" },
  { id: "5541", name: "Service Stations" },
  { id: "5542", name: "Automated Fuel Dispensers" },
  { id: "5551", name: "Boat Dealers" },
  { id: "5561", name: "Motorcycle Shops, Dealers" },
  { id: "5611", name: "Mens and Boys Clothing and Accessories Stores" },
  { id: "5621", name: "Womens Ready-To-Wear Stores" },
  { id: "5631", name: "Womens Accessory and Specialty Shops" },
  { id: "5641", name: "Childrens and Infants Wear Stores" },
  { id: "5661", name: "Shoe Stores" },
  { id: "5697", name: "Tailors, Alterations" },
  { id: "5698", name: "Wig and Toupee Stores" },
  {
    id: "5712",
    name:
      "Furniture, Home Furnishings, and Equipment Stores, Except Appliances",
  },
  { id: "5734", name: "Computer Software Stores" },
  { id: "5811", name: "Caterers" },
  { id: "5812", name: "Eating Places, Restaurants" },
  { id: "5912", name: "Drug Stores and Pharmacies" },
  { id: "5921", name: "Package Stores-Beer, Wine, and Liquor" },
  { id: "5932", name: "Antique Shops" },
  { id: "5935", name: "Wrecking and Salvage Yards" },
  { id: "5937", name: "Antique Reproductions" },
  { id: "5940", name: "Bicycle Shops" },
  { id: "5941", name: "Sporting Goods Stores" },
  { id: "5942", name: "Book Stores" },
  {
    id: "5944",
    name: "Jewelry Stores, Watches, Clocks, and Silverware Stores",
  },
  { id: "5945", name: "Hobby, Toy, and Game Shops" },
  { id: "5946", name: "Camera and Photographic Supply Stores" },
  { id: "5947", name: "Gift, Card, Novelty, and Souvenir Shops" },
  { id: "5960", name: "Direct Marketing - Insurance Services" },
  { id: "5963", name: "Door-To-Door Sales" },
  { id: "5969", name: "Direct Marketing - Other" },
  { id: "5970", name: "Artists Supply and Craft Shops" },
  { id: "5971", name: "Art Dealers and Galleries" },
  { id: "5972", name: "Stamp and Coin Stores" },
  { id: "5977", name: "Cosmetic Stores" },
  { id: "5983", name: "Fuel Dealers (Non Automotive)" },
  { id: "5992", name: "Florists" },
  { id: "5993", name: "Cigar Stores and Stands" },
  { id: "5995", name: "Pet Shops, Pet Food, and Supplies" },
  { id: "5997", name: "Electric Razor Stores" },
  { id: "6011", name: "Automated Cash Disburse" },
  { id: "6513", name: "Real Estate Agents and Managers - Rentals" },
  { id: "7011", name: "Hotels, Motels, and Resorts" },
  { id: "7210", name: "Laundry, Cleaning Services" },
  { id: "7211", name: "Laundries" },
  { id: "7217", name: "Carpet/Upholstery Cleaning" },
  { id: "7221", name: "Photographic Studios" },
  { id: "7230", name: "Barber and Beauty Shops" },
  { id: "7273", name: "Dating/Escort Services" },
  { id: "7277", name: "Counseling Services" },
  { id: "7278", name: "Buying/Shopping Services" },
  { id: "7296", name: "Clothing Rental" },
  { id: "7297", name: "Massage Parlors" },
  { id: "7298", name: "Health and Beauty Spas" },
  { id: "7311", name: "Advertising Services" },
  { id: "7321", name: "Credit Reporting Agencies" },
  { id: "7333", name: "Commercial Photography, Art and Graphics" },
  { id: "7349", name: "Cleaning and Maintenance" },
  { id: "7361", name: "Employment/Temp Agencies" },
  { id: "7372", name: "Computer Programming" },
  { id: "7379", name: "Computer Repair" },
  { id: "7392", name: "Consulting, Public Relations" },
  { id: "7394", name: "Equipment Rental" },
  { id: "7512", name: "Car Rental Agencies" },
  { id: "7531", name: "Auto Body Repair Shops" },
  { id: "7535", name: "Auto Paint Shops" },
  { id: "7538", name: "Auto Service Shops" },
  { id: "7542", name: "Car Washes" },
  { id: "7549", name: "Towing Services" },
  { id: "7623", name: "A/C, Refrigeration Repair" },
  { id: "7631", name: "Watch/Jewelry Repair" },
  { id: "7641", name: "Furniture Repair, Refinishing" },
  { id: "7692", name: "Welding Repair" },
  { id: "7841", name: "Video Tape Rental Stores" },
  { id: "7929", name: "Bands, Orchestras" },
  { id: "7932", name: "Billiard/Pool Establishment" },
  { id: "7933", name: "Bowling Alleys" },
  { id: "7994", name: "Video Game Arcades" },
  { id: "7995", name: "Betting/Casino Gambling" },
  { id: "7996", name: "Amusement Parks/Carnivals" },
  { id: "7997", name: "Country Clubs" },
  { id: "7998", name: "Aquariums" },
  { id: "8011", name: "Doctors" },
  { id: "8041", name: "Chiropractors" },
  { id: "8049", name: "Chiropodists, Podiatrists" },
  { id: "8050", name: "Nursing/Personal Care" },
  { id: "8062", name: "Hospitals" },
  { id: "8099", name: "Medical Services" },
  { id: "8220", name: "Colleges, Universities" },
  { id: "8241", name: "Correspondence Schools" },
  { id: "8244", name: "Business/Secretarial Schools" },
  { id: "8249", name: "Vocational/Trade Schools" },
  { id: "8299", name: "Educational Services" },
  { id: "8351", name: "Child Care Services" },
  {
    id: "8398",
    name: "Charitable and Social Service Organizations - Fundraising",
  },
  { id: "8641", name: "Civic, Social, Fraternal Associations" },
  { id: "8675", name: "Automobile Associations" },
  { id: "8734", name: "Testing Laboratories" },
  { id: "8911", name: "Architectural/Surveying Services" },
  { id: "8931", name: "Accounting/Bookkeeping Services" },
  {
    id: "9211",
    name: "Court Costs, Including Alimony and Child Support - Courts of Law",
  },
  {
    id: "9223",
    name:
      "Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency)",
  },
  { id: "0742", name: "Veterinary Services" },
];
