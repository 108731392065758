import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import "./JobListFilter.scss";
import Btn from "../../../components/Button/Button";
import useLocalStorage from "../../../utils/localStorage";
import { themeColor } from "../../../constants/themeColor";
import { Checkbox } from "@material-ui/core";
import { extensionList } from "../../../constants/extensionList";

const useStyles = makeStyles({
  drop: {
    "&.dropdown-container": {
      height: "10px",
    },
  },
  space: {
    marginRight: "10px",
  },
  switch: {
    float: "left",
    width: "100%",
    // marginTop: "1rem",
    // paddingLeft: "1rem",
  },
  columnsFilter: {
    display: "inline-block",
    paddingBottom: "2rem",
  },
});

const JobColumnFilter = (props: any) => {
  const colunmList = props.list;
  const classes = useStyles();
  const { t, i18n } = useTranslation(["Jobfilter", "Common"]);
  const { handleSubmit } = useForm();
  const [isLoading, setisLoading] = useState(true);
  const [columns, setColumns]: any = useState(props.list);
  const loginData: any = useLocalStorage.getItem("loginData");
  const activatedExtension = useLocalStorage.getItem("activatedExtension");
  const isRouteOptimization = activatedExtension.includes(
    extensionList.routeOptimization.id
  );
  useEffect(() => {
    if (!loginData.workflow_type || loginData.workflow_type == 2) {
      let list = props.list;

      let index = list.findIndex((element: any, index: any) => {
        return element.name === "Service Details";
      });
      if (index >= 0) {
        list.splice(index, 1);
        setColumns(list);
      }
      setisLoading(false);
    } else {
      let list = props.list;
      let index = [];
      for (let i = 0; i < list.length; i++) {
        if (
          list[i].name === "Pickup Details" ||
          list[i].name === "Delivery Details"
        ) {
          index.push(i);
        }
      }

      if (index.length > 0) {
        list.splice(index[0], 2);
        setColumns(list);
      }
      setisLoading(false);
    }
  }, []);

  const onSubmit = () => {
    if (isRouteOptimization) {
      let additionalResources = columns.filter(
        (element: any) => element.key === "ROdriver_column"
      );
      if (additionalResources[0].value == true) {
        useLocalStorage.setItem("isResourceFetchTrue", 1);
      } else {
        useLocalStorage.setItem("isResourceFetchTrue", 0);
      }
    }
    useLocalStorage.setItem("filteredColumns", columns);
    props.sendFilteredList(columns);
  };
  const checkBoxHandler = (event: any, index: any) => {
    let tempColumn = [...columns];
    tempColumn[index].value = event.target.checked;
    setColumns(tempColumn);
  };
  const resetFields = () => {
    let tempFields = [...columns];
    tempFields.map((val: any, index) => {
      if (index != 0) val.value = true;
    });
    setColumns(tempFields);
  };
  const createFields = () => {
    return columns.map((val: any, index: any) => {
      if (index !== 0 && index !== 1) {
        return (
          <div key={val.key}>
            {colunmList[index]?.template_name ? (
              colunmList[index].template_name !=
              colunmList[index - 1]?.template_name ? (
                <>
                  <hr></hr>

                  <div style={{ padding: "5px" }}>
                    <b style={{ marginLeft: "3%" }}>
                      {colunmList[index].template_name}
                    </b>
                  </div>
                </>
              ) : null
            ) : (
              ""
            )}
            {colunmList[index]?.name == "Actions" ? <hr></hr> : ""}

            <label className={classes.switch}>
              <Checkbox
                checked={val.value ? true : false}
                color={"primary"}
                onChange={(e) => checkBoxHandler(e, index)}
              />
              <span style={{ marginLeft: "0.3rem" }}>
                {val.name !== "Creation Datetime" && val.name}
                {val.name === "Creation Datetime" && "Creation Date Time"}
              </span>
            </label>
            <br />
          </div>
        );
      }
    });
  };
  return (
    <div
      style={{
        background: `${themeColor.defaultBackgroundColor}`,
        height: "calc(100vh - 70px)",
        overflow: "overlay",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"drop"}>
          <>
            {!isLoading && (
              <div className={classes.columnsFilter}>{createFields()}</div>
            )}
            <div className={"button"}>
              <Btn
                type={"Submit"}
                btnText={t("Common:Apply")}
                className={classes.space}
              >
                {" "}
              </Btn>
              <Btn
                type={"button"}
                btnText={t("Common:Reset")}
                onClick={resetFields}
                color={"default"}
              >
                {" "}
              </Btn>
            </div>
          </>
        </div>
      </form>
    </div>
  );
};
export default JobColumnFilter;
