export const BusinessUsecase = (t: any) => {
  return [
    {
      workflow_type: 0,
      use_case: 1,
      job_name: t("Profile:Pickup & Delivery"),
    },
    {
      workflow_type: 0,
      use_case: 2,
      job_name: t("Profile:White-Glove Delivery"),
    },

    {
      workflow_type: 0,
      use_case: 3,
      job_name: t("Profile:Courier Delivery"),
    },

    {
      workflow_type: 0,
      use_case: 4,
      job_name: t("Profile:Cannabis"),
    },

    {
      workflow_type: 1,
      use_case: 5,
      job_name: t("Profile:Field Workforce"),
    },

    {
      workflow_type: 1,
      use_case: 6,
      job_name: t("Profile:Home Services"),
    },
    {
      workflow_type: 1,
      use_case: 7,
      job_name: t("Profile:Healthcare Professional"),
    },
    {
      workflow_type: 0,
      use_case: 8,
      job_name: t("Profile:Others"),
    },
  ];
};
