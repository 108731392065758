const setVariables = (storeFront: any) => {
  if (storeFront.button) {
    document.documentElement.style.setProperty(
      "--primary-theme",
      storeFront.button
    );
  }

  if (storeFront.sidebar) {
    document.documentElement.style.setProperty(
      "--sidebar-theme",
      storeFront.sidebar
    );
  }
  if (storeFront.activeSidebarBorderImage) {
    document.documentElement.style.setProperty(
      "--active-sidebar-border-image",
      storeFront.activeSidebarBorderImage
    );
  }
  if (storeFront.activeSidebarBackgroundImage) {
    document.documentElement.style.setProperty(
      "--active-sidebar-background-image",
      storeFront.activeSidebarBackgroundImage
    );
  }
  if (storeFront.ds_header_color) {
    document.documentElement.style.setProperty(
      "--ds_header_color",
      storeFront.ds_header_color
    );
  }
  if (storeFront.ds_theme_color) {
    document.documentElement.style.setProperty(
      "--ds_theme_color",
      storeFront.ds_theme_color
    );
  }
  if (storeFront.ds_graph_fill_color) {
    document.documentElement.style.setProperty(
      "--ds_graph_fill_color",
      storeFront.ds_graph_fill_color
    );
  }
  if (storeFront.ds_graph_label_color) {
    document.documentElement.style.setProperty(
      "--ds_graph_label_color",
      storeFront.ds_graph_label_color
    );
  }
  if (storeFront.ds_panel_heading_color) {
    document.documentElement.style.setProperty(
      "--ds_panel_heading_color",
      storeFront.ds_panel_heading_color
    );
  }
  if (storeFront.ds_side_panel_color) {
    document.documentElement.style.setProperty(
      "--ds_side_panel_color",
      storeFront.ds_side_panel_color
    );
  }
  if (storeFront.ds_side_tab_active_color) {
    document.documentElement.style.setProperty(
      "--ds_side_tab_active_color",
      storeFront.ds_side_tab_active_color
    );
  }
};
export default setVariables;
