export const TaskDetail = {
  getTrackingToken(reqData: any) {
    return {
      url: "main/task/tracking/link",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getPolyline(reqData: any) {
    return {
      url: "socket/task/polyline",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getAssignmentLogs(reqData: any) {
    return {
      url: "assignment/task/assignment/history",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};
