export const paymentModes: any = {
  1: {
    name: "Cash",
  },
  2: {
    name: "Stripe",
  },
  3: {
    name: "Flutterwave",
  },
  4: {
    name: "Bank Transfer",
  },
  5: {
    name: "POS",
  },
};
export const paymentTypes: any = {
  1: {
    name: "Prepaid",
  },
  2: {
    name: "Postpaid",
  },
};
