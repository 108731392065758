import {
    Backdrop,
    Fade,
    Grid,
    InputAdornment,
    makeStyles,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import SearchIcon from "@material-ui/icons/Search";
import "./TaskHistory.scss";
import NoRecord from "../../NoRecord/NoRecord"
import TaskLocationMap from "./TaskLocationMap";
import Btn from "../../Button/Button";


const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        minWidth: "700x",
    },
    table: {

        "& .MuiTableCell-head": {
            fontWeight: 700,
        },
    },
    input: {
        borderRadius: "20px!important",
        height: "45px",
        backgroundColor: "#f5f5f5 !important",
    },

    searchOnBlur: {
        width: "120px",
        transition: "width .8s",
    },
    searchOnFocus: {
        width: "180px",
        transition: "width .8s",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 2, 2),
        width: "60%",
        outline: "none",
        borderRadius: "10px",
        height: "70vh",
        // overflowY: "scroll",
    },
}));

const AssignmentLogs = forwardRef((props: any, ref: any) => {
    const classes = useStyles();
    const [open, setOpen]: any = useState();
    const defaultAssignmentData = props.assignmentResourceData;
    const [assignmentData, setAssignmentData]: any = useState([]);
    const searchRef: any = useRef();
    const [showMap, setShowMap]: any = useState(false);


    const [mapData, setMapData]: any = useState({});
    const handleClose = () => {
        setOpen(false);
        setAssignmentData([])
        setShowMap(false)
    };
    useImperativeHandle(ref, () => ({
        handleOpen(operation: any, row: any) {
            setOpen(true);
        },
    }));
    const columns: any = [
        "Agent ID",
        "Agent Name",
        "Notification Time",

        "Agent Location",
    ];
    if (props.showDistance) {
        columns.splice(3, 0, "Aerial Distance")
    }

    useEffect(() => {
        setAssignmentData(props.assignmentResourceData);
    }, [props.assignmentResourceData]);

    const handleSearchAgent = (input: any) => {

        const filtered = defaultAssignmentData.filter((agent: any) => {
            return (agent?.type === 12) ? agent : (agent?.type === 11) ? null : (agent.name.toLowerCase().includes(input.toLowerCase()) || agent.resource_id.toString().includes(input));
        });
        setAssignmentData([...filtered]);
    };

    const agentLocationHandler = (value: any, data?: any) => {
        if (value) {
            setMapData(data);
        }
        setShowMap(value);
    };

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                className={classes.modal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        {!showMap ? (
                            <>
                                <Grid container justify="space-between" alignItems="center" style={{ borderBottom: '.5px solid lightgrey' }}>
                                    <h3>Assignment Logs</h3>

                                    <div className={classes.searchOnFocus}>
                                        <TextField
                                            id="search"
                                            className={classes.input}
                                            name="search"
                                            variant="outlined"
                                            placeholder={"Search"}
                                            style={
                                                !props.title && props.searchBar
                                                    ? { marginBottom: "5px" }
                                                    : {}
                                            }
                                            inputRef={searchRef}
                                            type="search"

                                            onChange={(e: any) => {
                                                if (e.target.value.length > 0) {
                                                    handleSearchAgent(e.target.value);
                                                } else {
                                                    setAssignmentData(defaultAssignmentData);
                                                }

                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon
                                                            style={{
                                                                color: "var(--primary-theme)",
                                                                marginRight: "-15px",
                                                            }}

                                                        />
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    root: classes.input,
                                                },
                                            }}

                                        />
                                    </div>
                                </Grid>
                                {assignmentData && assignmentData.length > 0 ?
                                    <TableContainer style={{ maxHeight: "60vh" }}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((element: any) => (
                                                        <TableCell style={{ width: '20%' }}>{element}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>


                                                {assignmentData.map((row: any, index: number) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell component="th" scope="row">
                                                                {row.type === 12 ? <p style={{ margin: "0", fontWeight: "bold" }}>{row.resource_id}</p> : row.resource_id ? row.resource_id : "-"}
                                                            </TableCell>
                                                            <TableCell>{(row.type === 11 || row.type === 12) ? " " : row.name || "-"}</TableCell>
                                                            <TableCell>{(row.type === 11 || row.type === 12) ? " " : row.time || "-"}</TableCell>

                                                            {props.showDistance ? <TableCell>{(row.type === 11 || row.type === 12) ? " " : (row.distance ? row.distance : "-")}</TableCell> : ''}
                                                            <TableCell>
                                                                {(row.type === 11 || row.type === 12) ? " " : row.lat ? <Typography
                                                                    style={{
                                                                        color: "blue",
                                                                        textDecoration: "underline",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                        agentLocationHandler(true, row);
                                                                    }}
                                                                >
                                                                    View Location
                                                                </Typography> : "-"}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>

                                    :
                                    <div>
                                        <NoRecord />
                                    </div>
                                }
                            </>
                        ) : (
                            <div className="map-container">
                                <Grid
                                    container
                                    justify="space-between"
                                    alignItems="center"
                                    className="map-container__header"
                                >
                                    <h3>Agent Location</h3>
                                </Grid>
                                <Grid className="map-container__body">
                                    <div>
                                        <TaskLocationMap
                                            taskHistoryDetail={{
                                                ...mapData,
                                                task_id: mapData.resource_id,
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    className="map-container__footer"
                                    container
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <Btn
                                        color="default"
                                        btnText="Back"
                                        type="button"
                                        onClick={() => {
                                            agentLocationHandler(false);
                                        }}
                                    />
                                </Grid>
                            </div>
                        )}
                    </div>
                </Fade>
            </Modal>
        </>
    );
});

export default AssignmentLogs;
