export const OnboardingEndpoint = {
  registerUser(reqData: any) {
    return {
      url: "auth/user/onboard",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getIPInfo() {
    return {
      API_BASE_URL: "https://ipinfo.io/",
      url: "json/?token=227afdb4573cc9",
      data: {},
      headers: {},
    };
  },
  updateProfile(reqData: any) {
    return {
      url: "auth/user/update",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};
