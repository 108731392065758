export const jobStatusData: any = {
  "0": {
    name: "Unassigned",
    status: 0,
    color: "#999999",
    gradientColor: "#99999975",
    markerIcon: "/assets/images/jobs-fleet-marker/assigned_pickup.png",
  },
  "1": {
    name: "Assigned",
    status: 1,
    color: "#fc8344",
    gradientColor: "#fc834475",
    markerIcon: "/assets/images/jobs-fleet-marker/assigned_pickup.png",
  },
  "2": {
    name: "Accepted",
    status: 2,
    color: "#ba68c8",
    gradientColor: "#ba68c861",
    markerIcon: "/assets/images/jobs-fleet-marker/assigned_pickup.png",
  },
  "3": {
    name: "In Progress",
    status: 3,
    color: "#2196f3",
    gradientColor: "#2196f373",
    markerIcon: "/assets/images/jobs-fleet-marker/assigned_pickup.png",
  },
  "4": {
    name: "Arrived",
    status: 4,
    color: "#4051b5",
    gradientColor: "#4051b575",
    markerIcon: "/assets/images/jobs-fleet-marker/assigned_pickup.png",
  },
  "5": {
    name: "Completed",
    status: 5,
    color: "#2c9f2c",
    gradientColor: "#2c9f2c75",
    markerIcon: "/assets/images/jobs-fleet-marker/assigned_pickup.png",
  },
  "6": {
    name: "Failed",
    status: 6,
    color: "#F18D29",
    gradientColor: "#e5393575",
    markerIcon: "/assets/images/jobs-fleet-marker/assigned_pickup.png",
  },
  "7": {
    name: "Cancelled",
    status: 7,
    color: "#e53935",
    gradientColor: "#e5393575",
    markerIcon: "/assets/images/jobs-fleet-marker/assigned_pickup.png",
  },
};
export const jobMarkers: any = {
  0: {
    0: {
      icon: "/assets/images/jobs-fleet-marker/unassigned_pickup.svg",
    },
    1: {
      icon: "/assets/images/jobs-fleet-marker/assigned_pickup.svg",
    },
    2: {
      icon: "/assets/images/jobs-fleet-marker/accepted_pickup.svg",
    },
    3: {
      icon: "/assets/images/jobs-fleet-marker/intransit_pickup.svg",
    },
    4: {
      icon: "/assets/images/jobs-fleet-marker/arrived_pickup.svg",
    },
    5: {
      icon: "/assets/images/jobs-fleet-marker/completed_pickup.svg",
    },
    6: {
      icon: "/assets/images/jobs-fleet-marker/failed_pickup.svg",
    },
    7: {
      icon: "/assets/images/jobs-fleet-marker/failed_pickup.svg",
    },
  },
  1: {
    0: {
      icon: "/assets/images/jobs-fleet-marker/unassigned_delivery.svg",
    },
    1: {
      icon: "/assets/images/jobs-fleet-marker/assigned_delivery.svg",
    },
    2: {
      icon: "/assets/images/jobs-fleet-marker/accepted_delivery.svg",
    },
    3: {
      icon: "/assets/images/jobs-fleet-marker/intransit_delivery.svg",
    },
    4: {
      icon: "/assets/images/jobs-fleet-marker/arrived_delivery.svg",
    },
    5: {
      icon: "/assets/images/jobs-fleet-marker/completed_delivery.svg",
    },
    6: {
      icon: "/assets/images/jobs-fleet-marker/failed_delivery.svg",
    },
    7: {
      icon: "/assets/images/jobs-fleet-marker/failed_delivery.svg",
    },
  },
  2: {
    0: {
      icon: "/assets/images/jobs-fleet-marker/unassigned_appointment.png",
    },
    1: {
      icon: "/assets/images/jobs-fleet-marker/assigned_appointment.png",
    },
    2: {
      icon: "/assets/images/jobs-fleet-marker/accepted_appointment.png",
    },
    3: {
      icon: "/assets/images/jobs-fleet-marker/intransit_appointment.png",
    },
    4: {
      icon: "/assets/images/jobs-fleet-marker/arrived_appointment.png",
    },
    5: {
      icon: "/assets/images/jobs-fleet-marker/completed_appointment.png",
    },
    6: {
      icon: "/assets/images/jobs-fleet-marker/failed_appointment.png",
    },
    7: {
      icon: "/assets/images/jobs-fleet-marker/failed_appointment.png",
    },
  },
};
export const JobStatusDataList = (t: any) => {
  return [
    { name: t("Common:Unassigned"), status: 0 },
    { name: t("Common:Assigned"), status: 1 },
    { name: t("Common:Accepted"), status: 2 },
    { name: t("Common:In Progress"), status: 3 },
    { name: t("Common:Arrived"), status: 4 },
    { name: t("Common:Completed"), status: 5 },
    { name: t("Common:Failed"), status: 6 },
    { name: t("Common:Cancelled"), status: 7 },
  ];
};
