const JobListEndpoints = {
  retryAllocation(reqData: any) {
    return {
      url: "main/job/retryAllocation",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  bulkReassignJobs(reqData: any) {
    return {
      url: "main/job/bulk/update",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  bulkDeleteJobs(reqData: any) {
    return {
      url: "main/job/bulk/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default JobListEndpoints;
