import React, { useEffect, useState, Fragment } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import classes from "../Header/Header.module.scss";
import classes from "../../assets/css/HeaderDropdown.module.scss";
import CommonEndpoints from "../../services/commonApi.service";
import useApiService from "../../services/api.service";
import { useAppContext } from "../../AppContext/App.context";
import useLocaStorage from "../../hooks/localStorage";
import headerDropdownService from "../../services/headerDropdown.service";
import TeamDropdownIcon from "../../assets/images/header/team_dropdown_icon.svg";

const StripePaymentTypeDropdown = (props: any) => {
  const stripePaymentTypes = [
    { id: 0, name: "Job Payments" },
    { id: 1, name: "Referral Payments" },
  ];
  const [selectedType, setTeamId] = useState(0);

  const onTypeChange = (event: any) => {
    setTeamId(event.target.value);
    const dropdownData = {
      type: "stripePaymentType",
      data: event.target.value,
    };
    headerDropdownService.emit(dropdownData);
  };

  const renderTeamList = () => {
    return (
      stripePaymentTypes.length > 0 &&
      stripePaymentTypes.map((type: any, index: any) => {
        return <MenuItem value={type.id}> {type.name}</MenuItem>;
      })
    );
  };

  return (
    <Fragment>
      {stripePaymentTypes.length ? (
        <div style={{ display: "flex" }} className={classes.dropdownOuter}>
          <Select
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              getContentAnchorEl: null,
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            disableUnderline={true}
            value={selectedType}
            onChange={onTypeChange}
          >
            {renderTeamList()}
          </Select>
        </div>
      ) : (
        <div></div>
      )}
    </Fragment>
  );
};
export default StripePaymentTypeDropdown;
