export const resourceStatusData: any = {
  "0": {
    name: "Free",
    status: 0,
    icon: "/assets/images/jobs-fleet-marker/driver_idle.png",
    color: "#2c9f2c",
  },
  "1": {
    name: "Busy",
    status: 1,
    icon: "/assets/images/jobs-fleet-marker/driver_intransit.png",
    color: "#3096f2",
  },
  "2": {
    name: "Offline",
    status: 2,
    icon: "/assets/images/jobs-fleet-marker/driver_offline.png",
    color: "#9b9b9b",
  },
  "3": {
    name: "Blocked",
    status: 3,
    icon: "/assets/images/jobs-fleet-marker/driver_offline.png",
    color: "#9b9b9b",
  },
  "4": {
    name: "Verification Pending",
    status: 4,
    icon: "/assets/images/jobs-fleet-marker/driver_offline.png",
    color: "#9b9b9b",
  },
  "5": {
    name: "Success",
    status: 5,
    icon: "/assets/images/jobs-fleet-marker/driver_offline.png",
    color: "#9b9b9b",
  },
  "6": {
    name: "Rejected",
    status: 6,
    icon: "/assets/images/jobs-fleet-marker/driver_offline.png",
    color: "#9b9b9b",
  },
};
export const resourceStatus = [
  {
    name: "Free",
    status: 0,
  },
  {
    name: "Busy",
    status: 1,
  },
  {
    name: "Offline",
    status: 2,
  },
  {
    name: "Blocked",
    status: 3,
  },
  {
    name: "Verification Pending",
    status: 4,
  },

  {
    name: "Verification Rejected",
    status: 6,
  },
];
export const resourceFilterData: any = {
  "1": {
    name: "Free",
    status: 0,
    color: "#2c9f2c",
  },
  "2": {
    name: "Busy",
    status: 1,
    color: "#3096f2",
  },
  "0": {
    name: "Offline",
    status: 2,
    color: "#999999",
  },
  "4": {
    name: "Rejected",
    status: 6,
    color: "#E53935",
  },
  "5": {
    name: "Blocked",
    status: 3,
    color: "#6B0B17",
  },
  "3": {
    name: "Verification Pending",
    status: 4,
    color: "#F18D29",
  },
};
