import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Input from "../../../components/Input/Input";
import { JobStatusDataList } from "../../../constants/jobStatusData";
import { useAppContext } from "../../../AppContext/App.context";
import useApiService from "../../../services/api.service";

import useLoader from "../../../hooks/useLoader";
import CommonEndpoints from "../../../services/commonApi.service";
import MultiselectTeamDropdown from "../../../components/MultiselectTeamDropdown/MultiselectTeamDropdown";
import Btn from "../../../components/Button/Button";
import "./JobListFilter.scss";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
//import { DateRange } from "react-date-range";
import DateRange from "../../../components/DateTimePicker/DateRangePicker";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import DateTimeFormatter from "../../../utils/DateFormatter";
import useLocalStorage from "../../../hooks/localStorage";
import useToast from "../../../components/Toast/hooks/useToast";
import { ValidationService } from "../../../utils/Validation";
import { RolePermissions } from "../../../enum/RolePermissions";
import {
  ColumnList,
  NumberOperatorList,
  StringOperatorList,
  SelectDateOptionList,
} from "./JobListFilterData";

import { useTranslation } from "react-i18next";
import moment from "moment";
import { themeColor } from "../../../constants/themeColor";
import { Grid } from "@material-ui/core";
import { extensionList } from "../../../constants/extensionList";

const useStyles = makeStyles({
  button: {
    background: "cyan",
    color: "black",
    borderRadius: "10px",
    height: "56px",
    width: "100%",
    "&:hover": {
      background: "cyan",
    },
  },

  drop: {
    "&.dropdown-container": {
      height: "10px",
    },
  },
  space: {
    marginRight: "10px",
  },
  formLabel: {
    color: "#000",
    "&.Mui-focused": {
      color: "#000",
    },
  },
});

const JobListFilter = (props: any) => {
  const [resetSearch, setResetSearch]: any = useState(false);
  const classes = useStyles();
  const loader: any = useLoader();
  const { t, i18n } = useTranslation(["Jobfilter", "Common"]);
  const jobStatusDataList = JobStatusDataList(t);
  const [appData]: any = useAppContext();
  const access_token = appData.loginData.access_token;
  const ApiService = useApiService();
  const [selectedTeamId, setTeamId] = useState([]);
  const [selectedStatus, setStatus] = useState([]);
  const [teamList, setTeamList]: any = useState([]);
  const [startDate, setStartDate]: any = useState(new Date());
  const [endDate, setEndDate]: any = useState(new Date());
  const [dateRadio, setDateRadio] = useState("");
  let [relatedDateValue, setRelatedDateValue]: any = useState();
  let [period, setPeriod]: any = useState(1);
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    errors,
    getValues,
  } = useForm();
  const filterJobData: any = useLocalStorage.getItem("filter_job");
  const Permission: any = useLocalStorage.getItem("Permission");
  const [localStartDate, setLocalStartDate]: any = useState("");
  const [localEndDate, setLocalEndDate]: any = useState("");
  const [operatorList, setOperatorList]: any = useState([]);
  const [fields, setFeilds] = useState(false);
  const [selectedColumn, setColumnName]: any = useState([]);
  const [selectedOperator, setOperator]: any = useState(
    props?.search?.operator || ""
  );
  const roleId = appData.loginData.role_id;
  const toaster = useToast();
  let columnList = ColumnList(t);
  if (useLocalStorage.getItem(`activatedExtension`)) {
    let data: any = localStorage.getItem(`activatedExtension`);
    let activatedExtension: any = JSON.parse(data);

    if (activatedExtension.includes(extensionList.barcodeExtension.id)) {
      columnList.push({ label: t("Common:Barcode"), value: "barcode" });
    }
  }
  const numberOperatorList = NumberOperatorList(t);
  const stringOperatorList = StringOperatorList(t);
  const selectDateOptionList = SelectDateOptionList(t);
  const [showSearch, setShow]: any = useState(props.showSearchOnly);

  const onColumnChange = (evt: any) => {
    setOperator();
    evt.target.value !== "job_id"
      ? setOperatorList(stringOperatorList)
      : setOperatorList(numberOperatorList);
    setColumnName(evt.target.value);
  };
  const onOperatorChange = (evt: any) => {
    setOperator(evt.target.value);
  };
  const [RangeData, setRangeData]: any = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "value",
    },
  ]);
  const onDateRadioChange = (event: any) => {
    if (event.target.value === dateRadio) {
      setDateRadio("");
      setStartDate("");
      setEndDate("");
      setLocalStartDate();
      setLocalEndDate();
    } else {
      setDateRadio(event.target.value);
    }
    setRelatedDateValue();
    if (event.target.value === "calendar") {
      let startDT = RangeData[0].startDate;
      // startDT.setHours(0);
      // startDT.setMinutes(0);
      // startDT.setSeconds(0);
      // setLocalStartDate(startDT);
      // startDT = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(startDT);
      // setStartDate(startDT);
      let endDT = RangeData[0].endDate;
      // endDT.setHours(23);
      // endDT.setMinutes(59);
      // endDT.setSeconds(59);
      // setLocalEndDate(endDT);
      // endDT = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(endDT);
      // setEndDate(endDT);
      onCalendarDateChange(startDT, endDT);
    }
  };
  useEffect(() => {
    //  setShown(true)
    if (Permission && Permission["Read_Teams"]) {
      getTeamList();
    }
    if (props.search) getSearchData();
    if (Object.keys(filterJobData).length) {
      getJobFilterData();
    }
  }, []);

  const getSearchData = async () => {
    setFeilds(true);
    let searchString: any;
    if (typeof props.search === "object") {
      searchString = props.search;
      if (searchString.column !== "job_id") {
        const str = props.search.string;
        setOperatorList(stringOperatorList);
        if (str.charAt(str.length - 1) === "%" && str.charAt(0) === "%") {
          //yourString.substring(1, yourString.length-1)
          // console.log(str.slice(1, -1));
          reset({ searchKeyword: str.slice(1, -1) });
          setOperator("contains");
        } else if (str.charAt(0) === "%") {
          reset({ searchKeyword: str.slice(1) });
          setOperator("ends_with");
        } else {
          reset({ searchKeyword: str.slice(0, -1) });
          setOperator("starts_with");
        }
        setColumnName(searchString.column);
      }
    } else {
      searchString = JSON.parse(props.search);
      if (searchString.column !== "job_id") {
        setOperatorList(stringOperatorList);
        setOperator(searchString.operator);
      } else {
        setOperatorList(numberOperatorList);
        setOperator(searchString.operator);
      }
      reset({
        searchKeyword: searchString.string,
      });
      setColumnName(searchString.column);
    }
  };
  const getJobFilterData = () => {
    let filters: any = {};
    if (filterJobData.team_id) {
      filters.team = setTeamId(filterJobData.team_id);
    }
    if (filterJobData.status_id) {
      filters.status = setStatus(filterJobData.status_id);
    }
    if (filterJobData.start_datetime && filterJobData.end_datetime) {
      let localStartDate = new Date(
        DateTimeFormatter.getUtcFromLocal(
          filterJobData.start_datetime,
          "YYYY-MM-DD HH:mm"
        )
      );
      //  let localStartDate = filterJobData.start_datetime;
      let localEndDate = new Date(
        DateTimeFormatter.getUtcFromLocal(
          filterJobData.end_datetime,
          "YYYY-MM-DD HH:mm"
        )
      );
      console.log(
        DateTimeFormatter.getUtcFromLocal(
          filterJobData.start_datetime,
          "YYYY-MM-DD HH:mm"
        )
      );
      // let localEndDate = filterJobData.end_datetime;
      // console.log(localEndDate);
      setStartDate(localStartDate);
      setEndDate(localEndDate);
      // setEndDate(filterJobData.end_datetime);
      // setStartDate(filterJobData.start_datetime);
      // let startDT: any = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
      //   filterJobData.start_datetime
      // );
      // let endDT: any = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
      //   filterJobData.end_datetime
      // );
      // // console.log(startDT);
      // setStartDate(startDT);
      // setEndDate(endDT);
      // let range_arr = [
      //   {
      //     startDate: new Date(filterJobData.start_datetime),
      //     endDate: new Date(filterJobData.end_datetime),
      //     key: "value",
      //   },
      // ];

      if (filterJobData.dateRadio === "calendar") {
        filters.dateRadio = setDateRadio("calendar");
        //filters.RangeData = setRangeData(range_arr);
      }
      if (filterJobData.dateRadio === "relative") {
        filters.dateRadio = setDateRadio("relative");
        filters.value = setRelatedDateValue(filterJobData.value);
        filters.period = setPeriod(filterJobData.period);
      }
    }
    // reset(filters);
    if (props.search) getSearchData();
  };
  const clearFields = () => {
    //on reset
    setStartDate();
    setEndDate();
    setStartDate();
    setEndDate();
    setDateRadio("");
    // setValue("searchKeyword", "")
    setOperatorList([]);
    if (showSearch) {
      setOperator();
      setResetSearch(true);
      setColumnName([]);
      reset({
        searchKeyword: "",
      });
      // setTimeout(()=>{
      setResetSearch(false);
    }

    reset({
      team: setTeamId([]),
      status: setStatus([]),
      RangeData: setRangeData([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "value",
        },
      ]),
      RelValue: "",
      period: setPeriod(1),
    });
    setRelatedDateValue(0);
  };
  const onDateRangeChange = (val?: number, count?: number) => {
    if (val) {
      period = val;
    }
    if (count) {
      relatedDateValue = count;
    }
    let today: any = new Date();
    period > 0 ? today.setHours(0) : today.setHours(23);
    period > 0 ? today.setMinutes(0) : today.setMinutes(59);
    period > 0 ? today.setSeconds(0) : today.setSeconds(59);
    period > 0 ? setStartDate(today) : setEndDate(today);
    today = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(today);

    let secondDate: any = new Date();
    if (Math.abs(period) === 1) {
      period > 0
        ? secondDate.setDate(secondDate.getDate() + parseInt(relatedDateValue))
        : secondDate.setDate(secondDate.getDate() - parseInt(relatedDateValue));
    } else if (Math.abs(period) == 2) {
      period > 0
        ? secondDate.setDate(secondDate.getDate() + relatedDateValue * 7)
        : secondDate.setDate(secondDate.getDate() - relatedDateValue * 7);
    } else {
      period > 0
        ? secondDate.setMonth(secondDate.getMonth() + Number(relatedDateValue))
        : secondDate.setMonth(secondDate.getMonth() - relatedDateValue);
    }
    period > 0 ? secondDate.setHours(23) : secondDate.setHours(0);
    period > 0 ? secondDate.setMinutes(59) : secondDate.setMinutes(0);
    period > 0 ? secondDate.setSeconds(59) : secondDate.setSeconds(0);
    period > 0 ? setEndDate(secondDate) : setStartDate(secondDate);
    secondDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
      secondDate
    );
    period > 0 ? setStartDate(today) : setStartDate(secondDate);
    period > 0 ? setEndDate(secondDate) : setEndDate(today);
  };
  const onCalendarDateChange = (start: any, end: any) => {
    let startDate = start;
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    // startDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(startDate);
    setStartDate(startDate);
    let endDate = end || start;
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    // endDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(endDate);
    setEndDate(endDate);

    // const endDateObj = { ...value };
    // let secondDate: any = new Date(endDateObj.value.endDate);
    // secondDate.setHours(23);
    // secondDate.setMinutes(59);
    // secondDate.setSeconds(59);
    // setLocalEndDate(secondDate);
    // secondDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
    //   secondDate
    // );
    // setEndDate(secondDate);
    // const startDateObj = { ...value };
    // let firstDate: any = new Date(startDateObj.value.startDate);
    // firstDate.setHours(0);
    // firstDate.setMinutes(0);
    // firstDate.setSeconds(0);
    // setLocalStartDate(firstDate);
    // firstDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
    //   firstDate
    // );
    // setStartDate(firstDate);
    // setRangeData([value.value]);
  };
  const getTeamList = async () => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams = {
      limit: 1000,
      skip: 0,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(CommonEndpoints.getTeamList(reqData));
    setTeamListDropdown(res.data.data);
  };
  const setTeamListDropdown = (data: any) => {
    let arr: any = data || [];
    arr.unshift({ team_id: null, team_name: t("Jobfilter:No Team") });
    setTeamList(arr);
  };
  const onSubmit = (data: any) => {
    let request: any = [];
    let TeamIds: any = [];
    let filterData: any = {};
    var searchValidation = false;
    // Search Filter
    if (
      (data && data.searchKeyword) ||
      selectedColumn.length ||
      selectedOperator
    ) {
      searchValidation = true;
    }
    // Search Filter
    if (data.searchKeyword && searchValidation) {
      if (!data.searchKeyword.trim()) {
        toaster.addToast({
          message: t("Jobfilter:Please enter search keyword"),
          timeout: 3000,
          type: "error",
        });
        return;
      }
    }
    if (!selectedColumn.length && searchValidation) {
      toaster.addToast({
        message: t("Jobfilter:Please select column name"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (!selectedOperator && searchValidation) {
      toaster.addToast({
        message: t("Jobfilter:Please select operator"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (searchValidation && showSearch) {
      var err: any = false;
      numberOperatorList.map((op: any) => {
        if (selectedOperator === op.value && selectedOperator !== "=") {
          return (err = true);
        }
      });
      if (isNaN(data.searchKeyword) && err) {
        toaster.addToast({
          message: t("Jobfilter:Invalid search keywords"),
          timeout: 3000,
          type: "error",
        });
        return;
      }
      let searchData: any;
      let operatorData: any = "LIKE";
      if (
        selectedOperator === "starts_with" ||
        selectedOperator === "ends_with" ||
        selectedOperator === "contains"
      ) {
        let stringData: any;
        if (selectedOperator === "starts_with") {
          stringData = data.searchKeyword + "%";
        } else if (selectedOperator === "ends_with") {
          stringData = "%" + data.searchKeyword;
        } else {
          stringData = "%" + data.searchKeyword + "%";
        }
        searchData = {
          string: stringData,
          operator: operatorData,
          column: selectedColumn,
        };
      } else {
        searchData = JSON.stringify({
          string: data.searchKeyword,
          operator: selectedOperator,
          column: selectedColumn,
        });
      }
      request.push({
        name: "search",
        value: searchData,
      });
      // filterData.search = searchData;
    } else if (showSearch && !searchValidation) {
      props.getFilterData(request, "cancel");
      return;
    }
    loader.showLoader();
    // if (selectedStatus.length) {
    //   filterData.status_id = selectedStatus;
    //   StatusIds = selectedStatus.map((team: any) => {
    //     return parseInt(team.value);
    //   });
    //   request.push({
    //     name: "job_status",
    //     value: StatusIds,
    //   });
    // }

    if (selectedTeamId.length) {
      filterData.team_id = selectedTeamId;
      selectedTeamId.map((team: any) => {
        // if (team.value) {
        TeamIds.push(parseInt(team.value));
        // } else {
        //   request.push({
        //     name: "get_tasks_with_unassigned_teams",
        //     value: true,
        //   });
        // }
      });
      if (TeamIds.length > 0) {
        request.push({
          name: "team_id",
          value: TeamIds,
        });
      }
    }
    if (startDate && endDate && dateRadio) {
      filterData.dateRadio = dateRadio;
      if (dateRadio === "relative") {
        filterData.value = relatedDateValue;
        filterData.period = period;
      }
      let tempFilterData = useLocalStorage.getItem("filter_job");
      if (startDate && endDate) {
        filterData.start_datetime = startDate;
        filterData.end_datetime = endDate;
      } else if (
        (typeof startDate != "string" || typeof endDate != "string") &&
        tempFilterData.start_datetime &&
        tempFilterData.end_datetime
      ) {
        filterData.start_datetime = tempFilterData.start_datetime;
        filterData.end_datetime = tempFilterData.end_datetime;
      }

      if (dateRadio === "relative") {
        request.push(
          {
            name: "start_datetime",
            value: filterData.start_datetime,
          },
          {
            name: "end_datetime",
            value: filterData.end_datetime,
          }
        );
      } else {
        request.push(
          {
            name: "start_datetime",
            value: DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
              filterData.start_datetime
            ),
          },
          {
            name: "end_datetime",
            value: DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
              filterData.end_datetime
            ),
          }
        );
      }
    }

    // let additionalResources =filterData.filter((element:any)=>element.ROdriver_column)
    // if (additionalResources.value == true){
    //   useLocalStorage.setItem("fetch_additional_resources", 1);
    // }else{
    //   useLocalStorage.setItem("fetch_additional_resources", 0);
    // }
    useLocalStorage.setItem("filter_job", filterData);

    props.getFilterData(request);
  };
  const obj = {
    RelValue:
      dateRadio === "relative"
        ? {
            validate: (val: any) => {
              return ValidationService.positiveNumber(val, t);
            },
          }
        : {},
    dateRadio: {},
    searchColumn: {},
    searchKeyword: {},
  };

  return (
    <div
      style={{
        background: `${themeColor.defaultBackgroundColor}`,
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"drop"}>
          {showSearch ? (
            <div className="inputDropdownLabelColor">
              <div style={{ margin: "20px 0px" }}></div>
              {!resetSearch && (
                <Input
                  type="text"
                  id="searchKeyword"
                  name="searchKeyword"
                  label={t("Common:Search Term")}
                  onchange={(evt: any) => {
                    if (evt.target.value.length) {
                      setFeilds(true);
                    } else {
                      setFeilds(false);
                      setColumnName();
                      setOperator();
                    }
                  }}
                  value={getValues("searchKeyword")}
                  refValue={register(obj.searchKeyword)}
                />
              )}

              <div
                style={
                  fields
                    ? { marginBottom: "2rem" }
                    : { opacity: "0.4", marginBottom: "2rem" }
                }
              >
                <Dropdown
                  labelName={t("Jobfilter:Column Name")}
                  onChange={onColumnChange}
                  selectedValue={selectedColumn}
                  itemList={columnList}
                  disabled={fields ? false : true}
                  uniqueId="value"
                  label="label"
                  refValue={register(obj.searchColumn)}
                ></Dropdown>
                <Dropdown
                  labelName={t("Jobfilter:Operator")}
                  onChange={onOperatorChange}
                  disabled={fields ? false : true}
                  selectedValue={selectedOperator}
                  itemList={operatorList}
                  uniqueId="value"
                  label="label"
                ></Dropdown>
              </div>
            </div>
          ) : (
            ""
          )}
          {showSearch === false ? (
            <div style={{ margin: "20px 13px 2rem 13px" }}>
              <div className={"filterSubHeading"}>{t("Jobfilter:Teams")}</div>
              <MultiselectTeamDropdown
                class="drop"
                overrideStrings={{
                  selectSomeItems: t("Jobfilter:Select Teams"),
                  allItemsAreSelected: t("Jobfilter:All teams are selected"),
                  selectAll: t("Jobfilter:Select All Teams"),
                  search: t("Jobfilter:Search Team"),
                  clearSearch: t("Jobfilter:Clear Search"),
                }}
                options={teamList}
                uniqueLabel={"team_name"}
                uniqueId={"team_id"}
                value={selectedTeamId}
                onChange={(value: any) => {
                  // console.log(value);
                  setTeamId(value);
                }}
                labelledBy={t("Jobfilter:Select")}
              />
              {/* <MultiselectTeamDropdown
              className={"drop"}
              overrideStrings={{
                selectSomeItems: t("Jobfilter:Select Status"),
                allItemsAreSelected: t("Jobfilter:All status are selected"),
                selectAll: t("Jobfilter:Select All Status"),
                search: t("Jobfilter:Search Status"),
                clearSearch: t("Jobfilter:Clear Search"),
              }}
              options={jobStatusDataList}
              uniqueLabel={"name"}
              uniqueId={"status"}
              value={selectedStatus}
              onChange={(value: any) => {
                setStatus(value);
              }}
              labelledBy={t("Jobfilter:Select")}
            /> */}
              <div style={{ margin: "15px 0px" }}>
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    classes={{ root: classes.formLabel }}
                    style={{ fontWeight: 600 }}
                  >
                    {t("Jobfilter:Date Range")}
                  </FormLabel>
                  <RadioGroup
                    aria-label="date"
                    style={{ display: "inline" }}
                    name="date"
                    value={dateRadio}
                    // defaultChecked = {"calendar"}
                    //onClick={onDateRadioChange}
                  >
                    <FormControlLabel
                      value="calendar"
                      control={
                        <Radio color={"primary"} onClick={onDateRadioChange} />
                      }
                      label={t("Jobfilter:Calendar")}
                    />
                    <FormControlLabel
                      value="relative"
                      control={
                        <Radio color={"primary"} onClick={onDateRadioChange} />
                      }
                      label={t("Jobfilter:Relative")}
                    />
                  </RadioGroup>
                  {dateRadio ? (
                    dateRadio === "calendar" ? (
                      <div style={{ textAlign: "center", width: "280px" }}>
                        <DateRange
                          start={startDate}
                          end={endDate}
                          onChange={(start: any, end: any) => {
                            onCalendarDateChange(start, end);
                          }}
                          inline={true}

                          // editableDateInputs={true}
                          // onChange={onCalendarDateChange}
                          // moveRangeOnFirstSelection={false}
                          // ranges={RangeData}
                        />
                      </div>
                    ) : (
                      <Grid container className={"relativeFilter"}>
                        <Grid item xs={5}>
                          <Input
                            type="text"
                            id="RelValue"
                            name="RelValue"
                            label={t("Common:Number")}
                            errors={errors}
                            onkeypress={() => {}}
                            value={relatedDateValue}
                            refValue={register(obj.RelValue)}
                            onchange={(evt: any) => {
                              let val = evt.target.value;
                              setRelatedDateValue(val);
                              onDateRangeChange(period, val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={7} className="inputDropdownLabelColor">
                          <Dropdown
                            labelName={t("Jobfilter:When")}
                            onChange={(evt: any) => {
                              let val = evt.target.value;
                              setPeriod(val);
                              onDateRangeChange(val, relatedDateValue);
                            }}
                            refValue={register}
                            selectedValue={period}
                            itemList={SelectDateOptionList(t)}
                            uniqueId="value"
                            label="label"
                          ></Dropdown>
                        </Grid>
                      </Grid>
                    )
                  ) : (
                    ""
                  )}
                </FormControl>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className={"button"}>
            <Btn
              type={"Submit"}
              btnText={t("Common:Apply")}
              className={classes.space}
            >
              {" "}
            </Btn>
            <Btn
              type={"button"}
              btnText={t("Common:Reset")}
              onClick={clearFields}
              color={"default"}
            >
              {" "}
            </Btn>
          </div>
        </div>
      </form>
    </div>
  );
};

export default JobListFilter;
