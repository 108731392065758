import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import noChat from "../../assets/images/svgs/noRecord.svg";

const NoRecord = () => {
  const { t, i18n } = useTranslation(["Common"]);
  const [showNoRecord, updateShowNoRecord] = useState(false);
  setTimeout(() => {
    updateShowNoRecord(true);
  }, 500);
  return showNoRecord ? (
    <div
      style={{
        textAlign: "center",
        height: "calc(100vh - 183px)",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <img src={noChat}></img>
        </div>
        {t("Common:No data available")}
      </div>
    </div>
  ) : null;
};

export default NoRecord;
