import React, {
  useState,
  forwardRef,
  Suspense,
  useRef,
  useEffect,
} from "react";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import DateTimeFormatter from "../../utils/DateFormatter";
import headerDropdownService from "../../services/headerDropdown.service";
import useToast from "../Toast/hooks/useToast";
import DateRangePopup from "../DateTimePicker/DateRangePicker";
import classes from "../Header/Header.module.scss";
import headerDropdownClasses from "../../assets/css/HeaderDropdown.module.scss";
import { useLocation } from "react-router";

const DateRangePopupHeader = forwardRef((props: any, ref: any) => {
  console.log("datestart", props.startDate);
  const dateRangeRef: any = useRef();
  const toaster = useToast();
  let startDT: any = new Date();
  let currentEnDate: any = new Date();
  const location = useLocation();

  const [count, setCount] = useState(0);
  if (props.startDate != undefined) {
    startDT = startDT.setDate(startDT.getDate() - props.startDate);
    console.log("tripif1", startDT);
  } else if (props.trip) {
    // startDT = startDT.setDate(startDT.getDate())
    // console.log("trip",startDT)
    startDT = startDT.setDate(startDT.getDate());
    currentEnDate = currentEnDate.setDate(
      currentEnDate.getDate() +
        (props.maxRange ? Number(props.maxRange) : currentEnDate.getDate())
    );
  } else {
    startDT = startDT.setDate(
      startDT.getDate() - (props.maxRange ? Number(props.maxRange) - 1 : 6)
    );
    console.log("tripelse", startDT);
  }
  startDT = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
    startDT,
    "YYYY-MM-DD"
  );
  currentEnDate = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
    currentEnDate,
    "YYYY-MM-DD"
  );
  console.log("end", currentEnDate);

  console.log("end---", currentEnDate);
  const [dateRange, setDateRange]: any = useState({
    startDate: startDT,
    endDate: currentEnDate,
  });
  console.log("date------", dateRange.startDate);
  console.log("endDate---------", dateRange.endDate);
  const onChangeDateRange = (range: any) => {
    const dropdownData = {
      type: "dateRange",
      data: { date: range },
    };
    headerDropdownService.emit(dropdownData);
  };

  let currentDate: any = new Date();
  let startDate = new Date(currentDate.setDate(currentDate.getDate() - 30));

  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  // startDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(startDate);
  let endDate = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);

  return (
    <div className={headerDropdownClasses.dropdownOuter}>
      <div className={classes.pickerIcon}>
        <CalendarTodayIcon
          className={classes["datepickerIcon"]}
          style={{ marginBottom: "0px" }}
          //style={{ color: themeColor.iconsColor }}
        />
        <DateRangePopup
          className={"standardRange"}
          start={dateRange.startDate}
          highlightDates={props.highlightDates ? props.highlightDates : null}
          end={dateRange.endDate}
          maxRange={props.maxRange ? props.maxRange : "7"}
          onChange={(start: any, end: any) => {
            let startDate = start;
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            // startDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(startDate);
            let endDate = end || start;
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);

            // endDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(endDate);
            let range: any = { startDate, endDate };
            setDateRange(range);
            if (location.pathname === "/routes/list") {
              let rangeForRouteList: any = { start, end };
              onChangeDateRange(rangeForRouteList);
            } else {
              onChangeDateRange(range);
            }
            // props.onChange(start, end);
          }}
          onCalenderOpen={(newData: any) => {
            if (location.pathname === "/routes/list") {
              props.calenderOpen(newData);
            }
          }}
          updatedMonth={(newData: any) => {
            if (location.pathname === "/routes/list") {
              props.monthChange(newData);
            }
          }}
          // value={dateRange} // <-------->
        ></DateRangePopup>
      </div>
    </div>
  );
});

export default DateRangePopupHeader;
