import React, {
  useEffect,
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useAppContext } from "../../../AppContext/App.context";
import { useForm } from "react-hook-form";
import useApiService from "../../../services/api.service";
import Input from "../../../components/Input/Input";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Grid, Paper, Switch } from "@material-ui/core";
import { ValidationService } from "../../../utils/Validation";
import { makeStyles } from "@material-ui/core/styles";
import Btn from "../../../components/Button/Button";
import useLoader from "../../../hooks/useLoader";
import useToast from "../../../components/Toast/hooks/useToast";
import CustomerSubscriptionEndpoints from "./CustomerSubscription.endpoints";
// import "./"
import { useTranslation } from "react-i18next";
import Dropdown from "../../../components/Dropdown/Dropdown";
import {
  RecurringTypesArray,
  repeatDays,
} from "../../../constants/recurringTypes";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",

    "@media (max-width: 375px)": {
      width: "300px",
      height: "700px",
      marginLeft: "35px",
      paddingRight: "20px",
    },
  },
  repeatOn: {
    marginTop: "10px",
  },
  repeatHeading: {
    marginTop: "12px",
    marginBottom: "5px",
  },
  repeatDays: {
    cursor: "pointer",
    borderRadius: "50%",
    background: "var(--white-color)",
    padding: "6px",
    color: "var(--black-color)",
    textAlign: "center",
    justifyContent: "center",
    display: "inline-flex",
    height: "13px",
    width: "13px",
    alignItems: "center",
    marginRight: "5px",
    border: "1px solid var(--border-color)",
  },
  repeatDaysSelected: {
    cursor: "pointer",
    background: "var(--primary-theme)",
    color: "#fff",
    // border: none;
    borderRadius: "50%",
    padding: "6px",
    textAlign: "center",
    justifyContent: "center",
    display: "inline-flex",
    height: "13px",
    width: "13px",
    border: "1px solid var(--border-color)",
    alignItems: "center",
    marginRight: "5px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "500px",
    borderRadius: "13px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
  toggleButtonText: {
    display: "inline-block",
  },
  dropdown: {
    textAlign: "left",
  },
  tagInputField: {
    marginTop: "0px",
    marginBottom: "10px",
  },
}));
let cycleDefObject = {
  "0": true,
  "1": true,
  "2": true,
  "3": true,
  "4": true,
  "5": true,
  "6": true,
};
const CreateSubscriptionPlan = forwardRef((props: any, ref: any) => {
  const [paymentType, setPaymentType] = useState(1);
  const [subscriptionType, setSubscriptionType] = useState(0);
  const classes = useStyles();

  const loader: any = useLoader();
  const toaster = useToast();
  const [subscriptionId, setSubscriptionId] = useState();
  const ApiService = useApiService();
  const [appData, dispatch]: any = useAppContext();

  const [open, setOpen] = React.useState(false);
  const { register, setValue, handleSubmit, reset, errors } = useForm();
  const { t, i18n } = useTranslation(["Team", "Common"]);
  const jobTypeObject = RecurringTypesArray(t);
  const [jobType, setJobType]: any = useState();
  const [currentOperation, setCurrentOperation]: any = useState(
    "openSubscription"
  );
  const currency = appData.loginData.currency;

  const [planActivate, setPlanActivate] = useState(false);
  // const [selectedDays, setSelectedDays] = useState();
  const [cycledef, setCycleDef] = useState(cycleDefObject);
  const [planPeriod, setPlanPeriod] = useState(0);
  const [selectedDays, setSelectedDays]: any = useState({
    "0": true,
    "1": true,
    "2": true,
    "3": true,
    "4": true,
    "5": true,
    "6": true,
  });
  useImperativeHandle(ref, () => ({
    openAddTeamPopup(operation: string, subscriptionDetails: any) {
      setCurrentOperation(operation);
      if (operation === "add") {
        setOpen(true);
        let tempArr: any;
        setSelectedDays(cycleDefObject);
        setJobType();
      } else {
        setSubscriptionId(subscriptionDetails.subscription_id);
        resetFieldsOfSubscriptionForm(subscriptionDetails);
        console.log("subscription Details--", subscriptionDetails);
        setOpen(true);
      }
    },
  }));
  const days = [
    {
      id: 0,
      value: "Monday",
    },
    {
      id: 1,
      value: "Tuesday",
    },
    {
      id: 2,
      value: "Wednesday",
    },
    {
      id: 3,
      value: "Thursday",
    },
    {
      id: 4,
      value: "Friday",
    },

    {
      id: 5,
      value: "Saturday",
    },
    {
      id: 6,
      value: "Sunday",
    },
  ];
  const resetFieldsOfSubscriptionForm = (subscriptionDetails: any) => {
    reset({
      planName: subscriptionDetails.plan_name,
      discription: subscriptionDetails.description,
      price: subscriptionDetails.amount,
    });
    setPlanPeriod(subscriptionDetails.period);
    setJobType(subscriptionDetails.job_type);
    setPlanActivate(subscriptionDetails.is_enabled);
    setPaymentType(subscriptionDetails.payment_type);
    setSubscriptionType(subscriptionDetails.subscription_type);
    setSelectedDays(subscriptionDetails.cycle_def);
  };
  const CustomPaper = (props: any) => {
    return <Paper elevation={4} {...props} />;
  };

  const handleClose = () => {
    setOpen(false);
    reset({
      planName: "",
      discription: "",
      price: "",
    });
    setPlanPeriod(0);
    setPlanActivate(false);
    setPaymentType(0);
    setCycleDef(cycleDefObject);
    setSubscriptionType(0);
  };

  const obj = {
    planName: {
      required: ValidationService.requiredValidator(),
    },
    plandescription: {},
  };

  const handleSwichChanged = (e: any) => {
    setPlanActivate(e.target.checked);
  };

  const onPaymentChange = (e: any) => {
    setPaymentType(parseInt(e.target.value));
  };

  const onSubscriptionTypeChange = (e: any) => {
    setSubscriptionType(parseInt(e.target.value));
  };
  const onPlanPeriodTypeChange = (e: any) => {
    setPlanPeriod(parseInt(e.target.value));
  };
  const filterCycleDef = (selectedDays: any) => {
    let tempObj: any = {};
    selectedDays.map((item: any) => {
      tempObj = { ...cycledef, [item.id]: true };
    });
    setCycleDef(tempObj);
  };

  //onSubmit
  const submitSubscriptionPlan = async (data: any) => {
    let planDesctription = Number(data.plandiscription);
    if (data.price <= 0) {
      toaster.addToast({
        message: "Please Fill Valid Price",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (!planPeriod) {
      toaster.addToast({
        message: "Please Select Period",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (!jobType && jobType != 0) {
      toaster.addToast({
        message: "Please Select Job Type",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (!subscriptionType) {
      toaster.addToast({
        message: "Please Select Subscription Type",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    const request: any = {
      plan_name: data.planName.trim(),
      description: data.discription.trim(),
      period: planPeriod,
      job_type: jobType,
      cycle_def: selectedDays,
      subscription_type: subscriptionType,
      // sending this because payment is prepaid
      payment_type: 1,
      is_fixed: 1,
      amount: data.price,
    };
    if (currentOperation === "edit") {
      request["is_enabled"] = planActivate;
    }
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    request["is_fixed"] === 1
      ? (request["is_fixed"] = true)
      : (request["is_fixed"] = false);
    if (currentOperation === "edit") {
      // const subscriptonId = subscriptionDetails.subscription_id
      request["subscription_id"] = subscriptionId;
      request["is_enabled"] === 1
        ? (request["is_enabled"] = true)
        : (request["is_enabled"] = false);
    }
    // loader.showLoader();
    return await ApiService[currentOperation === "edit" ? "put" : "post"](
      CustomerSubscriptionEndpoints.createPlan(reqData, currentOperation)
    )
      .then(async (res: any) => {
        // loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        handleClose();
        setCycleDef(cycleDefObject);
        props.getApi();
      })
      .catch((err: any) => {
        // loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const paymentOptionList = [
    { label: t("Createjob:PREPAID"), value: 1 },
    { label: t("Createjob:POSTPAID"), value: 2 },
  ];

  const subscriptionTypeList = [
    { label: t("Createjob:AUTOMATIC"), value: 1 },
    { label: t("Createjob:MANUAL"), value: 2 },
  ];
  const planPeriodObject = [
    { label: "Weekly", value: 1 },
    { label: "Monthly", value: 2 },
    { label: "Annually", value: 3 },
  ];
  const updateSelectedDays = (dayId: number) => {
    let newData = { ...selectedDays };
    newData[dayId] = newData[dayId] === true ? false : true;
    setSelectedDays(newData);
  };

  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form onSubmit={handleSubmit(submitSubscriptionPlan)}>
              <h2 id="modal-title">
                {currentOperation == "add" ? "Create" : "Edit"} Subscription
                Plan
              </h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Grid xs={6} item style={{ textAlign: "initial" }}>
                  <Input
                    className="AddPlan_input"
                    type="text"
                    id="planName"
                    style={{
                      textAlign: "initial",
                    }}
                    name="planName"
                    refValue={register(obj.planName)}
                    errors={errors}
                    label={t("plan:Plan Name") + "*"}
                  ></Input>
                </Grid>
                {/* {currentOperation == "edit" ? (
                  <div style={{ marginRight: "2rem", marginBottom: "1rem" }}>
                    <p className={classes.toggleButtonText}>Activate</p>
                    <Switch
                      checked={planActivate}
                      onChange={handleSwichChanged}
                      value="checked"
                      color="primary"
                    />
                  </div>
                ) : null} */}
                <Grid xs={6} item>
                  <Input
                    className="Discription_input"
                    type="text"
                    id="discription"
                    name="discription"
                    refValue={register(obj.plandescription)}
                    errors={errors}
                    label={t("plan:Description")}
                  ></Input>
                </Grid>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Grid item xs={6}>
                  <Input
                    // className="PlanDiscription_input"
                    type="number"
                    id="price"
                    name="Amount"
                    refValue={register(obj.planName)}
                    errors={errors}
                    label="Amount *"
                  ></Input>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    textAlign: "initial",
                  }}
                >
                  <Dropdown
                    labelName={t("Createjob:Plan Duration")}
                    MenuItemMaxWidth="100%"
                    onChange={(event: any) => {
                      setPlanPeriod(event.target.value);
                    }}
                    translationComponentName=""
                    selectedValue={planPeriod}
                    itemList={planPeriodObject}
                    uniqueId="value"
                    label="label"
                  ></Dropdown>
                </Grid>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Grid
                  style={{
                    textAlign: "initial",
                  }}
                  item
                  xs={6}
                >
                  {" "}
                  <Dropdown
                    required={true}
                    labelName={t("Common:Subscription Type")}
                    onChange={onSubscriptionTypeChange}
                    selectedValue={subscriptionType}
                    itemList={subscriptionTypeList}
                    uniqueId="value"
                    label="label"
                  ></Dropdown>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    textAlign: "initial",
                  }}
                >
                  <Dropdown
                    labelName={t("Createjob:Job Type")}
                    // MenuItemMaxWidth="100%"
                    onChange={(event: any) => {
                      setJobType(event.target.value);
                      // updateRepeatObj({
                      //   key: "cycle",
                      //   value: event.target.value,
                      // });
                    }}
                    translationComponentName=""
                    selectedValue={jobType}
                    itemList={jobTypeObject}
                    uniqueId="value"
                    label="name"
                  ></Dropdown>
                </Grid>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                {jobType == 1 ? (
                  <>
                    <p style={{ marginLeft: "70px" }}>{"Repeat On :"}</p>
                  </>
                ) : null}
                {jobType == 1 ? (
                  <Grid
                    item
                    style={{
                      marginBottom: "11px",
                      marginRight: "100px",
                      marginTop: "6px",
                    }}
                  >
                    <div
                      style={{ marginTop: 0 }}
                      className={classes.repeatHeading}
                    ></div>
                    {repeatDays.map((day: any) => (
                      <span
                        onClick={() => {
                          updateSelectedDays(day.id);
                        }}
                        className={
                          selectedDays[day.id] === true
                            ? classes.repeatDaysSelected
                            : classes.repeatDays
                        }
                        key={day.id}
                      >
                        {day.name}
                      </span>
                    ))}
                  </Grid>
                ) : null}
              </div>
              <Btn className={classes.btn} type={"Submit"} btnText={"Submit"}>
                {" "}
              </Btn>
            </form>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default CreateSubscriptionPlan;
