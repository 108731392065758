import {
  Backdrop,
  Checkbox,
  Fade,
  Grid,
  makeStyles,
  Switch,
} from "@material-ui/core";
import React, {
  Fragment,
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from "react";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";
import Btn from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { useForm } from "react-hook-form";
import { ValidationService } from "../../utils/Validation";
import useToast from "../../components/Toast/hooks/useToast";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import RadioButtonUncheckedSharpIcon from "@material-ui/icons/RadioButtonUncheckedSharp";

import { extensionService } from "./Extensions.endpoints";
import useLoader from "../../hooks/useLoader";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  textField: {
    marginTop: "4px!important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "2px 29px 7px",
    // width: "500px",
    borderRadius: "10px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
    marginBottom: "-15px!important",
  },
}));
let extensionPrice: any = 0;
const IdentityVerfication = forwardRef((props: any, ref: any) => {
  extensionPrice = props.price;
  const { t, i18n } = useTranslation(["Extension"]);
  const [open, setOpen] = React.useState(false);
  const [operation, setOperation]: any = useState("add");
  const toaster = useToast();
  const ApiService = useApiService();
  const loader: any = useLoader();
  const configFormDetails: any = useRef();
  const [step, updateStep] = useState(1);
  const [appData]: any = useAppContext();
  const [offDuty, setOffDuty]: any = useState(false);
  const [onDuty, setOnDuty]: any = useState(false);
  const [velidateGeofence, setVelidateGeofence]: any = useState(false);
  const [jobStart, setJobStart]: any = useState(false);
  const [propsConfigData, setPropsConfigData]: any = useState();

  const classes = useStyles();
  useImperativeHandle(ref, () => ({
    handleOpen(operation?: any, configData?: any) {
      updateStep(1);

      if (operation === 1) {
        setOperation("edit");
        if (configData) {
          setPropsConfigData(configData);
          resetConfigData(configData);
          // setConfigData(configData);
        } else if (props.config) {
          resetConfigData(props.config);
          // setConfigData(props.config);
        }
        setOpen(true);
      } else {
        resetSwitch();
        setOperation("add");
        setOpen(true);
      }
    },
  }));
  const resetSwitch = () => {
    setOffDuty(false);
    setOnDuty(false);
    setJobStart(false);
  };
  const onConfirm = () => {
    submitData();
  };
  const resetConfigData = (data: any) => {
    setOffDuty(data.off_duty_status == 1 ? true : false);
    setOnDuty(data.on_duty_status == 1 ? true : false);
    setJobStart(data.job_start == 1 ? true : false);
    setVelidateGeofence(data.validate_geofence == 1 ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const submitData = () => {
    let faceVerificationData: any = {};
    if (jobStart == false && onDuty == false) {
      toaster.addToast({
        message: "You have to select at least one to activate the Extension",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    const headers = {
      access_token: appData.loginData.access_token,
    };
    if (operation == "edit") {
      faceVerificationData = {
        id: props?.config?.id ? props.config.id : propsConfigData.id,
        job_start: jobStart,
        on_duty_status: onDuty,
        validate_geofence: velidateGeofence,
      };
    } else {
      faceVerificationData = {
        job_start: jobStart,
        on_duty_status: onDuty,
        validate_geofence: velidateGeofence,
        price: parseInt(extensionPrice),
      };
    }

    const reqData = {
      reqBody: faceVerificationData,
      headers: headers,
    };

    loader.showLoader();
    return ApiService[operation === "edit" ? "put" : "post"](
      extensionService.faceVerification(reqData, operation === "edit" ? 1 : 0)
    )
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        handleClose();
        props.handleIdentityVerificationActivation();
        props.fetchVerificationDetail(true);
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  //------------------------- Switch Controll ---------------------------------
  const handleJobStartSwitch = () => {
    setJobStart(!jobStart);
  };

  const handleOnDutySwitch = () => {
    setOnDuty(!onDuty);
  };

  const handleVelidateGeofance = () => {
    setVelidateGeofence(!velidateGeofence);
  };

  const handleOffDutySwitch = () => {
    setOffDuty(!offDuty);
  };
  // ----------------------------------------------------------------------------
  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} ref={configFormDetails}>
            <h2 id="modal-title">Configuration</h2>
            <span style={{ marginRight: "377px" }}>
              Face verification to be done at :
            </span>

            <Grid container style={{ marginTop: "10px" }}>
              <Checkbox
                style={{ marginLeft: "14px" }}
                color="primary"
                checked={jobStart}
                onChange={handleJobStartSwitch}
              />
              <span
                style={{
                  padding: "8px",
                  marginRight: "44px",
                  marginTop: "3px",
                }}
              >
                Job Start
              </span>
              <Checkbox
                style={{ marginLeft: "14px" }}
                color="primary"
                checked={onDuty}
                onChange={handleOnDutySwitch}
              />
              <span style={{ padding: "8px", marginTop: "3px" }}>
                On-Duty Status{" "}
              </span>

              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              ></span>
            </Grid>
            <div style={{ marginTop: "15px" }}>
              <span style={{ marginRight: "144px" }}>
                Do you want to restrict 'On duty' only in the assigned geofence?
                :
              </span>
              <Grid
                container
                style={{ marginTop: "10px", marginRight: "240px" }}
              >
                <Checkbox
                  style={{ marginLeft: "14px" }}
                  color="primary"
                  checked={velidateGeofence}
                  onChange={handleVelidateGeofance}
                />
                <span style={{ padding: "8px", marginTop: "3px" }}>
                  Validate Geofence{" "}
                </span>
              </Grid>
            </div>
            <Grid container></Grid>
            <div>
              <Btn
                className={classes.btn}
                type={"button"}
                onClick={onConfirm}
                btnText={t("Common:Confirm")}
              >
                {" "}
              </Btn>
              <Btn
                onClick={handleClose}
                color={"default"}
                type={"button"}
                btnText={t("Common:Discard")}
              >
                {" "}
              </Btn>
            </div>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default IdentityVerfication;
