import { CreateTaskOperation } from "../../enum/CreateTaskOperation.enum";

const CreateJobEndpoints = {
  crateJob(reqData: any, operation?: number, isRecurring?: boolean) {
    let url = "main/job/";
    if (isRecurring) {
      url = "main/recurring/job/create";
    } else {
      switch (operation) {
        case CreateTaskOperation.DUPLICATE:
        case CreateTaskOperation.CREATE:
          url += "create";
          break;
        case CreateTaskOperation.EDIT:
          url += "update";
          break;
      }
    }

    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  retryAllocation(reqData: any) {
    return {
      url: "main/job/retryAllocation",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getContactAutocomplete(reqData: any) {
    return {
      url: "main/customer/search",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getFilteredTeamByGeofence(reqData: any) {
    return {
      url: "main/team/geofence/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  updateRecurringDetails(reqData: any) {
    return {
      url: "main/recurring/job/update",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default CreateJobEndpoints;
