const LanguageManagerEndpoints = {
  addNewLanguage(reqData: any) {
    return {
      url: "language/language/insert",
      // API_BASE_URL: "http://api.networkon.tk:3014/",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  updateLanguage(reqData: any) {
    return {
      url: "language/language/update",
      // API_BASE_URL: "http://api.networkon.tk:3014/",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deleteLanguage(reqData: any) {
    return {
      url: "language/language/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getLanguageList(reqData: any) {
    return {
      url: "language/language/get",
      // API_BASE_URL: "http://api.networkon.tk:3014/",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getLanguageDetails(reqData: any) {
    return {
      url: "language/language/details/get",
      // API_BASE_URL: "http://api.networkon.tk:3014/",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getDefaultLanguageDetails(reqData: any) {
    return {
      url: "language/language/default/get",
      // API_BASE_URL: "http://api.networkon.tk:3014/",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  updateLanguageDetails(reqData: any) {
    return {
      url: "language/language/details/update",
      // API_BASE_URL: "http://api.networkon.tk:3014/",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default LanguageManagerEndpoints;
