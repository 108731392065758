const downloadDataAsCSV = (data: any, cb?: any) => {
  // resourceAnalyticsData
  const link = document.createElement("a");

  let csv = convertArrayOfObjectsToCSV(data);

  if (csv == null) return;

  const filename = "export.csv";

  var universalBOM = "\uFEFF";
  // var csvContent = BOM + csv;
  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${encodeURIComponent(
      universalBOM + csv
    )}`;
  }

  link.setAttribute("href", csv);
  link.setAttribute("download", filename);
  link.click();
  if (cb) {
    cb();
  }
};
const convertArrayOfObjectsToCSV = (data: any) => {
  let result: any;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(data[0]);

  result = "";
  result += keys.join(columnDelimiter);

  result += lineDelimiter;
  console.log(result);
  data.forEach((item: any) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key] ? '"' + item[key] + '"' : "";

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};
function base64ToArrayBuffer(data: any) {
  var binaryString = window.atob(btoa(unescape(encodeURIComponent(data))));
  // var binaryString = window.atob(data);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}
export const downloadDataAsPDF = (reqData: any, cb?: any) => {
  // const bytes = base64ToArrayBuffer(data);
  // const blob = new Blob([bytes], { type: "application/pdf" });
  // const link = document.createElement("a");
  // link.href = window.URL.createObjectURL(blob);
  // link.download = "exportPDF.pdf";
  // link.click();process.env.REACT_APP_API_URL+
  // var xhr = new XMLHttpRequest();
  // xhr.open("GET", "https://api-test.networkon.tk/export/pdf/get?job_id?"+reqData.reqBody.job_id, true);
  // xhr.setRequestHeader("Content-type", "application/json");
  // xhr.responseType = "arraybuffer";
  // xhr.setRequestHeader("access_token", reqData.headers.access_token);
  // xhr.onload = function () {
  //   // do something to response
  //   if (this.status == 200) {
  //     var blob = new Blob([this.response], { type: "application/pdf" });
  //     var link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = "Export_" + new Date() + ".pdf";
  //     link.click();
  //     cb();
  //   }
  // };
  var xhr = new XMLHttpRequest();
  // xhr.withCredentials = true;
  xhr.responseType = "arraybuffer";
  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      console.log(this.response);
      var blob = new Blob([this.response], { type: "application/pdf" });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Export_" + new Date() + ".pdf";
      link.click();
      cb();
    }
  });

  xhr.open(
    "GET",
    process.env.REACT_APP_API_URL +
      "export/job/pdf?job_id=" +
      reqData.reqBody.job_id
  );
  xhr.setRequestHeader("access_token", reqData.headers.access_token);

  xhr.send();
  // xhr.send(JSON.stringify(reqData.reqBody));
};
// ---------------------------------------------
export const downloadDataAnaliticsReportAsPDF = (reqData: any, cb?: any) => {
  var xhr = new XMLHttpRequest();
  xhr.responseType = "arraybuffer";
  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      console.log(this.response);
      var blob = new Blob([this.response], { type: "application/pdf" });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Export_" + new Date() + ".pdf";
      link.click();
      cb();
    }
  });

  xhr.open(
    "GET",
    process.env.REACT_APP_API_URL +
      `export/eatHappyNow/annual/report?start_datetime=${reqData.reqBody.start_datetime}&end_datetime=${reqData.reqBody.end_datetime}&customer_id=${reqData.reqBody.customer_id}`
  );
  xhr.setRequestHeader("access_token", reqData.headers.access_token);

  xhr.send();
};

// ---------------------------------------------
export default downloadDataAsCSV;
