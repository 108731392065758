import React, {
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Btn from "../../components/Button/Button";
import PageHeading from "../../components/PageHeading/PageHeading";
import Input from "../../components/Input/Input";
import Autocomplete from "../../components/Autocomplete/Autocomplete";
import { useForm } from "react-hook-form";
import { ValidationService } from "../../utils/Validation";
import { useAppContext } from "../../AppContext/App.context";
import useLoader from "../../hooks/useLoader";
import useToast from "../../components/Toast/hooks/useToast";
import { extensionService } from "./Extensions.endpoints";
import useApiService from "../../services/api.service";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    borderRadius: "15px",
    width: "500px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
  },
}));
const AgentReferralDetails = forwardRef((props: any, ref: any) => {
  console.log(props, "233232ewewwe");
  const { t, i18n } = useTranslation(["Extension"]);
  const ApiService = useApiService();
  const [open, setOpen] = React.useState(false);
  const { register, setValue, handleSubmit, reset, errors } = useForm();
  const classes = useStyles();
  const [appData]: any = useAppContext();
  const [agentReferralPrefillData, setAgentReferralPrefillData] = useState<any>(
    {
      number_of_jobs: "",
      referral_amount: "",
    }
  );
  const [historyDetail, setHistoryDetail]: any = useState("");
  const [type, setType]: any = useState();
  const [reqType, setReqType] = useState<"post" | "put">("post");
  const loader: any = useLoader();
  const toaster = useToast();

  useImperativeHandle(ref, () => ({
    handleOpen() {
      //initMap();
      setOpen(true);
      // setType(type);
      //setHistoryDetail(history);
    },
    showPrefillData(data: any) {
      if (data) {
        setAgentReferralPrefillData({
          ...data,
          referral_amount: Number(data?.referral_amount)?.toFixed(),
        });
        setReqType("put");
      } else {
        setAgentReferralPrefillData({
          number_of_jobs: "",
          referral_amount: "",
        });
        setReqType("post");
      }
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const submitAgentReferralDetails = (data: any) => {
    if (!data.no_of_jobs.trim()) {
      toaster.addToast({
        message: t("Extension:Please enter No. of Jobs"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (!data.referral_amount.trim()) {
      toaster.addToast({
        message: t("Team:Please enter Referral Amount"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    let request: any = {
      number_of_jobs: parseInt(data.no_of_jobs),
      referral_amount: parseInt(data.referral_amount),
    };
    if (reqType == "post") {
      request["extension_id"] = 19;
    }
    if (reqType == "put") {
      request["id"] = agentReferralPrefillData.id;
    }
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    loader.showLoader();

    return ApiService[reqType](
      reqType == "post"
        ? extensionService.agentReferralDetails(reqData)
        : extensionService.updateAgentReferralDetails(reqData)
    )
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        handleClose();
        props.handleAgentReferralExtention();
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  const obj = {
    no_of_jobs: {
      required: ValidationService.requiredValidator(),
    },
    referral_amount: {
      required: ValidationService.requiredValidator(),
    },
  };

  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form onSubmit={handleSubmit(submitAgentReferralDetails)}>
              <h2 id="modal-title">
                {agentReferralPrefillData.number_of_jobs
                  ? "Configure Details"
                  : "Add Details"}
              </h2>
              <Input
                type="number"
                id="no_of_jobs"
                name="no_of_jobs"
                value={agentReferralPrefillData.number_of_jobs}
                refValue={register(obj.no_of_jobs)}
                errors={errors}
                label={t("Extension:No. of Jobs")}
              ></Input>
              <Input
                type="number"
                id="referral_amount"
                name="referral_amount"
                value={agentReferralPrefillData.referral_amount}
                refValue={register(obj.referral_amount)}
                errors={errors}
                label={t("Extension:Referral Amount $")}
              ></Input>

              <div>
                <Btn
                  className={classes.btn}
                  type={"Submit"}
                  btnText={t("Common:Submit")}
                >
                  {" "}
                </Btn>
                <Btn
                  onClick={() => {
                    setOpen(false);
                  }}
                  color={"default"}
                  type={"button"}
                  btnText={t("Common:Cancel")}
                >
                  {" "}
                </Btn>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default AgentReferralDetails;
