import React, { useState, useEffect, useRef, forwardRef } from "react";
import DataTable from "react-data-table-component";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import "./Datatable.scss";
import DatatableEndpoints from "./Datatable.endpoints";
import PageLoader from "../pageLoader/pageLoader";
import DataLoadingInProgress from "../DataLoadingInProgress/DataLoadingInProgress";
import NoRecord from "../NoRecord/NoRecord";
import Input from "../../components/Input/Input";
import useDebounce from "./use-debounce";
import PageHeading from "../../components/PageHeading/PageHeading";
import { useTranslation } from "react-i18next";
import useToast from "../Toast/hooks/useToast";
import { themeColor } from "../../constants/themeColor";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "20px!important",
    height: "45px",
    backgroundColor: "#f5f5f5 !important",
  },
  searchOnFocus: {
    width: "230px",
    transition: "width .8s",
  },
  searchOnBlur: {
    width: "140px",
    transition: "width .8s",
  },
}));

const AdvancedPaginationTable = forwardRef((props: any, ref: any) => {
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation(["Common"]);
  const searchRef: any = useRef();
  const [showDatatable, setShowDatatable]: any = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const toaster = useToast();
  const [currentPerPage, setCurrentPerPage] = useState(
    props.currentPageLimit ? props.currentPageLimit : 10
  );
  const [showLoadingRecords, setShowLoadingRecords] = useState(true);
  const [appData, dispatch]: any = useAppContext();
  const ApiService = useApiService();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const classes = useStyles();
  let sorting: any = {
    sortColumnName: props.defaultSortColumnName || "",
    sortColumnOrder: props.defaultSortColumnOrder || "",
  };
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setShowDatatable(false);
      fetchDataBySearching(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm.trim()]);
  const fetchDataBySearching = async (debouncedSearchTerm: string) => {
    const response: any = await fetchDataFromApi(
      null,
      null,
      debouncedSearchTerm.trim()
    );
    setTotalRows(response?.data.count);
    setShowDatatable(true);
    setLoading(false);
  };
  const fetchDataFromApi = async (
    page?: any,
    newPerPage?: any,
    result?: any
  ) => {
    setLoading(true);
    if (!page) {
      page = currentPage;
    }
    if (!newPerPage) {
      newPerPage = currentPerPage;
    }
    setCurrentPage(page);
    setCurrentPerPage(newPerPage);
    let url =
      props.paginationServer === false
        ? props.endPoint
        : props.endPoint +
          `?skip=${(page - 1) * newPerPage}&limit=${newPerPage}`;
    if (props.extraParams) {
      for (let i = 0; i < props.extraParams.length; i++) {
        const param = props.extraParams[i];
        url +=
          props.paginationServer == false && i == 0
            ? `?${param["name"]}=`
            : `&${param["name"]}=`;
        if (typeof param["value"] === "object") {
          url += encodeURIComponent(JSON.stringify(param["value"]));
        } else {
          url += param["value"];
        }
      }
    }
    if (result || (debouncedSearchTerm && debouncedSearchTerm.trim())) {
      url += `&search=${result || debouncedSearchTerm.trim()}`;
    }
    if (sorting["sortColumnName"] && props.paginationServer !== false) {
      url +=
        "&order_column=" +
        sorting["sortColumnName"] +
        "&order_by=" +
        sorting["sortColumnOrder"].toUpperCase();
    }
    const reqData = {
      url: url,
      headers: {
        access_token: appData.loginData.access_token,
      },
    };
    return await ApiService.get(DatatableEndpoints.getData(reqData))
      .then((response: any) => {
        if (props?.isJobListByRefrenceId) {
          let data = response.data;
          let tempJobListArray: any = [],
            tempRefrenceId: any = [];
          for (let index = 0; index < data.data.length; index++) {
            for (
              let internalLoopIndex = 0;
              internalLoopIndex < data.data[index]["jobs"].length;
              internalLoopIndex++
            ) {
              tempJobListArray.push(
                data.data[index]["jobs"][internalLoopIndex]
              );
            }
            tempRefrenceId.push(data.data[index]["reference_id"]);
          }
          setData(tempJobListArray);
          if (props.sendDataToParent) {
            props.sendDataToParent(response.data, newPerPage);
          }
        } else {
          response.data.data || response.data.details
            ? setData(response.data.data || response.data.details)
            : setData(response.data);
          if (props.sendDataToParent) {
            props.sendDataToParent(response.data, newPerPage);
          }
        }

        return response;
      })
      .catch((err: any) => {
        if (props.showErrorMsgFromAPI) {
          toaster.addToast({
            message: err.message,
            timeout: 3000,
            type: "error",
          });
        }
      });
    // const response:any = {data:{"message":"success","status":200,"data":[{"job_id":87,"user_id":1,"job_status":0,"task_id":85,"task_description":"test","local_datetime":"2020-10-14T11:00:00.000Z","timezone":330,"utc_datetime":"2020-10-14T05:30:00.000Z","location":"London","lat":"12.000000","lng":"12.000000","task_status":0,"resource_id":204},{"job_id":87,"user_id":1,"job_status":0,"task_id":86,"task_description":"test","local_datetime":"2020-10-14T10:00:00.000Z","timezone":330,"utc_datetime":"2020-10-14T04:30:00.000Z","location":"London","lat":"12.000000","lng":"12.000000","task_status":0,"resource_id":204},{"job_id":87,"user_id":1,"job_status":0,"task_id":87,"task_description":"test","local_datetime":"2020-10-14T11:00:00.000Z","timezone":330,"utc_datetime":"2020-10-14T05:30:00.000Z","location":"London","lat":"12.000000","lng":"12.000000","task_status":0,"resource_id":204},{"job_id":87,"user_id":1,"job_status":0,"task_id":88,"task_description":"test","local_datetime":"2020-10-14T10:00:00.000Z","timezone":330,"utc_datetime":"2020-10-14T04:30:00.000Z","location":"London","lat":"12.000000","lng":"12.000000","task_status":0,"resource_id":204},{"job_id":88,"user_id":1,"job_status":0,"task_id":null,"task_description":null,"local_datetime":null,"timezone":null,"utc_datetime":null,"location":null,"lat":null,"lng":null,"task_status":null,"resource_id":null},{"job_id":89,"user_id":1,"job_status":0,"task_id":null,"task_description":null,"local_datetime":null,"timezone":null,"utc_datetime":null,"location":null,"lat":null,"lng":null,"task_status":null,"resource_id":null},{"job_id":90,"user_id":1,"job_status":0,"task_id":null,"task_description":null,"local_datetime":null,"timezone":null,"utc_datetime":null,"location":null,"lat":null,"lng":null,"task_status":null,"resource_id":null}]}}
  };

  const handlePageChange = (page: any) => {
    fetchDataAndSetTotalRows(page);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setLoading(true);

    const response: any = await fetchDataFromApi(page, newPerPage);
    setPerPage(newPerPage);
    setLoading(false);
    // props.sendPageCount(newPerPage,response.data.data);
  };
  const fetchDataAndSetTotalRows = async (page?: any) => {
    const response: any = await fetchDataFromApi(page, null);
    setTotalRows(response?.data.count);
    setLoading(false);
    setShowLoadingRecords(false);
  };

  useEffect(() => {
    if (searchRef && searchRef.current) searchRef.current.value = "";
    setTimeout(() => {
      if (props.resetPage) {
        setCurrentPerPage(10);
        setTotalRows(10);
      }
      fetchDataAndSetTotalRows(props.resetPage === 1 ? 1 : null);
    });
  }, [props.refreshDataTable]);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    });
  }, [props.refreshDataTableOnly]);
  const handleSort = (column: any, sortDirection: string) => {
    setLoading(true);
    sorting["sortColumnName"] = column.selector;
    sorting["sortColumnOrder"] = sortDirection;
    if (props.getSortData) {
      props.getSortData(column.selector, sortDirection);
    }
    fetchDataAndSetTotalRows(1);
  };
  const customStyles = {
    rows: {
      style: {
        minHeight: "auto !important",
        ...props.rowsStyle,
      },
    },
    headCells: {
      style: {
        ...props.headerStyle,
        backgroundColor: props.backgroundColor || themeColor.defaultTableColor,
        fontWeight: "700 !important",
        color: "#171725 !important",
        // color: "red",
        textAlign: "center",
        paddingLeft: "8px",
        height: "80px",
        paddingRight: "8px",
        overflow: "hidden",
        // lineHeight:"5px",
      },
    },
    cells: {
      style: {
        padding: "8px !important",
        ...props.cellsStyle,
      },
    },
  };

  return (
    <div
      style={{
        ...props.containerStyle,
        backgroundColor: themeColor.defaultTableColor,
      }}
    >
      {props.title || props.searchBar ? (
        <div className={props.searchBarCustomClass || "header"}>
          <PageHeading title={props.title} />
          <div
            className={`${
              isSearchFocused ? classes.searchOnFocus : classes.searchOnBlur
            }`}
            style={{ marginRight: "30px" }}
          >
            <TextField
              id="search"
              className={classes.input}
              name="search"
              variant="outlined"
              placeholder={t("Common:Search")}
              style={
                !props.title && props.searchBar ? { marginBottom: "5px" } : {}
              }
              inputRef={searchRef}
              type="search"
              value={searchTerm}
              onChange={(e: any) => {
                setSearchTerm(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{ color: "var(--primary-theme)" }}
                      onClick={() => {
                        setIsSearchFocused(true);
                        searchRef.current.focus();
                      }}
                    />
                  </InputAdornment>
                ),
                classes: {
                  root: classes.input,
                },
              }}
              onFocus={() => setIsSearchFocused(true)}
              onBlur={() => {
                if (!searchTerm) {
                  setIsSearchFocused(false);
                }
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {showDatatable ? (
        <DataTable
          persistTableHead={true}
          // fixedHeader={true}
          //title={props.title}
          columns={props.columns}
          data={data}
          defaultSortField={props.defaultSortColumnName}
          defaultSortAsc={props.defaultSortColumnOrder === "ASC" ? true : false}
          onSort={handleSort}
          sortServer={props.sortServer === false ? false : true}
          progressPending={loading}
          progressComponent={<PageLoader />}
          conditionalRowStyles={props.conditionalRowStyles}
          paginationComponentOptions={{
            rowsPerPageText: t("Common:Rows per page"),
            rangeSeparatorText: t("Common:of"),
          }}
          noDataComponent={
            showLoadingRecords ? <DataLoadingInProgress /> : <NoRecord />
          }
          pagination={props.showPagination === false ? false : true}
          paginationServer={props.paginationServer === false ? false : true}
          paginationResetDefaultPage={true}
          paginationTotalRows={totalRows}
          selectableRows={props.selectableRows || false}
          onSelectedRowsChange={props.onSelectedRowsChange}
          selectableRowsComponent={props.selectableRowsComponent}
          selectableRowsComponentProps={props.selectableRowsComponentProps}
          selectableRowSelected={props.selectableRowSelected}
          clearSelectedRows={props.clearSelectedRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          customStyles={customStyles}
          style={{
            width: "auto",
            margin: "0px 25px",
            ...props.style,
          }}
          striped={props.striped || true}
          fixedHeader={props.fixedHeader || false}
          fixedHeaderScrollHeight={props.fixedHeaderScrollHeight || "auto"}
        />
      ) : (
        ""
      )}
    </div>
  );
});
export default AdvancedPaginationTable;
