import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

// eslint-disable-next-line react/prop-types
export default (rowObj: any) => {
  const row: any = rowObj.row;
  const actionList = rowObj.actionList;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        disableScrollLock={true}
        onClose={handleClose}
      >
        {actionList.map((action: any) => (
          <MenuItem
            key={action.name}
            onClick={(event: any) => {
              handleClose(event);
              event.stopPropagation();
              action.actionHandler(row);
            }}
          >
            {action.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
