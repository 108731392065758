import React, { Fragment } from "react";

import { themeColor } from "../../constants/themeColor";
import className from "./TaskDetail.module.scss";
import clsx from "clsx";
import crossIcon from "../../assets/images/crossIcon.svg";
import { ArrowDropDown, AssignmentOutlined, Close } from "@material-ui/icons";
import {
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import { jobStatusData } from "../../constants/jobStatusData";
import useLocalStorage from "../../hooks/localStorage";
// import Header from "../Header/Header";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
  },
  dropdownOuter: {
    zIndex: 1,
  },
  paper: {
    marginLeft: "20px",
    //   marginRight: theme.spacing(2),
  },
  taskIdDropdown: {
    display: "flex",
    alignItems: "center",
  },
  connectedTaskId: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  jobsCircle: {
    // display: "inline-table",
    display: "flex",
    justifyContent: "center",
    width: "21px",
    height: "21px",
    borderRadius: "100px",
    textAlign: "center",
    padding: "1px 0.5px 0px 0.5px",
  },
}));

const TaskDetailHeader = (props: any) => {
  const classes = useStyles();
  console.log(props.taskDetail, "kjhhkjhjk");
  const taskDetailData = props.taskDetail;
  const [open, setOpen] = React.useState(false);
  const loginData: any = useLocalStorage.getItem("loginData");

  let connectedTasks: any = [];
  let connectedTaskArray: any = [];

  if (taskDetailData.connected_tasks) {
    connectedTasks = taskDetailData.connected_tasks.split(",");
  }
  if (taskDetailData.connected_tasks_details) {
    connectedTaskArray = JSON.parse(taskDetailData.connected_tasks_details);
  }
  const anchorRef: any = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  let statusCircle = (color: any, value: any, taskStatus: any) => {
    return (
      <div
        className={classes.jobsCircle}
        style={{ backgroundColor: jobStatusData[taskStatus]["color"] }}
      >
        <span
          style={{
            color: color,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 400,
            marginBottom: "1px",
          }}
        >
          {value}
        </span>
      </div>
    );
  };
  return (
    <Fragment>
      <div>
        <div
          // className={classes.taskIdDropdown}
          className={clsx(classes.taskIdDropdown, {
            [classes.connectedTaskId]: connectedTasks.length > 0,
          })}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <div style={{ display: "flex" }}>
            <div style={{ color: themeColor.primary.main, marginRight: "5px" }}>
              Task
            </div>
            #{taskDetailData.task_id}&nbsp;
            {statusCircle(
              "#fff",
              taskDetailData.task_type === 2
                ? "S"
                : taskDetailData.task_type === 0
                ? "P"
                : "D",
              taskDetailData.task_status
            )}
          </div>
          {connectedTasks.length > 0 ? (
            <ArrowDropDown
              style={{ color: themeColor.primary.main }}
            ></ArrowDropDown>
          ) : null}
        </div>
        <Popper
          className={classes.dropdownOuter}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {connectedTaskArray.map((item: any, index: any) => (
                      <MenuItem
                        onClick={(evt: any) => {
                          handleClose(evt);
                          props.openTaskDetailForSelectedTask(item.task_id);
                        }}
                      >
                        {" "}
                        <AssignmentOutlined
                          style={{ paddingRight: "20px" }}
                        ></AssignmentOutlined>
                        {"#" + item.task_id}&nbsp;
                        {statusCircle(
                          "#fff",
                          taskDetailData.task_type === 2
                            ? "S"
                            : item.task_type === 0
                            ? "P"
                            : "D",
                          item.task_status
                        )}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <div
        onClick={props.onCloseTaskDetail}
        style={{ cursor: "pointer" }}
        // className={className["task-detail__close-icon"]}
      >
        <img src={crossIcon} height={"22px"} />
      </div>
    </Fragment>
  );
};
export default TaskDetailHeader;
