import React, {
  useEffect,
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "./ImportExport.scss";
//   import { ValidationService } from "../../../../utils/Validation";
import { makeStyles } from "@material-ui/core/styles";
import DateRange from "../DateTimePicker/DateRangePicker";
import downloadDataAsCSV from "../../utils/exportCsv";
import { useTranslation } from "react-i18next";
import useApiService from "../../services/api.service";
import { useAppContext } from "../../AppContext/App.context";
import useLoader from "../../hooks/useLoader";
import useToast from "../Toast/hooks/useToast";
import useLocalStorage from "../../hooks/localStorage";
import CommonEndpoints from "../../services/commonApi.service";
import Dropdown from "../Dropdown/Dropdown";
import { JobStatusDataList } from "../../constants/jobStatusData";
import MultiselectTeamDropdown from "../MultiselectTeamDropdown/MultiselectTeamDropdown";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Btn from "../Button/Button";
import Input from "../Input/Input";
import DateTimeFormatter from "../../utils/DateFormatter";
import { SelectDateOptionList } from "../../containers/Jobs/JobsList/JobListFilterData";
import { ValidationService } from "../../utils/Validation";
import { useForm } from "react-hook-form";
import { ImportExportService } from "./ImportExport.endpoints";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { resourceStatus } from "../../constants/resourceStatusData";
import { templateApiService } from "../../services/ApiServices/templateApi.service";
import MaterialAutocomplete from "../MaterialAutocomplete/MaterialAutocomplete";
import DateRangePopupHeader from "../DateRangePopup/DateRangePopupHeader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    overflowY: "scroll",
    // textAlign: "center",

    "@media (max-width: 375px)": {
      width: "300px",
      height: "700px",
      marginLeft: "37px",
    },
  },
  formLabel: {
    color: "#000",
    "&.Mui-focused": {
      color: "#000",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "500px",
    outline: "none",
    borderRadius: "10px",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
  },
}));
const ExportJobs = forwardRef((props: any, ref: any) => {
  const { t, i18n } = useTranslation(["Common", "Supervisor", "Jobfilter"]);

  const ApiService = useApiService();
  const [appData]: any = useAppContext();
  const [open, setOpen] = React.useState(false);
  const { register, setValue, handleSubmit, reset, errors } = useForm();
  const loader: any = useLoader();
  const jobStatusDataList = JobStatusDataList(t);
  const [selectedStatus, setStatus] = useState([]);
  let [relatedDateValue, setRelatedDateValue]: any = useState();
  let [period, setPeriod]: any = useState(1);
  const toaster = useToast();
  const [showDropdown, setShowDropdown]: any = useState(false);
  const [showErrormsg, setShowErrormsg]: any = useState(true);
  const access_token = appData.loginData.access_token;
  const [teamList, setTeamList]: any = useState([]);
  const [templateList, setTemplateList]: any = useState([]);
  const [selectedTeamId, setTeamId]: any = useState([]);
  const [templateId, setTemplateId]: any = useState("");
  const [operation, setOperation]: any = useState("");
  // const currentStartDate: any = new Date();
  // currentStartDate.setHours(0);
  // currentStartDate.setMinutes(0);
  // currentStartDate.setSeconds(0);
  // const currentEndDate = new Date();
  // currentEndDate.setHours(23);
  // currentEndDate.setMinutes(59);
  // currentEndDate.setSeconds(59);
  //  currentDate = moment(currentDate).format("YYYY-MM-DD");
  const [startDate, setStartDate]: any = useState();
  const [customerFilterState, setCustomerFilterState]: any = useState({
    type: "",
    status: "",
  });
  const [endDate, setEndDate]: any = useState();
  const [localStartDate, setLocalStartDate]: any = useState("");
  const [localEndDate, setLocalEndDate]: any = useState("");
  const [dateRadio, setDateRadio] = useState("calendar");
  const [customerID, setCustomerID] = useState();

  const classes = useStyles();

  const Permission: any = useLocalStorage.getItem("Permission");

  useImperativeHandle(ref, () => ({
    handleOpenClick(operation: any, row: any) {
      resetData();
      setOperation(operation);
      if (operation === "exportAgents") {
        getTemplateList();
      }
      setOpen(true);
      if (
        Permission &&
        Permission["Read_Teams"] &&
        operation != "exportCustomers"
      ) {
        getTeamList();
      }
    },
    setCustomerId(op: any) {
      setCustomerID(op.current.value);
    },
  }));
  const CustomerTypeList = [
    {
      id: 0,
      value: "All",
    },
    {
      id: 2,
      value: "Contacts",
    },
    {
      id: 1,
      value: "Sign Up",
    },
  ];
  const CustomerStatusList = [
    {
      id: 1,
      value: "Active",
    },
    {
      id: 0,
      value: "Inactive",
    },
  ];
  const resetData = () => {
    setRangeData({
      startDate: null,
      endDate: null,
      key: "value",
    });
    setCustomerFilterState({ type: "", status: "" });
    const currentStartDate: any = new Date(
      new Date().setDate(new Date().getDate() - 365)
    );
    currentStartDate.setHours(0);
    currentStartDate.setMinutes(0);
    currentStartDate.setSeconds(0);
    const currentEndDate = new Date();
    currentEndDate.setHours(23);
    currentEndDate.setMinutes(59);
    currentEndDate.setSeconds(59);
    //  currentDate = moment(currentDate).format("YYYY-MM-DD");
    setStartDate(
      DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(currentStartDate)
    );
    setEndDate(
      DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(currentEndDate)
    );
    setTeamId([]);
    setStatus([]);
    setTemplateId(null);
    setLocalStartDate(currentStartDate);
    setLocalEndDate(currentEndDate);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [RangeData, setRangeData]: any = useState({
    startDate: null,
    endDate: null,
    key: "value",
  });

  const getTeamList = async () => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams = {
      limit: 1000,
      skip: 0,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(CommonEndpoints.getTeamList(reqData));
    setTeamList(res.data.data);
  };
  const getTemplateList = async () => {
    let headers = {
      access_token: access_token,
    };

    let reqData = {
      reqBody: {
        skip: 0,
        limit: 1000,
        fetch_agent_template: true,
      },
      headers: headers,
    };

    await ApiService.get(templateApiService.getTemplateList(reqData))
      .then((res: any) => {
        setTemplateList(res.data.data);
      })
      .catch((err) => {
        setTemplateList([]);
      });
  };
  const onCalendarDateChange = (start: any, end: any) => {
    if (start) {
      if (!end) {
        end = new Date();
      }
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      setLocalStartDate(start);

      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      setLocalEndDate(end);
      let range: any = { start, end };
      let RangeLimit: any = Math.round(
        (range["end"] - range["start"]) / (1000 * 60 * 60 * 24)
      );
      if (RangeLimit > 365) {
        toaster.addToast({
          message: t("Supervisor:Please select range for less than 365 Days"),
          timeout: 3000,
          type: "error",
        });
        return;
      }
      start = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(start);
      end = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(end);
      setStartDate(start);
      setEndDate(end);
    }
  };

  const onSubmit = (data: any) => {
    let request: any = {};
    let TeamIds: any = [];
    let StatusIds: any = [];
    let filterData: any = {};
    var searchValidation = false;
    if (operation === "exportCustomers" && customerFilterState.type === "") {
      toaster.addToast({
        message: "Please select customer type",
        timeout: 3000,
        type: "error",
      });
      return;
    }

    if (selectedStatus.length) {
      filterData.status_id = selectedStatus;
      StatusIds = selectedStatus.map((team: any) => {
        return parseInt(team.value);
      });

      request[
        operation === "exportAgents" ? "status" : "job_status"
      ] = StatusIds;
    }

    if (selectedTeamId.length && teamList.length !== selectedTeamId.length) {
      filterData.team_id = selectedTeamId;
      TeamIds = selectedTeamId.map((team: any) => {
        return parseInt(team.value);
      });
      request["team_id"] = TeamIds;
    }
    if (operation === "exportCustomers") {
      request["fetch_tags_details"] = true;
      request["fetch_customer_ref"] = customerFilterState.type;
      if (
        customerFilterState.status === 0 ||
        customerFilterState.status === 1
      ) {
        request["status"] = customerFilterState.status;
      }
    }

    if (startDate && endDate && dateRadio) {
      filterData.dateRadio = dateRadio;
      if (dateRadio === "relative") {
        filterData.value = relatedDateValue;
        filterData.period = period;
      }
      filterData.start_datetime = localStartDate;
      filterData.end_datetime = localEndDate;
      request["start_datetime"] = startDate;
      request["end_datetime"] = endDate;
    }
    if (customerID) {
      request["customer_id"] = customerID;
    }
    if (templateId) {
      request["template_id"] = templateId.template_id;
      request["fetch_template_details"] = true;
    }
    if (operation === "exportAgents") {
      request["fetch_team_details"] = true;
      request["fetch_tags_details"] = true;
      request["fetch_transport_details"] = true;
    }
    let headers = {
      access_token: access_token,
    };
    const reqBody = {
      reqBody: request,
      headers: headers,
    };
    loader.showLoader();
    const apiEndPoint: any =
      operation === "jobsList"
        ? "exportJob"
        : operation === "merchantAnalytics"
        ? "exportMerchant"
        : operation === "exportAgents"
        ? "exportAgents"
        : operation === "exportCustomers"
        ? "exportCustomers"
        : "exportResourceAnalytics";
    const res = ApiService.get(ImportExportService[apiEndPoint](reqBody))
      .then((response: any) => {
        if (response.data && response.data) {
          if (response.data.length > 0) {
            downloadDataAsCSV(response.data, () => {
              loader.hideLoader();
            });
          } else {
            if (
              operation === "exportAgents" ||
              operation === "exportCustomers"
            ) {
              toaster.addToast({
                message: response?.message.toString(),
                timeout: 3000,
                type: "success",
              });
            } else {
              toaster.addToast({
                message: response?.message.toString(),
                timeout: 3000,
                type: "success",
              });
            }
            loader.hideLoader();
          }
        } else {
          toaster.addToast({
            message: response?.message.toString(),
            timeout: 5000,
            type: "success",
          });
          loader.hideLoader();
        }

        handleClose();
        // handleReset();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
        loader.hideLoader();
        handleClose();
      });
  };
  const obj = {
    RelValue:
      dateRadio === "relative"
        ? {
            validate: (val: any) => {
              return ValidationService.positiveNumber(val, t);
            },
          }
        : {},
    dateRadio: {},
    searchColumn: {},
    searchKeyword: {},
  };

  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ textAlign: "center", margin: "-10px 0 20px 0" }}>
                <h3>
                  {t("Common:Export") +
                    " " +
                    t(
                      "Common:" +
                        (operation === "jobsList"
                          ? "Jobs"
                          : operation === "merchantAnalytics"
                          ? "Job Report"
                          : operation === "exportAgents"
                          ? "Agents"
                          : operation === "exportCustomers"
                          ? "Customers"
                          : "Agent")
                    )}
                </h3>
              </div>
              <Grid container direction="row" spacing={2}>
                {operation != "exportCustomers" ? (
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="row"
                      style={{ marginTop: "-8px" }}
                    >
                      {operation != "merchantAnalytics" &&
                        (operation === "jobsList" ||
                        operation === "exportAgents" ? (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MultiselectTeamDropdown
                              class="drop"
                              overrideStrings={{
                                selectSomeItems: t("Jobfilter:Select Teams"),
                                allItemsAreSelected: t(
                                  "Jobfilter:All teams are selected"
                                ),
                                selectAll: t("Jobfilter:Select All Teams"),
                                search: t("Jobfilter:Search Team"),
                                clearSearch: t("Jobfilter:Clear Search"),
                              }}
                              options={teamList}
                              uniqueLabel={"team_name"}
                              uniqueId={"team_id"}
                              value={selectedTeamId}
                              onChange={(value: any) => {
                                setTeamId(value);
                              }}
                              labelledBy={t("Jobfilter:Select")}
                              ArrowRenderer={() => (
                                <div className="Import__dropdownArrow"></div>
                              )}
                            />
                          </Grid>
                        ) : (
                          <div style={{ margin: "0 auto", width: "22em" }}>
                            <MultiselectTeamDropdown
                              class="drop"
                              ArrowRenderer={() => (
                                <div className="Import__dropdownArrow"></div>
                              )}
                              overrideStrings={{
                                selectSomeItems: t("Jobfilter:Select Teams"),
                                allItemsAreSelected: t(
                                  "Jobfilter:All teams are selected"
                                ),
                                selectAll: t("Jobfilter:Select All Teams"),
                                search: t("Jobfilter:Search Team"),
                                clearSearch: t("Jobfilter:Clear Search"),
                              }}
                              options={teamList}
                              uniqueLabel={"team_name"}
                              uniqueId={"team_id"}
                              value={selectedTeamId}
                              onChange={(value: any) => {
                                setTeamId(value);
                              }}
                              labelledBy={t("Jobfilter:Select")}
                            />
                          </div>
                        ))}
                      {operation === "jobsList" ||
                      operation === "exportAgents" ? (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <MultiselectTeamDropdown
                            className={"drop"}
                            ArrowRenderer={() => (
                              <div className="Import__dropdownArrow"></div>
                            )}
                            overrideStrings={{
                              selectSomeItems: t("Jobfilter:Select Status"),
                              allItemsAreSelected: t(
                                "Jobfilter:All status are selected"
                              ),
                              selectAll: t("Jobfilter:Select All Status"),
                              search: t("Jobfilter:Search Status"),
                              clearSearch: t("Jobfilter:Clear Search"),
                            }}
                            options={
                              operation === "exportAgents"
                                ? resourceStatus
                                : jobStatusDataList
                            }
                            uniqueLabel={"name"}
                            uniqueId={"status"}
                            value={selectedStatus}
                            onChange={(value: any) => {
                              setStatus(value);
                            }}
                            labelledBy={t("Jobfilter:Select")}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={6} className="import_dropdown">
                    <Dropdown
                      labelName={t("Common:Select Customer Type*")}
                      //required={true}
                      className={classes.dropdown}
                      showDropdown={showDropdown}
                      onChange={(e: any) => {
                        setCustomerFilterState({
                          ...customerFilterState,
                          type: e.target.value,
                        });
                      }}
                      selectedValue={customerFilterState.type}
                      itemList={CustomerTypeList}
                      uniqueId="id"
                      label="value"
                    />
                  </Grid>
                )}
                {operation === "exportCustomers" ? (
                  <Grid item xs={6} className="import_dropdown">
                    <Dropdown
                      labelName={t("Common:Select Status")}
                      //required={true}
                      className={classes.dropdown}
                      showDropdown={showDropdown}
                      onChange={(e: any) => {
                        setCustomerFilterState({
                          ...customerFilterState,
                          status: e.target.value,
                        });
                      }}
                      selectedValue={customerFilterState.status}
                      itemList={CustomerStatusList}
                      uniqueId="id"
                      label="value"
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                {operation != "exportCustomers" ? (
                  <Grid style={{ margin: "0 auto" }}>
                    <FormControl component="fieldset">
                      {dateRadio ? (
                        dateRadio === "calendar" ? (
                          <div className="Import__date_picker-div">
                            <DateRange
                              className={"export-date-picker"}
                              start={RangeData.startDate}
                              end={RangeData.endDate}
                              onChange={(start: any, end: any) => {
                                onCalendarDateChange(start, end);
                              }}
                              placeholderText={
                                operation === "exportAgents"
                                  ? "Select Joining Date"
                                  : "Select Date"
                              }
                              // inline={true}
                            />
                          </div>
                        ) : null
                      ) : (
                        ""
                      )}
                    </FormControl>

                    {operation === "exportAgents" ? (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginTop: "-5px" }}
                        className={"Import__template_dropdown"}
                      >
                        <MaterialAutocomplete
                          isMultiple={false}
                          freeSolo={false}
                          disablePortal={false}
                          size="medium"
                          id={"team-outlined"}
                          noOptionsText={t("Createjob:No template found")}
                          options={templateList}
                          labelName="name"
                          value={templateId}
                          onChange={(e: any, value: any) => {
                            setTemplateId(value);
                          }}
                          filterSelectedOptions
                          onKeyDown={() => {}}
                          textFieldLabel={t("Common:Select Template")}
                          textFieldPlaceholder={t("Common:Select Template")}
                          textFieldMargin={"normal"}
                          textFieldFullWidth={true}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <DateRange
                      className={"export-date-picker"}
                      start={RangeData.startDate}
                      end={RangeData.endDate}
                      onChange={(start: any, end: any) => {
                        onCalendarDateChange(start, end);
                      }}
                      placeholderText={
                        operation === "exportAgents"
                          ? "Select Joining Date"
                          : operation === "exportCustomers"
                          ? "Creation Date Range"
                          : "Select Date"
                      }
                      // inline={true}
                    />
                  </Grid>
                )}
              </Grid>

              <div style={{ textAlign: "center", padding: "10px" }}>
                <Btn
                  btnText={t("Common:Export")}
                  className={classes.btn}
                  type="Submit"
                />
                <Btn
                  btnText={t("Common:Cancel")}
                  onClick={() => {
                    setOpen(false);
                  }}
                  color={"default"}
                  type={"button"}
                />
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default ExportJobs;
