import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router";
import { useAppContext } from "../../AppContext/App.context";
import DateTimeFormatter from "../../utils/DateFormatter";
import useToast from "../Toast/hooks/useToast";
import "./DateTimePicker.scss";

const DateRangePicker = (props: any) => {
  const [appData]: any = useAppContext();
  const [startDate, setStartDate] = useState(null);
  const location = useLocation();

  const [endDate, setEndDate] = useState(null);
  const [highlightedDatesArr, setHighlightedDatesArr]: any = useState([]);
  const toaster = useToast();
  const onChange = (dates: any) => {
    let [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (props.inline) {
      if (!end) {
        let range: any = DateTimeFormatter.getUTCStartAndEndTimeFromLocal(
          start
        );
        // end = new Date(range.endDate);
        end = new Date(range.endDate.replace(/-/g, "/"));
      }
      props.onChange(start, end);
    }
  };
  const handleCalendarClose = () => {
    let end: any = endDate;
    if (!endDate && startDate) {
      let range: any = DateTimeFormatter.getUTCStartAndEndTimeFromLocal(
        startDate
      );
      end = new Date(range.endDate);
      setEndDate(end);
    }
    if (props.maxRange) {
      let start: any = startDate;
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      // startDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(startDate);
      let endDT: any = end;

      endDT.setHours(23);
      endDT.setMinutes(59);
      endDT.setSeconds(59);
      // endDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(endDate);
      let range: any = { start, endDT };
      let RangeLimit: any = Math.round(
        (range["endDT"] - range["start"]) / (1000 * 60 * 60 * 24)
      );

      if (RangeLimit > props.maxRange) {
        toaster.addToast({
          message: `Please select range for less than ${
            props.maxRange ? props.maxRange : 7
          } days`,
          timeout: 3000,
          type: "error",
        });
        let s: any = new Date(props.start);
        let e: any = new Date(props.end);
        setStartDate(s);
        setEndDate(e);
        return;
      } else props.onChange(startDate, endDate);
    } else props.onChange(startDate, endDate);
  };
  let convertDateFormat = () => {
    let currentFormat = DateTimeFormatter.getDateFormat();
    currentFormat = currentFormat.toLowerCase();
    currentFormat = currentFormat.replace("m", "M");
    currentFormat = currentFormat.replace("m", "M");
    return currentFormat;
  };

  useEffect(() => {
    if (props.start) {
      if (typeof props.start !== "object") {
        let convertedS = props.start.replace(/-/g, "/");
        let convertedE = props.end.replace(/-/g, "/");
        let start: any = new Date(convertedS);
        setStartDate(start);
        let end: any = new Date(convertedE);
        setEndDate(end);
      } else {
        let start: any = new Date(props.start);
        setStartDate(start);
        let end: any = new Date(props.end);
        setEndDate(end);
      }
    }
  }, [props.value]);
  const handleCalendarOpen = () => {
    if (location.pathname === "/routes/list" && props.onCalendarOpen) {
      props.onCalenderOpen(true);
    } else {
    }
  };
  const [data, setData]: any = useState([]);
  const getHighlightedDates = (arr: any) => {
    let tempArr: any = [];
    // console.log(arr,"poppop")
    arr.map((item: any) => {
      tempArr.push(
        appData.loginData.date_format?.includes("DD-MM-YYYY")
          ? new Date(DateTimeFormatter.getDateInJavascriptAllowedFormat(item))
          : new Date(item)
      );
    });
    setHighlightedDatesArr(tempArr);
    let tempObject = { customHighlightedCss: tempArr };
    setData([tempObject]);
  };
  useEffect(() => {
    if (props.highlightDates) {
      getHighlightedDates(props.highlightDates);
    }
  }, [props.highlightDates]);

  return (
    <>
      <DatePicker
        className={props.className}
        highlightDates={highlightedDatesArr ? data : null || []}
        //selected={startDate}
        onCalendarClose={
          props.inline
            ? () => {
                return false;
              }
            : handleCalendarClose
        }
        onMonthChange={(data) => {
          if (location.pathname === "/routes/list") {
            props.updatedMonth(data);
          }
        }}
        onCalendarOpen={handleCalendarOpen}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline={props.inline}
        placeholderText={props.placeholderText}
        dateFormat={convertDateFormat()}
      >
        {props.highlightDates ? (
          <div style={{ textAlign: "center" }}>
            <div style={{ display: "inline-flex" }}>
              <span
                style={{
                  height: "15px",
                  width: "15px",
                  backgroundColor: "#ff7600",
                  borderRadius: "50%",
                  display: "inline-flex",
                }}
              ></span>
              <span style={{ display: "inline-block" }}>
                &nbsp;Routes Created
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
      </DatePicker>
    </>
  );
};

export default DateRangePicker;
