import React, { useState } from "react";
import DataTable from "../../../components/Datatable/Datatable";
import ShowTaskDetailsInHtmlView from "../../../components/ShowTaskDetail/ShowTaskDetailsInHtmlView";

// const phoneValues = (data: any) => {
//   return data.filter((item: any) => item.task_body.task_details.some((task: any) => task.task_type === 0))
//     .map((item: any) => item.task_body.task_details.find((task: any) => task.task_type === 0)?.phone)
// }

const Subscribers = () => {
  const [sort, setSort]: any = useState({
    order_column: "id",
    order_by: "DESC",
  });

  const getSortData = (column: any, sortDirection: any) => {
    setSort({ sortColumn: column, sortDirection: sortDirection });
  };

  const getStatus = (row: any) => {
    switch (row.status) {
      case 1:
        return "Ongoing";
      case 2:
        return "Expired";
      case 3:
        return "Cancelled";
      default:
        return "-";
        break;
    }
  };

  const subscriptionType = (row: any) => {
    const type = row.plan_details.subscription_type;
    if (type == 1) {
      return "Auto-Renewal";
    } else {
      return "Manual";
    }
  };
  // const getTaskTypeForPickup = (taskDetails: any) => {
  //   if (taskDetails) {
  //     return taskDetails.task_details.map((task: any, index: any) =>
  //       task.task_type == 0 ? (
  //         <>
  //           {task.local_datetime}
  //           <br />
  //           {task.location}
  //         </>
  //       ) : (
  //         ""
  //       )
  //     );
  //   } else {
  //     return "";
  //   }
  // };

  // const getTaskTypeForDelivery = (taskDetails: any) => {
  //   if (taskDetails) {
  //     return taskDetails.task_details.map((task: any, index: any) =>
  //       task.task_type == 1 ? (
  //         <>
  //           {task.local_datetime}
  //           <br />
  //           {task.location}
  //         </>
  //       ) : (
  //         ""
  //       )
  //     );
  //   } else {
  //     return "";
  //   }
  // };

  const columns: any = [
    {
      name: "Customer ID",
      selector: "customer_id",
      wrap: true,
      sortable: true,
      cell: (row: any) => <div>{row.customer_id ? row.customer_id : "-"}</div>,
      center: "true",
      width: "100px",
    },
    {
      name: "Name",
      selector: "name",
      wrap: true,
      center: true,
      sortable: true,
      cell: (row: any) => <div>{row.name ? row.name : "-"}</div>,
      width: "190px",
    },
    {
      name: "Phone Number",
      selector: "phone_number",
      wrap: true,
      sortable: true,
      center: true,
      cell: (row: any) => (
        <div>
          {row.country_code ? row.country_code : ""}
          {row.phone_number ? row.phone_number : "-"}
        </div>
      ),
      width: "170px",
    },
    {
      name: "Email ID",
      selector: "email",
      wrap: true,
      sortable: true,
      center: true,
      cell: (row: any) => <div>{row.email ? row.email : "-"}</div>,
      width: "250px",
    },
    {
      name: "Subscription plan",
      selector: "plan_name",
      wrap: true,
      center: true,
      sortable: true,
      cell: (row: any) => <div>{row.plan_name ? row.plan_name : "-"}</div>,
      width: "190px",
    },
    {
      name: "Status",
      selector: "status",
      wrap: true,
      center: true,
      sortable: true,
      cell: (row: any) => <div>{getStatus(row)}</div>,
      width: "150px",
    },
    {
      name: "Subscription Type",
      selector: "subscription_type",
      wrap: true,
      center: true,
      sortable: true,
      cell: (row: any) => <div>{subscriptionType(row)}</div>,
      width: "150px",
    },
    {
      name: "Activation Date",
      selector: "start_date",
      wrap: true,
      center: true,
      sortable: true,
      cell: (row: any) => <div>{row.start_date ? row.start_date : "-"}</div>,
      width: "170px",
    },
    {
      name: "Expiry Date",
      selector: "end_date",
      wrap: true,
      center: true,
      sortable: true,
      cell: (row: any) => <div>{row.end_date ? row.end_date : "-"} </div>,
      width: "150px",
    },
    {
      name: "Pickup Details",
      selector: "pickup_details",
      wrap: true,
      // center: true,
      sortable: true,
      cell: (row: any) => (
        <ShowTaskDetailsInHtmlView
          style={{ overFlow: "hidden", textOverFlow: "ellipsies" }}
          doNotConverFormat={true}
          tasks={row["task_body"]["task_details"]}
          taskType={0}
        />
      ),
      width: "160px",
    },
    {
      name: "Delivery Details",
      selector: "delivery_details",
      wrap: true,
      // center: true,
      sortable: true,
      cell: (row: any) => (
        <ShowTaskDetailsInHtmlView
          doNotConverFormat={true}
          tasks={row["task_body"]["task_details"]}
          taskType={1}
        />
      ),
      width: "150px",
    },
  ];

  return (
    <div className="subscriber-data">
      <DataTable
        columns={columns}
        //   extraParams={extraParamsForIncentiveHit}
        fixedHeader={true}
        defaultSortColumnName={sort.order_column}
        defaultSortColumnOrder={sort.order_by}
        // showErrorMsgFromAPI
        // refreshDataTable={countRefreshDataTable}
        endPoint="main/subscription/history"
        fixedHeaderScrollHeight={"68vh"}
        rowsStyle={{
          minHeight: "57px",
        }}
        getSortData={getSortData}
      />
    </div>
  );
};

export default Subscribers;
