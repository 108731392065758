const CustomerListEndpoint = {
  getCustomerList(reqData: any) {
    return {
      url: "main/customer/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default CustomerListEndpoint;
