export const templateApiService = {
  getTemplateList(reqData: any) {
    return {
      url: "main/template/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

// export default templateService;
