export const NLevelApiService: any = {
  getProductlist(reqData: any) {
    return {
      url: "catalogue/product/",

      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getCustomerAppSettings(reqData: any) {
    return {
      url: "customer/appSettings/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  updateCategoryListOrder(reqData: any) {
    return {
      url: "catalogue/nLevel/category/sort",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getCategorylist(reqData: any) {
    return {
      url: "catalogue/nLevel/getCategory",

      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  changeProductStatus(reqData: any) {
    return {
      url: "catalogue/change/status",

      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  createProduct(reqData: any, operation?: number) {
    let url = "catalogue/product/";

    switch (operation) {
      case 1:
        url += "edit";
        break;
      default:
        url += "add";
        break;
    }
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  createCategory(reqData: any, operation?: number) {
    let url = "catalogue/nLevel/";

    switch (operation) {
      case 1:
        url += "updateCategory";
        break;
      default:
        url += "createCategory";
        break;
    }
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getTriggerList(reqData: any) {
    return {
      url: "notification/notification/events/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deleteCatalog(reqData: any) {
    return {
      url: "catalogue/product/delete",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};
