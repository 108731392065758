export const ColumnList: any = (t: any) => {
  return [
    { label: t("Jobfilter:Job ID"), value: "job_id" },
    { label: t("Jobfilter:Reference ID"), value: "reference_id" },
    { label: t("Jobfilter:Agent Name"), value: "user_name" },
    { label: t("Jobfilter:Customer Name"), value: "customer_name" },
    { label: t("Jobfilter:Customer Email ID"), value: "email" },
    { label: t("Jobfilter:Customer Phone No."), value: "phone" },
    { label: t("Jobfilter:Customer Address"), value: "address" },
  ];
};
export const NumberOperatorList: any = (t: any) => {
  return [
    { label: t("Jobfilter:Equal to"), value: "=" },
    { label: t("Jobfilter:Not equal to"), value: "<>" },
    { label: t("Jobfilter:Less than"), value: "<" },
    { label: t("Jobfilter:Greater than"), value: ">" },
    { label: t("Jobfilter:Less than equal to"), value: "<=" },
    { label: t("Jobfilter:Greater than equal to"), value: ">=" },
  ];
};
export const StringOperatorList: any = (t: any) => {
  return [
    { label: t("Jobfilter:starts with"), value: "starts_with" },
    { label: t("Jobfilter:ends with"), value: "ends_with" },
    { label: t("Jobfilter:exact"), value: "=" },
    { label: t("Jobfilter:contains"), value: "contains" },
  ];
};
export const SelectDateOptionList = (t: any) => {
  return [
    { label: t("Jobfilter:day(s) from today"), value: 1 },
    { label: t("Jobfilter:week(s) from today"), value: 2 },
    { label: t("Jobfilter:month(s) from today"), value: 3 },
    { label: t("Jobfilter:day(s) ago"), value: -1 },
    { label: t("Jobfilter:week(s) ago"), value: -2 },
    { label: t("Jobfilter:month(s) ago"), value: -3 },
  ];
};
