import React, { useEffect } from "react";
import reactManifest from "react-manifest";
import { useAppContext } from "../../AppContext/App.context";

const ReactManifestComponent = () => {
  const [appData]: any = useAppContext();
  useEffect(() => {}, []);
  const updateManifestDetail = () => {
    console.log(appData.loginData);
    console.log(appData.preLoginData);
    const companyData =
      appData.preLoginData && Object.keys(appData.preLoginData).length
        ? appData.preLoginData
        : appData.loginData && Object.keys(appData.loginData).length
        ? appData.loginData
        : {};
    const manifestDetails = {
      name: companyData.company_name,
      short_name: companyData.company_name,
      start_url: "index.html",
      display: "standalone",
      orientation: "portrait",
      theme_color: "#000000",
      background_color: "#ffffff",
      icons: [
        {
          src: companyData.favicon,
          sizes: "192x192",
        },
        {
          src: companyData.favicon,
          sizes: "512x512",
        },
      ],
    };

    reactManifest.update(manifestDetails, "#manifest-placeholder");
  };
  useEffect(() => {
    updateManifestDetail();
  }, [appData.loginData, appData.preLoginData]);
  return <div></div>;
};

export default ReactManifestComponent;
