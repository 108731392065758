export const singleLineList = (t: any) => {
  return [
    {
      key: "pickup_name",
      option: t("ImportJobs:Pickup Customer Name"),
    },
    {
      key: "pickup_country_code",
      option: t("ImportJobs:Pickup Country Code*"),
      is_required: 1,
    },
    {
      key: "pickup_phone",
      option: t("ImportJobs:Pickup Phone no*"),
      is_required: 1,
    },
    {
      key: "pickup_email",
      option: t("ImportJobs:Pickup Email ID"),
    },
    {
      key: "pickup_reference_id",
      option: t("ImportJobs:Pickup Reference ID"),
    },
    {
      key: "pickup_address_line1",
      option: t("ImportJobs:Pickup Address line 1*"),
      is_required: 1,
    },
    {
      key: "pickup_address_line2",
      option: t("ImportJobs:Pickup Address line 2"),
    },
    {
      key: "pickup_city/town",
      option: t("ImportJobs:Pickup City/Town*"),
      is_required: 1,
    },
    {
      key: "pickup_postal_code",
      option: t("ImportJobs:Pickup Postal Code*"),
      is_required: 1,
    },
    {
      key: "pickup_state/province",
      option: t("ImportJobs:Pickup State/province*"),
      is_required: 1,
    },
    {
      key: "pickup_country",
      option: t("ImportJobs:Pickup Country*"),
      is_required: 1,
    },
    
    {
      key: "pickup_lat",
      option: t("ImportJobs:Pickup Latitude"),
    },
    {
      key: "pickup_lng",
      option: t("ImportJobs:Pickup Longitude"),
    },
    {
      key: "pickup_task_description",
      option: t("ImportJobs:Pickup Task Description"),
    },
    {
      key: "pickup_local_datetime",
      option: t("ImportJobs:Pickup Job Time*"),
      is_required: 1,
    },
    {
      key: "pickup_service_time",
      option: t("ImportJobs:Pickup Service Time"),
    },
    {
      key: "delivery_name",
      option: t("ImportJobs:Delivery Customer Name"),
    },
    {
      key: "delivery_country_code",
      option: t("ImportJobs:Delivery Country Code*"),
      is_required: 1,
    },
    {
      key: "delivery_phone",
      option: t("ImportJobs:Delivery Phone no*"),
      is_required: 1,
    },
    {
      key: "delivery_email",
      option: t("ImportJobs:Delivery Email ID"),
    },
    
    {
      key: "delivery_reference_id",
      option: t("ImportJobs:Delivery Reference ID"),
    },
    {
      key: "delivery_address_line1",
      option: t("ImportJobs:Delivery Address line 1*"),
      is_required: 1,
    },
    {
      key: "delivery_address_line2",
      option: t("ImportJobs:Delivery Address line 2"),
    },
    {
      key: "delivery_city/town",
      option: t("ImportJobs:Delivery City/Town*"),
      is_required: 1,
    },
    {
      key: "delivery_postal_code",
      option: t("ImportJobs:Delivery Postal Code*"),
      is_required: 1,
    },
    {
      key: "delivery_state/province",
      option: t("ImportJobs:Delivery State/province*"),
      is_required: 1,
    },
    {
      key: "delivery_country",
      option: t("ImportJobs:Delivery Country*"),
      is_required: 1,
    },
    {
      key: "delivery_lat",
      option: t("ImportJobs:Delivery Latitude"),
    },
    {
      key: "delivery_lng",
      option: t("ImportJobs:Delivery Longitude"),
    },
    {
      key: "delivery_task_description",
      option: t("ImportJobs:Delivery Task Description"),
    },
    {
      key: "delivery_local_datetime",
      option: t("ImportJobs:Delivery Job Time*"),
      is_required: 1,
    },
    {
      key: "delivery_service_time",
      option: t("ImportJobs:Delivery Service Time"),
    },
    {
      key: "geofence",
      option: t("ImportJobs:Geofence check"),
    },
    {
      key: "tags",
      option: t("ImportJobs:Tags"),
    },
    {
      key: "barcode",
      option: t("ImportJobs:Barcode"),
    },
    {
      key: "team_id",
      option: t("ImportJobs:Team ID"),
    },
    {
      key: "resource_id",
      option: t("ImportJobs:Resource ID"),
    },
  ];
};
