import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import {
  jobStatusData,
  JobStatusDataList,
} from "../../../constants/jobStatusData";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import headerDropdownService from "../../../services/headerDropdown.service";

const useStyles: any = makeStyles({
  jobsCircle: {
    display: "inline-table",
    minWidth: "21px",
    borderRadius: "20px",
    textAlign: "center",
    padding: "2px 5px",
    marginRight: "8px",
    fontSize: "16px",
    fontWeight: 600,
  },
  root: {
    backgroundColor: "white!important",
    border: "1px solid !important",
    borderColor: "white !important",
    borderRadius: "20px !important",
    margin: "8px 12px",
    padding: "6px 8px",
  },
  tabText: {
    borderColor: "var(--primary-theme) !important",
  },
});

const JobListCount = (props: any) => {
  let classes = useStyles();
  const { t, i18n } = useTranslation(["Common"]);
  let [currentStatus, setCurrentStatus]: any = useState(["All"]);
  const jobStatusDataList = JobStatusDataList(t);
  let [jobStatus, setJobStatus] = useState<{
    0: any;
    1: any;
    3: any;
    5: any;
    6: any;
    7: any;
  }>({
    // All
    0: 0,
    // Unassinged
    1: 0,
    // Inprogress
    3: 0,
    // Complete
    5: 0,
    // Fail
    6: 0,
    // Cancelled
    7: 0,
  });

  const eventFromService = (eventData: any) => {
    switch (eventData.type) {
      case "jobStatusCount":
        {
          setCurrentStatus(["All"]);
        }
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    let headerSubscription = headerDropdownService.subscribe(eventFromService);
    return () => {
      headerSubscription.unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (!props?.statusCount?.isJobStatusFilterApplied) {
      if (
        (props.statusCount && props.statusCount.statusCount) ||
        props.isListEmpty
      ) {
        let count = 0;
        let data = props.statusCount.statusCount;
        let tempCount: any = {
          // All
          0: 0,
          // Unassinged
          1: 0,
          // Inprogress
          3: 0,
          // Complete
          5: 0,
          // Fail
          6: 0,
          // Cancelled
          7: 0,
        };
        for (let val in data) {
          // console.log(data[val]);
          count = data[val].count + count;
          if (data[val].job_status === 0) {
            tempCount[1] = data[val].count;
          } else if (data[val].job_status >= 1 && data[val].job_status <= 4) {
            tempCount[3] += data[val].count;
          } else {
            tempCount[data[val].job_status] = data[val].count;
          }
        }
        setJobStatus({
          0: count,
          1: tempCount["1"],
          3: tempCount["3"],
          5: tempCount["5"],
          6: tempCount["6"],
          7: tempCount["7"],
        });
      } else {
        setJobStatus({
          0: "0",
          1: "0",
          3: "0",
          5: "0",
          6: "0",
          7: "0",
        });
      }
    }

    getTaskCount();
  }, [props.statusCount]);

  let getTaskCount = () => {};
  let adjustColor = (color: string, amount: any) => {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16
            )
          ).substr(-2)
        )
    );
  };
  const statusCircle = (color: any, value: any) => {
    let background =
      color !== "#999999" ? adjustColor(color, 150) : adjustColor(color, 80);
    if (color === "#171725") background = adjustColor(color, 200);
    return (
      <div
        className={classes.jobsCircle}
        style={{ backgroundColor: background }}
      >
        <span
          style={{
            color: color,
          }}
        >
          {value || 0}
        </span>
      </div>
    );
  };
  const setFilter = (currentFormat: any) => {
    if (currentFormat.length) {
      let request: any = [];
      let statusArray = [];
      if (currentFormat[0] === "All" && currentFormat.length === 2) {
        return;
      }
      if (currentFormat.includes("All")) {
        statusArray = [0, 1, 2, 3, 4, 5, 6, 7];
      } else {
        statusArray = [...currentFormat];
        if (statusArray.includes(3)) {
          statusArray.push(1, 2, 4);
        }
      }
      request.push({
        name: "job_status",
        value: statusArray,
      });
      props.getFilterData(request, "append");
    }
  };
  const handleChange: any = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: any
  ) => {
    if (newFormats.length === 0 && currentStatus.length === 1) {
      return;
    }
    if (currentStatus[0] === "All" && newFormats.length > 1) {
      newFormats.splice("All", 1);
    }
    if (newFormats.length === 5 || newFormats.includes("All")) {
      setCurrentStatus(["All"]);
      setFilter(newFormats);
      return;
    }
    setCurrentStatus(newFormats);
    setFilter(newFormats);
  };
  const getColor = (color: any) => {
    const tempClass: any = makeStyles({
      text: {
        color: color,
        fontWeight: 600,
        textTransform: "capitalize",
      },
    });
    return tempClass();
  };

  return (
    <div style={{ marginLeft: "12px" }}>
      <ToggleButtonGroup value={currentStatus} onChange={handleChange}>
        {Object.entries(jobStatus).map(([key, value], index) =>
          key !== "0" ? (
            <ToggleButton
              value={key === "1" ? 0 : parseInt(key)}
              classes={{
                root: classes.root,
                label:
                  key === "1"
                    ? getColor(jobStatusData["0"]["color"]).text
                    : getColor(jobStatusData[key]["color"]).text,
                selected: classes.tabText,
              }}
            >
              <span>
                {statusCircle(
                  key === "1"
                    ? jobStatusData["0"]["color"]
                    : jobStatusData[key]["color"],
                  value
                )}
              </span>
              <span>
                {key === "1"
                  ? t(`Common:${jobStatusData["0"]["name"]}`)
                  : t(`Common:${jobStatusData[key]["name"]}`)}
              </span>
            </ToggleButton>
          ) : (
            <ToggleButton
              classes={{
                root: classes.root,
                selected: classes.tabText,
              }}
              value={"All"}
            >
              <span>{statusCircle("#171725", value)}</span>
              <span
                style={{
                  fontWeight: 600,
                  textTransform: "capitalize",
                  color: "#171725",
                }}
              >
                {t("Common:All")}
              </span>
            </ToggleButton>
          )
        )}
      </ToggleButtonGroup>
    </div>
  );
};

export default JobListCount;
