import React, {
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Btn from "../../components/Button/Button";
import PageHeading from "../../components/PageHeading/PageHeading";
import Input from "../../components/Input/Input";
import Autocomplete from "../../components/Autocomplete/Autocomplete";
import { useForm } from "react-hook-form";
import { ValidationService } from "../../utils/Validation";
import { useAppContext } from "../../AppContext/App.context";
import useLoader from "../../hooks/useLoader";
import useToast from "../../components/Toast/hooks/useToast";
import { extensionService } from "./Extensions.endpoints";
import useApiService from "../../services/api.service";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "500px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
  },
}));
const TwilioMasking = forwardRef((props: any, ref: any) => {
  const { t, i18n } = useTranslation(["Extension"]);
  const ApiService = useApiService();
  const [open, setOpen] = React.useState(false);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    errors,
  } = useForm();
  const classes = useStyles();
  const [appData]: any = useAppContext();
  const [configData, setConfigData]: any = useState();
  const [historyDetail, setHistoryDetail]: any = useState("");
  const [type, setType]: any = useState();
  const loader: any = useLoader();
  const toaster = useToast();

  useImperativeHandle(ref, () => ({
    handleOpen(operation: any) {
      //initMap();
      if (operation === 1) {
        setType(1);
        getConfigData();
      } else setOpen(true);
      // setType(type);
      //setHistoryDetail(history);
    },
  }));
  const getConfigData = () => {
    let header: any = {
      access_token: appData.loginData.access_token,
    };
    let request: any = {};
    let reqData = {
      reqBody: request,
      headers: header,
    };
    ApiService.get(extensionService.getTwilioMasking(reqData))
      .then(function (response: any) {
        // console.log(response);
        if (response.data.length > 0) {
          preFill(response.data[0]);
        }
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  const preFill = (data: any) => {
    reset({
      serviceID: data.service_id,
      accountSID: data.account_sid,
      authToken: data.auth_token,
    });
    setConfigData(data);
    setOpen(true);
  };
  const resetForm = () => {
    reset({
      serviceID: "",
      accountSID: "",
      authToken: "",
    });
  };

  const handleClose = () => {
    resetForm();
    setOpen(false);
  };
  const formatNameValidator = (params: any) => {
    let result = new RegExp(/^(?![\s-])[\w\s-]+$/).test(params);
    return result;
  };
  const submitTwilioMaskingDetails = (data: any) => {
    let check = formatNameValidator(data.authToken);
    if (!check) {
      toaster.addToast({
        message: t("Team:Please enter valid AuthToken"),
        timeout: 3000,
        type: "error",
      });
      reset({
        ...getValues(),
        authToken: "",
      });
      return;
    }
    check = formatNameValidator(data.accountSID);
    if (!check) {
      toaster.addToast({
        message: t("Team:Please enter valid Account SID"),
        timeout: 3000,
        type: "error",
      });
      reset({
        ...getValues(),
        accountSID: "",
      });
      return;
    }
    check = formatNameValidator(data.serviceID);
    if (!check) {
      toaster.addToast({
        message: t("Extension:Please enter service ID"),
        timeout: 3000,
        type: "error",
      });
      reset({
        ...getValues(),
        serviceID: "",
      });
      return;
    }
    let credentials: any = {
      service_id: data.serviceID,
      account_sid: data.accountSID,
      auth_token: data.authToken,
    };
    if (type === 1) {
      credentials.id = configData.id;
    }
    // const request: any = {
    //     price: props.price,
    //     credentials: credentials,
    // };
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: credentials,
      headers: headers,
    };
    loader.showLoader();
    return ApiService[type === 1 ? "put" : "post"](
      extensionService.twilioMasking(reqData, type)
    )
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        handleClose();
        props.handleTwilioMaskingActivation();
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const obj = {
    accountSID: {
      required: ValidationService.requiredValidator(),
    },
    authToken: {
      required: ValidationService.requiredValidator(),
    },
    serviceID: {
      required: ValidationService.requiredValidator(),
    },
  };
  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form onSubmit={handleSubmit(submitTwilioMaskingDetails)}>
              <h2 id="modal-title">Add Details</h2>
              <Input
                type="text"
                id="authToken"
                name="authToken"
                refValue={register(obj.authToken)}
                errors={errors}
                label={t("Extension:AuthToken") + "*"}
              ></Input>
              <Input
                type="text"
                id="accountSID"
                name="accountSID"
                refValue={register(obj.accountSID)}
                errors={errors}
                label={t("Extension:Account SID") + "*"}
              ></Input>

              <Input
                type="text"
                id="serviceID"
                name="serviceID"
                refValue={register(obj.serviceID)}
                errors={errors}
                label={t("Extension:Service ID") + "*"}
              ></Input>
              <div>
                <Btn
                  className={classes.btn}
                  type={"Submit"}
                  btnText={type === 1 ? t(`Common:Update`) : t(`Common:Submit`)}
                >
                  {" "}
                </Btn>
                <Btn
                  onClick={() => {
                    setOpen(false);
                  }}
                  color={"default"}
                  type={"button"}
                  btnText={t("Common:Cancel")}
                >
                  {" "}
                </Btn>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default TwilioMasking;
