import React from "react";
import DateTimeFormatter from "../../../utils/DateFormatter";

// eslint-disable-next-line react/prop-types
export default (rowObj: any) => {
  const row: any = rowObj.row;
  if (!row["tasks"] || row["tasks"].length === 0) {
    row["tasks"] = [{ timezone: 330 }];
  }
  const convertMinsToHrsMins = (mins: number) => {
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return "" + h + ":" + m + "";
  };
  const timeFormat = () => {
    const date = DateTimeFormatter.convertDateTimeToSpecificFormat(
      row["created_at"],
      row["tasks"][0] ? row["tasks"][0]["timezone"] : 330
    );
    if (date) {
      const dateTime: String[] = date.split(" ");
      return (
        <>
          {dateTime[1]}
          {dateTime[2] ? (
            <>
              {" "}
              <span>{dateTime[2]}</span>
            </>
          ) : (
            ""
          )}
          <br />
          {dateTime[0]}
        </>
      );
    }
  };
  return (
    <div>
      {/* {DateTimeFormatter.convertDateTimeToSpecificFormat(
        row["created_at"],
        row["tasks"][0] ? row["tasks"][0]["timezone"] : 330
      )} */}
      {timeFormat()}
      <br></br>
      {"(GMT  " +
        (row["tasks"][0]["timezone"] < 0 ? "-" : "+") +
        " " +
        convertMinsToHrsMins(Math.abs(row["tasks"][0]["timezone"])) +
        ")"}
    </div>
  );
};
