import React, {
  useState,
  useRef,
  useEffect,
  lazy,
  Suspense,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useClickOutside } from "../../hooks/clickOutside";
import GoogleAutocompleteService from "../../utils/googleAutocompleteService";
import "./Autocomplete.scss";
import debounce from "../../utils/debounce";
import locationIcon from "../../assets/images/svgs/location.svg";
import { Position } from "../../utils/geolocation";
import { loadThirdPartyScript } from "../../utils/loadThirdPartyScript";
// import { environment } from "../../utils/environment";
import Modal from "../modal/modal";
import { useNavigationKeys } from "../../hooks/navigationKeys";
import LocationIcon from "./locationIcon";

import useLocalStorage from "../../hooks/localStorage";
import Input from "../Input/Input";
import { useAppContext } from "../../AppContext/App.context";
// import { useAppContext } from "../../AppContext/App.context";
import axios from "axios";
import { MapBoxFunction } from "./AutocompleteServices";
import HereMapsFunctions from "./AutocompleteServices";
import { mapTypesEnum } from "../../enum/MapType.enum";
import { map } from "leaflet";

interface IAutocomplete {
  address?: any;
  showLocationPicker?: boolean;
  showGeolocationFetcher?: boolean;
  onAutocompleteLocationSelected?: any;
  addToLocalStorage?: boolean;
  prefill?: any;
  markerAddress?: any;
}

const Autocomplete: React.FC<any> = forwardRef((props: any, ref: any) => {
  const [appData]: any = useAppContext();
  const GOOGLE_API_KEY = appData.loginData.google_api_key
    ? appData.loginData.google_api_key
    : "AIzaSyBkRba8rlBqvQfp_4YBgow6fjfKUvMiPNw";

  const mapType = Number(appData.loginData.map_configuration?.map_type) || 1;

  let [errors, setError]: any = useState(false);
  const initGoogleClasses = () => {
    if (
      window["google"] &&
      window["google"]["maps"] &&
      window["google"]["maps"]["places"]
    ) {
      GoogleAutocompleteService.initGoogleClasses();
    } else {
      setTimeout(() => {
        initGoogleClasses();
      }, 2000);
    }
  };
  useEffect(() => {
    initGoogleClasses();

    // loadThirdPartyScript({
    //   src: `https://maps.googleapis.com/maps/api/js?libraries=geometry,language,places,drawing&amp&key=${GOOGLE_API_KEY}`,
    //   id: "google-api",
    // }).then(() => GoogleAutocompleteService.initGoogleClasses());

    // }
  }, []);

  useEffect(() => {
    if (props.address) {
      changeAutocompleteInputValue(props.address);
    }
  }, [props.address]);
  const isLatitude = (num: any) => isFinite(num) && Math.abs(num) <= 90;
  const isLongitude = (num: any) => isFinite(num) && Math.abs(num) <= 180;
  const inputValueRef: any = useRef({
    text: "",
  });
  const [filteredOptions, setFilteredOptions]: any = useState([]);
  const inputRef = useRef<any>();
  const dropdownRef = useRef<any>(null);
  const [showDropdown, setShowDropdown] = useState(true);
  const [showModal, setShowModal]: any = useState(false);
  const [loadingGif, setLoadingGif] = useState(false);
  // const [appData]: any = useAppContext();

  const LocationPicker: any = lazy(() =>
    import("../location-picker/LocationPicker")
  );

  const onLocationSelected = async (index: any) => {
    if (filteredOptions) {
    }
    if (!filteredOptions[index]) {
      return;
    }
    changeAutocompleteInputValue(filteredOptions[index].description);
    if (mapType === mapTypesEnum.GoogleMap) {
      const {
        lat: latitude,
        lng: longitude,
      } = await GoogleAutocompleteService.getlatlong(filteredOptions[index]);
      latLng.current = { latitude, longitude };
      if (props.onAutocompleteLocationSelected) {
        props.onAutocompleteLocationSelected({
          address: inputRef.current.value,
          latLng: latLng.current,
        });
        if (props.addToLocalStorage) {
          useLocalStorage.setItem("location", {
            lat: latLng.current.latitude,
            lng: latLng.current.longitude,
            address: inputRef.current.value,
          });
        }
      }
    } else if (mapType === mapTypesEnum.HereMap) {
      let latitude = filteredOptions[index].lat;
      let longitude = filteredOptions[index].lng;
      latLng.current = { latitude, longitude };

      if (props.onAutocompleteLocationSelected) {
        props.onAutocompleteLocationSelected({
          address: filteredOptions[index].description,
          latLng: latLng.current,
        });

        if (props.addToLocalStorage) {
          useLocalStorage.setItem("location", {
            lat: latLng.current.latitude,
            lng: latLng.current.longitude,
            address: filteredOptions[index].description,
          });
        }
      }
    } else if (mapType === mapTypesEnum.MapBox) {
      let latitude = filteredOptions[index].lat;
      let longitude = filteredOptions[index].lng;
      latLng.current = { latitude, longitude };

      if (props.onAutocompleteLocationSelected) {
        props.onAutocompleteLocationSelected({
          address: filteredOptions[index].description,
          latLng: latLng.current,
        });

        if (props.addToLocalStorage) {
          useLocalStorage.setItem("location", {
            lat: latLng.current.latitude,
            lng: latLng.current.longitude,
            address: filteredOptions[index].description,
          });
        }
      }
    }

    setFilteredOptions([]);
  };
  useEffect(() => {
    latlongValidator();
  }, [props.latlngObject]);
  const latlongValidator = async () => {
    if (
      inputValueRef.current.text &&
      props.latlngObject.lat &&
      props.latlngObject.lng
    ) {
      setError({ [props.name]: false });
      props.triggerLocationValidate(
        false,
        inputRef.current?.value
          ? inputRef.current?.value
          : inputValueRef.current?.text
      );
      return;
    }
  };
  let resetNavigationIndex = useNavigationKeys(dropdownRef, onLocationSelected);

  let latLng: any = useRef(0);

  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDropdown(true);
    const obj = {
      input: e.target.value,
    };
    inputValueRef.current.text = e.target.value;

    if (e.target.value) {
      debounce(() => fetchSearchResults(obj), 500);
    } else {
      setFilteredOptions([]);
      props.onAutocompleteLocationSelected({});
    }
    resetNavigationIndex();
  };

  const changeAutocompleteInputValue: any = (address: any) => {
    inputRef.current.value = address;
  };

  useImperativeHandle(ref, () => ({
    onMarkerChange() {
      if (props.changeAddressOnMarkerChange) {
        changeAutocompleteInputValue(props.markerAddress.address);
      }
      props.onAutocompleteLocationSelected({
        address: inputRef.current.value,
        latLng: props.markerAddress.latLng,
      });
    },
    onValueChange(address?: string) {
      if (address == null || address == undefined) {
        changeAutocompleteInputValue(props.value);
      } else {
        changeAutocompleteInputValue(address);
      }
      // props.onAutocompleteLocationSelected({
      //   address: inputRef.current.value,
      //   latLng: props.markerAddress.latLng,
      // });
    },
  }));
  // useEffect(() => {
  //   if(props.addToLocalStorage)
  //   inputRef.current.value = useLocalStorage.getItem("location").address
  // })

  const changeLatLng: any = (l: string) => {
    latLng.current = l;
    if (props.onAutocompleteLocationSelected) {
      props.onAutocompleteLocationSelected({
        address: inputRef.current.value,
        latLng: latLng.current,
      });
      if (props.addToLocalStorage) {
        useLocalStorage.setItem("location", {
          lat: latLng.current.latitude,
          lng: latLng.current.longitude,
          address: inputRef.current.value,
        });
      }
    }
  };

  const fetchSearchResults = async (obj: object) => {
    console.log("maaaaaaaaaaaaap", mapType);
    let mapTypes = Number(mapType);
    switch (mapTypes) {
      case 1:
        {
          const result = await GoogleAutocompleteService.getPredictions(obj);
          setFilteredOptions(result);
          const elemName = props.name;
          let tempObj = {};
          if (result.length === 0) {
            // @ts-ignore
            props.triggerLocationValidate(false, obj.input);

            if (!props.latlngObject.lat && !props.latlngObject.lng) {
              setError({ [elemName]: true });
            }
            //  (true);
          } else {
            props.triggerLocationValidate(true);
            setError({ elemName: false });
          }
        }
        break;
      case 2:
        {
          const { input }: any = obj;
          const results = await HereMapsFunctions.Heremaps(input);
          setFilteredOptions(results);
        }

        break;
      case 3: {
        const { input }: any = obj;
        const result = await MapBoxFunction.Mapbox(input);
        setFilteredOptions(result);
      }
      default:
        break;
    }
  };
  // useEffect(() => {
  //   if (loadingGif) {
  //     changeAutocompleteInputValue(appData.fetchApp.address);
  //     changeLatLng({
  //       latitude: appData.fetchApp.latitude,
  //       longitude: appData.fetchApp.longitude,
  //     });
  //   }
  // }, [loadingGif]);

  const fetchGeolocation = async () => {
    setLoadingGif(true);
    const { coords }: any = await Position();
    const location = await GoogleAutocompleteService.getGeolocation(
      new google.maps.LatLng(coords.latitude, coords.longitude)
    );

    changeAutocompleteInputValue(location);
    changeLatLng(coords);
    if (props.onAutocompleteLocationSelected) {
      props.onAutocompleteLocationSelected({
        address: inputRef.current.value,
        latLng: latLng.current,
      });
      if (props.addToLocalStorage) {
        useLocalStorage.setItem("location", {
          lat: latLng.current.latitude,
          lng: latLng.current.longitude,
          address: inputRef.current.value,
        });
      }
    }
    setShowDropdown(false);
  };

  const profileDropDownClosed = () => {
    setShowDropdown(false);
  };

  useClickOutside(dropdownRef, () => {
    profileDropDownClosed();
  });

  return (
    <div className="autocomplete-location__container">
      <div className="autocomplete-location">
        <div className="autocomplete-location__field">
          {props.showLocationPicker ? (
            <div
              onClick={() => setShowModal(true)}
              className="autocomplete-location__show-map-icon"
            >
              <div className="pt-2">
                <LocationIcon fill={props.color} />
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <input
            className="autocomplete-location__input"
            autoComplete="off"
            type="text"
            ref={inputRef}
            name="autocomplete-search"
            onChange={onInputChanged}
            placeholder="Please select a location"
            defaultValue={props.prefill}
          /> */}
          {props.doNotShowMaterialInput ? (
            <input
              type="text"
              className="autocomplete-location__field__plain-input"
              id={props.name || "team-address"}
              name={props.name || "team-address"}
              ref={inputRef}
              value={props.value}
              style={props.style}
              autoComplete="off"
              onChange={onInputChanged}
              placeholder={
                props.label ? props.label : "Please Select a Location"
              }
            ></input>
          ) : (
            <Input
              type="text"
              id={props.name || "team-address"}
              name={props.name || "team-address"}
              refValue={inputRef}
              variant={props.variant || "outlined"}
              helperText={props.helperText}
              InputLabelProps={props.InputLabelProps}
              errors={errors}
              style={props.style}
              value={props.value}
              onchange={onInputChanged}
              label={props.label ? props.label : "Please Select a Location"}
            ></Input>
          )}

          {showDropdown ? (
            <ul className="autocomplete-location__box" ref={dropdownRef}>
              {filteredOptions.map((item: any, index: any) => (
                <li key={index} onClick={() => onLocationSelected(index)}>
                  <img src={locationIcon} alt="location-marker" />
                  {item.description}
                </li>
              ))}
            </ul>
          ) : null}
          {/* {props.showGeolocationFetcher ? (
            <img
              onClick={fetchGeolocation}
              alt="target-marker"
              src={targetIcon}
            ></img>
          ) : null} */}
          <Modal
            shown={showModal}
            width={"50vw"}
            height={"60vh"}
            onClose={() => setShowModal(false)}
          >
            {showModal ? (
              <Suspense fallback={<h3>Loading&hellip;</h3>}>
                <LocationPicker
                  address={inputRef.current.value}
                  latLng={latLng.current}
                  changeAutocompleteInputValue={changeAutocompleteInputValue}
                  changeLatLng={changeLatLng}
                  setShowModal={setShowModal}
                />
              </Suspense>
            ) : null}
          </Modal>

          {/* <button
            type="button"
            className="autocomplete-location__btn"
            onClick={() => fetchGeolocation().then(() => setLoadingGif(false))}
            style={{ backgroundColor: props.btnBg }}
          >
            <div className="autocomplete-location__target-icon">
              {loadingGif ? (
                <img
                  src={loadingIcon}
                  alt="loading-gif"
                  className="autocomplete-location__loading-gif"
                />
              ) : (
                <Target fill={props.color} />
              )}
            </div>
            {props.val ? (
              <div
                className="autocomplete-location__target-value"
                style={{ minWidth: props.val !== undefined ? "70px" : "0" }}
              >
                {loadingGif ? "Loading..." : props.val}
              </div>
            ) : null}
          </button> */}
        </div>
      </div>
    </div>
  );
});

export default Autocomplete;
