import React, { useEffect, Fragment } from "react";
import DashboardMapMarkers from "../../../utils/dashboardMarkers";
import lMap from "../../../utils/leaflet/map";
import "./TaskLocation.scss";

const TaskLocationMap = (props: any) => {
  let jobsMakrerList: any = {};
  let myMap: any;
  let mapBounds: any = [];

  useEffect(() => {
    initMap();
    setResponseOnMapView(props.taskHistoryDetail);
  }, []);

  const setResponseOnMapView = async (data: any) => {
    await createMakrerForJobs(data);
    myMap.fitMap(mapBounds);
  };
  const createMakrerForJobs = (task: any) => {
    
   
    let template = DashboardMapMarkers.getDefaultMarker("#fc8344");
    jobsMakrerList[task["task_id"]] = new lMap.markers({
      map: myMap.map,
      lat: task.lat,
      lng: task.lng,
      // icon: `${
      //   window.location.origin +
      //   "/assets/images/jobs-fleet-marker/assigned_pickup.png"
      // }`,
      icon: `${"data:image/svg+xml;charset=UTF-8, " + encodeURIComponent(template)
        }`,
      params: [1, 2, 3],
    });
    mapBounds.push([task.lat, task.lng]);
  };

  const initMap = function () {
    myMap = new lMap.map({
      eleId: "map",
      zoom: 13,
    });
  };

  return (
    <Fragment>
      <div className={"taskMapGrid"}>
        <div id="map" className="networkOnMap taskHistoryMap"></div>
      </div>
    </Fragment>
  );
};

export default TaskLocationMap;
