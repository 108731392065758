import {
  Button,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import PageHeading from "../../components/PageHeading/PageHeading";
import { themeColor } from "../../constants/themeColor";
import "../ScheduledPayments/ScheduledPayment.scss";
import DataTable from "../../components/Datatable/Datatable";
import Btn from "../../components/Button/Button";
import DeleteRounded from "@material-ui/icons/DeleteRounded";
import AutocompleteMaterial from "@material-ui/lab/Autocomplete";
import Dropdown from "../../components/Dropdown/Dropdown";
import PaymentRules from "./PaymentRules/PaymentRules";
import { useAppContext } from "../../AppContext/App.context";
import ScheduledPaymentEndPoints from "./ScheduledPaymentsEndPoints";
import useApiService from "../../services/api.service";
import useToast from "../../components/Toast/hooks/useToast";
import headerDropdownService from "../../services/headerDropdown.service";
import DateTimeFormatter from "../../utils/DateFormatter";
import { useHistory, useParams } from "react-router-dom";
import useLoader from "../../hooks/useLoader";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "1.2rem",
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: "10px",
    marginTop: "10px",
    marginLeft: "18px",
  },
  tabs: {
    marginLeft: "7px",
    marginRight: "20px",
  },
  muiTabRoot: {
    color: "#000",
    minWidth: "0px !important",
    fontSize: "15px",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  tabsIndicator: {
    marginLeft: "10px",
    width: "120.409px !important",
    height: "3px",
  },
  container: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  tabBody: {
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "0 17px 24px 0 rgba(0, 126, 191, 0.26)",
    margin: "1.25rem 1rem",
    padding: "1.4rem 1.8rem",

    minHeight: "calc(95vh - 215px)",
  },
  btn: {
    margin: theme.spacing(1),
  },
}));

const paymentPeriod: any = {
  "0": "Instant",
  "1": "Daily",
  "2": "Weekly",
  "3": "Manually",
  "4": "Monthly",
};

const ScheduledPayments = () => {
  const classes = useStyles();
  const [tabType, setTabType] = useState(0);
  const [appData]: any = useAppContext();
  const ApiService = useApiService();
  const toaster = useToast();
  let currentDate: any = new Date();
  const history = useHistory();
  const loader = useLoader();
  let [countRefreshDataTable, setCountRefreshDataTable] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const access_token = appData.loginData.access_token;
  const currency = appData.loginData.currency;
  const [sort, setSort]: any = useState({
    sortColumn: "id",
    sortDirection: "DESC",
  });
  let startDate: any = new Date(currentDate.setDate(currentDate.getDate() - 6));
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
    startDate,
    "YYYY-MM-DD HH:mm"
  );
  let endDate: any = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
    endDate,
    "YYYY-MM-DD HH:mm"
  );
  let defaultExtraParams = [
    {
      name: "end_datetime",
      value: endDate,
    },
    {
      name: "start_datetime",
      value: startDate,
    },
  ];
  let headerFilterData = {
    tag_id: 0,
    date: { startDate: startDate, endDate: endDate },
  };
  const [
    extraParamsForAgentReferralHit,
    setExtraParamsForAgentReferralHit,
  ]: any = useState(defaultExtraParams);
  let params: any = useParams();

  useEffect(() => {
    let headerSubscription = headerDropdownService.subscribe(
      dropdownUpdatedFromHeader
    );

    return () => {
      headerSubscription.unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (params.type === "history") {
      setTabType(1);
    } else {
      setTabType(0);
    }
    // setTabType(0);
    // history.push("/extensions/scheduled/payments/rules")
  });
  const dropdownUpdatedFromHeader = (eventData: any) => {
    switch (eventData.type) {
      case "dateRange":
        let startdateTime = new Date(eventData.data.date.startDate);
        startdateTime.setHours(0);
        startdateTime.setMinutes(0);
        startdateTime.setSeconds(0);
        let endDateTime = new Date(eventData.data.date.endDate);
        endDateTime.setHours(23);
        endDateTime.setMinutes(59);
        endDateTime.setSeconds(59);
        defaultExtraParams = [
          {
            name: "start_datetime",
            value: DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
              startdateTime,
              "YYYY-MM-DD HH:mm"
            ),
          },
          {
            name: "end_datetime",
            value: DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
              endDateTime,
              "YYYY-MM-DD HH:mm"
            ),
          },
        ];
        let reqBody: any = [
          {
            name: "start_datetime",
            value: DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
              startdateTime,
              "YYYY-MM-DD HH:mm"
            ),
          },
          {
            name: "end_datetime",
            value: DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
              endDateTime,
              "YYYY-MM-DD HH:mm"
            ),
          },
        ];
        if (headerFilterData.tag_id) {
          reqBody.push({
            name: "tag_id",
            value: headerFilterData.tag_id,
          });
        }
        setExtraParamsForAgentReferralHit(reqBody);
        setTimeout(() => {
          setCountRefreshDataTable((perviousData) => perviousData + 1);
        }, 100);
        break;
      case "tagList":
        {
          headerFilterData.tag_id = eventData.data.tag_id;
          setExtraParamsForAgentReferralHit([
            ...defaultExtraParams,
            {
              name: "tag_id",
              value: eventData.data.tag_id,
            },
          ]);
          setTimeout(() => {
            setCountRefreshDataTable((perviousData) => perviousData + 1);
          }, 100);
        }
        break;
      default:
        break;
    }
    // refreshAgentReferralTable();
  };

  const handlePayNow = async (data: any) => {
    // console.log(data, "What data");

    let headers = {
      access_token: access_token,
    };
    let qureyParams: any = {
      transaction_id: data.transaction_id,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    loader.showLoader();
    await ApiService.post(ScheduledPaymentEndPoints.payNow(reqData))
      .then((res: any) => {
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "sucess",
        });
        loader.hideLoader();
        setCountRefreshDataTable(++countRefreshDataTable);
      })
      .catch((err) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
        loader.hideLoader();
      });
  };
  const ActionButton: any = {
    "0": <>--</>,
    "1": <>--</>,
  };
  const paymentStatus: any = {
    "0": "Pending",
    "1": "Paid",
    "2": <>Failed</>,
  };
  const ShowStatusInHtmlView = (rowObj: any) => {
    const message = rowObj.row.message;
    const type = rowObj.row.status;
    if (type == 2) {
      return (
        <>
          <div style={{ textAlign: "center" }}>
            {showMessage ? <>{rowObj.row.error}</> : "Failed"}
            <p
              style={{
                color: "#3c7bf6",
                textDecoration: "underline",
                cursor: "pointer",
                margin: 0,
                textAlign: "center",
              }}
              onClick={(e: any) => {
                setShowMessage(!showMessage);
              }}
            >
              {showMessage ? "Hide Reason" : "Show Reason"}
            </p>
          </div>
          {/* {showMessage && <>{JSON.stringify()}</>} */}
        </>
      );
    }
    return paymentStatus[type];
  };
  const ShowTimePeriodInHtmlView = (rowObj: any) => {
    const type = rowObj.row.payment_frequency;
    return paymentPeriod[type];
  };
  const ShowCurrency = (rowObj: any) => {
    return currency + rowObj.row.amount;
  };
  const ShowActionButtonInHtmlView = (rowObj: any) => {
    const type = rowObj.row.status;
    if (type == 2) {
      return (
        <>
          <p
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={() => handlePayNow(rowObj.row)}
          >
            Pay Now
          </p>
        </>
      );
    }
    return ActionButton[type];
  };
  const columns: any = [
    {
      name: "Customer ID",
      selector: "customer_id",
      wrap: true,

      sortable: true,
      cell: (row: any) => (
        <div style={{ marginLeft: "20px" }}>{row["customer_id"]}</div>
      ),
    },
    {
      name: "Customer Name",
      selector: "name",
      wrap: true,
      center: true,
      sortable: true,
      minWidth: "125px",
      //   cell: (row: any) => showTooltip(row["name"]),
    },
    {
      name: "Customer Tag",
      selector: "tags",
      wrap: true,
      sortable: true,
      center: true,
      minWidth: "125px",
      //   cell: (row: any) => showTooltip(row["email"]),
    },
    {
      name: "Amount",
      selector: "amount",
      wrap: true,
      sortable: true,
      center: true,
      cell: (row: any) => <ShowCurrency row={row} />,
      //   cell: (row: any) =>
      //     showTooltip(`${row["country_code"] + row["phone_number"]}`),
    },

    {
      name: "Time period",
      selector: "time_period",
      wrap: true,
      center: true,
      sortable: true,

      cell: (row: any) => <ShowTimePeriodInHtmlView row={row} />,
    },

    {
      name: "Status",
      selector: "status",
      wrap: true,
      center: true,
      sortable: true,

      cell: (row: any) => (
        <>
          <ShowStatusInHtmlView row={row} />
        </>
      ),
    },
    {
      name: "Action",
      wrap: true,
      center: true,
      sortable: true,

      cell: (row: any) => (
        <>
          <ShowActionButtonInHtmlView row={row} />
        </>
      ),
    },
  ];

  let handleTabChange = (event: any, newValue: number) => {
    if (newValue == 0) {
      setTabType(0);
      history.push("/extensions/payments/rules");
    } else if (newValue == 1) {
      setTabType(1);
      history.push("/extensions/payments/history");
    }
  };
  let ScheduledPaymentsTabs = () => {
    return (
      <>
        <Tabs
          value={tabType}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="Enp Tabs"
          classes={{
            root: classes.tabs,
            indicator: classes.tabsIndicator,
          }}
        >
          <Tab className={classes.muiTabRoot} label="Payment Rules" />
          <Tab className={classes.muiTabRoot} label="Payment History" />
        </Tabs>
      </>
    );
  };
  return (
    <Fragment>
      <div
        className="Template"
        style={{ backgroundColor: themeColor.defaultTableColor }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{ backgroundColor: "white" }}>
              <PageHeading title="Payments"></PageHeading>
              <Grid item xs={12}>
                <Typography style={{ marginLeft: "1.4rem" }}>
                  Schedule automated payments for customer based on tags and
                  track payment history.
                </Typography>
              </Grid>
              {ScheduledPaymentsTabs()}
            </div>
          </Grid>
        </Grid>
        {tabType == 0 && <PaymentRules />}
        {tabType == 1 && (
          <div className="customerList datatable">
            <DataTable
              columns={columns}
              extraParams={extraParamsForAgentReferralHit}
              fixedHeader={true}
              defaultSortColumnName={sort.sortColumn}
              defaultSortColumnOrder={sort.sortDirection}
              showErrorMsgFromAPI
              refreshDataTable={countRefreshDataTable}
              endPoint="main/customer/payment/history"
              fixedHeaderScrollHeight={"68vh"}
              rowsStyle={{
                minHeight: "57px",
              }}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ScheduledPayments;
