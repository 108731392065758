import useLocalStorage from "../hooks/localStorage";
import { extensionList } from "./extensionList";

export const JobColumnList = (t: any) => {
  const activatedExtension = useLocalStorage.getItem("activatedExtension");
  const isRouteOptimization = activatedExtension.includes(
    extensionList.routeOptimization.id
  );
  let columns = [
    { name: t("Jobslist:Job IDs"), key: "checked" },
    { name: t("Jobslist:Job ID"), key: "jobId__column" },
    { name: t("Common:Reference ID"), key: "referenceId__column" },
    { name: t("Jobslist:Job Status"), key: "jobStatus__column" },
    { name: t("Jobslist:Team Name"), key: "teamName__column" },
    { name: t("Jobslist:Agent Details"), key: "resourceDetails__column" },
    { name: t("Jobslist:Pickup Details"), key: "pickupDetails__column" },
    { name: t("Jobslist:Delivery Details"), key: "deliveryDetails__column" },
    { name: t("Jobslist:Service Details"), key: "serviceDetails__column" },
    { name: t("Jobslist:Creation Datetime"), key: "creationDatetime__column" },

    // This field is commented because now dynamic list is made according to template
    // and actions is list to end of the columns by -- Chetan
    // { name: t("Common:Actions"), key: "actions__column" },
  ];
  if (isRouteOptimization) {
    columns.splice(6, 0, {
      name: t("Jobslist:Resources"),
      key: "ROdriver_column",
    });
  }
  return columns;
};
