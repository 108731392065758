const DatatableEndpoints = {
    getData(reqData:any) {
      return {
        url: reqData.url,
        data:reqData["reqBody"],
        headers:reqData["headers"]
      };
    }
  };
  
  export default DatatableEndpoints;