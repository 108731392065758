import React, { useState, Fragment, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import headerDropdownService from "../../services/headerDropdown.service";
import { useTranslation } from "react-i18next";
import useApiService from "../../services/api.service";
import { NLevelApiService } from "../../containers/Settings/NLevelCatalog/Nlevel.endpoints";
import { useAppContext } from "../../AppContext/App.context";
import { InputLabel } from "@material-ui/core";

const CustomerAppOptionDropdown = (props: any) => {
  const { t, i18n } = useTranslation(["Header", "Common"]);
  const [selectedAppSetting, setAppSetting] = useState(0);
  const ApiService = useApiService();
  const [appData]: any = useAppContext();
  const [list, setlist]: any = useState([]);

  const onChange = (event: any) => {
    setAppSetting(event.target.value);
    const dropdownData = {
      type: "customerAppId",
      data: event,
    };
    headerDropdownService.emit(dropdownData);
  };
  useEffect(() => {
    GetCustomerAppSettings();
  }, []);
  const GetCustomerAppSettings = async () => {
    let headers = {
      access_token: appData.loginData.access_token,
    };
    let qureyParams = {
      skip: 0,
      limit: 1000,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    await ApiService.get(NLevelApiService.getCustomerAppSettings(reqData))
      .then((response) => {
        setlist(response.data.data);
        const dropdownData = {
          type: "customerAppId",
          data: response.data.data[response.data.data.length - 1].id,
        };
        setTimeout(() => {
          headerDropdownService.emit(dropdownData);
        }, 800);
      })
      .catch((error: any) => {});
  };

  const renderTeamList = () => {
    return (
      list.length > 0 &&
      list.map((team: any, index: any) => {
        return <MenuItem value={team.id}> {team.url}</MenuItem>;
      })
    );
  };
  return (
    <Fragment>
      {/* <img
        src={TeamDropdownIcon}
        alt="team drpdown"
        className={classes["dropdown-pre-icon"]}
      ></img> */}
      <div hidden={true}>
        <InputLabel id="demo-controlled-open-select-label">
          {"Select Setting"}
        </InputLabel>
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          label="Select Setting"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          disableUnderline={true}
          value={selectedAppSetting}
          onChange={onChange}
        >
          {renderTeamList()}
        </Select>
      </div>
    </Fragment>
  );
};
export default CustomerAppOptionDropdown;
