import React, { useEffect, useState } from 'react'
import "./Dashboard.scss"
import NotificationsIcon from '@material-ui/icons/Notifications';
import { themeColor } from '../../constants/themeColor';
import { Grid, Typography } from '@material-ui/core';
import { useAppContext } from '../../AppContext/App.context';
import useApiService from '../../services/api.service';
import DashboardEndpoints from './Dashboard.endpoints';
import InfiniteScroll from "react-infinite-scroll-component"
import CloseIcon from "@material-ui/icons/Close";


const ReleaseUpdates = () => {
  const [showReleaseUpdate, setShowReleaseUpdate]: any = useState(false);
  const [releaseUpdatesRows, setReleaseUpdatesRows]: any = useState([]);
  const [hasMore, setHasMore]: any = useState(true);
  const [showUpdateRedDot, setShowUpdateRedDot]: any = useState(false);
  const [appData]: any = useAppContext();
  const ApiService = useApiService();

  useEffect(() => {
    getReleaseNotes()
  }, [])

  const fetchDataFromAPI = () => {
    getReleaseNotes(releaseUpdatesRows.length)
  }
  const getReleaseNotes = async (skip?: number) => {

    const headers = {
      access_token: appData.loginData.access_token,
    };
    const requestBody = {
      limit: 10,
      skip: skip ? skip : 0
    }
    const reqData = {
      reqBody: requestBody,
      headers: headers,
    };
    await ApiService.get(DashboardEndpoints.getReleaseNotes(reqData)).then((res: any) => {

      if (res.data.length > 0) {
        setReleaseUpdatesRows([...releaseUpdatesRows, ...res.data])

        setShowUpdateRedDot(!res.data[0].is_read)

      }
      else {
        setHasMore(false)
      }
    }).catch((err: any) => {
      setReleaseUpdatesRows([])
      setHasMore(false)

    })
  }
  const handleNotesClick = (row: any) => {
    window.open(row.pdf_url)
  }
  const updateReadReleaseUpdatesStatus = async (id: number) => {
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const requestBody = {
      release_id: id
    }
    const reqData = {
      reqBody: requestBody,
      headers: headers,
    };
    await ApiService.put(DashboardEndpoints.updateReleaseNotesStatus(reqData)).then((res: any) => {
      let tempData = [...releaseUpdatesRows]
      tempData[0].is_read = true;
      setReleaseUpdatesRows(tempData)
    }).catch((err: any) => {
    })
  }
  useEffect(() => {
    if (showReleaseUpdate && releaseUpdatesRows.length > 0 && !releaseUpdatesRows[0]?.is_read) {
      updateReadReleaseUpdatesStatus(releaseUpdatesRows?.[0].release_id)
      setShowUpdateRedDot(false)
    }
  }, [showReleaseUpdate])
  return (
    <>
      <div style={{ marginLeft: "10px", position: "relative" }}>
        <NotificationsIcon
          style={{ color: themeColor.iconsColor, cursor: "pointer" }}
          onClick={() => {
            setShowReleaseUpdate((value: any) => !value)

          }}
        />
        {
          showUpdateRedDot ?
            <div className="update-dot" >

            </div>
            : <></>
        }
      </div>
      <div className="relasenotes_popup arrow-top" hidden={!showReleaseUpdate}>
        <div className="ReleaseUpdate">
          <Grid container justify="space-between" alignItems="center" style={{ marginBottom: "10px" }}>
            <Typography style={{ fontWeight: "bold", color: "black" }}>Product Releases</Typography>
            <CloseIcon style={{ color: themeColor.iconsColor, cursor: "pointer", fontSize: "18px" }}
              onClick={() => {
                setShowReleaseUpdate(false)

              }} />

          </Grid>
          <InfiniteScroll
            // style={{ width: "100%" }}
            className="infinte-scroll-updates"
            dataLength={releaseUpdatesRows.length}
            next={fetchDataFromAPI}
            hasMore={hasMore}
            height={"55vh"}
            loader={<h5 style={{ color: "#000000", textAlign: "center", margin: "5px" }}>Loading...</h5>}
            endMessage={<></>}
          >
            {
              releaseUpdatesRows.length > 0 ?
                releaseUpdatesRows.map((item: any) => (
                  <Grid container direction="row" spacing={2} alignItems={"baseline"}>
                    <Grid item xs={1}>
                      <div className="ReleaseUpdate__dot"></div>
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container direction="row">
                        <Grid item xs={12}  >

                          <Typography style={{ color: "#000000", fontSize: "13px" }}>{item.notes}<a href={item.pdf_url} style={{color:themeColor.primary.main}} target="_blank"> Know more</a>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{ color: "#000000", opacity: "0.7", fontSize: "12px" }}>{item.date}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))

                : releaseUpdatesRows.length === 0 && hasMore ?
                  <></>

                  : <>
                    <Typography style={{ color: "#000000", fontSize: "15px" }}>
                      No updates available
                    </Typography>
                  </>
            }

          </InfiniteScroll>
        </div>
      </div>
    </>
  )
}

export default ReleaseUpdates


