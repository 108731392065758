import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import DatePicker from "react-datepicker";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.scss";
import setHours from "date-fns/setHours";
import CancelIcon from "@material-ui/icons/Cancel";
import setMinutes from "date-fns/setMinutes";
import { themeColor } from "../../constants/themeColor";
import DateTimeFormatter from "../../utils/DateFormatter";
import { useLocation } from "react-router";
import useLocalStorage from "../../utils/localStorage";
import { useAppContext } from "../../AppContext/App.context";
const DateTimePicker = (props: any) => {
  const curr = new Date();
  let currentDateTime: any = setHours(
    setMinutes(new Date(), curr.getMinutes()),
    curr.getHours()
  );
  const location = useLocation();
  const [appData]: any = useAppContext();
  const [startDate, setStartDate] = useState(null);
  const [highlightedDatesArr, setHighlightedDatesArr]: any = useState([]);
  const [openDatePicker, setOpenDatePicker]: any = useState(false);
  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return props.disablePast
      ? currentDate.getTime() < selectedDate.getTime()
      : true;
  };
  const [data, setData]: any = useState([]);
  const getHighlightedDates = (arr: any) => {
    let tempArr: any = [];
    // console.log(arr,"poppop")
    arr.map((item: any) => {
      tempArr.push(
        appData.loginData.date_format?.includes("DD-MM-YYYY")
          ? new Date(DateTimeFormatter.getDateInJavascriptAllowedFormat(item))
          : new Date(item)
      );
    });
    setHighlightedDatesArr(tempArr);
    let tempObject = { customHighlightedCss: tempArr };
    setData([tempObject]);
  };
  useEffect(() => {
    if (props.highlightDates) {
      getHighlightedDates(props.highlightDates);
    }
  }, [props.highlightDates]);

  useEffect(() => {
    //  setShown(true)
    if (props.value) {
      let dateSelected: any = new Date(
        typeof props.value === "string"
          ? props.value.replace(/-/g, "/")
          : props.value
      );
      setStartDate(dateSelected);
    } else {
      // setStartDate(null)
      // props.onChange(null)
    }
  }, [props.value]);
  let convertTimeForDatePicker = (format: any, type: any) => {
    // Type 0: Time, 1: Date Time, 2: Date
    let currentFormat = format;
    if (type === 0 && props.showTimeSelectOnly) {
      if (format === "HH:mm") {
        return format;
      }
      return "hh:mm a";
    } else if (type === 0) {
      let dateTime: any = DateTimeFormatter.getDateTimeFormat();
      dateTime = dateTime.split(" ");
      if (dateTime[2] === "A") {
        return "hh:mm a";
      } else {
        return "HH:mm";
      }
    }
    currentFormat = currentFormat.toLowerCase();
    if (type === 1 || type === 2) {
      if (type === 1) {
        currentFormat = currentFormat.split("h").join("H");
      }
      currentFormat = currentFormat.replace("m", "M");
      currentFormat = currentFormat.replace("m", "M");
      let dateTime = currentFormat.split(" ");
      if (dateTime[2] === "a") {
        currentFormat = currentFormat.replace("H", "h");
        currentFormat = currentFormat.replace("H", "h");
      }
      return currentFormat;
    }
  };
  const handleCalendarOpen = () => {
    if (location.pathname === "/routes/list" && props.onCalendarOpen) {
      props.onCalenderOpen(true);
    } else {
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <label
        onClick={(e: any) => {
          openDatePicker && e.preventDefault();
        }}
      >
        <DatePicker
          //className="red-border"

          className={props.className || "outlined"}
          onInputClick={() => setOpenDatePicker((current: any) => !current)}
          selected={startDate}
          id={props.id}
          onChange={(date: any) => {
            if (date < new Date() && props.disablePast) {
              setStartDate(currentDateTime);
              props.onChange(currentDateTime);
            } else {
              setStartDate(date);
              props.onChange(date);
            }
          }}
          filterTime={filterPassedTime}
          minDate={props.disablePast ? new Date() : null}
          shouldCloseOnSelect={true}
          onCalendarClose={() => setOpenDatePicker(false)}
          timeIntervals={props.timeIntervals || 15}
          timeFormat={convertTimeForDatePicker(
            DateTimeFormatter.getTimeFormat(),
            0
          )}
          onMonthChange={(data) => {
            if (location.pathname === "/routes/list") {
              props.updatedMonth(data);
            }
          }}
          onCalendarOpen={handleCalendarOpen}
          highlightDates={highlightedDatesArr ? data : null || []}
          showTimeSelect={props.showTimeSelect}
          showTimeSelectOnly={props.showTimeSelectOnly}
          placeholderText={props.label}
          isClearable={props.isClearable}
          showYearPicker={props.showYearPicker}
          dateFormat={
            props.showYearPicker
              ? props.dateFormat
              : props.showTimeSelect
              ? props.showTimeSelectOnly
                ? convertTimeForDatePicker(DateTimeFormatter.getTimeFormat(), 0)
                : convertTimeForDatePicker(
                    DateTimeFormatter.getDateTimeFormat(),
                    1
                  )
              : convertTimeForDatePicker(DateTimeFormatter.getDateFormat(), 2)
          }
        >
          {props.highlightDates ? (
            <div style={{ textAlign: "center" }}>
              <div style={{ display: "inline-flex" }}>
                <span
                  style={{
                    height: "15px",
                    width: "15px",
                    backgroundColor: "#ff7600",
                    borderRadius: "50%",
                    display: "inline-flex",
                    // paddingTop:'10px'
                  }}
                ></span>
                <span style={{ display: "inline-block" }}>
                  &nbsp;Routes Created
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </DatePicker>
        <div
          style={{
            position: "absolute",
            right: "19px",
            top: "15px",
            cursor: "pointer",
          }}
          hidden={props.showIcon ? false : true}
        >
          <CalendarTodayIcon style={{ color: `${themeColor.primary.main}` }} />
        </div>
        <div
          style={{
            position: "absolute",
            right: "0",
            top: "15px",
            cursor: "pointer",
          }}
          hidden={props.isCustomClearable && startDate ? false : true}
        >
          <CancelIcon
            onClick={() => {
              setStartDate(null);
              props.onChange(null);
            }}
            color="primary"
          />
        </div>
      </label>
    </div>
  );
};
// const CustomDatePickDiv = styled.div`

// `;

export default DateTimePicker;
