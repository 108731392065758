export const extensionService = {
  getExtensionsList(reqData: any) {
    return {
      url: "integration/extensions/get",

      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getRecurringDetail(reqData: any) {
    return {
      url: "integration/recurring/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getExtensionsDetails(reqData: any) {
    return {
      url: "integration/extensions/get/details",

      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getAgentReferralExtentionDetails(reqData: any) {
    return {
      url: "integration/referral/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  activateExtension(reqData: any, operation?: number) {
    let url = "integration/user/extension/activate";
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },

  sosSubscription(reqData: any, operation: any) {
    let url = "integration/sos";
    switch (operation) {
      case 1:
        url += "/update";
        break;
      default:
        url += "/create";
        break;
    }
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  capacityManagement(reqData: any, operation: any) {
    let url = "integration/capacity";
    switch (operation) {
      case 1:
        url += "/update";
        break;
      default:
        url += "/create";
        break;
    }
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  faceVerification(reqData: any, operation: any) {
    let url = "integration/face/verify";
    switch (operation) {
      case 1:
        url += "/update";
        break;
      default:
        url += "/create";
        break;
    }
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  removeCapacityManagement(reqData: any) {
    let url = "integration/capacity/remove";
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  hubspotDeal(reqData: any) {
    return {
      url: "integration/extension/hubspot",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deactivateExtension(reqData: any) {
    return {
      url: "integration/user/extension/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },

  createPayment(reqData: any, isRetry?: boolean) {
    return {
      url: isRetry
        ? "resource/earning/payment/retry"
        : "resource/earning/payment",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  createReferralPayment(reqData: any) {
    return {
      url: "resource/referral/payment",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  createRecurring(reqData: any, operation: any) {
    let url = "integration/recurring";
    switch (operation) {
      case 1:
        url += "/update";
        break;
      default:
        url += "/create";
        break;
    }
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  stripeConnect(reqData: any, operation?: number) {
    let url = "integration/extension/stripe";

    switch (operation) {
      case 1:
        url += "/update";
        break;
      default:
        break;
    }
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getTwilioMasking(reqData: any) {
    return {
      url: "integration/extension/mask/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  twilioMasking(reqData: any, operation?: number) {
    let url = "integration/extension/";
    switch (operation) {
      case 1:
        url += "mask/update";
        break;
      default:
        url += "create";
        break;
    }
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deactivateStripeConnect(reqData: any) {
    return {
      url: "integration/extension/stripe/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  twilioDetails(reqData: any) {
    return {
      url: "integration/extension/twilio",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  agentReferralDetails(reqData: any) {
    return {
      url: "integration/referral/create",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  updateAgentReferralDetails(reqData: any) {
    return {
      url: "integration/referral/update",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deactivateAgentReferral(reqData: any) {
    return {
      url: "integration/referral/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deactivateIdentityVerification(reqData: any) {
    return {
      url: "integration/face/verify/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deactivateSosSubscription(reqData: any) {
    return {
      url: "/integration/sos/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },

  agentWalletConfig(reqData: any, operation?: number) {
    let url = "integration/wallet";

    switch (operation) {
      case 1:
        url += "/update";
        break;
      default:
        url += "/create";
        break;
    }
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  addWalletTransaction(reqData: any) {
    return {
      url: "main/wallet/transaction",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getWalletconfig(reqData: any) {
    return {
      url: "integration/Wallet/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getWalletReport(reqData: any) {
    return {
      url: "main/wallet/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getROconfig(reqData: any) {
    return {
      url: "integration/ro/config/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  ROconfig(reqData: any, operation?: number) {
    let url = "integration/ro/config";

    switch (operation) {
      case 1:
        url += "/update";
        break;
      default:
        url += "/create";
        break;
    }
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deactivateRO(reqData: any) {
    return {
      url: "integration/ro/config/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deactivateAgentWallet(reqData: any) {
    return {
      url: "integration/wallet/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deactivateTwilio(reqData: any) {
    return {
      url: "integration/twilio/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getOTPVerificationSettings(reqData: any) {
    return {
      url: "integration/extension/otp_verification/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  updateOTPVerificationSettings(reqData: any, operation: number) {
    let url = "integration/extension/otp_verification";

    switch (operation) {
      case 1:
        url += "/update";
        break;
      default:
        break;
    }
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  createBroadCastRequest(reqData: any) {
    return {
      url: "notification/broadcast",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};
