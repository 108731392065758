import React, { useEffect, useState, Fragment, useRef, Suspense } from "react";
import lMap from "../../utils/leaflet/map";
import { jobMarkers, jobStatusData } from "../../constants/jobStatusData";
import classes from "./TaskDetail.module.scss";
import "../../utils/leaflet/css/leaflet.responsive.popup.scss";
import DashboardMapMarkers from "../../utils/dashboardMarkers";
import ReactDOM from "react-dom";
import { Grid, Typography } from "@material-ui/core";
import { taskHistoryTypes } from "../../constants/taskHistoryTypes";
import TaskHistoryCard from "./TaskDetailTabs/TaskHistoryCard";
import DateTimeFormatter from "../../utils/DateFormatter";
import useLocalStorage from "../../hooks/localStorage";
import { useAppContext } from "../../AppContext/App.context";

// import Header from "../Header/Header";
const TaskDetailMap = (props: any) => {
  const taskDetailData = props.taskDetail;
  let mapBounds: any = [];
  const loginData: any = useLocalStorage.getItem("loginData");
  const [appData]: any = useAppContext();

  const [markerList, updateMarkerList]: any = useState({
    jobsMarker: {},
    resourceMarker: {},
    historyMarker: {},
  });
  const [myMap, setMyMap]: any = useState();
  let [counter, setCounter]: any = useState(0);
  // let myMap: any;
  const initMap = () => {
    const tempMap: any = new lMap.map({
      eleId: "taskDetailMap",
      zoom: 13,
      callback: function () {
        createTaskDetailMarkers(tempMap);
      },
      //  template: '<create-job-button-on-map/>',
    });
    setMyMap(tempMap);
    setTimeout(() => {
      tempMap.validateSize();
    }, 2000);
  };
  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    drawPolyline();
  }, [props.selectedTab, props.polyline]);
  const drawPolyline = (map?: any) => {
    let mymap = myMap || map;
    if (props.selectedTab && props.polyline.length > 0) {
      props.polyline.map((item: any, index: number) => {
        mapBounds = mapBounds.concat(myMap.decodedPoly(item.toString(), 1));
      });
      myMap.fitMap(mapBounds, null);
    } else {
      if (mymap) {
        mymap.removePoly();
      }
    }
  };

  useEffect(() => {
    if (counter !== 0) {
      setUpMarkerOnMap();
    }

    setCounter(++counter);
  }, [props.taskDetail]);
  const removeAllMarker = (cb: any) => {
    // if(!Object.keys())
    myMap.removePoly();
    for (var job in markerList["jobsMarker"]) {
      markerList["jobsMarker"][job].remove();
    }
    for (var resource in markerList["resourceMarker"]) {
      markerList["resourceMarker"][resource].remove();
    }
    for (var task in markerList["historyMarker"]) {
      markerList["historyMarker"][task].remove();
    }

    markerList.historyMarker = {};
    updateMarkerList({
      jobsMarker: {},
      resourceMarker: {},
      historyMarker: {},
    });
    cb();
  };
  const setUpMarkerOnMap = () => {
    removeAllMarker(() => {
      createTaskDetailMarkers();
    });
  };
  const createTaskDetailMarkers = async (tempMap?: any) => {
    await createHistorytaskMarker(tempMap);
    await createTaskMarker(tempMap);
    await createResourceMarker(tempMap);
    setTimeout(() => {
      if (tempMap) {
        tempMap.validateSize();
        tempMap.fitMap(mapBounds);
      } else {
        myMap.validateSize();
        myMap.fitMap(mapBounds);
      }
    }, 200);
  };
  const createHistorytaskMarker = async (tempMap?: any) => {
    if ((!myMap && !tempMap) || !taskDetailData) {
      return;
    }
    for (var taskKey in taskDetailData["task_history"]) {
      let task = taskDetailData["task_history"][taskKey];
      if (!task.lat || !task.lng) {
        continue;
      }

      let template = DashboardMapMarkers.getDefaultMarker("#fc8344");
      markerList["historyMarker"][
        `${task["job_id"]}${taskKey}`
      ] = new lMap.markers({
        map: tempMap?.map || myMap.map,
        lat: task.lat,
        lng: task.lng,
        // icon: `${
        //   window.location.origin +
        //   "/assets/images/jobs-fleet-marker/assigned_pickup.png"
        // }`,
        icon: `${
          "data:image/svg+xml;charset=UTF-8, " + encodeURIComponent(template)
        }`,
        params: [1, 2, 3],
      });
      updateMarkerList(markerList);

      const popupContent = await updateTaskPopUpContent(task, taskKey);
      const taskDetail = {
        task: task,
        taskKey: taskKey,
        popupContent: popupContent,
      };
      await bindPopupToJobMarker(markerList, taskDetail);

      markerList["historyMarker"][
        `${task["job_id"]}${taskKey}`
      ].showPopupOnHover();
      drawPolyline(tempMap || myMap);
      mapBounds.push([parseFloat(task.lat), parseFloat(task.lng)]);
    }
  };
  const bindPopupToJobMarker = function (markerList: any, taskDetail: any) {
    const myDiv = document.createElement("div");

    markerList["historyMarker"][
      `${taskDetail.task["job_id"]}${taskDetail.taskKey}`
    ].setResponsivePopup(taskDetail.popupContent);
  };
  const updateTaskPopUpContent = (task: any, taskKey: any) => {
    const myDiv = document.createElement("div");
    ReactDOM.render(
      <Suspense fallback={<h3>Loading&hellip;</h3>}>
        <TaskHistoryCard history={task}></TaskHistoryCard>
      </Suspense>,

      // <Grid container direction="row" style={{minWidth:"200px"}}>
      //   <Grid item xs={4}>
      //     <Typography className={classes['popupText']}>Status</Typography>
      //   </Grid>
      //   <Grid item xs={8}  style={{
      //         color: taskHistoryTypes[task.type]["color"],
      //       }}>
      //     <p className={classes['popupTextValue']} > {taskHistoryTypes[`${task.type}`].name}</p>

      //   </Grid>
      //   <Grid item xs={4}>
      //     <Typography className={classes['popupText']}>Time</Typography>
      //   </Grid>
      //   <Grid item xs={8}>
      //     <p className={classes['popupTextValue']} >{DateTimeFormatter.convertUTCTimeToLocalTimeZone(
      //       task.created_at,
      //       DateTimeFormatter.getDateTimeFormat(),
      //       loginData.timezone ? loginData.timezone : "Asia/Calcutta"
      //     )}</p>
      //   </Grid>
      //   <Grid item xs={4}>
      //     <Typography className={classes['popupText']}>By</Typography>

      //   </Grid>
      //   <Grid item xs={8}>
      //     <p className={classes['popupTextValue']}> {task.updated_by_name}</p>
      //   </Grid>
      //   <Grid item xs={4}>
      //     <Typography className={classes['popupText']}>Comment</Typography>

      //   </Grid>
      //   <Grid item xs={8}>
      //     <p className={classes['popupTextValue']} > {task.comment}</p>
      //   </Grid>
      // </Grid>
      myDiv
    );
    return myDiv;
    // markerList["historyMarker"][`${task["job_id"]}${taskKey}`].marker._popup.setContent(myDiv);
  };
  const createTaskMarker = (tempMap?: any) => {
    if (
      (!myMap && !tempMap) ||
      !taskDetailData ||
      !taskDetailData.lat ||
      !taskDetailData.lng
    ) {
      return;
    }

    createMarker(taskDetailData, tempMap);

    if (taskDetailData.connected_tasks_details) {
      createConnectedTaskMarkers(
        JSON.parse(taskDetailData.connected_tasks_details),
        tempMap
      );
    }
    // for(let i=0;i<taskDetailData.connected)
    updateMarkerList(markerList);
  };
  const createConnectedTaskMarkers = (connectedTasks: any, tempMap?: any) => {
    for (let i = 0; i < connectedTasks.length; i++) {
      createMarker(connectedTasks[i], tempMap);
    }
  };
  const createMarker = (task: any, tempMap?: any) => {
    const template = getTemplateForTaskMarker(task);
    let stringOfTagsName: any;
    if (appData?.loginData?.allow_tag_marker) {
      for (let index = 0; index < task?.tags?.length; index++) {
        stringOfTagsName =
          (stringOfTagsName ? stringOfTagsName : "") +
          task.tags[index].tag_name +
          (index + 1 < task?.tags?.length ? "," : "");
      }
    }
    markerList["jobsMarker"][task.task_id] = new lMap.markers({
      map: tempMap?.map || myMap.map,
      lat: task.lat,
      lng: task.lng,
      icon: `${
        "data:image/svg+xml;charset=UTF-8, " + encodeURIComponent(template)
      }`,
      params: [1, 2, 3],
      value: stringOfTagsName ? stringOfTagsName : "",
    });
    mapBounds.push([parseFloat(task.lat), parseFloat(task.lng)]);
  };
  const getTemplateForTaskMarker = (task: any) => {
    let template = "";
    let color = jobStatusData[task.task_status].color;
    if (task.task_type === 0) {
      template = DashboardMapMarkers.getPickupIcon(color);
    } else if (task.task_type === 1) {
      template = DashboardMapMarkers.getDeliveryIcon(color);
    } else if (task.task_type === 2) {
      template = DashboardMapMarkers.getServiceIcon(color);
    }
    return template;
  };
  const createResourceMarker = (tempMap?: any) => {
    if (
      (!myMap && !tempMap) ||
      !taskDetailData ||
      !taskDetailData.res_lat ||
      !taskDetailData.res_lng
    ) {
      return;
    }
    markerList["resourceMarker"][
      taskDetailData.resource_id
    ] = new lMap.animatedMarker({
      map: tempMap?.map || myMap.map,
      lat: taskDetailData.res_lat,
      lng: taskDetailData.res_lng,
      icon: `${
        window.location.origin +
        "/assets/images/jobs-fleet-marker/driver_idle.png"
      }`,
      params: [1, 2, 3],
    });
    updateMarkerList(markerList);
    mapBounds.push([
      parseFloat(taskDetailData.res_lat),
      parseFloat(taskDetailData.res_lng),
    ]);
  };

  return (
    <Fragment>
      <div id="taskDetailMap" className={classes["taskDetailMap"]}></div>
    </Fragment>
  );
};
export default TaskDetailMap;
