import React, { useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import AutocompleteMaterial from "@material-ui/lab/Autocomplete";

import TextField from "@material-ui/core/TextField";
// import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    padding: "16px 14px !important",
  },
  root: {
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
  },
}));
const MaterialAutocomplete: React.FC<any> = (props) => {
  // const { t, i18n } = useTranslation(["Chat"]);
  const [reRenderDropdownCount, updateReRenderDropdownCount] = useState(
    Math.random()
  );
  const classes = useStyles();

  useEffect(() => {
    // eslint-disable-next-line
    console.log("props.value ----> ", props.value);
  }, [props]);

  useEffect(() => {}, [props.errors]);

  useEffect(() => {
    updateReRenderDropdownCount(Math.random());
  }, [props.rerender]);

  return (
    <React.Fragment>
      <AutocompleteMaterial
        // Removed cross label
        clearText={""}
        multiple={props.isMultiple ? props.isMultiple : false}
        freeSolo={props.freeSolo || false}
        filterOptions={props.filterOptions}
        PaperComponent={props.PaperComponent}
        // disablePortal={props.disablePortal===false?false:false}
        disableClearable={props.disableClearable || false}
        fullWidth={props.textFieldFullWidth || false}
        // forcePopupIcon={true}
        id={props.id}
        defaultValue={props.defaultValue}
        style={props.style}
        disableCloseOnSelect={props.disableCloseOnSelect}
        ListboxProps={props.ListboxProps}
        options={props.options || []}
        getOptionLabel={(option: any) => {
          return option[props.labelName] || option;
        }}
        renderTags={props.renderTags}
        getOptionSelected={props.getOptionSelected}
        renderOption={props.renderOption}
        disabled={props.disabled || false}
        getOptionDisabled={
          props.getOptionDisabled ? props.getOptionDisabled : null
        }
        classes={{
          inputRoot: props.rootStyle || "",
          input: classes.input,
        }}
        value={props.value}
        noOptionsText={props.noOptionsText || "No options"}
        onChange={props.onChange}
        filterSelectedOptions={props.filterSelectedOptions}
        renderInput={(params: any) => {
          if (props.onKeyDown) params.inputProps.onKeyDown = props.onKeyDown;
          return (
            <TextField
              className={`${classes.root} ${props.textFieldClassName}`}
              name={props.name || props.textFieldLabel}
              // inputProps={props.inputProps}
              style={props.textfieldStyle}
              inputRef={props.inputRef}
              inputProps={{ "aria-label": "naked" }}
              {...params}
              error={
                props.errors && !props.value
                  ? props.errors[props.name]
                    ? true
                    : false
                  : false
              }
              InputLabelProps={{
                style: {
                  transform: "translate(14px, 17px) scale(1)",
                },
                // classes: classes.groupLabel,
              }}
              variant={props.variant || "outlined"}
              label={props.textFieldLabel}
              placeholder={props.textFieldPlaceholder}
              margin={props.textFieldMargin}
              fullWidth={props.textFieldFullWidth || false}
            />
          );
        }}
      />
      {props.errors && !props.value ? (
        <div className="input__errormsg">
          <ErrorMessage
            errors={props.errors}
            name={props.name}
            render={({ message }) => <div>{message}</div>}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default React.memo(MaterialAutocomplete);
