const ScheduledPaymentEndPoints = {
  getExistingRules(reqData: any) {
    return {
      url: "main/customer/payment/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getTags(reqData: any) {
    const url = "main/tag/get";
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  createCustomerTag(reqData: any) {
    const url = "main/customer/payment/update";
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deleteRule(reqData: any) {
    const url = "main/customer/payment/remove";
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  payNow(reqData: any) {
    const url = "main/customer/payment/pending";
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};
export default ScheduledPaymentEndPoints;
