import React, { Fragment, useRef, useState } from "react";
import TaskHistoryCard from "./TaskHistoryCard";
import { makeStyles } from "@material-ui/core/styles";
import "./TaskHistory.scss";
import { taskHistoryTypes } from "../../../constants/taskHistoryTypes";
import { Assignment } from "@material-ui/icons";
import AssignmentLogs from "./AssignmentLogs";
import { Typography } from "@material-ui/core";
import useApiService from "../../../services/api.service";
import { useAppContext } from "../../../AppContext/App.context";

import { TaskDetail } from "../TaskDetail.endpoints";
import useToast from "../../Toast/hooks/useToast";
import useLoader from "../../../hooks/useLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    margin: "20px 7px",
    maxHeight: `calc(100vh - 200px)`,
    overflowY: "scroll",
  },
}));

const TaskHistory = (props: any) => {
  var tempHistoryData: any = props.taskDetail["task_history"]
    ? [...props.taskDetail["task_history"]]
    : null;

  if (tempHistoryData && tempHistoryData.length > 0 && props.taskDetail["assignmentStatus"]) {

    tempHistoryData.splice(1, 0, {
      type: 10,
      comment: "Assignment Started"
    })



  }
  const historyData: any = tempHistoryData ? [...tempHistoryData.reverse()] : null;
  const classes = useStyles();
  const [appData]: any = useAppContext();
  const access_token = appData.loginData.access_token;
  const [assignmentData, setAssignmentData] = useState()
  const assignmentLogsRef: any = useRef()
  const ApiService = useApiService();
  const toaster: any = useToast();
  const loader = useLoader();
  const [showDistanceColumn, setShowDistanceColumn]: any = useState()
  const getAssignmentLogsStatus = async (value: any) => {
    loader.showLoader();
    const { task_id, job_id } = props.taskDetail;
    const headers = {
      access_token: access_token,
    };

    const qureyParams = {
      task_id: task_id,
      job_id: job_id,

    };
    const reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    await ApiService.get(
      TaskDetail.getAssignmentLogs(reqData)
    ).then((res: any) => {
      let tempData = res.data
      let assignmentData: any = []
      let showDistance = false
      for (let i = tempData.length - 1; i >= 0; i--) {
        if (tempData[i].resource_details) {
          for (let x = 0; x < tempData[i].resource_details.length; x++) {
            if (tempData[i].resource_details[x]) {
              let temp = {};
              temp = { ...tempData[i].resource_details[x], time: tempData[i].created_at, }
              assignmentData.push(temp)
              if (tempData[i].resource_details[x].distance) {
                showDistance = true
              }
            }
          }
        }
        if (tempData[i].type == 11) {
          let temp = {};
          temp = { resource_id: tempData[i].comments, type: 11, name: "", }
          assignmentData.push(temp)

        }
        if (tempData[i].type == 12) {
          let temp = {};
          temp = { resource_id: "Retry", type: 12 }
          assignmentData.push(temp)
        }

      }
      loader.hideLoader()
      setAssignmentData(assignmentData)
      setShowDistanceColumn(showDistance)
      assignmentLogsRef.current.handleOpen()


    }).catch((err: any) => {
      toaster.addToast({
        message: err.message,
        timeout: 3000,
        type: "error",
      });
      loader.hideLoader()
      return;
    })

  };

  return (
    <Fragment>

      <div hidden={props.index !== props.value} className={classes.root}>
        {historyData && historyData.length ? (
          historyData.map((history: any) => (
            <div className="history-item" style={{}} key={historyData.type}>
              <div
                className="history-item__status-circle-dot"
                style={{
                  backgroundColor: taskHistoryTypes[history.type]["color"],
                  zIndex: 2,
                }}
              ></div>
              <TaskHistoryCard
                history={history}
                showCardView={true}
                showLocationOnMap={true}
                showAssignmentLogs={getAssignmentLogsStatus}
              />
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <AssignmentLogs ref={assignmentLogsRef} assignmentResourceData={assignmentData} showDistance={showDistanceColumn} />
    </Fragment>
  );
};
export default TaskHistory;
