export const RecurringTypesObj = (t: any) => {
  return {
    0: t("Createjob:Daily"),
    7: t("Createjob:Alternate Days"),
    1: t("Createjob:Weekly"),
    4: t("Createjob:Fortnightly"),
    2: t("Createjob:Monthly"),
    // 5: "Quarterly ",
    // 6: "Half Yearly",
    3: t("Createjob:Yearly"),
  };
};
export const RecurringTypesArray = (t: any) => {
  return [
    { name: t("Createjob:Daily"), value: 0 },
    { name: t("Createjob:Alternate Days"), value: 7 },

    { name: t("Createjob:Weekly"), value: 1 },
    { name: t("Createjob:Fortnightly"), value: 4 },
    { name: t("Createjob:Monthly"), value: 2 },
    { name: t("Createjob:Yearly"), value: 3 },
  ];
};
export const repeatDays = [
  { id: 0, name: "S", fname: "Sunday", selected: true, date: "" },
  { id: 1, name: "M", fname: "Monday", selected: true, date: "" },
  { id: 2, name: "T", fname: "Tuesday", selected: true, date: "" },
  { id: 3, name: "W", fname: "Wednesday", selected: true, date: "" },
  { id: 4, name: "T", fname: "Thursday", selected: true, date: "" },
  { id: 5, name: "F", fname: "Friday", selected: true, date: "" },
  { id: 6, name: "S", fname: "Saturday", selected: true, date: "" },
];
