import React, { Suspense, useRef } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { taskHistoryTypes } from "../../../constants/taskHistoryTypes";
import TaskPopup from "./TaskPopup";
import moment from "moment";
import { jobStatusData } from "../../../constants/jobStatusData";
import DateTimeFormatter from "../../../utils/DateFormatter";
import useLocalStorage from "../../../utils/localStorage";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    width: 300,
    //margin: "5px",
    borderRadius: "10px",
    boxShadow: "0 10px 20px 0 rgba(37, 116, 255, 0.3)",
    // padding: "10px",
    margin: "5px",
    "&.MuiCardContent-root": {
      padding: "0px",
    },
  },
  size: {
    fontSize: "14px",
    padding: "2px",
    lineHeight: "20px",
  },
  headerTitle: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  cardContent: {
    padding: "10px",
    "&:last-child": {
      paddingBottom: "10px",
    },
  },
  locationLink: {
    color: "var(--primary-theme)",
  },
  location: {
    display: "flex",
    justifyContent: "space-between",
  },
  image: {
    maxHeight: "70px",
    maxWidth: "70px",
    cursor: "pointer",
  },
}));

const TaskHistoryCard = (props: any) => {
  const { t, i18n } = useTranslation(["TaskDetail", "Common"]);
  const classes = useStyles();
  const taskLocationMapRef: any = useRef();
  const loginData: any = useLocalStorage.getItem("loginData");
  const CardInternalContent = () => {
    return (
      <>
        <div className={classes.location}>
          {props.showLocationOnMap && props.history.lat && props.history.lng ? (
            <div className={classes.size}>
              <div
                onClick={() => {
                  taskLocationMapRef.current.handleOpen(props.history, 0);
                }}
                style={{ cursor: "pointer" }}
                className={classes.locationLink}
              >
                {t("TaskDetail:Location on map")}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className={classes.size}>
          <span style={{ fontWeight: "bold" }}>{t("Common:Status")}</span> -{" "}
          <span
            style={{
              color: taskHistoryTypes[props.history.type]
                ? taskHistoryTypes[props.history.type]["color"]
                : "#B5A642",
            }}
          >
            {" "}
            {t("TaskDetail:" + taskHistoryTypes[props.history.type]?.name)}
          </span>
        </div>
        {props.history.type != 10 ? (
          <div className={classes.size}>
            <span style={{ fontWeight: "bold" }}> {t("TaskDetail:Time")}</span>{" "}
            -{" "}
            <span style={{ wordSpacing: "5.3px" }}>
              {DateTimeFormatter.convertUTCTimeToLocalTimeZone(
                props.history.created_at,
                DateTimeFormatter.getDateTimeFormat(),
                loginData.timezone ? loginData.timezone : "Asia/Calcutta"
              )}
            </span>
          </div>
        ) : (
          ""
        )}
        <div className={classes.size} hidden={props.history.type === 10}>
          <span style={{ fontWeight: "bold" }}> {t("TaskDetail:By")} </span> -{" "}
          {props.history.updated_by
            ? props.history.updated_by_name
            : t("TaskDetail:System")}
        </div>
        {/* <TaskHistoryComment props={props} isBoldKeyName={true} showLocationOnMap={true}></TaskHistoryComment> */}
        {props.history.type === 20 ? (
          <div className={classes.size}>
            <span style={{ fontWeight: "bold" }}>{t("TaskDetail:Note ")}</span>{" "}
            - {`${props.history.text}`}{" "}
          </div>
        ) : null}
        {props.history.type === 18 || props.history.type === 19 ? (
          <div className={classes.size}>
            <span style={{ fontWeight: "bold" }}>{`${t(
              "TaskDetail:Failure reason"
            )}`}</span>{" "}
            - {`${props.history.failure_reason || ""}`}{" "}
          </div>
        ) : null}
        {props.history.image ? (
          <div>
            <img
              className={classes.image}
              src={props.history.image}
              alt="user icon"
              onClick={() =>
                taskLocationMapRef.current.handleOpen(
                  props.history.image,
                  props.history.type
                )
              }
            ></img>
          </div>
        ) : (
          <div className={classes.size}>
            <span style={{ fontWeight: "bold" }}>
              {t("TaskDetail:Comment")}
            </span>{" "}
            -{" "}
            {props.history.type === 9 ? (
              props.history.resource_name ? (
                t("TaskDetail:Job status changed to") +
                " " +
                t(
                  "Common:" + jobStatusData[props.history.task_status || 0].name
                ) +
                ".(" +
                props.history.resource_name +
                ")"
              ) : (
                t("TaskDetail:Job status changed to") +
                " " +
                t(
                  "Common:" + jobStatusData[props.history.task_status || 0].name
                )
              )
            ) : props.history.type === 10 ? (
              <span
                onClick={() => {
                  props.showAssignmentLogs(1);
                }}
                style={{
                  color: "#0000FF",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                View Assignment Logs
              </span>
            ) : (
              t("TaskDetail:" + taskHistoryTypes[props.history.type].comment)
            )}{" "}
            {/* {
              props.history.type === 12 &&
              <p >Assignment Logs</p>
            } */}
          </div>
        )}
      </>
    );
  };
  return (
    <>
      {" "}
      {props.showCardView ? (
        <Card className={classes.root}>
          <CardContent className={classes.cardContent}>
            <CardInternalContent />
            <Suspense fallback={<h3>Loading&hellip;</h3>}>
              <TaskPopup ref={taskLocationMapRef}></TaskPopup>
            </Suspense>
          </CardContent>
        </Card>
      ) : (
        <CardInternalContent />
      )}
    </>
  );
};

export default TaskHistoryCard;
