import React, { useEffect, useState } from "react";
import moment from "moment";
import useLocalStorage from "../../hooks/localStorage";
import DateTimeFormatter from "../../utils/DateFormatter";
import { useTranslation } from "react-i18next";

const ShowTaskDetailsInHtmlView = (rowObj: any) => {
  const { t, i18n } = useTranslation(["Sidebar", "Wallet", "Common"]);

  const row = rowObj.row;
  let taskDetail = rowObj.tasks;
  // row["definition"]
  //   ? (taskDetail = row["definition"]["task_body"]["task_details"])
  //   : (taskDetail = row.tasks);
  const [showMore, setShowMore]: any[] = useState(
    Array(taskDetail.length).fill(false)
  );
  const timeFormat = (task: any) => {
    // let format = DateTimeFormatter.convertDateTimeToSpecificFormat(task);

    if (task && !task.includes("T")) {
      const dateTime: String[] = task.split(" ");
      return (
        <>
          {dateTime[1]}
          {dateTime[2] ? " " + dateTime[2] + " " : " "}
          {dateTime[0]}
        </>
      );
    } else {
      let time = moment(task).utc().format("HH:mm");
      let date = moment(task).utc().format("YYYY-MM-DD");
      return (
        <>
          {time} {date}
        </>
      );
    }
  };
  useEffect(() => {
    let tempCollapse = useLocalStorage.getItem("expandCollapseFilter");
    if (tempCollapse.delivery !== undefined && rowObj.taskType === 1) {
      setShowMore(Array(taskDetail.length).fill(tempCollapse.delivery));
    } else if (tempCollapse.pickup !== undefined && rowObj.taskType === 0) {
      setShowMore(Array(taskDetail.length).fill(tempCollapse.pickup));
    } else if (tempCollapse.service !== undefined && rowObj.taskType === 2) {
      setShowMore(Array(taskDetail.length).fill(tempCollapse.service));
    } else {
      useLocalStorage.removeItem("expandCollapseFilter");
    }
  }, []);

  return (
    <div>
      {taskDetail.map((task: any, index: any) =>
        task.task_type === rowObj.taskType ? (
          <div>
            <div>
              {rowObj.doNotConverFormat
                ? task["local_datetime"]
                : timeFormat(task["local_datetime"])}
              {task.task_type === 2 ? (
                <div>
                  {rowObj.doNotConverFormat
                    ? task["end_datetime"]
                    : timeFormat(task["end_datetime"])}
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <div>{task["name"]}</div> */}
            {/* <div>{task["country_code"] + task["phone"]}</div> */}
            {showMore[index] ? (
              <>
                <div>{task["name"]}</div>
                <div>{task["location"]}</div>
              </>
            ) : (
              ""
            )}
            <span
              style={{
                cursor: "pointer",
                color: "var(--primary-theme)",
              }}
              onClick={() => {
                let address = [...showMore];
                address[index] = !showMore[index];
                setShowMore(address);
              }}
            >
              {showMore[index]
                ? t("Common:Less Details")
                : t("Common:More Details")}
            </span>
            <br></br>
          </div>
        ) : null
      )}
    </div>
  );
};
export default ShowTaskDetailsInHtmlView;
