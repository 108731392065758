import React, {
  useEffect,
  useState,
  Fragment,
  forwardRef,
  useImperativeHandle,
} from "react";
import "../CreateJob/CreateJob.scss";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dropdown from "../Dropdown/Dropdown";
import MaterialAutocomplete from "../MaterialAutocomplete/MaterialAutocomplete";
import { useForm } from "react-hook-form";
import Btn from "../Button/Button";
import CommonEndpoints from "../../services/commonApi.service";
import { useAppContext } from "../../AppContext/App.context";
import useToast from "../Toast/hooks/useToast";
import useLoader from "../../hooks/useLoader";
import useApiService from "../../services/api.service";
import ResourceListDropdown from "../ResourceListDropdown/ResourceListDropdown";
import { JobStatusDataList } from "../../constants/jobStatusData";
import headerDropdownService from "../../services/headerDropdown.service";
import useLocaStorage from "../../hooks/localStorage";
import { JobStatusValueFromName } from "../../enum/JobStatus.enum";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import JobListEndpoints from "../../containers/Jobs/JobsList/JobList.endpoints";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "10px",
    border: "solid 1px #dcdcdc",
  },
  tagInputField: {
    marginTop: "0px",
  },
  space: {
    marginRight: "10px",
  },
  taskdropdown: {
    marginTop: "0px",
    marginBottom: "10px",
  },
}));

const ReassignJob = forwardRef((props: any, ref: any) => {
  const { t, i18n } = useTranslation(["Createjob", "Common", "Reassign"]);
  const jobStatusDataList = JobStatusDataList(t);
  const toaster = useToast();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedTeamId, setTeamId]: any = useState({});
  const [teamList, setTeamList]: any = useState([]);
  const [selectedTags, updateSelectedTags]: any = useState([]);
  let [preSavedTagList, updatePreSavedTagList]: any = useState([]);
  const [selectedGeofence, setGeofence] = useState(0);
  const localStorageObject = useLocaStorage;
  const Permission: any = localStorageObject.getItem("Permission");
  const [preSavedTeamForEdit, setPreSavedTeamForEdit] = useState(0);
  const [selectedTaskStatus, setTaskStatus]: any = useState({
    taskStatus: 0,
    isUpdated: false,
  });
  const [selectedResourceId, updateSelectedResource]: any = useState(0);
  const [dataForResourceFiltering, setDataForResourceFiltering]: any = useState(
    {}
  );
  const ApiService = useApiService();
  const loader: any = useLoader();
  const [appData]: any = useAppContext();
  const [reRenderDropdownCount, updateReRenderDropdownCount] = useState(
    Math.random()
  );
  const access_token = appData.loginData.access_token;
  const [jobLocation, setJobLocation]: any = useState({ lat: "", lng: "" });
  const [currentJobId, setCurrentJobID]: any = useState(0);
  const [selectedJobsData, setSelectedJobsData]: any = useState({});
  const geoFenceOptionList = [
    { label: t("Createjob:Yes"), value: 1 },
    { label: t("Createjob:No"), value: 0 },
  ];

  const handleClose = () => {
    resetData();
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleOpen: (jobId?: any) => {
      if (typeof jobId === "object" && jobId !== null) {
        setSelectedJobsData(jobId);
        setTeamId({});
        setDataForResourceFiltering({});
        setTaskStatus({
          taskStatus: 0,
          isUpdated: true,
        });
      } else {
        setCurrentJobID(jobId);
        setSelectedJobsData({ selectedCount: 0, selectedJobID: [], data: [] });
        getJobDetailsByJobId(jobId);
      }
      setOpen(true);
    },
  }));
  const resetData = () => {
    setTaskStatus({
      taskStatus: 0,
      isUpdated: false,
    });

    updateSelectedResource(null);
    updateSelectedTags([]);
  };
  const getJobDetailsByJobId = async (jobId: any) => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams = {
      job_id: parseInt(jobId),
      fetch_task_details: "true",
      fetch_resource_details: "true",
      fetch_tags_details: "true",
      fetch_team_details: "true",
      limit: 1,
      skip: 0,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };

    const res: any = await ApiService.get(
      CommonEndpoints.getJobDetailsByJobId(reqData)
    );

    setJobLocation({
      lat: res.data.data[0]["tasks"][0]["lat"],
      lng: res.data.data[0]["tasks"][0]["lng"],
    });

    setTaskStatus({
      taskStatus: res.data.data[0]["job_status"],
      isUpdated: false,
    });
    // updateSelectedResource(res.data.data[0]["resource_id"]);
    // updateSelectedTags(res.data.data[0]["tags"]);
    setPrefillDataForAssignResourcePart(res.data.data[0]);
  };
  const setPrefillDataForAssignResourcePart = async (jobData: any) => {
    updatePropsForFilteringTeam(jobData.use_geofence_check ? 1 : 0, jobData);

    let index = teamList.findIndex(
      (index: any) => index.team_id === jobData.team_id
    );
    if (index > -1) {
      setTeamId(teamList[index]);
    } else {
      setTeamId({});
    }

    setPreSavedTeamForEdit(jobData.team_id);
    setGeofence(jobData.use_geofence_check ? 1 : 0);
    // updatePropsForFilteringResource(jobData.use_geofence_check ? 1 : 0);
    updateSelectedTags(jobData.tags || []);
    updateSelectedResource(jobData.resource_id || null);
    updatePropsForFilteringResource({
      type: "all",
      resourceId: jobData.resource_id,
      tags: jobData.tags || [],
      teamId: jobData.team_id,
      geofence: jobData.use_geofence_check ? 1 : 0,
    });
  };
  const updatePropsForFilteringResource = (data: any) => {
    let tempObj = { ...dataForResourceFiltering };
    tempObj.teamId = selectedTeamId ? selectedTeamId.team_id : 0;
    tempObj.geofence = selectedGeofence;
    tempObj.selectedTags = selectedTags;
    tempObj.resourceId = null;
    //updateSelectedResource(null);
    switch (data.type) {
      case "team":
        tempObj.teamId = data.value;
        break;
      case "geofence":
        tempObj.geofence = data.value;
        break;
      case "tags":
        tempObj.selectedTags = data.value;
        break;
      case "resource":
        tempObj.resourceId = data.value;
        break;
      case "all": {
        tempObj.teamId = data.teamId;
        tempObj.geofence = data.geofence;
        tempObj.selectedTags = data.tags;
        tempObj.resourceId = data.resourceId;
      }
    }

    if (tempObj.geofence) {
      // setLocationForGetResourceList
      tempObj.locationForGetResourceList = {
        lat: jobLocation["lat"],
        lng: jobLocation["lng"],
      };
    }
    setDataForResourceFiltering(tempObj);
  };
  const { handleSubmit } = useForm();

  const setTeamListDropdown = (data: any) => {
    let arr: any = data;

    setTeamList(arr);
  };
  const getTeamList = async (data?: any) => {
    // return;
    let headers = {
      access_token: access_token,
    };
    let qureyParams: any = {
      limit: 1000,
      skip: 0,
    };
    if (data && Object.keys(data).length) {
      qureyParams["check_geofence"] = data.geofence;
      qureyParams["point"] =
        data.locationForGetTeamList.lat + " " + data.locationForGetTeamList.lng;
    }
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(CommonEndpoints.getTeamList(reqData));
    setTeamListDropdown(res.data.data);
  };
  const checkTeamIdExistInList = () => {
    let isTeamFound = false;
    for (let i = 0; i < teamList.length; i++) {
      if (selectedTeamId.team_id === teamList[i]["team_id"]) {
        isTeamFound = true;
      }
    }
    return isTeamFound;
  };
  const onSubmit = (data: any) => {
    let reqBody: any = {
      // team_id: selectedTeamId,
      use_geofence_check: selectedGeofence,
      resource_id: selectedResourceId,

      action_type: 1,
    };
    if (
      selectedJobsData.selectedJobID &&
      selectedJobsData.selectedJobID.length > 0
    ) {
      reqBody.job_id = selectedJobsData.selectedJobID;
    } else {
      reqBody.job_id = currentJobId;
    }
    if (selectedTaskStatus.isUpdated) {
      reqBody["job_status"] = selectedTaskStatus.taskStatus;
    }
    if (!selectedTaskStatus.taskStatus && selectedResourceId) {
      toaster.addToast({
        message: t("Reassign:Task status cant be unassigned"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (Permission && !Permission["Read_Teams"] && preSavedTeamForEdit) {
      reqBody["team_id"] = preSavedTeamForEdit;
    }
    if (typeof selectedTeamId?.team_id === "number" && selectedTeamId) {
      var isSelectedTeamExist = checkTeamIdExistInList();
      if (!isSelectedTeamExist) {
        toaster.addToast({
          message: t("Common:Please select a team"),
          timeout: 3000,
          type: "error",
        });
        return;
      }
      reqBody["team_id"] = selectedTeamId.team_id;
    }
    if (
      !selectedTeamId ||
      (selectedTeamId && Object.keys(selectedTeamId).length === 0)
    ) {
      reqBody["team_id"] = 0;
    }
    if (selectedGeofence && !selectedTeamId) {
      toaster.addToast({
        message: t("Common:Please select a team"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    const tempTags = selectedTags.map((tag: any) => {
      if (typeof tag === "string") {
        return tag;
      }
      return tag.tag_name;
    });
    reqBody["tags"] = tempTags;

    // if (selectedGeofence !== 0) {
    //   reqBody["lat"] = jobLocation.lat;
    //   reqBody["lng"] = jobLocation.lng;
    // }
    selectedResourceId
      ? (reqBody["resource_id"] = selectedResourceId)
      : (reqBody["resource_id"] = 0);

    if (
      !reqBody["resource_id"] &&
      [
        JobStatusValueFromName.ASSIGNED,
        JobStatusValueFromName.ACCEPTED,
        JobStatusValueFromName.INPROGRESS,
        JobStatusValueFromName.ARRIVED,
        JobStatusValueFromName.COMPLETED,
        JobStatusValueFromName.FAILED,
      ].indexOf(selectedTaskStatus.taskStatus) > -1
    ) {
      toaster.addToast({
        message:
          "Agent is required for " +
          jobStatusDataList[selectedTaskStatus.taskStatus].name.toLowerCase() +
          " status",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (reqBody["resource_id"] && !reqBody["team_id"]) {
      toaster.addToast({
        message: t("Common:Please select a team"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: reqBody,
      headers: headers,
    };
    loader.showLoader();

    return ApiService.put(
      selectedJobsData.selectedJobID?.length === 0
        ? CommonEndpoints.updateJobDetails(reqData)
        : JobListEndpoints.bulkReassignJobs(reqData)
    )
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        handleClose();
        const eventData = {
          type: "refreshTaskList",
          data: true,
        };
        headerDropdownService.emit(eventData);
      })
      .catch((err) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
        // setTimeout(() => {
        //   props.handleAPIResonse({
        //     selectedCount : selectedJobsData.length,
        //     selectedRows:[]
        //   })
        // },200)
      });
  };

  useEffect(() => {
    //  setShown(true)
    if (Permission && Permission["Read_Teams"]) {
      getTeamList();
    }
    getTagList();
  }, []);
  const getTagList = async (team?: any) => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams: any = {
      limit: 1000,
      skip: 0,
      validate_resource_tags: true,
    };
    if (team) {
      qureyParams.team_id = team;
    }
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(CommonEndpoints.getTagsList(reqData));
    if (res.data.data && res.data.data.length > 0) {
      // preSavedTagList= res.data.data;
      updatePreSavedTagList(res.data.data);
    } else {
      updatePreSavedTagList([]);
    }
    // setTeamListDropdown(res.data.data)
  };

  const onTeamChange = (value: any) => {
    setTeamId(value);
    updateSelectedResource(null);
    updatePropsForFilteringResource({
      type: "team",
      value: value ? value.team_id : 0,
    });

    getTagList(value ? value.team_id : 0);
  };
  const onGeofenceChange = (event: any) => {
    setGeofence(event.target.value);
    updatePropsForFilteringResource({
      type: "geofence",
      value: event.target.value,
    });
    if (Permission && Permission["Read_Teams"]) {
      updatePropsForFilteringTeam(event.target.value);
    }
  };
  const updatePropsForFilteringTeam = (geofenceValue: any, jobData?: any) => {
    let tempObj: any = {};
    setTeamId({});
    if (geofenceValue) {
      // let lat: any= jobData ? jobData["lat"] : jobLocation["lat"]
      // let lng: any= jobData ? jobData["lng"] : jobLocation["lng"]
      // tempObj.geofence = geofenceValue;
      // tempObj.locationforGetTeamList = {
      //   lat: lat,
      //   lng: lng,
      // };
      tempObj.geofence = geofenceValue;
      if (jobData) {
        tempObj.locationForGetTeamList = {
          lat: jobData["lat"],
          lng: jobData["lng"],
        };
      } else {
        tempObj.locationForGetTeamList = {
          lat: jobLocation["lat"],
          lng: jobLocation["lng"],
        };
      }
    }
    getTeamList(tempObj);
  };
  const onTaskStatusChange = (event: any) => {
    if (selectedTaskStatus.taskStatus !== parseInt(event.target.value)) {
      setTaskStatus({
        taskStatus: parseInt(event.target.value),
        isUpdated: true,
      });
    }
  };
  const handleKeyDownInTagsInput = (event: any) => {
    switch (event.key) {
      case ",":
      case " ": {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          updateSelectedTags([...selectedTags, event.target.value]);
        }
        break;
      }
      default:
    }
  };

  const AssignAgentViewInHtml = () => {
    return (
      <div
        className="CreateJob__form__task-accordion"
        style={{ marginTop: "10px" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div hidden={selectedJobsData.selectedJob > 0}>
              <Dropdown
                required={true}
                labelName={t("Common:Geofence")}
                onChange={onGeofenceChange}
                selectedValue={selectedGeofence}
                itemList={geoFenceOptionList}
                uniqueId="value"
                label="label"
              ></Dropdown>
            </div>
            <div>
              <MaterialAutocomplete
                isMultiple={false}
                freeSolo={false}
                disablePortal={false}
                size="medium"
                id={"team-outlined"}
                options={teamList}
                labelName="team_name"
                onChange={(e: any, newValue: any) => {
                  onTeamChange(newValue);
                }}
                rerender={reRenderDropdownCount}
                defaultValue={{ team_id: selectedTeamId }}
                getOptionLabel={(option: any) => option["option"]}
                value={selectedTeamId}
                filterSelectedOptions
                onKeyDown={() => {}}
                textFieldClassName={classes.tagInputField}
                textFieldLabel={t("ImportJobs:Select Team")}
                textFieldPlaceholder={t("ImportJobs:Select")}
                textFieldMargin={"normal"}
                textFieldFullWidth={true}
              />
            </div>
            <div>
              <MaterialAutocomplete
                isMultiple={true}
                freeSolo={false}
                disablePortal={true}
                size="medium"
                id={"tags-outlined"}
                options={preSavedTagList}
                labelName="tag_name"
                value={selectedTags}
                noOptionsText={"Add Tag from Tags Manager"}
                onChange={(event: any, newValue: any) => {
                  updateSelectedTags(newValue);
                  updateSelectedResource(null);
                  updatePropsForFilteringResource({
                    type: "tags",
                    value: newValue,
                  });
                }}
                filterSelectedOptions
                onKeyDown={handleKeyDownInTagsInput}
                textFieldClassName={classes.tagInputField}
                textFieldLabel={t("Common:Tags")}
                textFieldPlaceholder={t("Common:Tags")}
                textFieldMargin={"normal"}
                textFieldFullWidth={true}
              />
            </div>
            <div className="resource-dropdown">
              <ResourceListDropdown
                onChange={(event: any, newValue: any) => {
                  updateSelectedResource(newValue?.resource_id || null);
                  if (event) {
                    newValue
                      ? setTaskStatus({ taskStatus: 1, isUpdated: true })
                      : setTaskStatus({ taskStatus: 0, isUpdated: true });
                  }
                }}
                dataForResourceFiltering={dataForResourceFiltering}
                name={"resource"}
                textFieldClassName={classes.tagInputField}
                labelName={t("Common:Agent")}
                fullWidth={true}
                teamId={selectedTeamId}
                selectedTags={selectedTags}
                geofence={selectedGeofence}
                showIcon={false}
                variant={"outlined"}
                location="create_task"
              ></ResourceListDropdown>
            </div>
            <div>
              <Dropdown
                required={true}
                labelName="Task Status"
                onChange={onTaskStatusChange}
                selectedValue={selectedTaskStatus.taskStatus}
                itemList={jobStatusDataList}
                uniqueId="status"
                label="name"
                className={classes.taskdropdown}
              ></Dropdown>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <form onSubmit={handleSubmit(onSubmit)} className="CreateJob">
            <div className={classes.paper}>
              <div className="CreateJob__form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2
                    id="transition-modal-title"
                    className="CreateJob__form__padding"
                  >
                    {selectedJobsData.selectedJobID?.length > 0
                      ? t("Reassign:Update")
                      : t("Reassign:Reassign Agent")}
                  </h2>
                  {selectedJobsData.selectedJobID?.length > 0 ? (
                    <>
                      <Typography style={{ marginRight: "2rem" }}>
                        No. of Jobs:{selectedJobsData.selectedJobID?.length}
                      </Typography>
                    </>
                  ) : (
                    <h3
                      id="transition-modal-title"
                      style={{ fontWeight: "normal" }}
                      className="CreateJob__form__jobid"
                    >
                      {t("Reassign:Job ID")}: {currentJobId}
                    </h3>
                  )}
                </div>
                <div className="CreateJob__form__padding CreateJob__form__reassign">
                  {(() => {
                    return AssignAgentViewInHtml();
                  })()}
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    margin: "20px",
                  }}
                >
                  <Btn
                    type={"Submit"}
                    btnText={t("Common:Submit")}
                    className={classes.space}
                  >
                    {" "}
                  </Btn>
                  <Btn
                    type={"button"}
                    btnText={t("Common:Cancel")}
                    onClick={handleClose}
                    color={"default"}
                  >
                    {" "}
                  </Btn>
                </div>
              </div>
            </div>
          </form>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default ReassignJob;
