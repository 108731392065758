import React from "react";
import useApiService from "../../../services/api.service";
import useLoader from "../../../hooks/useLoader";
import useToast from "../../../components/Toast/hooks/useToast";
import { useAppContext } from "../../../AppContext/App.context";

import { themeColor } from "../../../constants/themeColor";
import ReplayIcon from "@material-ui/icons/Replay";
import { ReactComponent as InProgressAutoAssignmentIcon } from "../../../assets/images/resources/in-progress-auto-assignment.svg";
import JobListEndpoints from "./JobList.endpoints";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  tooltip: {
    "&::before": {
      borderBottomColor: "rgba(97, 97, 97, 0.92) !important",
    },
    fontSize: "10px",
  },
});
// eslint-disable-next-line react/prop-types
export default (rowObj: any) => {
  const { t, i18n } = useTranslation(["Jobslist"]);

  const row = rowObj.row;
  const [appData]: any = useAppContext();
  const access_token = appData.loginData.access_token;
  const ApiService = useApiService();
  const loader: any = useLoader();
  const classes: any = useStyles();
  const toaster = useToast();
  const showTooltip = (data: any) => {
    if (data && data.length > 13) {
      return (
        <Tooltip
          content={data}
          direction={"bottom"}
          style={{
            backgroundColor: "rgba(97, 97, 97, 0.92)",
            marginBottom: "-8px",
          }}
          wrapperStyle={{
            width: "115px",
          }}
          className={classes.tooltip}
        >
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data}
          </div>
        </Tooltip>
      );
    } else {
      return <span>{data}</span>;
    }
  };

  const retryAutoAssignment = () => {
    let reqBody: any = {
      job_id: row.job_id,
    };
    const headers = {
      access_token: access_token,
    };
    const reqData = {
      reqBody: reqBody,
      headers: headers,
    };
    loader.showLoader();
    return ApiService.put(JobListEndpoints.retryAllocation(reqData))
      .then((res: any) => {
        loader.hideLoader();
        rowObj.refreshJobList();
      })
      .catch((err) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const showResourceInfo = () => {
    return (
      <div>
        {row["user_name"]}
        <br />
        {row["res_phone"]
          ? showTooltip(`${row["res_country_code"] + row["res_phone"]}`)
          : ""}
      </div>
    );
  };
  const showAutoAssignmentStatus = () => {
    return (
      // onClick="retryAutoAssignment()"
      <div className="jobList__auto-assignment-action">
        {row.assignment_status === 1 ? (
          <div>
            <InProgressAutoAssignmentIcon /> {t("Jobslist:In Progress")}{" "}
          </div>
        ) : row.assignment_status === 2 || row.assignment_status === 3 ? (
          <div
            onClick={() => {
              retryAutoAssignment();
            }}
          >
            <ReplayIcon style={{ color: themeColor.primary.main }} />{" "}
            {t("Jobslist:Retry")}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div>
      {row.auto_assign === 0 || row.resource_id
        ? showResourceInfo()
        : showAutoAssignmentStatus()}
    </div>
  );
};
