/**
 * Created by Vikash-Mehta on 9/1/16.
 */

//var lightMap = "https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoidG9va2FuIiwiYSI6ImNpczcyMnNlcTAyNjEyc25wam1rcDBmcDcifQ.Vq7zVwwWII6EQY31Gw8LTA";
//var darkMap = "https://api.mapbox.com/styles/v1/mapbox/dark-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoidG9va2FuIiwiYSI6ImNpczcyMnNlcTAyNjEyc25wam1rcDBmcDcifQ.Vq7zVwwWII6EQY31Gw8LTA";
/* eslint-disable no-undef */
import googleMutant from "./Leaflet.GoogleMutant";
import leafletDraw from "./Leaflet.Draw";
// import leafletLabel from "./leaflet.label";
import movingMarker from "./moving-marker";
import responsivePopup from "./Leaflet.ResponsivePopup";

import { TrendingUpTwoTone } from "@material-ui/icons";
import "./Polyline.encoded";
import "./leaflet.polylineDecorator";
// import { useAppContext } from "../../AppContext/App.context";
import "./css/leaflet.label.scss";
import "./css/leaflet.scss";
import { mapTypesEnum } from "../../enum/MapType.enum";
import { updateGoogleAPIKey } from "../updateGoogleAPIKey";
// import { useAppContext } from "../../AppContext/App.context";

const L = require("leaflet");

var lightMap =
  "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png";
var darkMap =
  "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png";
var bounds = new L.LatLngBounds(new L.LatLng(85, -180), new L.LatLng(-85, 180));
var mapStyle = {
  dark_theme: [
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          saturation: 36,
        },
        {
          color: "#939393",
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#0a0e12",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#2d3339",
        },
        {
          lightness: 0,
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#2d3339",
        },
        {
          lightness: 0,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          hue: "#ff0000",
        },
        {
          visibility: "simplified",
        },
        {
          saturation: "-100",
        },
        {
          lightness: "-67",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          lightness: "25",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          lightness: "-43",
        },
        {
          saturation: "-14",
        },
        {
          gamma: "1.01",
        },
        {
          hue: "#ff0000",
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          lightness: "0",
        },
        {
          color: "#141c24",
        },
        {
          hue: "#a4ff00",
        },
        {
          saturation: "-100",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.stroke",
      stylers: [
        {
          visibility: "on",
        },
        {
          hue: "#ff0f00",
        },
        {
          saturation: "4",
        },
        {
          lightness: "-64",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          lightness: "0",
        },
        {
          color: "#141c24",
        },
        {
          visibility: "simplified",
        },
        {
          gamma: "0.94",
        },
        {
          hue: "#ff0000",
        },
        {
          saturation: "-100",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#0a0e12",
        },
        {
          lightness: 0,
        },
      ],
    },
  ],
  light_theme: [],
};

var polylineColorsArray = [
  "#1394ff",
  "#2a9670",
  "#4513e2",
  "#ff0000",
  "#f00bef",
  "#11718d",
  "#09c512",
  "#0cb8ab",
  "#f18142",
  "#2e10d7",
];

var MapDisplay = (function () {
  var MapObject = function (options) {
    var loginData = JSON.parse(localStorage.getItem("loginData"));
    var companyLatitude = Number(loginData?.latitude);
    var companyLongitude = Number(loginData?.longitude);
    const hereMapKey = loginData?.map_configuration?.here?.dashboard;
    const mapBoxKey = loginData?.map_configuration?.mapbox?.dashboard;
    const googleApiKey = loginData?.map_configuration?.google?.dashboard;
    const mapBoxUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapBoxKey}`;
    const hereMapUrl = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=${hereMapKey}&ppi=320`;
    const mapType = Number(loginData.map_configuration?.map_type);
    const isGoogleApi = loginData.google_api_key;

    this.is_clustering = options.is_clustering;
    this.options = options;
    this.clickCallback = options.clickCall || !1;
    this.params = options.params || [];
    this.layerType = options.style || 0;
    this.polygons = [];
    this.polygonLayersList = [];
    this.teamMarkerCoords = [];
    this.teamMarkers = [];
    this.polygonLayer = L.featureGroup();
    this.map = L.map(options.eleId, {
      attributionControl: false,
      zoomControl: true,
      center: options.center
        ? new L.LatLng(+options.center[0], +options.center[1])
        : companyLatitude && companyLongitude
        ? new L.LatLng(companyLatitude, companyLongitude)
        : new L.LatLng(47.6304131, -122.226877),
      zoom: 12,
      maxZoom: options.maxZoom || 18,
      maxBoundsViscosity: 0.75,
      maxBounds: bounds,
      tap: false,
      // minZoom: (options.minZoom || 8)
      minZoom: 1,
      closePopupOnClick: false,
    });
    if (!mapType) {
      this.layer = L.gridLayer
        .googleMutant({
          type: options.mapType,
          // styles: options.style,
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
          showTraffic: options.mapType == "hybrid" ? false : options.mapTraffic,
        })
        .addTo(this.map);
    } else if (mapType == mapTypesEnum.GoogleMap) {
      updateGoogleAPIKey(googleApiKey);
      this.layer = L.gridLayer
        .googleMutant({
          type: options.mapType,
          // styles: options.style,
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
          showTraffic: options.mapType == "hybrid" ? false : options.mapTraffic,
        })
        .addTo(this.map);
    } else if (mapType == mapTypesEnum.HereMap) {
      this.layer = L.tileLayer(hereMapUrl, {
        attributionControl: false,
      }).addTo(this.map);
    } else if (mapType == mapTypesEnum.MapBox) {
      this.layer = L.tileLayer(mapBoxUrl, {
        attributionControl: false,
      }).addTo(this.map);
    }
    this.polygonLayer.addTo(this.map);
    // window["x"] = this.map;
    this.callback = options.callback;
    this.bindEvents();
    //this.addLayers();
    if (options.customControl) {
      this.ourCustomControl(options.template, options.map_zoom_template);
    } else {
      setTimeout(() => {
        if (options.callback) {
          options.callback();
        }
      });
    }
    this.polylineAndDecorator = [];
  };

  MapObject.prototype = {
    addLayers: function (cluster) {
      this.map.addLayer(cluster);
    },
    addDrawnItems: function () {
      return L.featureGroup().addTo(this.map);
    },
    addDrawingLayer: function (drawEventCreated) {
      const multiPolyLineOptions = {
        shapeOptions: {
          color: "red",
          weight: 2,
        },
      };
      let drawControl = new L.Draw.Polygon(this.map, multiPolyLineOptions);
      drawControl.enable();
      this.map.on(L.Draw.Event.CREATED, drawEventCreated);
    },
    addDrawingLayer1: function () {
      this.drawingLayer = L.featureGroup().addTo(this.map);
      this.map.addControl(
        new L.Control.Draw({
          edit: {
            featureGroup: this.drawingLayer,
            poly: {
              allowIntersection: false,
            },
          },
          draw: {
            polygon: {
              allowIntersection: false,
              showArea: true,
            },
          },
        })
      );

      this.map.on(L.Draw.Event.CREATED, function (event) {
        let layer = event.layer;

        this.drawingLayer.addLayer(layer);
      });
      // L.featureGroup().addTo(this.map);
      // this.map.addLayer(L.featureGroup())
    },
    pointsToArray: function (points) {
      if (!_.isArray(points)) {
        points = [points];
      }

      return _.map(
        points,
        function (point) {
          if (this.options.inChina) {
            var gcjPoint = transform.wgs2gcj(point.latitude, point.longitude);
            return [gcjPoint.lat, gcjPoint.lng];
          } else {
            return [point.fleet_latitude, point.fleet_longitude];
          }
        }.bind(this)
      );
    },

    bindEvents: function () {
      this.map.on(
        "click",
        () => {
          if (
            typeof this.clickCallback == "function" &&
            typeof this.params == "object"
          )
            this.clickCallback.apply(this, this.params);
        },
        this
      );
    },

    fitBoundsOptions: function (animate) {},
    // getSouthAndNorthWestFromBoundsbounds:function(bounds){
    //   if (bounds && bounds.length) {
    //     var completeBounds = new L.LatLngBounds();
    //     for (var i = 0; i < bounds.length; i++) {
    //       // completeBounds.extend((bounds[i]));
    //       var s=L.latLng(bounds[i][0], bounds[i][1]);
    //       completeBounds.extend(s);
    //     }
    //   }
    //   this.map.fitBounds(L.latLngBounds(completeBounds.getSouthWest(), completeBounds.getNorthEast()));
    //   this.map.fitBounds(completeBounds, {
    //     padding:  [10, 10],
    //   });
    //   // return L.latLngBounds(completeBounds.getSouthWest(), completeBounds.getNorthEast());
    // },
    fitMap: function (a, padding, fitBoundsForPolygons) {
      if (a && a.length) {
        var completeBounds = new L.LatLngBounds();
        for (var i = 0; i < a.length; i++) {
          completeBounds.extend(a[i]);
        }
        // for (var i = 0; i < this.teamMarkerCoords.length; i++) {
        //   completeBounds.extend(this.teamMarkerCoords[i]);
        // }
        if (fitBoundsForPolygons) {
          completeBounds.extend(this.getboundFromDecoratorPolygons());
        }

        if (completeBounds.equals(new L.LatLngBounds())) {
          this.map.fitBounds(bounds);
        } else {
          this.map.fitBounds(completeBounds, {
            maxZoom: 13,
            padding: padding || [10, 10],
          });
        }
        setTimeout(
          function () {
            this.map.invalidateSize(false);
          }.bind(this),
          0
        );
      }
    },
    setCenter: function (a) {
      if (a && !isNaN(a[0]) && !isNaN(a[1])) {
        this.map.panTo(a, { animate: true, duration: 1.0 });
      }
    },
    setCenterFromLatlng: function (latlng) {
      const latlngObj = L.latLng(
        parseFloat(latlng["latitude"] || 0),
        parseFloat(latlng["longitude"] || 0)
      );
      this.map.panTo(latlngObj, { animate: true, duration: 1.0 });
      // temp["latitude"] = arg.latLng?.latitude || 0;
      // temp["longitude"] = arg.latLng?.longitude || 0;
    },
    setMapZoom: function (a) {
      this.map.setZoom(a);
    },
    validateSize: function () {
      this.map.invalidateSize();
    },
    ourCustomControl: function (template, map_zoom_template) {
      // FILTER POSITION CONTROL
      var thisVar = this;
      setTimeout(function () {
        var customControl = L.Control.extend({
          options: {
            position: "bottomleft",
          },
          onAdd: function () {
            var container = L.DomUtil.create(
              "div",
              "leaflet-bar leaflet-control leaflet-control-custom text-center"
            );
            container.innerHTML = template;
            return container;
          },
        });

        var zoomControl = L.control.zoom({
          position: "bottomright",
        });
        var mapBoundControl = L.Control.extend({
          options: {
            position: "bottomright",
          },
          onAdd: function () {
            var container = L.DomUtil.create(
              "div",
              "leaflet-bar leaflet-control leaflet-control-custom text-center"
            );
            container.innerHTML = map_zoom_template;
            return container;
          },
        });
        thisVar.map.addControl(new customControl());
        thisVar.map.addControl(new mapBoundControl());
        thisVar.map.addControl(zoomControl);
        thisVar.callback();
      }, 0);
    },
    addPointsPolyline: function (latlngs) {
      this.pointsPolyline = L.polyline(latlngs, { color: "red" }).addTo(
        this.map
      );

      // zoom the map to the polyline
      this.map.fitBounds(this.pointsPolyline.getBounds());
    },
    removePointsPolyline: function () {
      if (!this.pointsPolyline) {
        return;
      }
      this.map.removeLayer(this.pointsPolyline);
      this.pointsPolyline = null;
    },
    decodedPoly: function (encoded, extraDetails, encodingLevel) {
      // var randomcolor;
      // var r = Math.floor(Math.random() * 255);
      // var g = Math.floor(Math.random() * 255);
      // var b = Math.floor(Math.random() * 255);
      // randomcolor = "rgb(" + r + " ," + g + "," + b + ")";

      var color = polylineColorsArray[extraDetails - 1];
      // polylineColorsArray[extraDetails.routeNumber || 0] ||
      // polylineColorsArray[0];
      const encoded1 = L.PolylineUtil.decode(encoded, encodingLevel || 5);
      //console.log(encoded);
      var polyline = L.polyline(encoded1, {
        weight: 3,
        color: color,
        opacity: 1.0,
      });
      polyline.addTo(this.map);
      const decorator = L.polylineDecorator(polyline, {
        patterns: [
          {
            offset: 25,
            repeat: 1000,
            symbol: L.Symbol.arrowHead({
              pixelSize: 7,
              pathOptions: {
                fillOpacity: 10,
                color: color,
                weight: 3,
              },
            }),
          },
        ],
      }).addTo(this.map);
      if (
        extraDetails.routeNumber != null &&
        extraDetails.routeNumber != undefined
      ) {
        polyline.setText(
          "       Route ID " + extraDetails.routeId + "           ",
          { repeat: true, offset: -5, attributes: { fill: color } }
        );
      }
      // console.log(polyline);
      this.polylineAndDecorator.push({
        polyline: polyline,
        decorator: decorator,
      });

      return encoded1;
    },
    // removePointPoly: function () {

    // },
    removePoly: function () {
      if (this.polylineAndDecorator && this.polylineAndDecorator.length) {
        for (var i = 0; i < this.polylineAndDecorator.length; i++) {
          try {
            this.map.removeLayer(this.polylineAndDecorator[i].polyline);
            this.polylineAndDecorator[i].polyline = null;
            this.map.removeLayer(this.polylineAndDecorator[i].decorator);
            this.polylineAndDecorator[i].decorator = null;
          } catch (e) {}
        }
      }
      this.polylineAndDecorator = [];
      //if (this.polyline) {
      //    this.map.removeLayer(this.polyline);
      //}
      //if (this.decorator) {
      //    this.map.removeLayer(this.decorator);
      //}
    },
    getboundFromDecoratorPolygons: function () {
      bounds = new L.LatLngBounds();
      for (var i = 0; i < this.polylineAndDecorator.length; i++) {
        var polygon = this.polylineAndDecorator[i].polyline;
        bounds.extend(polygon.getBounds());
        // for (var j = 0; j < polygon.length; j++) {
        //   bounds.extend(polygon);
        // }
      }
      return bounds;
    },
    addPolygon: function (polygon) {
      this.polygons.push(polygon);
    },
    drawPolygons: function () {
      this.polygonLayer.clearLayers();
      this.polygonLayersList = [];
      for (var i = 0; i < this.polygons.length; i++) {
        const poly = L.polygon(this.polygons[i], {
          color: "blue",
          fillOpacity: 0.3,
        });
        this.polygonLayersList.push(poly);
        this.polygonLayer.addLayer(poly);
      }
    },
    removePolygons: function () {
      this.polygonLayer.clearLayers();
      this.polygons = [];
    },
    boundOnPolygons: function () {
      bounds = new L.LatLngBounds();
      for (var i = 0; i < this.polygons.length; i++) {
        var polygon = this.polygons[i];
        for (var j = 0; j < polygon.length; j++) {
          bounds.extend(polygon[j]);
        }
      }
      return bounds;
    },
    addTeamMarker: function (latlng, teamId, teamName) {
      var teamIcon = L.icon({
        iconUrl: "app/img/team_location.svg",
        iconSize: [38, 95],
      });
      var popupContent =
        '<div class="popup-contianer task-info-popup" style="font-size: 13px;"><div>#' +
        teamId +
        '&nbsp&nbsp<span style="font-size:13px;text-transform: uppercase;font-weight:bolder;">' +
        teamName +
        "</span></div></div>";
      var marker = L.marker(latlng, { icon: teamIcon });
      var popup = new L.responsivePopup({
        offset: [0, 20],
        autoPanPadding: [10, 10],
        autoClose: false,
        closeOnEscapeKey: false,
        closeOnClick: true,
        closeButton: false,
        autoPan: false,
        className: "task-info-popup",
      }).setContent(popupContent);
      marker.addTo(this.map).bindPopup(popup);
      this.teamMarkers.push(marker);
      this.teamMarkerCoords.push(latlng);
    },
    removeAllTeamMarkers: function () {
      while (this.teamMarkers.length !== 0) {
        var marker = this.teamMarkers.pop();
        this.map.removeLayer(marker);
      }
      this.teamMarkerCoords = [];
    },
    switchLayer: function (styleType, mapType, dataTraffic) {
      this.map.removeLayer(this.layer);
      //
      // this.layer = L.tileLayer((type ? lightMap : darkMap),{
      //     attributionControl: false
      // }).addTo(this.map);
      if (mapType) {
        this.layer = L.tileLayer(
          "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
          {
            subdomains: ["mt0", "mt1", "mt2", "mt3"],
          }
        ).addTo(this.map);
      } else {
        var styleToApply;
        if (styleType == 0) {
          styleToApply = mapStyle.light_theme;
        } else {
          try {
            mapLayout = JSON.parse(localStorage.getItem("customMapLayout"));
            if (!mapLayout && !mapLayout.mapConfig) {
              styleToApply = mapStyle.dark_theme;
            } else {
              var mapConfig;
              mapConfig = JSON.parse(mapLayout.mapConfig);
              styleToApply = mapConfig;
            }
          } catch (e) {
            styleToApply = mapStyle.dark_theme;
          }
        }
        if (
          localStorage.getItem("map_options") &&
          localStorage.getItem("map_options") != "undefined" &&
          !JSON.parse(localStorage.getItem("map_options")).setMap
        ) {
          this.layer = L.gridLayer
            .googleMutant({
              type: "roadmap",
              styles: styleToApply,
              showTraffic: dataTraffic,
            })
            .addTo(this.map);
        }
      }
    },
    remove: function () {
      this.map.remove();
      this.map = null;
    },
  };

  return MapObject;
})();

var MapMarkers = (function () {
  // var appData = useAppContext();
  // var loginData=appData.loginData;

  // var loginData = JSON.parse(localStorage.getItem("loginData"));

  var marker = function (a) {
    this.options = a || {};
    this.map = a.map || !1;
    this.position = [parseFloat(a.lat), parseFloat(a.lng)];
    this.icon = a.icon;
    this.index = a.index;
    this.clickCallback = a.clickCall || !1;
    this.params = a.params;
    this.clusters = a.clusters;
    var loginData = JSON.parse(localStorage.getItem("loginData"));
    this.createMarker(a);
    if (loginData.allow_tag_marker) {
      if (a.value != "" && a.value != undefined) {
        this.setTagLabel(a.value ? a.value : "");
      }
    }
  };

  marker.prototype = {
    createMarker: function (a) {
      var icon = L.icon({
        // iconUrl: 'http://leafletjs.com/docs/images/leaf-red.png',
        // shadowUrl: 'http://leafletjs.com/docs/images/leaf-shadow.png',

        // iconSize:     [38, 95], // size of the icon
        // shadowSize:   [50, 64], // size of the shadow
        //  iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
        // shadowAnchor: [4, 62],  // the same for the shadow
        // popupAnchor:  [-3, -76],
        iconSize: [48, 72],
        iconAnchor: [20, 50],
        //  labelAnchor: [-12, -36],
        iconUrl: this.icon,
      });
      // this.marker = new L.marker(this.position, {icon: icon, keyboard: true})
      if (localStorage.getItem("is_clustering") == 1) {
        this.marker = new L.marker(this.position, {
          icon: icon,
          keyboard: true,
        });
      } else {
        this.marker = new L.marker(this.position, {
          icon: icon,
          keyboard: true,
          draggable: a.draggable,
        }).addTo(this.map);
      }
      // .bindLabel('A sweet static label!', { noHide: true })
      //     .addTo(this.map);
      // .showLabel();

      //.bindLabel('A sweet static label!', { noHide: true })
      // .bindTooltip("Test Label",
      //     {
      //         permanent: true,
      //         direction: 'right'
      //     })
      // .addTo(this.map);

      this.marker.setZIndexOffset(1);
      this.bindEvents();
    },
    bindEvents: function () {
      this.marker.on(
        "click",
        () => {
          if (
            typeof this.clickCallback == "function" &&
            typeof this.params == "object"
          )
            this.clickCallback.apply(this, this.params);
        },
        this
      );
    },
    setIcon: function (icon, bigger_size) {
      var a = L.icon({
        iconSize: [bigger_size ? 68 : 48, bigger_size ? 102 : 72],
        iconAnchor: [24, 72],
        iconUrl: icon,
      });
      this.marker.setIcon(a);
    },
    setTooltip: function (tooltip_content) {
      this.marker
        .bindTooltip(tooltip_content, {
          permanent: false,
          direction: "right",
          offset: [20, -20],
        })
        .openTooltip();
    },
    setResponsivePopup: function (popupContent) {
      let div = document.createElement("div");
      div.classList.add("popup-contianer");
      div.appendChild(popupContent);
      let popup = new L.responsivePopup({
        offset: [0, 40],
        autoPanPadding: [10, 10],
        autoClose: false,
        closeOnEscapeKey: true,
        closeOnClick: true,

        closeButton: false,
        autoPan: TrendingUpTwoTone,
        className: "task-info-popup",
      });
      popup.setContent(div);
      this.marker.bindPopup(popup);
    },
    closeTooltip: function () {
      this.marker.closeTooltip();
      this.marker.unbindTooltip();
    },
    setTagLabel: function (value) {
      // this.marker
      //   .bindLabel(value.toString(), {
      //     noHide: true,
      //     className: "div-overflow",
      //     direction: "right",
      //     //offset:[12,-50]
      //   })
      //   .showLabel();
      this.marker.bindTooltip(value.toString(), {
        permanent: true,
        className: "tag-label",
        direction: "bottom",
        //offset:[12,-50]
      });
    },
    setLabel: function (value) {
      this.marker.bindTooltip(value.toString(), {
        permanent: true,
        className: "div-overflow route-no",
        direction: "top",
        //offset:[12,-50]
      });
    },
    showPopupOnHover: function () {
      this.marker.on("mouseover", function (e) {
        this.openPopup();
      });
      this.marker.on("mouseout", function (e) {
        this.closePopup();
      });
    },
    bindPopup: function (value) {
      this.marker.bindPopup(value);
    },
    openPopup: function () {
      this.marker.openPopup();
      // this.marker.on('mouseover', function (e) {
      //     this.openPopup();
      // });
    },
    closePopup: function () {
      this.marker.closePopup();
      this.marker.unbindPopup();
      // this.marker.on('mouseout', function (e) {
      //     this.closePopup();
      // });
    },
    resetLabel: function () {
      this.marker.unbindTooltip();
    },
    setLatLng: function (a) {
      this.marker.setLatLng(a);
    },
    remove: function () {
      this.map.removeLayer(this.marker);
    },
    setZoom: function (x) {
      this.marker.setZIndexOffset(x);
    },
  };

  return marker;
})();

var animateMarker = (function () {
  var marker = function (a) {
    this.clusters = a.clusters;
    this.options = a || {};
    this.map = a.map || !1;
    this.position = [parseFloat(a.lat), parseFloat(a.lng)];
    this.pts = a.pts || [[parseFloat(a.lat), parseFloat(a.lng)]];
    this.icon = a.icon;
    this.ptsIdx = a.point || 0;
    this.interval = 10e3 / a.speed;
    this.params = a.params;
    this.is_agent_clustering = a.is_agent_clustering;
    this.clickCallback = a.clickCall || !1;
    this.animMarker = L.Marker.movingMarker(
      [
        [parseFloat(a.lat), parseFloat(a.lng)],
        [parseFloat(a.lat), parseFloat(a.lng)],
      ],
      [this.interval]
    );
    this.createIcon(a.size, a.anchor);
    //this.clusters[1].addLayer(this.animMarker);
    //this.animMarker = new L.AnimatedMarker([[this.position[0], this.position[1]]], {
    //    autoStart: !1,
    //    interval: this.interval
    //});
    //this.animMarker.on("add", function() {
    //b.setPosition({
    //    animate: this.animate || !0,
    //    lat:parseFloat(a.lat),
    //    lon:parseFloat(a.lng)
    //});
    //});
  };

  marker.prototype = {
    setPosition: function (a) {
      var b = parseFloat(a.lat);
      var c = parseFloat(a.lon);

      this.animMarker._latlngs.push([b, c]);
      var m = this.map;

      if (a.animate) {
        this.animMarker.animate();
      } else {
        this.animMarker.setLatLng([b, c]);
      }
      return !0;
    },
    startDriving: function (a) {
      if (this.isDriving) return !1;
      a = a || {};
      this.isDriving = !0;
      var b = parseFloat(a.lat).toFixed(6);
      var c = parseFloat(a.lon).toFixed(6);
      if (a.animate) {
        this.animMarker.start();
        this.intervals = setInterval(
          function (b, a) {
            b.setPosition(a);
          },
          this.interval,
          this,
          a
        );
      } else {
        for (; this.setPosition(); );

        this.isDriving = !1;
      }
      return !0;
    },
    addPoints: function (a) {
      //this.startDriving(a);
      this.animMarker.moveTo(
        [parseFloat(a.lat).toFixed(6), parseFloat(a.lon).toFixed(6)],
        2000
      );
    },
    stopDriving: function () {
      clearInterval(this.intervals);
      this.isDriving = this.intervals = !1;
      return !0;
    },
    createIcon: function (size, anchor) {
      var a = L.icon({
        iconSize: size || [24, 24],
        iconAnchor: anchor || [12, 12],
        iconUrl: this.icon,
      });
      this.animMarker.setIcon(a);
      if (!this.is_agent_clustering) {
        this.animMarker.addTo(this.map);
      }
      this.bindEvents();
      if (
        this.icon == "app/img/driver_idle.png" ||
        this.icon == "app/img/driver_intransit.png" ||
        this.icon == "app/img/jugnoo-marker.png" ||
        this.icon == "app/img/partner_driver.png"
      ) {
        this.animMarker.setZIndexOffset(50);
      }
      return this;
    },
    setIcon: function (icon, bigger_size) {
      var a = L.icon({
        iconSize: [bigger_size ? 60 : 24, bigger_size ? 40 : 24],
        iconAnchor: [bigger_size ? 22 : 12, bigger_size ? 22 : 12],
        iconUrl: icon,
      });
      this.animMarker.setIcon(a);
    },
    setIconWithSize: function (
      icon,
      size,
      anchor,
      transport_applied,
      bigger_size
    ) {
      var a;
      if (transport_applied) {
        a = L.icon({
          iconSize: bigger_size ? [50, 50] : size,
          iconAnchor: bigger_size ? [25, 50] : anchor,
          iconUrl: icon,
        });
        this.animMarker.setIcon(a);
      } else {
        this.setIcon(icon, bigger_size);
      }
    },
    setLatLng: function (a) {
      this.animMarker.setLatLng(a);
    },
    bindEvents: function () {
      this.animMarker.on(
        "click",
        () => {
          if (
            typeof this.clickCallback == "function" &&
            typeof this.params == "object"
          )
            this.clickCallback.apply(this, this.params);
        },
        this
      );
    },
    bindPopup: function (a) {
      var content =
        "<div>Driver Id: " +
        a.params[0] +
        "</div>" +
        "<div>Driver Name: <span>" +
        a.params[1] +
        " </span></div>" +
        "<div>Driver Phone: " +
        a.params[2] +
        "</div>" +
        "<div>Vehicle Type: " +
        a.params[3] +
        "</div>" +
        "<div>Location Updated At : " +
        a.params[4] +
        "</div>" +
        "";
      this.animMarker.bindPopup(content.toString());
    },
    setResponsivePopup: function (popupContent) {
      let div = document.createElement("div");
      div.classList.add("popup-contianer");
      div.appendChild(popupContent);
      let popup = new L.responsivePopup({
        offset: [0, 40],
        autoPanPadding: [10, 10],
        autoClose: false,
        closeOnEscapeKey: false,
        closeOnClick: true,
        closeButton: false,
        autoPan: false,
        className: "task-info-popup",
      });
      popup.setContent(div);
      this.animMarker.bindPopup(popup);
    },

    openPopup: function () {
      this.animMarker.openPopup();
      // this.marker.on('mouseover', function (e) {
      //     this.openPopup();
      // });
    },
    remove: function () {
      this.map.removeLayer(this.animMarker);
    },
    setZoom: function (x) {
      this.animMarker.setZIndexOffset(x);
    },
    addToMap: function () {
      this.animMarker.addTo(this.map);
    },
  };

  return marker;
})();

var lMap = (function () {
  return {
    map: MapDisplay,
    markers: MapMarkers,
    animatedMarker: animateMarker,
  };
})();

export default lMap;
// var e = '<div><img src=' +icon+ ' /></div><div class="job-icon div-overflow">' +value+ '</div>';
// var i = L.divIcon({
//     html: e,
//     iconSize: [24,36],
//     iconAnchor: [12,36]
// });
