import React, {
  useEffect,
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
  useRef,
  useLayoutEffect,
  Suspense,
} from "react";
import XLSX from "xlsx";
import { useStyles } from "./ImportStyles";
import "./ImportExport.scss";
import BackupIcon from "@material-ui/icons/Backup";
import { headerList } from "./headerList";
import { singleLineList } from "./singleLineList";

import MaterialAutocomplete from "../MaterialAutocomplete/MaterialAutocomplete";
import Btn from "../Button/Button";
import { useForm } from "react-hook-form";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import { templateApiService } from "../../services/ApiServices/templateApi.service";
import useApiService from "../../services/api.service";
import { useAppContext } from "../../AppContext/App.context";
import useToast from "../Toast/hooks/useToast";
import templateMethodService from "../TemplatesCommonMethods/TemplateMethod.service";
import { ImportExportService } from "./ImportExport.endpoints";
import { useHistory } from "react-router-dom";
import useLoader from "../../hooks/useLoader";
import FormatSelector from "./ImportFormatSelector";
import PageHeading from "../PageHeading/PageHeading";
import AlertDialog from "../Dialog/Dialog";
import { themeColor } from "../../constants/themeColor";
import useLocalStorage from "../../utils/localStorage";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useDialog = () => {
  const [open, setOpen] = useState(false);
  const openDialog = (value?: any) => {
    let v = value === false ? false : true;
    setOpen(v);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const props = {
    open,
    handleClose,
  };
  return [openDialog, props];
};

const MyDialog = ({ open, handleClose, title, children }: any) => {
  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      style={{ textAlign: "center" }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

const Import = forwardRef((props: any, ref: any) => {
  const { control } = useForm();
  const ApiService = useApiService();
  const [openDialog, dialogProps]: any = useDialog();
  const classes = useStyles();
  const deleteFormatRef: any = useRef();
  const { t, i18n } = useTranslation(["Common", "ImportJobs"]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [appData]: any = useAppContext();
  const [deliveryTemplateId, setDeliveryTemplateId]: any = useState();
  const [fileHeaders, setFileHeaders]: any = useState([]);
  const [templateId, setTemplateId]: any = useState();
  const access_token = appData.loginData.access_token;
  const toaster = useToast();
  const [fileHeaderListForSubmit, setFileHeaderListForSubmit]: any = useState();
  const [selectedReqBody, setSelectedRequestBody]: any = useState();
  const [formatChecked, setFormatChecked]: any = useState(false);
  const [showAddScreen, setShowAddScreen] = useState(false);
  const loginData: any = useLocalStorage.getItem("loginData");
  const [jobformatType, setjobFormatType]: any = useState();
  const [file, setFile]: any = useState();
  const [newFilterListState, setNewFilterList]: any = useState();
  const [deliverySheetList, setDeliverySheetList]: any = useState();
  const [fileName, setFileName]: any = useState();
  const [fileExtension, setFileExtension]: any = useState();
  const [getFormatList, setFormatList]: any = useState([]);
  const [formatName, setFormatName]: any = useState();
  const [formatId, setFormatId]: any = useState();
  const [defaultCheck, setDefaultCheck]: any = useState();
  const [pickupSheetList, setPickupSheetList]: any = useState();
  const [
    deliveryTemplateFieldList,
    setDeliveryTemplateFieldList,
  ]: any = useState();
  const [pickupTemplateFieldList, setPickupTemplateFieldList]: any = useState();
  const [templateList, setTemplateList]: any = useState();
  const [reRenderDropdownCount, updateReRenderDropdownCount] = useState(
    Math.random()
  );
  let [stepsArray, setStepsArray] = useState([
    t("ImportJobs:Upload File"),
    t("ImportJobs:Select/Add Format"),
    t("ImportJobs:Format Name"),
    t("ImportJobs:JobList Mapping"),
    t("ImportJobs:Template Mapping"),
  ]);
  const formatType: any = {
    SingleLine: 2,
    MultiLine: 1,
  };
  let [showSelectScreen, setShowSelectScreen] = useState(true);
  const steps = getSteps();
  const [workflow, setWorkflow]: any = useState(
    loginData.workflow_type ? loginData.workflow_type : 0
  );
  const optionList = headerList(t);
  const SingleLineList = singleLineList(t);
  // useLocalStorage.getItem("workflow")
  const [defaultDropdownList, setDefaultDropdownList]: any = useState(
    optionList
  );
  let [formatOptionList, setFormatOptionList]: any = useState(optionList);
  useImperativeHandle(ref, () => ({
    handleOpen(operation: any) {
      handleReset();

      if (operation === 0) {
        openDialog();
        getFormat();
        getTemplateList();
        getWorkFlow(loginData.workflow_type);

        // handleReset();
      } else {
      }
    },
  }));
  useEffect(() => {}, []);

  const getWorkFlow = (work: any) => {
    if (work >= 0) {
      setWorkflow(Number(work));
      checkFieldList(work);
    } else {
      setWorkflow(null);
    }
  };

  const getFormat = async () => {
    let headersAPI = {
      access_token: access_token,
    };
    let qureyParams = {};
    let reqData = {
      reqBody: { ...qureyParams, workflow_type: workflow },
      headers: headersAPI,
    };

    const res: any = await ApiService.get(
      ImportExportService.getFormat(reqData)
    )
      .then(function (response) {
        if (Object.keys(response.data).length === 0) {
          setFormatList([]);
        }
        setFormatList(response.data.data);
      })
      .catch((err: any) => {
        setFormatList([]);
      });
  };

  function getSteps() {
    return stepsArray;
  }
  const getTemplateList = async () => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams = {
      skip: 0,
      limit: 1000,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      templateApiService.getTemplateList(reqData)
    );
    if (showSelectScreen === false) {
      stepsArray.splice(1, 1);
      setStepsArray(stepsArray);
    }
    if (res.data && res.data) {
      if (Object.keys(res.data).length === 0) {
        setTemplateList([]);
      } else {
        setTemplateList(res.data.data);
      }
    } else {
      setTemplateList([]);
    }
  };

  const checkFieldList = (newWorkflow: number) => {
    const tempFieldList: any = [...optionList];

    if (newWorkflow === 1) {
      let index: any = tempFieldList.findIndex(
        (i: any) => i.key === "local_datetime"
      );
      tempFieldList.splice(
        tempFieldList.findIndex((i: any) => {
          return i.key === "local_datetime";
        }),
        1
      );
      tempFieldList.splice(
        tempFieldList.findIndex((i: any) => {
          return i.key === "task_type";
        }),
        1
      );
      tempFieldList.splice(index - 1, 0, {
        key: "start_datetime",
        option: t("ImportJobs:Start time*"),
        is_required: 1,
      });
      tempFieldList.splice(index, 0, {
        key: "end_datetime",
        option: t("ImportJobs:End time*"),
        is_required: 1,
      });

      setDefaultDropdownList(tempFieldList);
      setFormatOptionList(tempFieldList);
    }
  };

  const getPickupTemplateList = (params: any) => {
    const pickupTemplateFields: any = [];
    const Fields = templateMethodService.getTemplateFieldsByTemplateId(
      params,
      templateList
    );
    for (let i = 0; i < Fields.length; i++) {
      pickupTemplateFields.push({
        key: `P_${Fields[i].name}`,
        option: `Pickup ${Fields[i].name}`,
        is_template_fields: 1,
        is_pickup: 1,
      });
    }

    setPickupTemplateFieldList(pickupTemplateFields);
  };

  const getDeliveryTemplateList = (params: any) => {
    const Fields = templateMethodService.getTemplateFieldsByTemplateId(
      params,
      templateList
    );

    const deliveryTemplateFields: any = [];

    for (let i = 0; i < Fields.length; i++) {
      deliveryTemplateFields.push({
        key: `D_${Fields[i].name}`,
        option: `Delivery ${Fields[i].name}`,
        is_template_fields: 1,
        is_delivery: 1,
      });
    }

    setDeliveryTemplateFieldList(deliveryTemplateFields);
  };

  const getTemplateFields = (params: any) => {
    const newFieldList: any =
      Number(jobformatType) === 2
        ? [...SingleLineList]
        : [...defaultDropdownList];
    const pickupTemplateFields: any = [],
      deliveryTemplateFields: any = [];
    const Fields = templateMethodService.getTemplateFieldsByTemplateId(
      params,
      templateList
    );
    // if (workflow === 0 && Number(jobformatType) === 2) {
    //   for (let i = 0; i < Fields.length; i++) {
    //     pickupTemplateFields.push({
    //       key: `P_${Fields[i].name}`,
    //       option: `Pickup ${Fields[i].name}`,
    //       is_template_fields: 1,
    //       is_pickup: 1,
    //     });
    //   }
    //   for (let i = 0; i < Fields.length; i++) {
    //     deliveryTemplateFields.push({
    //       key: `D_${Fields[i].name}`,
    //       option: `Delivery ${Fields[i].name}`,
    //       is_template_fields: 1,
    //       is_delivery: 1,
    //     });
    //   }
    // } else {
    // for (let i = 0; i < Fields.length; i++) {
    //   newFieldList.push({
    //     key: Fields[i].name,
    //     option: Fields[i].name,
    //     is_template_fields: 1,
    //     is_pickup: 1,
    //   });
    // }
    // }
    // setPickupTemplateFieldList(pickupTemplateFields);
    // setDeliveryTemplateFieldList(deliveryTemplateFields);
    setFormatOptionList(newFieldList);
  };
  var regex = new RegExp("(.*?).(csv|xls|xlsx)$");

  function triggerValidation(el: any) {
    if (!regex.test(el.toLowerCase())) {
      // el.value = '';
      toaster.addToast({
        message: t("ImportJobs:Please select correct file format"),
        timeout: 3000,
        type: "error",
      });
      return false;
    }
    return true;
  }
  const FileHandler = async (ev: any) => {
    let formatCheck = triggerValidation(ev.target.files[0].name);
    if (!formatCheck) {
      return false;
    }
    setFileName(ev.target.files[0].name);
    setFileExtension(ev.target.files[0].name.split(".").pop());
    setFile(ev.target.files[0]);
  };
  let headArray: any = [];
  const onDeleteDiscard = () => {
    deleteFormatRef.current.handleClose();
  };
  const onDeleteConfirm = () => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams = {
      id: formatId,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    ApiService.put(ImportExportService.deleteFormat(reqData))
      .then((res: any) => {
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        deleteFormatRef.current.handleClose();
        getFormat();
      })
      .catch((res: any) => {
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "error",
        });
        deleteFormatRef.current.handleClose();
      });
  };

  const handleDeleteFormat = (id: any) => {
    setFormatId(id);
    deleteFormatRef.current.handleClickOpen();
  };

  // const jobChoiceModal = () => {
  //   return (
  //     <div className="Import__radioType__padding Import__radioType__list">
  //       <RadioGroup
  //         value={workflow}
  //         onChange={(evt: any) => {
  //           switchWorkFlow(parseInt(evt.target.value));
  //         }}
  //         row
  //         aria-label="position"
  //         name="position"
  //       >
  //         <div
  //           style={{ marginRight: "10px" }}
  //           className="Import__radioType__option Import__radioType__heading"
  //         >
  //           <Radio id="pickupDelivery" color="primary" value={0} />
  //           <label
  //             htmlFor="pickupDelivery"
  //             className={"Import__radioType__label"}
  //           >
  //             {t("Common:Pickup & Delivery")}
  //           </label>
  //         </div>
  //         <div className="Import__radioType__option Import__radioType__heading">
  //           <Radio id="Services" color="primary" value={1} />
  //           <label htmlFor="Services" className={"Import__radioType__label"}>
  //             {t("Common:Services")}
  //           </label>
  //         </div>
  //       </RadioGroup>
  //     </div>
  //   );
  // };

  const fileUpload = (params?: any) => {
    return (
      <Fragment>
        <Grid container direction="row" justify="center" alignItems="center">
          {/* {jobChoiceModal()} */}
          {workflow === 0 || workflow === 1 ? (
            <Fragment>
              <label htmlFor="file-upload" className="Import__fileUpload">
                <BackupIcon fontSize="large" />

                {!fileName ? (
                  <>
                    <br></br>
                    <Typography component={"span"}>
                      {t("ImportJobs:File Upload")}
                      {" (.csv or .xlsx)"}
                      {/* <br></br> */}
                    </Typography>
                  </>
                ) : (
                  <>
                    <p
                      style={{
                        marginTop: "0px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {" "}
                      {fileName}
                    </p>
                  </>
                )}
              </label>
              <input
                id="file-upload"
                accept=".csv,.xls,.xlsx"
                type="file"
                onChange={FileHandler}
                className="Import__fileUpload__input"
              ></input>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
              >
                <Typography>
                  <a
                    href={
                      workflow === 0
                        ? "https://network-on.s3.ap-south-1.amazonaws.com/template/08496424511621489355520-pickup.csv"
                        : "https://network-on.s3.ap-south-1.amazonaws.com/template/16922165861621489327457-service.csv"
                    }
                    target="_blank"
                    style={{
                      color: `${themeColor.primary.main}`,
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    {" "}
                    {t("ImportJobs:Sample")}
                    {workflow === 0 ? t("ImportJobs: Multiline ") : " "}
                    {t("ImportJobs:CSV")}
                  </a>
                </Typography>
              </Grid>
              {workflow != 1 && (
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-start"
                >
                  <Typography>
                    <a
                      href={
                        "https://network-on.s3.ap-south-1.amazonaws.com/template/87814312321658405566468-06633876361637859023304Singleline%2014.csv"
                      }
                      target="_blank"
                      style={{
                        color: `${themeColor.primary.main}`,
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      {" "}
                      {t("ImportJobs:Sample Singleline CSV")}
                    </a>
                  </Typography>
                </Grid>
              )}
            </Fragment>
          ) : null}
        </Grid>
      </Fragment>
    );
  };

  const processData = (dataString: any) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    headArray = [];
    headArray = dataStringLines[0].split(/,(?![^"]"(?:(?:[^"]"){2})[^"]$)/);
    let temp = [];
    for (let i = 0; i < headArray.length; i++) {
      temp.push({
        key: headArray[i],
      });
    }

    setFileHeaders(temp);
  };
  const handleFiles = async (files: any) => {
    var reader = new FileReader();
    if (fileExtension === "csv") {
      reader.onload = await function (e) {
        processData(reader.result);
      };
      reader.readAsText(files);
    } else {
      reader.onload = await function (evt: any) {
        const bstr: any = evt.target.result;
        const wb: any = XLSX.read(bstr, { type: "binary" });

        const wsname: any = wb.SheetNames[0];
        const ws: any = wb.Sheets[wsname];

        const data: any = XLSX.utils.sheet_to_csv(ws);
        processData(data);
      };
      reader.readAsBinaryString(files);
    }
  };
  function checkSelectedValue(arr: any, value: any) {
    const { length } = arr;
    const id = length + 1;
    const found = arr.some((el: any) => el.value === value);
    if (!found) {
      return true;
    }
    return false;
  }
  const newFilterList = { ...fileHeaders };

  const handlePickupDropdownChange = (index: number, selectedValue: any) => {
    if (selectedValue != null) {
      let check = checkSelectedValue(pickupSheetList, selectedValue.key);
      if (check) {
        pickupSheetList[index] = {
          ...pickupSheetList[index],
          value: selectedValue.key,
          isTemplate: 1,
        };
      }
    } else {
      delete pickupSheetList[index]["value"];
      delete pickupSheetList[index]["isTemplate"];
    }
  };

  const handleDeliveryDropdownChange = (index: number, selectedValue: any) => {
    if (selectedValue != null) {
      let check = checkSelectedValue(deliverySheetList, selectedValue.key);
      if (check) {
        deliverySheetList[index] = {
          ...deliverySheetList[index],
          value: selectedValue.key,
          isTemplate: 1,
        };
      }
    } else {
      delete deliverySheetList[index]["value"];
      delete deliverySheetList[index]["isTemplate"];
    }
  };

  const handleDropdownChange = (index: number, selectedvalue: any) => {
    // debugger;
    if (selectedvalue !== null) {
      let check = checkSelectedValue(fileHeaders, selectedvalue.key);
      if (check) {
        if (selectedvalue.is_template_fields === 1) {
          fileHeaders[index] = {
            ...fileHeaders[index],
            value: selectedvalue.key,
            isTemplate: 1,
            optionLabel: selectedvalue.option,
          };
        } else {
          fileHeaders[index] = {
            ...fileHeaders[index],
            value: selectedvalue.key,
            isTemplate: 0,
            optionLabel: selectedvalue.option,
          };
        }
      } else {
        if (
          document.getElementsByClassName("MuiAutocomplete-clearIndicator")[
            index
          ]
        ) {
          /* tslint:disable-next-line */
          let element: any = document.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
          )[index];
          setTimeout(() => {
            element.click();
          });
        }
        toaster.addToast({
          message: t("ImportJobs:Please Select Another Value"),
          timeout: 3000,
          type: "error",
        });
      }
    } else {
      delete fileHeaders[index]["value"];
      delete fileHeaders[index]["isTemplate"];
    }
    // if(fileHeaders[index].value){
    //   delete newFilterList[index];
    // }
    // setNewFilterList(newFilterList);
  };

  const AddFormat = (params?: any) => {
    return (
      <Fragment>
        <Grid
          container
          spacing={1}
          className="add-format"
          style={{ maxHeight: "48vh", overflowY: "scroll" }}
        >
          <Grid item xs={6} style={{ marginBottom: "10px" }}>
            <Typography component={"span"} className={classes.instructions}>
              Imported Fields
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component={"span"} className={classes.instructions}>
              Template Fields
            </Typography>
          </Grid>
          {fileHeaders.map((head: any, key: 0) => {
            return (
              <>
                <Grid item xs={6}>
                  <Input
                    control={control}
                    label={t("ImportJobs:Field name")}
                    type="text"
                    value={head.key}
                    name="field-name"
                    disabled={true}
                  ></Input>
                </Grid>
                <Grid item xs={6}>
                  <MaterialAutocomplete
                    isMultiple={false}
                    freeSolo={false}
                    disablePortal={false}
                    size="medium"
                    id={"team-outlined"}
                    options={formatOptionList}
                    defaultValue={head.optionLabel ? head.optionLabel : ""}
                    labelName="option"
                    onChange={(e: any, newValue: any) => {
                      handleDropdownChange(key, newValue);
                      updateReRenderDropdownCount(Math.random());
                    }}
                    rerender={reRenderDropdownCount}
                    getOptionDisabled={(selectedvalue: any) => {
                      return !checkSelectedValue(
                        fileHeaders,
                        selectedvalue.key
                      );
                    }}
                    filterSelectedOptions
                    onKeyDown={() => {}}
                    textFieldClassName={classes.tagInputField}
                    textFieldLabel={t("ImportJobs:Select")}
                    textFieldPlaceholder={t("ImportJobs:Select")}
                    textFieldMargin={"normal"}
                    textFieldFullWidth={true}
                  />
                </Grid>
              </>
            );
          })}
        </Grid>
      </Fragment>
    );
  };

  const setPickupAndDeliveryList = (listType: any, selectedValue: any) => {
    // list type 1 = pickup and 2 = delivery
    if (listType == 2) {
      for (let index = 0; index < pickupSheetList.length; index++) {
        if (pickupSheetList[index]?.key == selectedValue) {
          delete pickupSheetList[index];
          return;
        }
      }
    } else if (listType == 1) {
      for (let index = 0; index < deliverySheetList.length; index++) {
        if (deliverySheetList[index]?.key == selectedValue) {
          delete deliverySheetList[index];
          return;
        }
      }
    }
  };

  const PickupAndDeliveryTemplate = (params?: any) => {
    return (
      <>
        {templateId ? (
          <Fragment>
            <div style={{ display: "flex", padding: "15px" }}>
              <Grid item xs={6} style={{ marginBottom: "10px" }}>
                <Typography component={"span"} className={classes.instructions}>
                  Pickup Imported Fields
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component={"span"} className={classes.instructions}>
                  Pickup Template Fields
                </Typography>
              </Grid>
            </div>
            <Grid
              container
              spacing={1}
              className="add-format"
              style={{ maxHeight: "37vh", overflowY: "scroll" }}
            >
              {pickupSheetList.map((head: any, key: 0) => {
                return (
                  <>
                    <Grid item xs={6}>
                      <Input
                        control={control}
                        label={t("ImportJobs:Field name")}
                        type="text"
                        value={head.key}
                        name="field-name"
                        disabled={true}
                      ></Input>
                    </Grid>
                    <Grid item xs={6}>
                      <MaterialAutocomplete
                        isMultiple={false}
                        freeSolo={false}
                        disablePortal={false}
                        size="medium"
                        id={"team-outlined"}
                        options={pickupTemplateFieldList}
                        labelName="option"
                        onChange={(e: any, newValue: any) => {
                          // handleDropdownChange(key, newValue);
                          handlePickupDropdownChange(key, newValue);
                          // if (jobformatType == 2) {
                          //   let selectedValue = pickupSheetList[key].key;
                          //   // let selectedValue = newValue.option.substring(7);
                          //   setPickupAndDeliveryList(1, selectedValue);
                          //   // handleDropdownChange(key, newValue);
                          // }
                          updateReRenderDropdownCount(Math.random());
                        }}
                        rerender={reRenderDropdownCount}
                        getOptionDisabled={(selectedvalue: any) => {
                          return !checkSelectedValue(
                            pickupSheetList,
                            selectedvalue.key
                          );
                        }}
                        filterSelectedOptions
                        onKeyDown={() => {}}
                        textFieldClassName={classes.tagInputField}
                        textFieldLabel={t("ImportJobs:Select")}
                        textFieldPlaceholder={t("ImportJobs:Select")}
                        textFieldMargin={"normal"}
                        textFieldFullWidth={true}
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Fragment>
        ) : null}
        {deliveryTemplateId ? (
          <Fragment>
            <div style={{ display: "flex", padding: "15px" }}>
              <Grid item xs={6} style={{ marginBottom: "10px" }}>
                <Typography component={"span"} className={classes.instructions}>
                  Delivery Imported Fields
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component={"span"} className={classes.instructions}>
                  Delivery Template Fields
                </Typography>
              </Grid>
            </div>
            <Grid
              container
              spacing={1}
              className="add-format"
              style={{ maxHeight: "53vh", overflowY: "scroll" }}
            >
              {deliverySheetList.map((head: any, key: 0) => {
                return (
                  <>
                    <Grid item xs={6}>
                      <Input
                        control={control}
                        label={t("ImportJobs:Field name")}
                        type="text"
                        value={head.key}
                        name="field-name"
                        disabled={true}
                      ></Input>
                    </Grid>
                    <Grid item xs={6}>
                      <MaterialAutocomplete
                        isMultiple={false}
                        freeSolo={false}
                        disablePortal={false}
                        size="medium"
                        id={"team-outlined"}
                        options={deliveryTemplateFieldList}
                        labelName="option"
                        onChange={(e: any, newValue: any) => {
                          handleDeliveryDropdownChange(key, newValue);
                          // if (jobformatType == 2) {
                          //   let selectedValue = deliverySheetList[key].key;
                          //   setPickupAndDeliveryList(2, selectedValue);
                          // }

                          updateReRenderDropdownCount(Math.random());
                        }}
                        rerender={reRenderDropdownCount}
                        getOptionDisabled={(selectedvalue: any) => {
                          return !checkSelectedValue(
                            deliverySheetList,
                            selectedvalue.key
                          );
                        }}
                        filterSelectedOptions
                        onKeyDown={() => {}}
                        textFieldClassName={classes.tagInputField}
                        textFieldLabel={t("ImportJobs:Select")}
                        textFieldPlaceholder={t("ImportJobs:Select")}
                        textFieldMargin={"normal"}
                        textFieldFullWidth={true}
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Fragment>
        ) : null}
      </>
    );
  };
  const formatNameValidator = (params: any) => {
    let result = new RegExp(/^(?![\s-])[\w\s-]+$/).test(params);
    return result;
  };

  const FormatName = () => {
    return (
      <Fragment>
        {workflow === 0 && (
          <Grid style={{ marginBottom: "15px" }}>
            <RadioGroup aria-label="" name="default" row value={jobformatType}>
              <FormControlLabel
                value={`${formatType.MultiLine}`}
                control={
                  <Radio
                    color="primary"
                    // value={defaultChecked}
                    onChange={(e: any) => {
                      // debugger;
                      setjobFormatType(e.target.value);
                      setFormatOptionList(optionList);
                      setTemplateId("");
                      setDeliveryTemplateId("");
                    }}
                  />
                }
                label={t("ImportJobs:Multiline Format")}
              />
              <span style={{ marginLeft: "104px" }}>
                <FormControlLabel
                  value={`${formatType.SingleLine}`}
                  control={
                    <Radio
                      color="primary"
                      // value={defaultChecked}
                      onChange={(e: any) => {
                        // debugger;
                        setjobFormatType(e.target.value);
                        setFormatOptionList(SingleLineList);
                        setTemplateId("");
                        setDeliveryTemplateId("");
                      }}
                    />
                  }
                  label={t("ImportJobs:Singleline Format")}
                />
              </span>
            </RadioGroup>
          </Grid>
        )}
        {((jobformatType && workflow === 0) || workflow === 1) && (
          <>
            <Grid
              container
              className="Import__formatName"
              spacing={1}
              direction="row"
              // justify="center"
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={templateList ? 6 : 11}>
                <Input
                  control={control}
                  type="text"
                  label={t("ImportJobs:Format Name")}
                  value={formatName}
                  name="format-name"
                  onchange={(e: any) => {
                    setFormatName(e.target.value);
                  }}
                />
              </Grid>

              {/* <br></br>
                    <br></br> */}
            </Grid>
            <Grid
              container
              className="Import__formatName"
              spacing={1}
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              {/* <Grid item xs={12} sm={templateList ? 6 : 11}> */}
              {/* <Input
                  control={control}
                  type="text"
                  label={t("ImportJobs:Format Name")}
                  value={formatName}
                  name="format-name"
                  onchange={(e: any) => {
                    setFormatName(e.target.value);
                  }}
                /> */}
              {/* </Grid> */}
              {templateList ? (
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    labelName={t("ImportJobs:Select Pickup Template")}
                    itemList={templateList || []}
                    className={classes.dropdown}
                    value={templateId}
                    onClick={() =>
                      setFormatOptionList(
                        Number(jobformatType) === 1
                          ? defaultDropdownList
                          : SingleLineList
                      )
                    }
                    onChange={(e: any) => {
                      setTemplateId(e.target.value);
                      getPickupTemplateList(e.target.value);
                      // getTemplateFields(e.target.value);
                    }}
                    uniqueId="template_id"
                    selectedValue={templateId}
                    label="name"
                  ></Dropdown>
                </Grid>
              ) : (
                ""
              )}
              {templateList ? (
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    labelName={t("ImportJobs:Select Delivery Template")}
                    itemList={templateList || []}
                    className={classes.dropdown}
                    value={deliveryTemplateId}
                    onClick={() =>
                      setFormatOptionList(
                        Number(jobformatType) === 1
                          ? defaultDropdownList
                          : SingleLineList
                      )
                    }
                    onChange={(e: any) => {
                      setDeliveryTemplateId(e.target.value);
                      getDeliveryTemplateList(e.target.value);
                      // setTemplateId(e.target.value);
                      // getTemplateFields(e.target.value);
                    }}
                    uniqueId="template_id"
                    selectedValue={deliveryTemplateId}
                    label="name"
                  ></Dropdown>
                </Grid>
              ) : (
                ""
              )}
              {/* <br></br>
                    <br></br> */}
            </Grid>
          </>
        )}
      </Fragment>
    );
  };
  const getUpdateData = (body: any, opr: any) => {
    handleFormatSubmit(body, opr);
  };

  const getCheckboxStatus = (status: any, def?: any, body?: any) => {
    setSelectedRequestBody(body);
    setFormatChecked(status);
    {
      def === 1 || def === 2
        ? setDefaultCheck({ value: def, flag: true })
        : setDefaultCheck(false);
    }
  };

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return fileUpload();

      case 1:
        return (
          <FormatSelector
            getFormatList={getFormatList}
            databody={getUpdateData}
            templateList={templateList}
            addFormat={(step: any) => {
              setActiveStep(step);
            }}
            formatChecked={getCheckboxStatus}
            handleDeleteFormat={handleDeleteFormat}
          />
        );

      case 2:
        return FormatName();

      case 3:
        return AddFormat();
      case 4:
        return PickupAndDeliveryTemplate();
      default:
        return fileUpload();
    }
  }

  const checkPrefilledKeys = () => {
    let newListForTemplates: any = [],
      fileHeadersWithMap: any = [];
    for (let index = 0; index < fileHeaders.length; index++) {
      if (!fileHeaders[index].value) {
        newListForTemplates.push(fileHeaders[index]);
      } else {
        fileHeadersWithMap.push(fileHeaders[index]);
      }
    }
    setFileHeaderListForSubmit(fileHeadersWithMap);
    setPickupSheetList([...newListForTemplates]);
    setDeliverySheetList([...newListForTemplates]);
    // setPickupTemplateFieldList(newListForTemplates);
    // setDeliveryTemplateFieldList(newListForTemplates);
    setNewFilterList(newListForTemplates);
  };

  const handleNext = () => {
    let check = formatNameValidator(formatName);
    if (activeStep === 0 && !file) {
      toaster.addToast({
        message: t("ImportJobs:Please Upload a File"),
        timeout: 3000,
        type: "error",
      });
    } else if (activeStep === 2 && !formatName) {
      toaster.addToast({
        message: t("ImportJobs:Please Fill Format Name"),
        timeout: 3000,
        type: "error",
      });
    }
    //  else if (
    //   (activeStep === 2 && !deliveryTemplateId && templateList) ||
    //   (activeStep == 2 && !templateId && templateList)
    // ) {
    //   toaster.addToast({
    //     message: t(
    //       "ImportJobs:Please Select" +
    //         (!templateId ? " Pickup" : " Delivery") +
    //         " Template"
    //     ),
    //     timeout: 3000,
    //     type: "error",
    //   });
    // }
    else if (activeStep === 2 && !check) {
      toaster.addToast({
        message: t("ImportJobs:Please Fill Correct Format Name"),
        timeout: 3000,
        type: "error",
      });
    } else if (activeStep == 3) {
      let isMandateFieldsFilled = false;
      isMandateFieldsFilled = checkValidation();
      if (!isMandateFieldsFilled) {
        return;
      }
      if (isMandateFieldsFilled) {
        checkPrefilledKeys();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      handleFiles(file);
    }
    if (activeStep > 4) {
      openDialog(false);
    }
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setFormatChecked(false);
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setShowAddScreen(false);

    if (activeStep - 1 === 0) {
      handleReset();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormatChecked(false);
    setFileName("");
    setFormatId("");
    setFormatOptionList(optionList);
    setDefaultDropdownList(optionList);
    setTemplateId("");
    setFile("");
    setFormatName("");
    setjobFormatType("");
  };

  const checkValidation = () => {
    for (let j = 0; j < formatOptionList.length; j++) {
      let is_found = false;

      if (fileHeaders?.length) {
        for (let i = 0; i < fileHeaders.length; i++) {
          if (formatOptionList[j].is_required) {
            if (formatOptionList[j].key === fileHeaders[i].value) {
              is_found = true;
            }
          }
        }
      }
      if (!is_found && formatOptionList[j].is_required) {
        toaster.addToast({
          message: `${formatOptionList[j].option} must be mapped`,
          timeout: 3000,
          type: "error",
        });
        return false;
      }
    }

    return true;
  };

  const handleFormatSubmit = async (body?: any, opr?: any) => {
    // debugger;
    let validate = true;
    // if (opr !== 1) {
    //   validate = checkValidation();
    // }
    let jobMapping: any = {};
    let templateMapping: any = {};
    let pickupTemplateObj: any = {},
      deliveryTemplateObj: any = {};
    let mapping: any = {};
    if (opr === 1) {
    } else {
      for (var i = 0; i < fileHeaderListForSubmit.length; i++) {
        if (fileHeaderListForSubmit[i].isTemplate === 1) {
          templateMapping[fileHeaderListForSubmit[i].key] =
            fileHeaderListForSubmit[i].value;
        } else {
          jobMapping[fileHeaderListForSubmit[i].key] =
            fileHeaderListForSubmit[i].value;
        }
      }
      if (templateId) {
        for (let index = 0; index < pickupSheetList.length; index++) {
          if (pickupSheetList[index]?.isTemplate == 1)
            pickupTemplateObj[pickupSheetList[index]?.key] =
              pickupSheetList[index].value;
        }
      }

      if (deliveryTemplateId) {
        for (let index = 0; index < deliverySheetList.length; index++) {
          if (deliverySheetList[index]?.isTemplate == 1)
            deliveryTemplateObj[deliverySheetList[index]?.key] =
              deliverySheetList[index].value;
        }
      }
    }
    let templateMappingObject: any = {};
    if (deliveryTemplateId && templateId) {
      templateMappingObject = {
        pickupTemplate: pickupTemplateObj,
        deliveryTemplate: deliveryTemplateObj,
      };
    } else if (deliveryTemplateId) {
      templateMappingObject = {
        deliveryTemplate: deliveryTemplateObj,
      };
    } else if (templateId) {
      templateMappingObject = {
        pickupTemplate: pickupTemplateObj,
      };
    }
    mapping["jobMapping"] = jobMapping;
    // if (deliveryTemplateId || templateId) {
    mapping["templateMapping"] = templateMappingObject;
    // }
    let headersAPI = {
      access_token: access_token,
    };
    let request: any = {
      name: formatName,
      mapping: mapping,
      workflow_type: workflow,
    };
    let templatesObject = {};
    if (deliveryTemplateId && templateId) {
      templatesObject = {
        pickup_template: templateId,
        delivery_template: deliveryTemplateId,
      };
    } else if (deliveryTemplateId) {
      templatesObject = {
        delivery_template: deliveryTemplateId,
      };
    } else if (templateId) {
      templatesObject = {
        pickup_template: templateId,
      };
    }
    if (templateId || deliveryTemplateId) {
      request.template = templatesObject;
    }
    if (jobformatType) {
      let formatType: any = Number(jobformatType);
      request.format_type = formatType;
    }
    if (workflow === 1) {
      request.format_type = 1;
    }

    let reqData = {
      reqBody: { ...(opr === 1 ? body : request) },
      headers: headersAPI,
    };

    if ((opr !== 1 && validate) || (opr === 1 && !validate)) {
      return await ApiService[opr === 1 ? "put" : "post"](
        ImportExportService.createFormat(reqData, opr)
      )
        .then((response: any) => {
          if (opr === 1) {
            toaster.addToast({
              message: response.message,
              timeout: 3000,
              type: "success",
            });
          }

          if (opr !== 1) {
            postFile({
              id: response.data.id,
              format_type: Number(jobformatType),
            });
            openDialog(false);
          }
        })
        .catch((err: any) => {
          toaster.addToast({
            message: err.message,
            timeout: 3000,
            type: "error",
          });
        });
    }
  };
  const handleSelectedsubmit = () => {
    if (defaultCheck?.flag) {
      postFile();
    } else {
      postFile(selectedReqBody);
    }
  };

  const postFile = (params?: any) => {
    const formData = new FormData();
    formData.append("file", file);
    if (defaultCheck?.flag && workflow === 0) {
      formData.append("format_type", defaultCheck.value);
    }

    // if (jobformatType) {
    //   let formatType: any = Number(jobformatType);
    //   formData.append("format_type", formatType);
    // }

    if (params !== undefined) {
      formData.append("id", params.id);
      if (workflow === 0) {
        formData.append("format_type", params.format_type);
      }
    }
    if (workflow === 1) {
      let formatType: any = 1;
      formData.append("format_type", formatType);
    }
    let headersAPI = {
      access_token: access_token,
    };
    formData.append("workflow_type", workflow);
    const reqBody = {
      reqBody: formData,
      headers: headersAPI,
    };
    const res = ApiService.putFormData(ImportExportService.importJob(reqBody))
      .then((response: any) => {
        toaster.addToast({
          message: t(`ImportJobs:Import is in progress.
        The status report will be mailed to you once the import is done`),
          timeout: 5000,
          type: "success",
        });

        openDialog(false);
        handleReset();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 5000,
          type: "error",
        });
      });
  };
  const handleSelectTemplateFields = () => {};
  return (
    <Fragment>
      <MyDialog {...dialogProps} title={t("ImportJobs:Import Jobs")}>
        <div
          className={classes.backButton}
          hidden={activeStep === 0 ? true : false}
        >
          <ArrowBackIcon
            onClick={handleBack}
            style={{ fontSize: "1.8rem" }}
          ></ArrowBackIcon>
        </div>
        <div className="Import">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            <Typography component={"span"} className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
            <div
              style={
                steps.length - 1 === activeStep
                  ? {
                      width: "100%",
                      paddingTop: "10px",
                    }
                  : {}
              }
            >
              {/* <Btn
                type={"submit"}
                onClick={handleBack}
                color={"default"}
                className={classes.backButton}
                variant={activeStep === 0 ? "disabled" : ""}
                btnText={t("ImportJobs:Back")}
              ></Btn> */}
              {activeStep === 1 ? (
                <span style={{ marginRight: "1rem" }}>
                  <Btn
                    btnText={t("ImportJobs:Add Format")}
                    onClick={(e: any) => {
                      e.preventDefault();
                      setActiveStep(2);
                      getCheckboxStatus(false);
                    }}
                    type="button"
                  />
                </span>
              ) : (
                ""
              )}
              {!formatChecked ? (
                activeStep !== 1 ? (
                  <Btn
                    variant="contained"
                    btnText={
                      activeStep === steps.length - 1
                        ? t("ImportJobs:Upload")
                        : t("ImportJobs:Next")
                    }
                    type={"submit"}
                    color="primary"
                    disable={
                      activeStep === 1 && !showSelectScreen ? true : false
                    }
                    onClick={
                      activeStep === steps.length - 1
                        ? handleFormatSubmit
                        : handleNext
                    }
                  ></Btn>
                ) : (
                  ""
                )
              ) : (
                <Btn
                  variant="contained"
                  btnText={
                    activeStep === 1 && formatChecked
                      ? t("ImportJobs:Upload")
                      : t("ImportJobs:Next")
                  }
                  type={"submit"}
                  color="primary"
                  onClick={
                    activeStep === 1 && formatChecked
                      ? handleSelectedsubmit
                      : handleNext
                  }
                ></Btn>
              )}
              {/* {activeStep == 3 ? (
                <Btn
                  variant="contained"
                  btnText={"Select Template Fields"}
                  type={"submit"}
                  color="primary"
                  onClick={() => {
                    handleSelectTemplateFields();
                  }}
                ></Btn>
              ) : null} */}
            </div>
          </div>
        </div>
      </MyDialog>
      <Suspense fallback={<h3>Loading&hellip;</h3>}>
        <AlertDialog
          ref={deleteFormatRef}
          description={t(
            "ImportJobs:Are you sure you want to delete this Format?"
          )}
          title={t("ImportJobs:Delete Format")}
          confirm={t("Common:Confirm")}
          discard={t("Common:Discard")}
          onConfirm={onDeleteConfirm}
          onDiscard={onDeleteDiscard}
        ></AlertDialog>
      </Suspense>
    </Fragment>
  );
});
export default Import;
