import React, { useState } from "react";
import { Grid, Switch } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Dropdown from "../../../components/Dropdown/Dropdown";
import Input from "../../../components/Input/Input";
import Tooltip from "../../../components/Tooltip/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import MaterialAutocomplete from "../../../components/MaterialAutocomplete/MaterialAutocomplete";

const jobTypeConstants = [
  {
    key: "Pickup & Delivery",
    value: 0,
  },
  {
    key: "Pickup",
    value: 1,
  },
  {
    key: "Delivery",
    value: 2,
  },
];
const locationConstants = [
  {
    key: "Home Location",
    value: 1,
  },
  {
    key: "Team Location",
    value: 2,
  },
  {
    key: "Current Location",
    value: 3,
  },
];
const useStyles = makeStyles((theme) => ({
  dropdown: {
    textAlign: "left",
  },
}));
const AutoRoutingConfig = ({
  teamlist,
  getTeams,
  setOtherReqItems,
  operation,
  otherReqItems,
  autoRoutingData,
  setAutoRoutingData,
  defaultData,
}: any) => {
  const [teamDropdownData, setTeamDropdownData] = useState([]);
  const [teamId, setTeamID]: any = useState();
  const [loading, setIsLoading]: any = useState(true);
  const classes = useStyles();
  const [localInputData, setLocalInputdata] = useState<any>();

  const { t, i18n } = useTranslation(["Common", "Extensions"]);

  React.useEffect(() => {
    if (autoRoutingData) {
      setOtherReqItems({
        ...otherReqItems,
        data: autoRoutingData?.data,
      });
    }
    if (operation != "edit") {
      setIsLoading(false);
    }
  }, [autoRoutingData]);

  const onSwitchChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setAutoRoutingData((previousData: any) => ({
      ...previousData,
      shouldDisplay: event.target.checked,
      data: {
        ...autoRoutingData.data,
        auto_routing: event.target.checked,
      },
    }));

    return;
  };

  React.useEffect(() => {
    if (defaultData && operation === "edit") {
      if (!defaultData.auto_routing) {
        setIsLoading(false);
      }
      // setTeamID(teamDropdownData[defaultData.team_id]);
      if (teamlist.length > 0) {
        let index = teamlist.findIndex(
          (index: any) => index.team_id === defaultData.team_id
        );

        if (index > -1) {
          setTeamID(teamlist[index]);
        } else {
          setTeamID({});
        }
        setAutoRoutingData((previousData: any) => ({
          data: { ...defaultData },
          shouldDisplay: true,
        }));
        setIsLoading(false);
      }
    } else {
      setAutoRoutingData({ data: {}, shouldDisplay: false });
    }
  }, [defaultData, teamlist]);

  return (
    <>
      <Grid container>
        <span style={{ padding: "8px" }}>Auto Routing? </span>
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip
            direction={"right"}
            style={{
              width: "200px",
              "white-space": "break-spaces",
              backgroundColor: "rgba(97, 97, 97, 0.92)",
              marginBottom: "-8px",
              textAlign: "left",
            }}
            content={`After enabling this toggle, route optimization would be triggered automatically, every time the number of assigned jobs reaches the defined limit.`}
          >
            <InfoIcon />
          </Tooltip>
          <Switch
            onChange={onSwitchChangeHandler}
            checked={autoRoutingData.data.auto_routing ? true : false}
            color="primary"
          />
        </span>

        {autoRoutingData.data.auto_routing == 1 && !loading ? (
          <Grid
            container
            direction="row"
            spacing={2}
            style={{ marginTop: "5px" }}
          >
            <Grid item xs={6}>
              <Dropdown
                labelName={t("Extentsions:Job Type")}
                itemList={jobTypeConstants}
                className={classes.dropdown}
                onChange={(event: any) => {
                  setAutoRoutingData({
                    ...autoRoutingData,
                    data: {
                      ...autoRoutingData.data,
                      job_type: event.target.value,
                    },
                  });
                }}
                uniqueId="value"
                label="key"
                selectedValue={
                  autoRoutingData.data.job_type != undefined &&
                  autoRoutingData.data.job_type != null
                    ? autoRoutingData.data.job_type
                    : operation === "edit" && defaultData?.job_type
                    ? defaultData?.job_type
                    : ""
                }
              />
            </Grid>

            <Grid item xs={6} style={{ marginTop: "-15px" }}>
              <MaterialAutocomplete
                style={{ marginTop: "0px" }}
                isMultiple={false}
                freeSolo={false}
                disablePortal={false}
                size="medium"
                id={"recurring-outlined"}
                options={teamlist}
                defaultValue={{ team_name: teamId?.team_name }}
                onChange={(event: any, newValue: any) => {
                  setAutoRoutingData({
                    ...autoRoutingData,
                    data: {
                      ...autoRoutingData.data,
                      team_id: newValue,
                    },
                  });
                  setTeamID(newValue);
                }}
                rerender={loading}
                labelName="team_name"
                getOptionLabel={(option: any) => option["team_name"]}
                value={teamId}
                filterSelectedOptions
                onKeyDown={() => {}}
                textFieldLabel={t("Common:Select Team")}
                textFieldMargin={"normal"}
                textFieldFullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                labelName={t("Extentsions:Start Location")}
                itemList={locationConstants}
                onChange={(event: any) =>
                  setAutoRoutingData({
                    ...autoRoutingData,
                    data: {
                      ...autoRoutingData.data,
                      start_location: event.target.value,
                    },
                  })
                }
                uniqueId="value"
                className={classes.dropdown}
                label="key"
                selectedValue={
                  autoRoutingData.data.start_location
                    ? autoRoutingData.data.start_location
                    : operation === "edit" && defaultData?.start_location
                    ? defaultData?.start_location
                    : ""
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Dropdown
                labelName={t("Extentsions:End Location")}
                itemList={locationConstants}
                onChange={(event: any) => {
                  // console.log("event -> ", event);
                  setAutoRoutingData({
                    ...autoRoutingData,
                    data: {
                      ...autoRoutingData.data,
                      end_location: event.target.value,
                    },
                  });
                }}
                style={{ textAlign: "left" }}
                uniqueId="value"
                className={classes.dropdown}
                label="key"
                selectedValue={
                  autoRoutingData.data.end_location
                    ? autoRoutingData.data.end_location
                    : operation === "edit" && defaultData?.end_location
                    ? defaultData?.end_location
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                type="text"
                onkeypress={() => {}}
                onchange={(event: any) =>
                  setAutoRoutingData({
                    ...autoRoutingData,
                    data: {
                      ...autoRoutingData.data,
                      min_jobs_to_route: event.target.value,
                    },
                  })
                }
                id=""
                name=""
                label={t("Extension:Min. jobs to route")}
                value={
                  operation === "edit" && defaultData
                    ? defaultData.min_jobs_to_route
                    : null
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                type="text"
                onkeypress={() => {}}
                onchange={(event: any) =>
                  setAutoRoutingData({
                    ...autoRoutingData,
                    data: {
                      ...autoRoutingData.data,
                      capacity: event.target.value,
                    },
                  })
                }
                id=""
                name=""
                label={t("Extension:Max. Jobs Per Agent")}
                value={
                  operation === "edit" && defaultData
                    ? defaultData.capacity
                    : null
                }
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default AutoRoutingConfig;
