import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppContext } from "../../../AppContext/App.context";
import useToast from "../../../components/Toast/hooks/useToast";
import NoRecord from "../../../components/NoRecord/NoRecord";
import useApiService from "../../../services/api.service";
import RuleListAction from "../../../components/Datatable/DatatableActionButton";
import { useStyles } from "../Earning-Pricing/EarningPricingStyles";
import AlertDialog from "../../../components/Dialog/Dialog";
import headerDropdownService from "../../../services/headerDropdown.service";
import { themeColor } from "../../../constants/themeColor";
import useLocalStorage from "../../../hooks/localStorage";
import DataLoadingInProgress from "../../../components/DataLoadingInProgress/DataLoadingInProgress";
import useLoader from "../../../hooks/useLoader";
import CreateSubscriptionPlan from "./CreateSubscriptionPlan";
import CustomerSubscriptionEndpoints from "./CustomerSubscription.endpoints";
import Subscribers from "./Subscribers";

const CustomerSubscriptionsList = () => {
  const { t, i18n } = useTranslation(["EarningPricing", "Common"]);
  const history = useHistory();
  const [, setShown]: any = useState(true);
  const classes = useStyles();

  const ApiService = useApiService();
  const [appData]: any = useAppContext();
  const [activeTab, setActiveTab]: any = useState(0);

  const access_token = appData.loginData.access_token;
  const toaster = useToast();
  const [subscriptionPlanId, setSubscriptionPlanId]: any = useState();
  const [enpDetailsFetched, updateEnpDetailsFetched] = useState(false);
  const [delayedRender, setDelayedRender] = useState<boolean>(false);
  const [earningRules, setEarningRules]: any = useState([]);
  const deleteRuleRef: any = useRef();
  let [count, setCount]: any = useState();
  const Permission: any = useLocalStorage.getItem("Permission");
  const loader: any = useLoader();

  let params: any = useParams();

  useEffect(() => {
    if (params.type === "details") {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
    setTimeout(() => {
      setDelayedRender(true);
    }, 1000);
    // setTabType(0);
    // history.push("/extensions/scheduled/payments/rules")
  });

  useEffect(() => {
    let headerSubscription = headerDropdownService.subscribe(eventFromService);
    setShown(false);
    return () => {
      headerSubscription.unsubscribe();
    };
  }, []);
  useEffect(() => {
    getSubscriptionRule();
  }, []);
  const eventFromService = (eventData: any) => {
    switch (eventData.type) {
      case "clickOnAddPlan":
        openAddPlan();
        break;
      default:
        break;
    }
  };

  const addPlanRef: any = useRef();
  const openAddPlan = (subscriptionDetails?: any) => {
    if (subscriptionDetails) {
      addPlanRef.current.openAddTeamPopup("edit", subscriptionDetails);
    } else {
      addPlanRef.current.openAddTeamPopup("add");
    }
  };

  const getSubscriptionRule = async (subscriptionId?: any) => {
    let headers = {
      access_token: access_token,
    };
    let queryParam = {
      subscription_id: subscriptionId,
      skip: 0,
      limit: 1000,
    };
    if (!subscriptionId) {
      delete queryParam.subscription_id;
    }
    let reqData = {
      reqBody: queryParam,
      headers: headers,
    };
    // loader.showLoader();
    return await ApiService.get(
      CustomerSubscriptionEndpoints.getSubscriptionRules(reqData)
    )
      .then((res: any) => {
        if (subscriptionId) {
          return res.data.data[0];
        } else {
          setEarningRules(res.data.data);
          updateEnpDetailsFetched(true);
        }
        // loader.hideLoader();
      })
      .catch((error: any) => {
        // loader.hideLoader();
        toaster.addToast({
          message: error.message,
          timeout: 4000,
          type: "error",
        });
      });
  };
  const openRulesEdit = async (id: any) => {
    let subscriptionId = id;
    const subscriptionDetails = await getSubscriptionRule(subscriptionId);
    openAddPlan(subscriptionDetails);
  };
  const deleteRule = (row: any) => {
    setSubscriptionPlanId(row);
    deleteRuleRef.current.handleClickOpen();
  };
  const onDeleteDiscard = () => {
    deleteRuleRef.current.handleClose();
  };
  const onDeleteConfirm = () => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams = {
      subscription_id: subscriptionPlanId,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    loader.showLoader();
    ApiService.put(
      CustomerSubscriptionEndpoints.removeSubscriptionRule(reqData)
    )
      .then((response: any) => {
        toaster.addToast({
          message: response.message,
          timeout: 4000,
          type: "success",
        });
        setCount(++count);
        deleteRuleRef.current.handleClose();
        getSubscriptionRule();
        loader.hideLoader();
      })
      .catch((error: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: error.message,
          timeout: 4000,
          type: "error",
        });
      });
  };
  let actionList: any = [
    {
      name: t("Common:Edit"),
      actionHandler: openRulesEdit,
    },
    {
      name: t("Common:Delete"),
      actionHandler: deleteRule,
    },
  ];
  const tabWidths = ["50px", "100px"];

  const handleTabChange = (event: any, newValue: any) => {
    if (newValue == 0) {
      setActiveTab(0);
      history.push("/extensions/subscription/plans");
    } else if (newValue == 1) {
      setActiveTab(1);
      history.push("/extensions/subscription/details");
    }
  };

  return (
    <>
      <CreateSubscriptionPlan ref={addPlanRef} getApi={getSubscriptionRule} />
      <Grid container className="Earning__listView">
        <Grid item xs={11}></Grid>
        <div
          style={{
            backgroundColor: themeColor.defaultBackgroundColor,
            width: "100%",
            minHeight: "84vh",
          }}
        >
          {
            <>
              <Typography className={`${classes.listHeading} paragraphText`}>
                <h3>Customer Subscription</h3>
                {t(
                  `Create and manage the Subscription plans for your customers.`
                )}
              </Typography>
            </>
          }
          {delayedRender && (
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              className={classes.tabs}
              textColor="primary"
              aria-label="Enp Tabs"
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  height: "3px",
                  width: tabWidths[activeTab],
                  marginLeft: activeTab === 0 ? "15px" : "30px",
                },
              }}
            >
              <Tab
                style={{ minWidth: "85px" }}
                className={classes.muiTabRoot}
                label={
                  <span style={{ position: "absolute" }}>
                    {t("TaskDetail:Plans")}{" "}
                  </span>
                }
              />
              <Tab
                className={classes.muiTabRoot}
                label={t("TaskDetail:Subscribers")}
              />
            </Tabs>
          )}

          {activeTab == 0 && (
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              {earningRules.length > 0 ? (
                <Grid container style={{ padding: "1rem" }}>
                  {earningRules &&
                    earningRules.map((data: any, index: any) => (
                      <>
                        <Grid
                          item
                          xs={2}
                          lg={3}
                          md={4}
                          sm={6}
                          style={{ marginBottom: "0.8rem" }}
                        >
                          <Card className={classes.rootCard}>
                            <CardHeader
                              style={{
                                paddingBottom: "10px",
                                paddingTop: "10px",
                              }}
                              action={
                                <RuleListAction
                                  row={data.subscription_id}
                                  actionList={actionList}
                                />
                              }
                              classes={{
                                title: classes.headerTitle,
                              }}
                              // title={t(`Common:${index.rule_name}`)}
                              title={<span>{data.plan_name}</span>}
                            />
                            <CardContent
                              classes={{
                                root: classes.cardContentRoot,
                              }}
                            >
                              <span>
                                {appData.loginData.currency
                                  ? appData.loginData.currency
                                  : "$"}{" "}
                                {data.amount}
                              </span>
                            </CardContent>
                          </Card>
                        </Grid>
                      </>
                    ))}
                </Grid>
              ) : enpDetailsFetched ? (
                <>
                  <Typography style={{ marginLeft: "1.3rem", color: "grey" }}>
                    <NoRecord />
                  </Typography>
                </>
              ) : (
                <Grid container justify="center">
                  <DataLoadingInProgress />
                </Grid>
              )}
            </Grid>
          )}

          {activeTab == 1 && <Subscribers />}
        </div>
      </Grid>
      <Suspense fallback={<h3>Loading&hellip;</h3>}>
        <AlertDialog
          ref={deleteRuleRef}
          description={t(
            "EarningPricing:Are you sure you want to delete this Rule?"
          )}
          title={t("EarningPricing:Delete Earning Rule")}
          confirm={t("Common:Confirm")}
          discard={t("Common:Discard")}
          onConfirm={onDeleteConfirm}
          onDiscard={onDeleteDiscard}
        ></AlertDialog>
      </Suspense>
    </>
  );
};

export default CustomerSubscriptionsList;
