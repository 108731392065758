import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import Header from "../Header/Header";
import "./TaskDetailTabs.scss";
import TemplateInfo from "./TemplateInfo";
import DateTimeFormatter, {
  convertDateTimeFormatToTimeDateString,
} from "../../../utils/DateFormatter";
import { JobStatusValueFromName } from "../../../enum/JobStatus.enum";
import Rating from "@material-ui/lab/Rating";
import { jobStatusData } from "../../../constants/jobStatusData";
import { paymentModes, paymentTypes } from "../../../constants/paymentData";
import useApiService from "../../../services/api.service";
import { TaskDetail } from "../TaskDetail.endpoints";
import { useAppContext } from "../../../AppContext/App.context";
import { Link } from "react-router-dom";
import { themeColor } from "../../../constants/themeColor";
import DistanceFormatter from "../../../utils/distanceFormatter";
import { extensionList } from "../../../constants/extensionList";
import { extensionService } from "../../../containers/Extensions/Extensions.endpoints";
import ProductDetail from "./ProductDetail";

const TaskDetailInfo = (props: any) => {
  const { t, i18n } = useTranslation(["TaskDetail", "Common"]);
  const ApiService = useApiService();
  const taskDetail = props.taskDetail;
  const activatedExtension = localStorage.getItem("activatedExtension");
  const [unit, setUnit]: any = useState();
  const [appData]: any = useAppContext();
  const [trackingToken, setTrackingToken]: any = useState();
  const access_token = appData.loginData.access_token;
  useEffect(() => {
    if (activatedExtension?.includes(extensionList.capacityManagement.id)) {
      getExtensionDetail();
    }
  }, []);
  const getExtensionDetail = async () => {
    let headers = {
      access_token: access_token,
    };
    let request: any = {
      extension_id: extensionList.capacityManagement.id,
    };
    let reqData = {
      reqBody: request,
      headers: headers,
    };
    const res: any = await ApiService.get(
      extensionService.getExtensionsDetails(reqData)
    );
    setUnit(res.data[0]?.config?.unit);
  };
  useEffect(() => {}, []);
  const getTrackingToken = (task_id: any, job_id: any) => {
    let headers = {
      access_token: access_token,
    };

    let qureyParams = {
      task_id: task_id,
      job_id: job_id,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    ApiService.get(TaskDetail.getTrackingToken(reqData))
      .then((res: any) => {
        window.open(window.location.origin + "/tracking/" + res.data, "_blank");
      })

      .catch(() => {});
  };
  const displayTags = () => {
    let tags = "";
    tags = taskDetail.tags[0].tag_name;

    taskDetail.tags.map((item: any, i: any) => {
      if (i > 0) {
        tags = tags + "," + item.tag_name;
      }
    });

    return tags;
  };

  const earningPricing =
    props.taskDetail.enp && Object.keys(props.taskDetail.enp).length > 0
      ? [props.taskDetail.enp[0], props.taskDetail.enp[1]]
      : [];
  return (
    <Fragment>
      <div
        hidden={props.index !== props.value}
        className={"task-detail-tabs__info"}
      >
        <div className={"task-detail-tabs__info__basic-detail"}>
          <div className={"task-detail-tabs__info__basic-detail__key-name"}>
            {t("Common:Status")}
          </div>
          <div className={"task-detail-tabs__info__basic-detail__key-value"}>
            {t("Common:" + jobStatusData[taskDetail.task_status]?.name)}
          </div>
        </div>
        {taskDetail.task_type === 2 ? (
          <div>
            <div className={"task-detail-tabs__info__basic-detail"}>
              <div className={"task-detail-tabs__info__basic-detail__key-name"}>
                {t("TaskDetail:Start Time")}
              </div>
              <div
                className={"task-detail-tabs__info__basic-detail__key-value"}
              >
                {DateTimeFormatter.checkIfTimeInTz(taskDetail.local_datetime)
                  ? DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
                      taskDetail.local_datetime
                    )
                  : convertDateTimeFormatToTimeDateString(
                      taskDetail.local_datetime
                    )}
              </div>
            </div>
            <div className={"task-detail-tabs__info__basic-detail"}>
              <div className={"task-detail-tabs__info__basic-detail__key-name"}>
                {t("TaskDetail:End Time")}
              </div>
              <div
                className={"task-detail-tabs__info__basic-detail__key-value"}
              >
                {DateTimeFormatter.checkIfTimeInTz(taskDetail.end_datetime)
                  ? DateTimeFormatter.convertDateTimeToSpecificFormat(
                      taskDetail.end_datetime
                    )
                  : convertDateTimeFormatToTimeDateString(
                      taskDetail.end_datetime
                    )}
              </div>
            </div>
          </div>
        ) : (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("TaskDetail:Task Time")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {DateTimeFormatter.checkIfTimeInTz(taskDetail.local_datetime)
                ? DateTimeFormatter.convertDateTimeToSpecificFormat(
                    taskDetail.local_datetime
                  )
                : convertDateTimeFormatToTimeDateString(
                    taskDetail.local_datetime
                  )}
            </div>
          </div>
        )}
        <div className={"task-detail-tabs__info__basic-detail"}>
          <div className={"task-detail-tabs__info__basic-detail__key-name"}>
            {t("TaskDetail:Address")}
          </div>
          <div className={"task-detail-tabs__info__basic-detail__key-value"}>
            {taskDetail.location}
          </div>
        </div>
        <div className={"task-detail-tabs__info__basic-detail"}>
          <div className={"task-detail-tabs__info__basic-detail__key-name"}>
            {t("Common:Reference ID")}
          </div>
          <div className={"task-detail-tabs__info__basic-detail__key-value"}>
            {taskDetail.reference_id || "-"}
          </div>
        </div>
        {activatedExtension?.includes(extensionList.capacityManagement.id) && (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("Common:Quantity")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {taskDetail?.capacity > 0
                ? taskDetail?.capacity
                : -taskDetail?.capacity}{" "}
              &nbsp;
              {unit ? unit : ""}
            </div>
          </div>
        )}

        <div className={"task-detail-tabs__info__basic-detail"}>
          <div className={"task-detail-tabs__info__basic-detail__key-name"}>
            {t("Common:Team")}
          </div>
          <div className={"task-detail-tabs__info__basic-detail__key-value"}>
            {taskDetail.team_name || "-"}
          </div>
        </div>
        <div className={"task-detail-tabs__info__basic-detail"}>
          <div className={"task-detail-tabs__info__basic-detail__key-name"}>
            {t("Common:Agent")}
          </div>
          <div className={"task-detail-tabs__info__basic-detail__key-value"}>
            {taskDetail.res_name || "-"}
          </div>
        </div>
        <div className={"task-detail-tabs__info__basic-detail"}>
          <div className={"task-detail-tabs__info__basic-detail__key-name"}>
            {t("Common:Tag")}
          </div>
          <div className={"task-detail-tabs__info__basic-detail__key-value"}>
            {taskDetail.tags && taskDetail.tags.length > 0
              ? displayTags()
              : "-"}
          </div>
        </div>

        <div className={"task-detail-tabs__info__basic-detail"}>
          <div className={"task-detail-tabs__info__basic-detail__key-name"}>
            {t("Common:Geofence")}
          </div>
          <div className={"task-detail-tabs__info__basic-detail__key-value"}>
            {taskDetail.geofence_name || "-"}
          </div>
        </div>
        {taskDetail.barcode && (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("Common:Barcode")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {taskDetail.barcode || "-"}
            </div>
          </div>
        )}
        {taskDetail.service_time ? (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("Common:Service Time")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {`${taskDetail.service_time} mins` || "-"}
            </div>
          </div>
        ) : (
          <> </>
        )}
        <div className={"task-detail-tabs__info__basic-detail"}>
          <div className={"task-detail-tabs__info__basic-detail__key-name"}>
            {t("Common:Name")}
          </div>
          <div className={"task-detail-tabs__info__basic-detail__key-value"}>
            {taskDetail.name || "-"}
          </div>
        </div>
        <div className={"task-detail-tabs__info__basic-detail"}>
          <div className={"task-detail-tabs__info__basic-detail__key-name"}>
            {t("Common:Phone no.")}
          </div>
          <div className={"task-detail-tabs__info__basic-detail__key-value"}>
            {taskDetail.country_code + taskDetail.phone}
          </div>
        </div>
        <div className={"task-detail-tabs__info__basic-detail"}>
          <div className={"task-detail-tabs__info__basic-detail__key-name"}>
            {t("Common:Email")}
          </div>
          <div className={"task-detail-tabs__info__basic-detail__key-value"}>
            {taskDetail.email || "-"}
          </div>
        </div>
        {taskDetail.otp ? (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("TaskDetail:OTP")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {taskDetail.otp}
            </div>
          </div>
        ) : null}
        <div className={"task-detail-tabs__info__basic-detail"}>
          <div className={"task-detail-tabs__info__basic-detail__key-name"}>
            {t("Common:Description")}
          </div>
          <div
            className={"task-detail-tabs__info__basic-detail__key-value"}
            dangerouslySetInnerHTML={{
              __html: taskDetail.task_description || " -",
            }}
          >
            {/* {taskDetail.task_description || " -"} */}
          </div>
        </div>
        <div className={"task-detail-tabs__info__basic-detail"}>
          <div className={"task-detail-tabs__info__basic-detail__key-name"}>
            {t("TaskDetail:Distance Travelled")}
          </div>
          <div className={"task-detail-tabs__info__basic-detail__key-value"}>
            {(taskDetail.distance_travelled / 1000).toFixed(2)}
            {" " + DistanceFormatter.getDistanceFormat()}
          </div>
        </div>
        {taskDetail.enp && taskDetail.enp.calculation ? (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("TaskDetail:Estimated Distance")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {taskDetail.enp.calculation[0]?.distance
                ? `${taskDetail.enp.calculation[0]?.distance}${
                    " " + DistanceFormatter.getDistanceFormat()
                  }`
                : taskDetail.enp.calculation[1]?.distance
                ? `${taskDetail.enp.calculation[1]?.distance}${
                    " " + DistanceFormatter.getDistanceFormat()
                  }`
                : "-"}
            </div>
          </div>
        ) : null}
        {/* {appData.loginData.workflow_type === 1 && taskDetail.enp && taskDetail.enp.calculation ?
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("TaskDetail:Time Duration")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {taskDetail.enp.calculation[0]?.time_duration || "-"}
            </div>
          </div> : null
        } */}

        {taskDetail?.plan_details &&
        activatedExtension?.includes(extensionList.customerSubscription.id) ? (
          <>
            <div className={"task-detail-tabs__info__basic-detail"}>
              <div className={"task-detail-tabs__info__basic-detail__key-name"}>
                <b>{t(`TaskDetail:${"Pricing"}`)}</b>
              </div>
            </div>
            <div className={"task-detail-tabs__info__basic-detail"}>
              <div className={"task-detail-tabs__info__basic-detail__key-name"}>
                {t("TaskDetail:Subscription")}
                <br></br>Plan
              </div>
              <div
                className={"task-detail-tabs__info__basic-detail__key-value"}
              >
                {taskDetail?.plan_details
                  ? taskDetail?.plan_details?.plan_name
                  : " -"}
              </div>
            </div>
            <div className={"task-detail-tabs__info__basic-detail"}>
              <div className={"task-detail-tabs__info__basic-detail__key-name"}>
                {t("TaskDetail:Amount")}
              </div>
              <div
                className={"task-detail-tabs__info__basic-detail__key-value"}
              >
                {taskDetail?.currency ? taskDetail?.currency : "$"}{" "}
                {taskDetail?.currency ? taskDetail?.currency : " -"}
              </div>
            </div>
            {taskDetail?.discount || taskDetail?.plan_details?.discount ? (
              <>
                <div className={"task-detail-tabs__info__basic-detail"}>
                  <div
                    className={"task-detail-tabs__info__basic-detail__key-name"}
                  >
                    {t("TaskDetail:Discount")}
                  </div>
                  <div
                    className={
                      "task-detail-tabs__info__basic-detail__key-value"
                    }
                  >
                    {"-"}
                    {taskDetail?.currency ? taskDetail?.currency : "$"}{" "}
                    {taskDetail?.plan_details
                      ? taskDetail?.plan_details?.discount?.toFixed(2)
                      : " -"}
                  </div>
                </div>
                <div className={"task-detail-tabs__info__basic-detail"}>
                  <div
                    className={"task-detail-tabs__info__basic-detail__key-name"}
                  >
                    {t("TaskDetail:Total Amount")}
                  </div>
                  <div
                    className={
                      "task-detail-tabs__info__basic-detail__key-value"
                    }
                  >
                    {taskDetail?.currency ? taskDetail?.currency : "$"}{" "}
                    {taskDetail.plan_details
                      ? (
                          taskDetail?.plan_details?.amount -
                          taskDetail?.plan_details?.discount
                        )?.toFixed(2)
                      : " -"}
                  </div>
                </div>
              </>
            ) : null}
          </>
        ) : null}
        {taskDetail?.enp ? (
          <>
            {earningPricing.map((data: any, index: any) => {
              return data ? (
                <>
                  <div className={"task-detail-tabs__info__basic-detail"}>
                    <div
                      className={
                        "task-detail-tabs__info__basic-detail__key-name"
                      }
                    >
                      <b>
                        {t(`TaskDetail:${index === 0 ? "Earning" : "Pricing"}`)}
                      </b>
                    </div>
                  </div>

                  {data?.geofence_pricing ? (
                    <div className={"task-detail-tabs__info__basic-detail"}>
                      <div
                        className={
                          "task-detail-tabs__info__basic-detail__key-name"
                        }
                      >
                        {t("TaskDetail:Base Cost")}
                      </div>
                      <div
                        className={
                          "task-detail-tabs__info__basic-detail__key-value"
                        }
                      >
                        {data.geofence_cost
                          ? `${
                              taskDetail?.currency ? taskDetail?.currency : "$"
                            }${data.geofence_cost}`
                          : " -"}
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* Distance Cost */}
                      <div className={"task-detail-tabs__info__basic-detail"}>
                        <div
                          className={
                            "task-detail-tabs__info__basic-detail__key-name"
                          }
                        >
                          {t("TaskDetail:Distance Cost")}
                        </div>
                        <div
                          className={
                            "task-detail-tabs__info__basic-detail__key-value"
                          }
                        >
                          {data.distance_cost
                            ? `${
                                taskDetail?.currency
                                  ? taskDetail?.currency
                                  : "$"
                              } ${data.distance_cost}`
                            : " -"}
                        </div>
                      </div>
                      {/* Time Cost */}
                      <div className={"task-detail-tabs__info__basic-detail"}>
                        <div
                          className={
                            "task-detail-tabs__info__basic-detail__key-name"
                          }
                        >
                          {t("TaskDetail:Time Cost")}
                        </div>
                        <div
                          className={
                            "task-detail-tabs__info__basic-detail__key-value"
                          }
                        >
                          {data.time_cost
                            ? `${
                                taskDetail?.currency
                                  ? taskDetail?.currency
                                  : "$"
                              } ${data.time_cost}`
                            : " -"}
                        </div>
                      </div>
                      {/* Waiting cost */}
                      <div className={"task-detail-tabs__info__basic-detail"}>
                        <div
                          className={
                            "task-detail-tabs__info__basic-detail__key-name"
                          }
                        >
                          {t("TaskDetail:Waiting Cost")}
                        </div>
                        <div
                          className={
                            "task-detail-tabs__info__basic-detail__key-value"
                          }
                        >
                          {data.waiting_cost
                            ? `${
                                taskDetail?.currency
                                  ? taskDetail?.currency
                                  : "$"
                              } ${data.waiting_cost}`
                            : " -"}
                        </div>
                      </div>
                    </>
                  )}
                  {/* Template Field Cost */}
                  <div className={"task-detail-tabs__info__basic-detail"}>
                    <div
                      className={
                        "task-detail-tabs__info__basic-detail__key-name"
                      }
                    >
                      {t("TaskDetail:Template Field Cost")}
                    </div>
                    <div
                      className={
                        "task-detail-tabs__info__basic-detail__key-value"
                      }
                    >
                      {data.template_fields_cost
                        ? `${
                            taskDetail?.currency ? taskDetail?.currency : "$"
                          } ${data.template_fields_cost}`
                        : " -"}
                    </div>
                  </div>
                  {/* Surge */}
                  <div className={"task-detail-tabs__info__basic-detail"}>
                    <div
                      className={
                        "task-detail-tabs__info__basic-detail__key-name"
                      }
                    >
                      {t("TaskDetail:Surge")}
                    </div>
                    <div
                      className={
                        "task-detail-tabs__info__basic-detail__key-value"
                      }
                    >
                      {data.surge ? `${data.surge}` : " -"}
                    </div>
                  </div>
                  {data.discount ? (
                    <div className={"task-detail-tabs__info__basic-detail"}>
                      <div
                        className={
                          "task-detail-tabs__info__basic-detail__key-name"
                        }
                      >
                        {t("TaskDetail:Discount")}
                      </div>
                      {
                        <div
                          className={
                            "task-detail-tabs__info__basic-detail__key-value"
                          }
                        >
                          {taskDetail?.currency ? taskDetail?.currency : "$"}
                          {data.discount ? data.discount : ""}
                        </div>
                      }
                    </div>
                  ) : null}
                  {/* Earnings/Pricing */}
                  <div className={"task-detail-tabs__info__basic-detail"}>
                    <div
                      className={
                        "task-detail-tabs__info__basic-detail__key-name"
                      }
                    >
                      {t(`TaskDetail:Subtotal`)}
                    </div>
                    <div
                      className={
                        "task-detail-tabs__info__basic-detail__key-value"
                      }
                    >
                      {data.earnings
                        ? `${
                            taskDetail?.currency ? taskDetail?.currency : "$"
                          } ${(data.earnings - data.discount).toFixed(2)}`
                        : " -"}
                    </div>
                  </div>
                  {/* Service tax */}
                  {data.service_tax ? (
                    <div className={"task-detail-tabs__info__basic-detail"}>
                      <div
                        className={
                          "task-detail-tabs__info__basic-detail__key-name"
                        }
                      >
                        {t("TaskDetail:Service Tax")}
                      </div>
                      <div
                        className={
                          "task-detail-tabs__info__basic-detail__key-value"
                        }
                      >
                        {data.service_tax
                          ? `${
                              taskDetail?.currency ? taskDetail?.currency : "$"
                            } ${data.service_tax}`
                          : " -"}
                      </div>
                    </div>
                  ) : null}
                  {/* GST */}
                  {data.gst ? (
                    <div className={"task-detail-tabs__info__basic-detail"}>
                      <div
                        className={
                          "task-detail-tabs__info__basic-detail__key-name"
                        }
                      >
                        {t("TaskDetail:VAT")}
                      </div>
                      <div
                        className={
                          "task-detail-tabs__info__basic-detail__key-value"
                        }
                      >
                        {data.gst
                          ? `${
                              taskDetail?.currency ? taskDetail?.currency : "$"
                            } ${data.gst}`
                          : " -"}
                      </div>
                    </div>
                  ) : null}
                  {/* Tax array */}
                  {data.tax &&
                    data.tax.map((value: any, index: any) => {
                      return (
                        <>
                          <div
                            className={"task-detail-tabs__info__basic-detail"}
                          >
                            <div
                              className={
                                "task-detail-tabs__info__basic-detail__key-name"
                              }
                            >
                              {t("TaskDetail:Tax Name")}
                            </div>
                            <div
                              className={
                                "task-detail-tabs__info__basic-detail__key-value"
                              }
                            >
                              {value.tax_name || " -"}
                            </div>
                          </div>
                          <div
                            className={"task-detail-tabs__info__basic-detail"}
                          >
                            <div
                              className={
                                "task-detail-tabs__info__basic-detail__key-name"
                              }
                            >
                              {t("TaskDetail:Tax Name")}
                            </div>
                            <div
                              className={
                                "task-detail-tabs__info__basic-detail__key-value"
                              }
                            >
                              {value.tax_amount || " -"}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  {/* Total */}

                  <div className={"task-detail-tabs__info__basic-detail"}>
                    <div
                      className={
                        "task-detail-tabs__info__basic-detail__key-name"
                      }
                    >
                      {t("TaskDetail:Total")}
                    </div>
                    {
                      <div
                        className={
                          "task-detail-tabs__info__basic-detail__key-value"
                        }
                      >
                        {`${
                          taskDetail?.currency ? taskDetail?.currency : "$"
                        } ${Number(
                          Math.round(
                            (Number(data.earnings) -
                              Number(data.discount) +
                              Number(data.gst) +
                              Number(data.service_tax)) *
                              100
                          ) / 100
                        )}` || " -"}
                      </div>
                    }
                  </div>
                </>
              ) : null;
            })}
          </>
        ) : (
          ""
        )}
        {taskDetail.payment_status || taskDetail.payment_status === 0 ? (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {`${t("TaskDetail:Payment Status")}`}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {taskDetail.payment_status === 0
                ? t("TaskDetail:Pending")
                : taskDetail.payment_status === 1
                ? t("TaskDetail:Paid")
                : t("TaskDetail:Failed")}
            </div>
          </div>
        ) : null}
        {taskDetail.payment_type ? (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("TaskDetail:Payment Type")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {taskDetail.payment_type && paymentTypes[taskDetail.payment_type]
                ? t(
                    `TaskDetail:${
                      paymentTypes[taskDetail.payment_type]["name"]
                    }`
                  )
                : " -"}
            </div>
          </div>
        ) : null}

        {taskDetail.payment_mode ? (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("TaskDetail:Payment Mode")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {taskDetail.payment_mode && paymentModes[taskDetail.payment_mode]
                ? t(
                    `TaskDetail:${
                      paymentModes[taskDetail.payment_mode]["name"]
                    }`
                  )
                : " -"}
            </div>
          </div>
        ) : null}
        {taskDetail.pricing != null && taskDetail.pricing !== undefined ? (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("TaskDetail:Earning")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {taskDetail.earnings
                ? `${taskDetail?.currency ? taskDetail?.currency : "$"} ${
                    taskDetail.earnings
                  }`
                : " -"}
            </div>
          </div>
        ) : null}
        {taskDetail.pricing != null && taskDetail.pricing !== undefined ? (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("TaskDetail:Pricing")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {taskDetail.pricing
                ? `${taskDetail?.currency ? taskDetail?.currency : "$"} ${
                    taskDetail.pricing
                  }`
                : " -"}
            </div>
          </div>
        ) : null}
        {taskDetail.task_status === JobStatusValueFromName.COMPLETED ? (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("TaskDetail:Rating")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {taskDetail.rating > 0 ? (
                <Rating
                  name="simple-controlled"
                  value={taskDetail.rating}
                  style={{
                    color: `${themeColor.primary.main}`,
                    fontSize: "17px",
                  }}
                  precision={0.5}
                  readOnly
                />
              ) : (
                " -"
              )}
            </div>
          </div>
        ) : null}
        {taskDetail.task_status === JobStatusValueFromName.COMPLETED ? (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("TaskDetail:Review")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              {taskDetail.comments || " -"}
            </div>
          </div>
        ) : null}

        {taskDetail.resource_id ? (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div className={"task-detail-tabs__info__basic-detail__key-name"}>
              {t("TaskDetail:Tracking Link")}
            </div>
            <div className={"task-detail-tabs__info__basic-detail__key-value"}>
              <span
                className="task-detail-tabs__info__basic-detail__trackinglink"
                onClick={() =>
                  getTrackingToken(taskDetail.task_id, taskDetail.job_id)
                }
              >
                {t("TaskDetail:Visit Tracking Page")}
              </span>
            </div>
          </div>
        ) : null}

        {taskDetail.template && taskDetail.template.template_id ? (
          <TemplateInfo taskDetail={taskDetail}></TemplateInfo>
        ) : null}
        {taskDetail?.product_details?.length > 0 && (
          <div className={"task-detail-tabs__info__basic-detail"}>
            <div
              className={"task-detail-tabs__info__basic-detail__key-name"}
              style={{ fontWeight: "bold" }}
            >
              {t("TaskDetail:Product Details")}
            </div>
          </div>
        )}

        {taskDetail?.product_details?.length > 0 ? (
          <ProductDetail taskDetail={taskDetail} />
        ) : null}
      </div>
    </Fragment>
  );
};
export default TaskDetailInfo;
