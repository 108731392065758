export const colorArrayObjectConst = {
  0: true,
  1: true,
  2: false,
  3: false,
  4: true,
  5: true,
  6: false,
  7: false,
  8: true,
  9: true,
  10: true,
  11: true,
  12: false,
  13: false,
  14: true,
  15: true,
  16: false,
  17: false,
  18: true,
  19: true,
  20: true,
  21: true,
  22: false,
  23: false,
  24: true,
  25: true,
  26: false,
  27: false,
  28: true,
  29: true,
  30: false,
};
