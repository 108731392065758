import React, { Fragment } from "react";
import classes from "./TaskDetail.module.scss";
import TaskDetailHeader from "./TaskDetailHeader";
import TaskDetailBody from "./TaskDetailTabs/TaskDetailBody";
// import Header from "../Header/Header";
const TaskDetailDataView = (props: any) => {
  const taskDetail = props.taskDetail;

  const getSelectedTab = (data: any) => {
    props.selectedTab(data)
  }


  return (
    <Fragment>
      <div className={classes["task-detail__data-view__container"]}>
        <div className={classes["task-detail__header"]}>
          <TaskDetailHeader
            openTaskDetailForSelectedTask={props.openTaskDetailForSelectedTask}
            onCloseTaskDetail={props.onCloseTaskDetail}
            taskDetail={taskDetail}
          ></TaskDetailHeader>
        </div>
        <TaskDetailBody taskDetail={taskDetail} selectedTab={getSelectedTab}></TaskDetailBody>
      </div>
    </Fragment>
  );
};
export default TaskDetailDataView;
