import io from "socket.io-client";
import { Subject } from "rxjs";
import useLocalStorage from "../hooks/localStorage";
import { taskHistoryTypes } from "../constants/taskHistoryTypes";

const subject = new Subject();
//
export const chatSocketSubject = {
  subscribe: (update: any) => subject.subscribe(update),
  unsubscribe: () => subject.unsubscribe(),
  emit: (socketData: any) => {
    subject.next(socketData);
  },
  // subject.unsubscribe();
};
export const socketToasterMessageSubject = {
  subscribe: (update: any) => subject.subscribe(update),
  unsubscribe: () => subject.unsubscribe(),
  emit: (socketData: any) => {
    subject.next(socketData);
  },
  // subject.unsubscribe();
};
let socket: any;
export const chatSocket = () => {
  // alert(2)
  let API_BASE_URL: any = process.env.REACT_APP_CHAT_SOCKET_URL;

  socket = socket = io(API_BASE_URL, {
    transports: ["websocket"],
    query: "access_token=" + useLocalStorage.getItem("loginData").access_token,
    // path:
    //   "/chatsocket.io/socket.io/?access_token=" +
    //   useLocalStorage.getItem("loginData").access_token,
  });

  socket.on("new_conversation", (res: any) => {
    console.log(res, "new conversation");
    chatSocketSubject.emit({ res: res, type: "new_conversation" });
    // subject.next(res);
  });

  socket.on("messageToClient", (res: any) => {
    console.log("messageTo client");
    console.log(res, "messageToClient");
    chatSocketSubject.emit({ res: res, type: "messageToClient" });
  });
  socket.on("supervisor_assigned", (res: any) => {
    console.log("supervisor_assigned");
    chatSocketSubject.emit({ res: res, type: "supervisor_assigned" });
  });

  // socket.emit("register_", {

  //   id: 3,
  // });
};
export const emitSocketEvents = {
  joinChatRoom: (id: any) => {
    socket.emit("joinConversation", {
      conversation_id: id,
    });
  },
  readMessage: (id: any) => {
    socket.emit("readMessage", {
      conversation_id: id,
      chat_type: 1,
    });
  },
  sendMessage: (socketObj: any) => {
    socket.emit("messageToServer", {
      conversation_id: socketObj.conversationId,
      text: socketObj.message,
      admin_id: socketObj.adminId,
      message_type: socketObj.messageType,
      resource_id: socketObj.resourceId,
      chat_type: 1,
    });
  },
};
const showSocketToaster = (res: any) => {
  showJobUpdationToaster(res);
};
const showJobUpdationToaster = (res: any) => {
  console.log(res);
  let toasterMessage = "Job ID: " + res.job_id + " ";
  if (res.task_id) {
    toasterMessage = "Task ID: " + res.task_id + " ";
  }

  toasterMessage = toasterMessage + taskHistoryTypes[res.type].comment;
  ShowToasterMessage(toasterMessage);
};
const ShowToasterMessage = (message: any) => {
  socketToasterMessageSubject.emit({
    message: message,
    type: "toasterMessage",
  });
  // const toastRight = useToastRight();
  // console.log(message);
  // toastRight.addToastRight({
  //   message: message,
  //   timeout: 3000,
  //   type: "success",
  // });
};
