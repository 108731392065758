import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    display: "flex!important",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    overflow: "scroll",
    top: "10%",
    height: "100%",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  screenHeading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
  backButton: {
    position: "absolute",
    top: "20px",
  },
  instructions: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  input: {
    width: "2rem!important",
  },
  tagInputField: {
    marginTop: "0px",
    marginBottom: "10px",
  },
  expandedAccordionSummary: {
    margin: "0px !important",
  },
  expanded: {},
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "550px",
    outline: "none",
    maxHeight: "80%",
    overflowY: "scroll",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
  },
}));
