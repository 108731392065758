import React, {
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
  Suspense,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from "@material-ui/core/styles";
import Btn from "../../components/Button/Button";
import PageHeading from "../../components/PageHeading/PageHeading";
import Input from "../../components/Input/Input";
import Autocomplete from "../../components/Autocomplete/Autocomplete";
import { useForm } from "react-hook-form";
import { ValidationService } from "../../utils/Validation";
import { useAppContext } from "../../AppContext/App.context";
import useLoader from "../../hooks/useLoader";
import useToast from "../../components/Toast/hooks/useToast";
import { extensionService } from "./Extensions.endpoints";
import useApiService from "../../services/api.service";
import { countryList, industryList } from "../../constants/stripeConnect";
import MaterialAutocomplete from "../../components/MaterialAutocomplete/MaterialAutocomplete";
import Dropdown from "../../components/Dropdown/Dropdown";
import AlertDialog from "../../components/Dialog/Dialog";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  textField: {
    marginTop: "4px!important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "500px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
    marginBottom: "-15px!important",
  },
}));
const StripeConnect = forwardRef((props: any, ref: any) => {
  const { t, i18n } = useTranslation(["Extension"]);
  const ApiService = useApiService();
  const [open, setOpen] = React.useState(false);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    errors,
  } = useForm();
  const classes = useStyles();
  const [currencyList, setCurrencyList]: any = useState([]);
  const [paymentFrequency, setPaymentFrequency]: any = useState();
  const [reRenderCurrency, updateReRenderCurrency] = useState(Math.random());
  const [defaultCountry, setdefaultCountry] = useState("");
  const [defaultCurrency, setdefaultCurrency]: any = useState("");
  const [defaultFrequency, setdefaultFrequency]: any = useState("");
  const [defaultCronDay, setdefaultCronDay]: any = useState("");
  const [cronDay, setcornDay]: any = useState();
  const updateConfigRef: any = useRef();
  const [stripeId, setStripeId] = useState("");
  const [configData, setConfigData]: any = useState({});
  const [appData]: any = useAppContext();
  const configFormDetails: any = useRef();
  const [operation, setOperation]: any = useState("add");
  const [step, updateStep] = useState(1);
  const loader: any = useLoader();
  const toaster = useToast();

  useImperativeHandle(ref, () => ({
    handleOpen(operation?: any, configData?: any) {
      updateStep(1);
      //initMap();
      setPaymentFrequency("");
      if (operation === 1) {
        setOperation("edit");
        if (configData) {
          resetConfigData(configData);
          setConfigData(configData);
        } else if (props.config) {
          resetConfigData(props.config);
          setConfigData(props.config);
        }
        setOpen(true);
      } else {
        resetForm();
        setOperation("add");
        setOpen(true);
      }

      // setType(type);
      //setHistoryDetail(history);
    },
  }));

  const resetForm = () => {
    reset({
      secretKey: "",
    });
    setCurrencyList([]);
    setdefaultCountry("");
    setdefaultCurrency("");
    setdefaultFrequency("");
    setdefaultCronDay("");
    setStripeId("");
  };

  const resetConfigData = (data: any) => {
    // console.log(data);
    let countryObj: any = countryList.find(
      (obj: any) => obj.code === data.country
    );
    setCurrencyList(countryObj?.currencies.split(","));
    setdefaultCountry(countryObj?.name);
    setdefaultCurrency(data?.currency);
    setStripeId(data?.stripe_extension_id);
    let paymentFrequencyobj = paymentFrequencyList.find(
      (obj: any) => obj.value === data.payment_frequency
    );
    setdefaultFrequency(paymentFrequencyobj?.name);
    setPaymentFrequency(paymentFrequencyobj);

    if (data.payment_frequency === 2) {
      let crondayobj = daysList.find(
        (obj: any) => obj.value === data?.cron_day
      );
      setdefaultCronDay(crondayobj?.name);
      setcornDay(crondayobj);
    }

    if (data.payment_frequency === 4) {
      let crondayobj = monthlyCronList.find(
        (obj: any) => obj.value === data?.cron_date
      );
      setdefaultCronDay(crondayobj?.name);
      setcornDay(crondayobj);
    }
    reset({
      secretKey: data.private_key,
    });
  };

  const paymentFrequencyList = [
    {
      name: "Instant",
      value: 0,
    },
    {
      name: "Daily",
      value: 1,
    },
    {
      name: "Weekly",
      value: 2,
    },
    {
      name: "Monthly",
      value: 4,
    },
    {
      name: "Manual",
      value: 3,
    },
  ];
  const monthlyCronList = [
    {
      name: "First day of month",
      value: 1,
    },
    {
      name: "Last day of month",
      value: 2,
    },
  ];
  const daysList = [
    {
      name: "Sunday",
      value: 0,
    },
    {
      name: "Monday",
      value: 1,
    },
    {
      name: "Tuesday",
      value: 2,
    },
    {
      name: "Wednesday",
      value: 3,
    },
    {
      name: "Thursday",
      value: 4,
    },
    {
      name: "Friday",
      value: 5,
    },
    {
      name: "Saturday",
      value: 6,
    },
  ];
  const handleClose = () => {
    setOpen(false);
  };
  const submitStripeConnectDetails = (data: any) => {
    let dataBody: any = {
      private_key: data.secretKey,
      // public_key: data.publishableKey
    };
    let countryObj: any = countryList.find(
      (obj: any) => obj.name === data.country
    );

    let request: any = {};

    if (operation != "edit") {
      request = {
        country: countryObj.code,
        currency: data.currency,
        payment_frequency: paymentFrequency?.value,
        data: dataBody,
      };
      if (paymentFrequency?.value === 2) {
        request.cron_day = cronDay.value;
      }
      if (paymentFrequency?.value === 4) {
        request.cron_date = cronDay.value;
      }
    } else {
      request = {
        stripe_extension_id: stripeId,
      };
    }
    let paymentFrequencyobj: any = paymentFrequencyList.find(
      (obj: any) => obj.value === configData.payment_frequency
    );
    if (paymentFrequency?.name != paymentFrequencyobj?.name) {
      request.payment_frequency = paymentFrequency.value;
    }
    if (paymentFrequency.value === 2) {
      let crondayobj: any = daysList.find(
        (obj: any) => obj.value === configData?.cron_day
      );

      if (data.day !== configData?.cron_day) {
        let crondayobjforAPIvalue: any = daysList.find(
          (obj: any) => obj.name === data.day
        );
        request.cron_day = crondayobjforAPIvalue?.value;
      }
    }

    if (paymentFrequency.value === 4) {
      let crondayobj: any = monthlyCronList.find(
        (obj: any) => obj.value === configData?.cron_date
      );

      if (data.day !== configData?.cron_date) {
        let crondateobjforAPIvalue: any = monthlyCronList.find(
          (obj: any) => obj.name === data.day
        );
        request.cron_date = crondateobjforAPIvalue?.value;
      }
    }

    if (dataBody.private_key != configData.private_key) {
      request.data = dataBody;
    }
    if (data.currency != configData.currency) {
      request.currency = data.currency;
    }
    if (operation === "edit") {
      let defcountryObj: any = countryList.find(
        (obj: any) => obj.code === configData.country
      );

      if (data.country != defcountryObj.name) {
        request.country = countryObj.code;
      }
    }

    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };

    loader.showLoader();
    return ApiService[operation === "edit" ? "put" : "post"](
      extensionService.stripeConnect(reqData, operation === "edit" ? 1 : 0)
    )
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        handleClose();
        props.handleStripeConnectActivation();
        props.fetchExtensionDetail(true);
        // updateConfigRef.current.handleClose();
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message.toString(),
          timeout: 3000,
          type: "error",
        });
      });
  };

  const obj = {
    publishableKey: {
      required: ValidationService.requiredValidator(),
    },
    secretKey: {
      required: ValidationService.requiredValidator(),
    },
    country: {
      required: ValidationService.requiredValidator(),
    },
    currency: {
      required: ValidationService.requiredValidator(),
    },
    frequency: {
      required: ValidationService.requiredValidator(),
    },
    industry: {
      required: ValidationService.requiredValidator(),
    },
    businessURL: {
      required: ValidationService.requiredValidator(),
    },
    day: {
      required: ValidationService.requiredValidator(),
    },
  };
  const onDiscard = () => {
    // updateConfigRef.current.handleClose();
  };
  const onConfirm = () => {
    submitStripeConnectDetails(configFormDetails.current.value);
  };
  const openConfirmation = (data: any) => {
    let updateValue: any = false;

    if (data.secretKey != configData.private_key) {
      updateValue = true;
    }
    let paymentFrequencyobj: any = paymentFrequencyList.find(
      (obj: any) => obj.value === configData.payment_frequency
    );
    if (paymentFrequency.name != paymentFrequencyobj.name) {
      updateValue = true;
    }

    if (paymentFrequency.value === 2) {
      let crondayobj: any = daysList.find(
        (obj: any) => obj.value === configData?.cron_day
      );

      if (data.day !== crondayobj?.name) {
        updateValue = true;
      }
    }
    if (paymentFrequency.value === 4) {
      let crondayobj: any = monthlyCronList.find(
        (obj: any) => obj.value === configData?.cron_date
      );

      if (data.day !== crondayobj?.name) {
        updateValue = true;
      }
    }

    if (data.currency != configData.currency) {
      updateValue = true;
    }
    if (operation === "edit") {
      let defcountryObj: any = countryList.find(
        (obj: any) => obj.code === configData.country
      );
      if (data.country != defcountryObj.name) {
        updateValue = true;
      }
    }

    if (!updateValue) {
      toaster.addToast({
        message: "Please update a value",
        timeout: 3000,
        type: "error",
      });

      return;
    }

    configFormDetails.current.value = data;
    updateStep(2);
    // updateConfigRef.current.handleClickOpen();
  };

  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} ref={configFormDetails}>
            {step === 1 ? (
              <form
                onSubmit={handleSubmit(
                  operation === "edit"
                    ? openConfirmation
                    : submitStripeConnectDetails
                )}
              >
                <h2 id="modal-title">
                  {(operation != "edit" ? "Add " : "Edit ") + "Details"}
                </h2>
                {/* <Input
                type="text"
                id="publishableKey"
                name="publishableKey"
                refValue={register(obj.publishableKey)}
                errors={errors}
                label={t("Extension:Publishable Key") + "*"}
              ></Input> */}
                <Input
                  type="text"
                  id="secretKey"
                  name="secretKey"
                  refValue={register(obj.secretKey)}
                  errors={errors}
                  label={t("Extension:Secret Key")}
                ></Input>
                {/* <Dropdown
                labelName={t("Common:Payment Frequency")}
                className={classes.dropdown}
                onChange={(e: any) => {
                  setPaymentFrequency(e.target.value);
                }}
                selectedValue={paymentFrequency}
                itemList={paymentFrequencyList}
                uniqueId="value"
                label="key"
                refValue={register(obj.frequency)}
              ></Dropdown> */}

                {/* <Input
                type="text"
                id="businessURL"
                name="businessURL"
                refValue={register(obj.businessURL)}
                errors={errors}
                label={t("Extension:Business URL") + "*"}
              ></Input> */}
                <MaterialAutocomplete
                  isMultiple={false}
                  freeSolo={false}
                  disableClearable={false}
                  disablePortal={true}
                  size="medium"
                  id={"country"}
                  options={countryList}
                  labelName="name"
                  value={getValues("country")}
                  inputRef={register(obj.country)}
                  defaultValue={{ name: defaultCountry }}
                  getOptionLabel={(option: any) => option["name"]}
                  onChange={(evt: any, newValue: any) => {
                    if (newValue) {
                      setValue("country", newValue);
                      setValue("currency", "");
                      setdefaultCurrency("");
                      updateReRenderCurrency(Math.random());
                      setCurrencyList(newValue.currencies.split(","));
                    } else {
                      setValue("country", "");
                      setValue("currency", "");
                      updateReRenderCurrency(Math.random());
                      setCurrencyList();
                    }
                    if (
                      document.getElementsByClassName(
                        "MuiAutocomplete-clearIndicator"
                      )[1]
                    ) {
                      /* tslint:disable-next-line */
                      let element: any = document.getElementsByClassName(
                        "MuiAutocomplete-clearIndicator"
                      )[1];
                      setTimeout(() => {
                        element.click();
                      });
                    }
                  }}
                  errors={errors}
                  name={"country"}
                  filterSelectedOptions
                  onKeyDown={() => {}}
                  textFieldClassName={classes.textField}
                  textFieldLabel={t("Extension:Country")}
                  textFieldPlaceholder={t("Extension:Country")}
                  textFieldMargin={"normal"}
                  textFieldFullWidth={true}
                />
                {currencyList && currencyList.length > 0 ? (
                  <MaterialAutocomplete
                    isMultiple={false}
                    freeSolo={false}
                    disableClearable={false}
                    disablePortal={true}
                    size="medium"
                    id={"currency"}
                    options={currencyList}
                    errors={errors}
                    labelName="name"
                    value={getValues("currency")}
                    inputRef={register(obj.currency)}
                    defaultValue={{ name: defaultCurrency }}
                    getOptionLabel={(option: any) => option["name"]}
                    onChange={(evt: any, newValue: any) => {
                      updateReRenderCurrency(Math.random());

                      setValue("currency", newValue, { shouldDirty: true });
                    }}
                    name={"currency"}
                    filterSelectedOptions
                    onKeyDown={() => {}}
                    textFieldClassName={classes.textField}
                    textFieldLabel={t("Extension:Currency")}
                    textFieldPlaceholder={t("Extension:Currency")}
                    textFieldMargin={"normal"}
                    textFieldFullWidth={true}
                  />
                ) : null}
                <MaterialAutocomplete
                  isMultiple={false}
                  freeSolo={false}
                  disableClearable={false}
                  disablePortal={true}
                  size="medium"
                  id={"frequency"}
                  options={paymentFrequencyList}
                  errors={errors}
                  labelName="name"
                  value={paymentFrequency}
                  inputRef={register(obj.frequency)}
                  defaultValue={{ name: defaultFrequency }}
                  getOptionLabel={(option: any) => option["name"]}
                  onChange={(evt: any, newValue: any) => {
                    setValue("frequency", newValue);
                    setPaymentFrequency(newValue);
                    setdefaultCronDay("");
                    setcornDay("");
                    if (
                      document.getElementsByClassName(
                        "MuiAutocomplete-clearIndicator"
                      )[3]
                    ) {
                      /* tslint:disable-next-line */
                      let element: any = document.getElementsByClassName(
                        "MuiAutocomplete-clearIndicator"
                      )[3];
                      setTimeout(() => {
                        element.click();
                      });
                    }
                  }}
                  name={"frequency"}
                  filterSelectedOptions
                  onKeyDown={() => {}}
                  textFieldClassName={classes.textField}
                  textFieldLabel={t("Extension:Payment Frequency")}
                  textFieldPlaceholder={t("Extension:Payment Frequency")}
                  textFieldMargin={"normal"}
                  textFieldFullWidth={true}
                />
                {(paymentFrequency?.value === 2 ||
                  paymentFrequency?.value === 4) && (
                  <MaterialAutocomplete
                    isMultiple={false}
                    freeSolo={false}
                    disableClearable={false}
                    disablePortal={true}
                    size="medium"
                    id={"day"}
                    options={
                      paymentFrequency?.value === 2 ? daysList : monthlyCronList
                    }
                    errors={errors}
                    labelName="name"
                    defaultValue={{ name: defaultCronDay }}
                    getOptionLabel={(option: any) => option["name"]}
                    value={getValues("day")}
                    inputRef={register(obj.day)}
                    onChange={(evt: any, newValue: any) => {
                      setValue("day", newValue);
                      setcornDay(newValue);
                    }}
                    name={"day"}
                    filterSelectedOptions
                    onKeyDown={() => {}}
                    textFieldClassName={classes.textField}
                    textFieldLabel={t("Extension:Select Payment Day")}
                    textFieldPlaceholder={t("Extension:Select Payment Day")}
                    textFieldMargin={"normal"}
                    textFieldFullWidth={true}
                  />
                )}
                <div>
                  <Btn
                    className={classes.btn}
                    type={"Submit"}
                    btnText={t("Common:Submit")}
                  >
                    {" "}
                  </Btn>
                  <Btn
                    onClick={() => {
                      setOpen(false);
                    }}
                    color={"default"}
                    type={"button"}
                    btnText={t("Common:Cancel")}
                  >
                    {" "}
                  </Btn>
                </div>
              </form>
            ) : (
              <div>
                <h2 id="modal-title">{t("Extension:Update Configuration")}</h2>
                <p style={{ textAlign: "left" }}>
                  {" "}
                  {t(
                    "Extension:If you update the configuration Settings, your resources would have to register again and your payouts will be affected. Do you still want to continue?"
                  )}
                </p>
                <div>
                  <Btn
                    className={classes.btn}
                    type={"button"}
                    onClick={onConfirm}
                    btnText={t("Common:Confirm")}
                  >
                    {" "}
                  </Btn>
                  <Btn
                    onClick={handleClose}
                    color={"default"}
                    type={"button"}
                    btnText={t("Common:Discard")}
                  >
                    {" "}
                  </Btn>
                </div>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
      <Suspense fallback={<h3>Loading&hellip;</h3>}>
        <AlertDialog
          ref={updateConfigRef}
          description={t(
            "Extension:If you update the configuration Settings, payouts might get affected. Do you still want to continue?"
          )}
          title={t("Extension:Update Configuration")}
          confirm={t("Common:Confirm")}
          discard={t("Common:Discard")}
          onConfirm={onConfirm}
          onDiscard={onDiscard}
        ></AlertDialog>
      </Suspense>
    </Fragment>
  );
});

export default StripeConnect;
