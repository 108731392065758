

import { OnboardingEndpoint } from "../containers/OnboardingUser/Onboarding.endpoints";
import useLocalStorage from "../hooks/localStorage";


export const useUpdateOnboardStep = async(data: any) => {


    const { onBoardStep, appData, ApiService, dispatch }: any = data
    let response = false

    const updateOnboardStep = async () => {
        const headers = {
            access_token: appData.loginData.access_token,
        };
        const request: any = {
            user_id: appData.loginData.user_id,
            onboard_step: onBoardStep,

        };
        const reqData = {
            reqBody: request,
            headers: headers,
        };


        return await ApiService["put"](OnboardingEndpoint.updateProfile(reqData))
            .then((res: any) => {
                let loginData = res.data;
                loginData.access_token = res.data.access_token;
                loginData.onboard_step = res.data.onboard_step;
                useLocalStorage.setItem("loginData", loginData);

                return res.data
            })
            .catch((err: any) => {

                return false
            });
    }

    if (appData.loginData.onboard_step < onBoardStep) {
      const result= await updateOnboardStep()
      
          return result
      
    }



}

