import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MaterialAutocomplete from "../MaterialAutocomplete/MaterialAutocomplete";
import Input from "../Input/Input";
import { useStyles } from "./ImportStyles";
import templateMethodService from "../TemplatesCommonMethods/TemplateMethod.service";
import Btn from "../Button/Button";
import { headerList } from "./headerList";
import { ImportExportService } from "./ImportExport.endpoints";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { useAppContext } from "../../AppContext/App.context";
import useToast from "../Toast/hooks/useToast";
import "./ImportExport.scss";
import { useTranslation } from "react-i18next";
import Tooltip from "../Tooltip/Tooltip";
import { themeColor } from "../../constants/themeColor";
import InfoIcon from "@material-ui/icons/Info";
import { singleLineList } from "./singleLineList";
import useLoader from "../../hooks/useLoader";
import useLocalStorage from "../../hooks/localStorage";

const FormatSelector = (props?: any) => {
  let getFormatList = props.getFormatList;
  const [expanded, setExpanded]: any = useState();
  const { t, i18n } = useTranslation(["ImportJobs"]);
  const optionList = headerList(t);
  const SingleLineList = singleLineList(t);
  const loader: any = useLoader();
  const [showAccordionContent, setShowAccordionContent]: any = useState({});
  const classes = useStyles();
  const [
    templateFieldDropdownList,
    setTemplateFieldDropdownList,
  ]: any = useState();
  const toaster = useToast();
  const [templateList, setTemplateList]: any = useState(props.templateList);
  const Permission: any = useLocalStorage.getItem("Permission");
  const [defaultChecked, setDefaultChecked]: any = useState();
  const [isLoading, setLoading]: any = useState(true);
  let [formatDropdownList, setFormatDropdownList]: any = useState();
  let [selectedMapping, setSelectedMapping]: any = useState([]);
  let [num, setNum]: any = useState(0);
  const [formatName, updateFormatName]: any = useState();
  const [jobformatType, updateFormatType]: any = useState();
  const [templateId, updateTemplateId]: any = useState();
  const [formatId, updateFormatId]: any = useState();
  const [appData]: any = useAppContext();

  const [selectedFormatRadio, setSelectedFormatRadio] = React.useState("");
  const [reRenderDropdownCount, updateReRenderDropdownCount] = useState(
    Math.random()
  );
  const formatType: any = {
    SingleLine: 2,
    DoubleLine: 1,
  };
  const handleChange = (event: any) => {
    setSelectedFormatRadio(event.target.value);
  };
  function checkSelectedValue(arr: any, value: any) {
    const { length } = arr;
    const id = length + 1;
    const found = arr.some((el: any) => el.option === value);
    if (!found) {
      return true;
    }
    return false;
  }
  useEffect(() => {}, [isLoading, selectedMapping]);
  const getTemplateFields = async (params: any, formatType?: any) => {
    const Fields = templateMethodService.getTemplateFieldsByTemplateId(
      params,
      templateList
    );
    let dropdownList: any = [];

    dropdownList = await checkFieldList(appData.loginData.workflow_type);
    const templateFieldList: any = [];
    const newFieldList: any =
      formatType === 2 ? [...SingleLineList] : [...dropdownList];

    if (appData.loginData.workflow_type === 0 && formatType === 2) {
      for (let i = 0; i < Fields.length; i++) {
        templateFieldList.push({
          key: `P_${Fields[i].name}`,
          option: `Pickup ${Fields[i].name}`,
          is_template_fields: 1,
          is_pickup: 1,
        });
      }
      for (let i = 0; i < Fields.length; i++) {
        templateFieldList.push({
          key: `D_${Fields[i].name}`,
          option: `Delivery ${Fields[i].name}`,
          is_template_fields: 1,
          is_delivery: 1,
        });
      }
    } else {
      for (let i = 0; i < Fields.length; i++) {
        templateFieldList.push({
          key: Fields[i].name,
          option: Fields[i].name,
          is_template_fields: 1,
          is_pickup: 1,
        });
      }
    }
    setTemplateFieldDropdownList(templateFieldList);
    setFormatDropdownList(newFieldList);
    return newFieldList;
  };
  const handleUpdateDropdownChange = (
    index: number,
    selectedvalue: any,
    defaultValue: any
  ) => {
    if (selectedvalue !== null) {
      let check = checkSelectedValue(selectedMapping, selectedvalue.option);

      if (check) {
        if (selectedvalue.is_template_fields === 1) {
          selectedMapping[index] = {
            ...selectedMapping[index],
            option: selectedvalue.key,
            is_template_fields: 1,
          };
        } else {
          selectedMapping[index] = {
            ...selectedMapping[index],
            option: selectedvalue.option,
            is_template_fields: 0,
          };
        }
      } else {
        if (
          document.getElementsByClassName("MuiAutocomplete-clearIndicator")[
            index
          ]
        ) {
          /* tslint:disable-next-line */
          let element: any = document.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
          )[index];
          setTimeout(() => {
            element.click();
          });
        }
        toaster.addToast({
          message: t("ImportJobs:Please Select Another Value"),
          timeout: 3000,
          type: "error",
        });
      }
    } else {
      delete selectedMapping[index]["option"];
      delete selectedMapping[index]["isTemplate"];
    }
  };
  const handleAccordionContent = async (
    params: any,
    mapping: any,
    index: any,
    isExpanded: any
  ) => {
    // loader.showLoader()
    setLoading(true);
    updateFormatName(index.name);
    updateFormatId(index.id);
    updateFormatType(index.format_type);
    updateTemplateId(index.template_id);
    let dropdownList: any = [];

    dropdownList = await checkFieldList(appData.loginData.workflow_type);

    let selectedDropdownValues: any = [];
    let templateValues: any =
      index.format_type === 2 ? [...SingleLineList] : [...dropdownList];
    for (let key in mapping) {
      if (key === "jobMapping") {
        for (let key1 in mapping[key]) {
          selectedDropdownValues.push({
            key: key1,
            option: mapping[key][key1],
          });
        }
      } else {
        if (key == "templateMapping") {
          // if(mapping[key]["pickupTemplate"]=="pickupTemplate"){
          for (let key1 in mapping[key]["pickupTemplate"]) {
            selectedDropdownValues.push({
              key: key1,
              option: mapping[key]["pickupTemplate"][key1],
              is_template_fields: 1,
            });
            templateValues.push({
              key: mapping[key]["pickupTemplate"][key1],
              option: mapping[key]["pickupTemplate"][key1],
              is_template_fields: 1,
            });
          }
          // }else if (mapping[key]["deliveryTemplate"]=="deliveryTemplate"){
          for (let key1 in mapping[key]["deliveryTemplate"]) {
            selectedDropdownValues.push({
              key: key1,
              option: mapping[key]["deliveryTemplate"][key1],
              is_template_fields: 1,
            });
            templateValues.push({
              key: mapping[key]["deliveryTemplate"][key1],
              option: mapping[key]["deliveryTemplate"][key1],
              is_template_fields: 1,
            });
          }
          // }
        }
      }
    }
    setFormatDropdownList(templateValues);
    let newList: any = [];
    if (index.template_id !== null) {
      newList = await getTemplateFields(index.template_id, index?.format_type);
    } else {
      newList = templateValues;
    }

    await setDropdownData(selectedDropdownValues, newList, index?.format_type);
    // setExpanded(isExpanded ? `panel${params + 1}` : false);
    updateReRenderDropdownCount(Math.random());
    setExpanded(isExpanded ? `panel${params + 1}` : false);
  };

  const setDropdownData = (
    selectedDropdownValues: any,
    list?: any,
    format?: any
  ) => {
    let dropdownlist =
      formatDropdownList?.length > 0 ? formatDropdownList : list;
    for (let i = 0; i < selectedDropdownValues.length; i++) {
      for (let j = 0; j < dropdownlist.length; j++) {
        if (format === 2) {
          if (selectedDropdownValues[i].option === dropdownlist[j].key) {
            selectedDropdownValues[i] = {
              ...selectedDropdownValues[i],
              option: dropdownlist[j].option,
              option_key: dropdownlist[j].key,
            };
          }
        } else {
          if (selectedDropdownValues[i].option === dropdownlist[j].key) {
            selectedDropdownValues[i] = {
              ...selectedDropdownValues[i],
              option: dropdownlist[j].option,
              key: selectedDropdownValues[i].option,
            };
          }
        }
      }
    }

    setSelectedMapping([...selectedDropdownValues]);
    // console.log(selectedDropdownValues)
    setLoading(false);
  };
  const checkFieldList = (newWorkflow: number) => {
    const tempFieldList: any = [...optionList];

    if (newWorkflow === 1) {
      let index: any = tempFieldList.findIndex(
        (i: any) => i.key === "local_datetime"
      );
      tempFieldList.splice(
        tempFieldList.findIndex((i: any) => {
          return i.key === "local_datetime";
        }),
        1
      );
      tempFieldList.splice(
        tempFieldList.findIndex((i: any) => {
          return i.key === "task_type";
        }),
        1
      );
      tempFieldList.splice(index - 1, 0, {
        key: "start_datetime",
        option: t("ImportJobs:Start time*"),
        is_required: 1,
      });
      tempFieldList.splice(index, 0, {
        key: "end_datetime",
        option: t("ImportJobs:End time*"),
        is_required: 1,
      });

      // setFormatDropdownList(tempFieldList)
      return tempFieldList;
    } else return tempFieldList;
  };

  const EditFormat = (index: number) => {
    if (!isLoading)
      return (
        <Fragment>
          {selectedMapping && (
            <Grid container spacing={1} style={{ marginBottom: "10px" }}>
              <Grid item xs={6}>
                <Typography component={"span"} className={classes.instructions}>
                  Imported Fields
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component={"span"} className={classes.instructions}>
                  Template Fields
                </Typography>
              </Grid>
            </Grid>
          )}
          {selectedMapping &&
            selectedMapping.map((head: any, key: any) => (
              <Grid container spacing={1} key={head.key}>
                <Grid item xs={6}>
                  <Input
                    label={t("ImportJobs:Field name")}
                    type="text"
                    value={head.key}
                    disabled={true}
                  ></Input>
                </Grid>
                <Grid item xs={6}>
                  <MaterialAutocomplete
                    isMultiple={false}
                    freeSolo={false}
                    disablePortal={false}
                    size="medium"
                    id={"team-outlined" + key + index}
                    options={formatDropdownList}
                    labelName="option"
                    defaultValue={{
                      option: head.option,
                    }}
                    getOptionLabel={(option: any) => option["option"]}
                    onChange={(e: any, newValue: any) => {
                      handleUpdateDropdownChange(key, newValue, head.option);
                      updateReRenderDropdownCount(Math.random());
                    }}
                    rerender={reRenderDropdownCount}
                    getOptionDisabled={(selectedvalue: any) => {
                      return !checkSelectedValue(
                        selectedMapping,
                        selectedvalue.option
                      );
                    }}
                    filterSelectedOptions
                    onKeyDown={() => {}}
                    textFieldClassName={classes.tagInputField}
                    textFieldLabel={t("ImportJobs:Select")}
                    textFieldPlaceholder={t("ImportJobs:Select")}
                    textFieldMargin={"normal"}
                    textFieldFullWidth={true}
                  />
                </Grid>
              </Grid>
            ))}
        </Fragment>
      );
  };
  const setUpdateBody = (params?: any) => {
    let jobMapping: any = {};
    let templateMapping: any = {};
    for (var i = 0; i < selectedMapping.length; i++) {
      if (selectedMapping[i].is_template_fields === 1) {
        if (jobformatType === 2) {
          templateMapping[selectedMapping[i].key] =
            selectedMapping[i].option_key;
        } else {
          templateMapping[selectedMapping[i].key] = selectedMapping[i].option;
        }
      } else {
        for (let j = 0; j < formatDropdownList.length; j++) {
          if (selectedMapping[i].option === formatDropdownList[j].option) {
            jobMapping[selectedMapping[i].key] = formatDropdownList[j].key;
          }
        }
      }
    }
    let mapping: any = {};

    mapping["jobMapping"] = jobMapping;
    mapping["templateMapping"] = templateMapping;

    let body: any = {
      mapping: mapping,
      name: formatName,
    };
    if (formatId !== undefined) {
      body.id = formatId;
    }
    if (jobformatType !== undefined) {
      body.format_type = jobformatType;
    }

    if (!templateId) {
    } else {
      body.template_id = templateId;
    }
    props.databody(body, 1);
  };

  const handleCheckboxChange = (index: any) => {
    props.formatChecked(true, 0, getFormatList[index]);
  };
  const handleDeleteFormat = (id: any) => {
    props.handleDeleteFormat(id);
    setNum(++num);
  };

  return (
    <Fragment>
      <div className="importFormatSelector">
        <Paper
          elevation={2}
          style={{
            width: "100%",
            height: "4rem",
            justifyContent: "flex-start",
          }}
        >
          <div style={{ marginLeft: "0.9rem", paddingTop: "0.5rem" }}>
            <RadioGroup
              aria-label=""
              name="default"
              row
              style={{ alignItems: "center" }}
            >
              <FormControlLabel
                value={`${formatType.MultiLine}`}
                control={
                  <Radio
                    color="primary"
                    // value={defaultChecked}
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        setDefaultChecked(e.target.value);
                        setSelectedFormatRadio("");
                        props.formatChecked(true, 1);
                      } else {
                        props.formatChecked(false);
                      }
                    }}
                  />
                }
                label={
                  appData.loginData.workflow_type === 0
                    ? t("ImportJobs:Default Multiline Format")
                    : "Default Format"
                }
              />
              {appData.loginData.workflow_type === 0 && (
                <Tooltip
                  content={
                    "Pickup and delivery tasks are added in separate rows of the sheet."
                  }
                  direction={"right"}
                  style={{
                    width: "200px",
                    "white-space": "break-spaces",
                    backgroundColor: "rgba(97, 97, 97, 0.92)",
                    marginBottom: "-8px",
                    textAlign: "left",
                  }}
                  wrapperStyle={{
                    // width: "-webkit-fill-available",
                    margin: "10px 10px 0px -10px",

                    color: `${themeColor.iconsColor}`,
                  }}
                >
                  <InfoIcon />
                </Tooltip>
              )}

              {appData.loginData.workflow_type === 0 && (
                <>
                  <FormControlLabel
                    value={`${formatType.SingleLine}`}
                    control={
                      <Radio
                        color="primary"
                        // value={defaultChecked}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setDefaultChecked(e.target.value);
                            setSelectedFormatRadio("");
                            props.formatChecked(true, 2);
                          } else {
                            props.formatChecked(false);
                          }
                        }}
                      />
                    }
                    label={t("ImportJobs:Default Singleline Format")}
                  />
                  <Tooltip
                    content={`Pickup and delivery tasks are added in the same row of the sheet. Works only for single pickup and single delivery`}
                    direction={"left"}
                    style={{
                      width: "300px",
                      "white-space": "break-spaces",
                      backgroundColor: "rgba(97, 97, 97, 0.92)",
                      marginBottom: "-8px",
                      textAlign: "left",
                      // left: "65%",
                    }}
                    className={`info-content-import`}
                    wrapperStyle={{
                      // width: "-webkit-fill-available",
                      // marginLeft: "-10px",
                      color: `${themeColor.iconsColor}`,
                      margin: "10px 10px 0px -10px",
                    }}
                  >
                    <InfoIcon />
                  </Tooltip>
                </>
              )}
            </RadioGroup>
          </div>
        </Paper>

        {getFormatList.map((index: any, row: any) => (
          <Accordion
            expanded={expanded === `panel${row + 1}`}
            onChange={(e, expanded) => {
              if (expanded) {
                setShowAccordionContent({ row: row });
                // handleCheckboxChange(row);
                handleAccordionContent(
                  row,
                  index.mapping,
                  index,
                  `panel${row + 1}`
                );
              } else {
                setExpanded(false);
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${row + 1}a-content`}
              id={`panel${row + 1}a-header`}
              classes={{
                expanded: classes.expandedAccordionSummary,
              }}
            >
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Typography
                  component={"span"}
                  className={classes.heading}
                  style={{ width: "90%", textAlign: "left" }}
                >
                  <FormControl component="fieldset" style={{ width: "100%" }}>
                    <RadioGroup
                      value={selectedFormatRadio}
                      onChange={handleChange}
                      style={{
                        display: "flex!important",
                        flexWrap: "wrap",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <FormControlLabel
                        value={index.id + ""}
                        control={
                          <Radio
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                setDefaultChecked(false);
                                handleCheckboxChange(row);
                              } else {
                                props.formatChecked(false);
                              }
                            }}
                            onClick={(e: any) => e.stopPropagation()}
                            color="primary"
                          />
                        }
                        label={index.name}
                      />
                      {appData.loginData.workflow_type === 0 ? (
                        index.format_type === 1 ? (
                          <Typography
                            style={{ color: "grey", marginLeft: "-5px" }}
                          >
                            (Multiline)
                          </Typography>
                        ) : index.format_type === 2 ? (
                          <Typography
                            style={{ color: `grey`, marginLeft: "-5px" }}
                          >
                            (Singleline)
                          </Typography>
                        ) : null
                      ) : null}
                    </RadioGroup>
                  </FormControl>
                </Typography>
                {
                  <DeleteOutlineOutlinedIcon
                    onClick={(e: any) => {
                      handleDeleteFormat(index.id);
                      e.stopPropagation();
                    }}
                  />
                }
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {showAccordionContent["row"] === row ? EditFormat(row) : null}

                <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                  {
                    <Btn
                      btnText={t("ImportJobs:Update")}
                      type="submit"
                      onClick={() => setUpdateBody()}
                    ></Btn>
                  }
                </div>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      <br></br>
      {/* <Btn
        btnText={t("ImportJobs:Add Format")}
        onClick={(e: any) => {
          e.preventDefault();
          props.addFormat(2);
          props.formatChecked(false);
        }}
        type="Submit"
      /> */}
      <br></br>
      <br></br>
    </Fragment>
  );
};

export default FormatSelector;
