import moment from "moment";
import useLocalStorage from "./localStorage";

export function convertDateTimeFormatToTimeDateString(dateTimeString: any) {
  const dateTime: String[] = dateTimeString.split(" ");
  return `${dateTime[1]} ${dateTime[0]}`;
}

export const defaultDateTimeFormat: any =
  useLocalStorage.getItem("loginData") &&
  useLocalStorage.getItem("loginData").datetime_format
    ? convertDateTimeFormatToTimeDateString(
        `${useLocalStorage.getItem("loginData").datetime_format}`
      )
    : "YYYY-MM-DD HH:mm";
export const defaultTimeForamt =
  useLocalStorage.getItem("loginData") &&
  useLocalStorage.getItem("loginData").time_format
    ? `${useLocalStorage.getItem("loginData").time_format}`
    : "HH:mm";
export const defaultDateFormat =
  useLocalStorage.getItem("loginData") &&
  useLocalStorage.getItem("loginData").date_format
    ? `${useLocalStorage.getItem("loginData").date_format}`
    : "YYYY-MM-DD";

const DateTimeFormatter = {
  // To get updated values from localstorage
  getDateTimeFormat() {
    let a = useLocalStorage.getItem("loginData").datetime_format
      ? `${useLocalStorage.getItem("loginData").datetime_format}`
      : "YYYY-MM-DD HH:mm";
    return useLocalStorage.getItem("loginData").datetime_format
      ? `${useLocalStorage.getItem("loginData").datetime_format}`
      : "YYYY-MM-DD HH:mm";
  },
  convertTime12to24(time12h: string) {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes]: any = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  },
  convertDateStringIntoSpecificFormat(
    dateString: string,
    formatOfDate: string,
    toWhichFormat: string
  ) {
    const defaultFOrmat = this.getDateFormat();
    return moment(dateString, defaultFOrmat).format(toWhichFormat);
  },
  getDateFormat() {
    return useLocalStorage.getItem("loginData")?.date_format
      ? `${useLocalStorage.getItem("loginData").date_format}`
      : "YYYY-MM-DD";
  },
  getTimeFormat() {
    return useLocalStorage.getItem("loginData").time_format
      ? `${useLocalStorage.getItem("loginData").time_format}`
      : "HH:mm";
  },

  convert12HoursFormatTo24Format(time: string) {
    return moment(time, "hh:mm A").format("HH:mm");
  },
  converDateTimeTo24Hours(dateTime: string) {
    return moment(dateTime, this.getDateTimeFormat())
      .utc()
      .format("YYYY-MM-DD HH:mm");
  },
  converDateTimeTo24HoursWithoutUTC(dateTime: string) {
    return moment(dateTime, this.getDateTimeFormat()).format(
      "YYYY-MM-DD HH:mm"
    );
  },

  checkIfTimeInTz(dateTime: any) {
    if (dateTime && dateTime.includes("T")) {
      return true;
    } else {
      return false;
    }
  },
  checkIfTimeInTzAndConvertTO12(dateTime: any) {
    if (dateTime?.includes("T")) {
      return dateTime;
    } else {
      if (
        this.getDateTimeFormat() === "DD-MM-YYYY hh:mm A" &&
        dateTime.length === 5
      ) {
        return moment(dateTime, "HH:mm").format(this.getTimeFormat());
      }
      return dateTime;
    }
  },
  convertTtimeTo12(dateTime: any) {
    return moment(dateTime).utc().format(this.getTimeFormat());
  },
  convertDateTimeToSpecificFormatInUtc(dateTime: Date, setRequestFormat?: any) {
    let format = setRequestFormat ? setRequestFormat : "YYYY-MM-DD HH:mm";
    // let format = 'YYYY-MM-DD HH:mm';
    return moment(dateTime).utc().format(format);
  },
  convertTimeToCurrentTz(
    time: any,
    timezone: any,
    format: any,
    type?: any,
    b?: any
  ) {
    if (type) {
      console.log(format);
    }
    if (typeof time === "string" && time.includes("T")) {
      return moment(time).format(format);
    } else if (typeof time?.getMonth === "function") {
      return moment(time).format(format);
    } else if (!time?.includes("T")) {
      let a = moment
        .utc(time, this.getDateTimeFormat())
        .tz(timezone)
        .format(format);
      return moment
        .utc(time, this.getDateTimeFormat())
        .tz(timezone)
        .format(format);
    }
  },
  convertDateTimeInUTC(dateTime: any, format: any) {
    if (dateTime.includes("T")) {
      return moment.utc(dateTime).format(format);
    } else {
      if (typeof dateTime === "string") {
        return moment.utc(dateTime, this.getDateTimeFormat()).format(format);
      }
      return dateTime;
    }
  },
  convertDateTimeWithoutUTC(dateTime: any, format: any) {
    if (dateTime.includes("T")) {
      return moment(dateTime).utc().format(format);
    } else {
      if (typeof dateTime === "string") {
        return moment(dateTime, this.getDateFormat()).utc().format(format);
      }
      return dateTime;
    }
  },
  convertDateTimeToSpecificFormat(
    dateTime: string,
    utcOffset?: any,
    format = defaultDateTimeFormat
  ) {
    format = this.getDateTimeFormat();
    if (dateTime.includes("T")) {
      if (utcOffset) {
        return moment.utc(dateTime).utcOffset(utcOffset).format(format);
      }
      return moment(dateTime).utc().format(format);
    } else {
      if (utcOffset) {
        return moment.utc(dateTime, format).utcOffset(utcOffset).format(format);
      }
      return moment(dateTime, format).utc().format(format);
    }
  },
  getDateInJavascriptAllowedFormat(date: string) {
    let dateStringArr = date.split("-");
    return `${dateStringArr[1]}-${dateStringArr[0]}-${dateStringArr[2]}`;
  },
  changeOnlyFormatInSameTimeZone(dateTime: any, format?: string) {
    return moment(dateTime).format(format ? format : defaultDateTimeFormat);
  },
  convertUTCTimeToLocalTimeZone(datTime: any, format: string, timezone: any) {
    let a = moment.utc(datTime).tz(timezone).format(format);
    if (datTime.includes("T")) {
      return moment.utc(datTime).tz(timezone).format(format);
    } else {
      return moment.utc(datTime, format).tz(timezone).format(format);
    }
  },
  getTimzoneOffsetInHoursAndMinutes(timezoneOffset: number) {
    //eslint-disable-next-line
    let timezone_offset_min = timezoneOffset,
      offset_hrs: any = parseInt(Math.abs(timezone_offset_min / 60).toString()),
      offset_min: any = Math.abs(timezone_offset_min % 60),
      timezone_standard;

    if (offset_hrs < 10) offset_hrs = "0" + offset_hrs;

    if (offset_min < 10) offset_min = "0" + offset_min;

    // Add an opposite sign to the offset
    // If offset is 0, it means timezone is UTC
    if (timezone_offset_min < 0)
      timezone_standard = "-" + offset_hrs + ":" + offset_min;
    else if (timezone_offset_min > 0)
      timezone_standard = "+" + offset_hrs + ":" + offset_min;
    else if (timezone_offset_min == 0) timezone_standard = "Z";

    // Timezone difference in hours and minutes
    // String such as +5:30 or -6:00 or Z
    return timezone_standard;
  },

  getTimeFromDateTimeInSameTimeZone(
    dateTime: Date,
    format = defaultTimeForamt
  ) {
    return moment(dateTime).utc().format(this.getTimeFormat());
  },
  getValueInMilliSeconds(dateTime: any) {
    // console.log(moment(dateTime).utc());
    return moment(dateTime).valueOf();
  },
  getUTCStartAndEndTimeFromLocal(selectedDate: any) {
    let startDate = selectedDate;
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate = this.convertDateTimeToSpecificFormatInUtc(startDate);
    let endDate: any = selectedDate;
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate = this.convertDateTimeToSpecificFormatInUtc(endDate);
    return { startDate, endDate };
  },
  getUtcFromLocal(selectedDate: any, format?: string) {
    return moment(selectedDate)
      .local()
      .format(format ? format : this.getDateTimeFormat());
  },
  getLocalTimzoneOffsetInHoursAndMinutes() {
    //eslint-disable-next-line
    let timezone_offset_min = new Date().getTimezoneOffset(),
      offset_hrs: any = parseInt(Math.abs(timezone_offset_min / 60).toString()),
      offset_min: any = Math.abs(timezone_offset_min % 60),
      timezone_standard;

    if (offset_hrs < 10) offset_hrs = "0" + offset_hrs;

    if (offset_min < 10) offset_min = "0" + offset_min;

    // Add an opposite sign to the offset
    // If offset is 0, it means timezone is UTC
    if (timezone_offset_min < 0)
      timezone_standard = "+" + offset_hrs + ":" + offset_min;
    else if (timezone_offset_min > 0)
      timezone_standard = "-" + offset_hrs + ":" + offset_min;
    else if (timezone_offset_min == 0) timezone_standard = "Z";

    // Timezone difference in hours and minutes
    // String such as +5:30 or -6:00 or Z
    return timezone_standard;
  },
  isBefore(date: any) {
    return moment(date).isBefore(new Date());
  },
};
export default DateTimeFormatter;
