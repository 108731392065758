import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import className from "./TaskDetail.module.scss";
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import TaskDetailInfo from "./TaskDetailInfo";
// import TaskCustomerDetail from "./TaskCustomerDetail";
import TaskHistory from "./TaskHistory";

const useStyles = makeStyles((theme: any) => ({
  tabs: {
    minWidth: "100px",
  },
  "MuiTab-root": {
    minWidth: "100px !important",
    background: "white!important",
  },
  selected: {
    fontWeight: "bold",
  },
}));

const TaskDetailBody = (data: any) => {
  const { t, i18n } = useTranslation(["TaskDetail"]);
  const classes = useStyles();
  const taskDetail = data.taskDetail;
  const [activeTab, setActiveTab] = React.useState(0);
  const handleTabChange = (event: any, newValue: number) => {
    setActiveTab(newValue);
    data.selectedTab(newValue);
  };
  return (
    <Fragment>
      <Tabs
        className={classes["MuiTab-root"]}
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        onChange={handleTabChange}
        aria-label="disabled tabs example"
      >
        <Tab
          label={t("TaskDetail:Details")}
          classes={{
            selected: classes.selected,
          }}
        />
        {/* <Tab label="Customer"  /> */}
        <Tab
          label={t("TaskDetail:History")}
          classes={{
            selected: classes.selected,
          }}
        />
      </Tabs>
      <TaskDetailInfo
        taskDetail={taskDetail}
        value={activeTab}
        index={0}
      ></TaskDetailInfo>
      {/* <TaskCustomerDetail  value={activeTab} index={1} >
         
        </TaskCustomerDetail> */}
      <TaskHistory
        taskDetail={taskDetail}
        value={activeTab}
        index={1}
      ></TaskHistory>
    </Fragment>
  );
};
export default TaskDetailBody;
