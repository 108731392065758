import React, {
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Btn from "../../../components/Button/Button";
import { extensionService } from "../Extensions.endpoints";
import Input from "../../../components/Input/Input";
import { Controller, useForm } from "react-hook-form";
import { ValidationService } from "../../../utils/Validation";
import { useAppContext } from "../../../AppContext/App.context";
import useLoader from "../../../hooks/useLoader";
import useLocalStorage from "../../../utils/localStorage";
import useToast from "../../../components/Toast/hooks/useToast";
import useApiService from "../../../services/api.service";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import DateTimeFormatter from "../../../utils/DateFormatter";
import { extensionList } from "../../../constants/extensionList";
import DateTimePickerInput from "../../../components/TemplatesCommonMethods/DateTimePickerInput";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "500px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
  },
}));
const OTPVerification = forwardRef((props: any, ref: any) => {
  const { t, i18n } = useTranslation(["Extension", "Common"]);
  const ApiService = useApiService();
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const [appData]: any = useAppContext();
  const loader: any = useLoader();
  const [key, setkey]: any = useState();
  const toaster = useToast();
  const [configData, setConfigData]: any = useState();
  const [operation, setOperation]: any = useState();
  const [breaktime, setBreaktime]: any = useState();
  const [lunchBreak, setlunchBreak]: any = useState(false);
  const [priority, setpriority]: any = useState(`1`);
  const [breakDuration, setbreakDuration]: any = useState();
  const access_token = appData.loginData.access_token;

  const [
    otpVerificationSettings,
    updateOtpVerificationSettings,
  ]: any = useState({
    pickup: false,
    delivery: false,
    service: 0,
    id: 0,
  });
  const updateVerificationSettingsForService = (data: any, opr: number) => {
    const request: any = {
      pickup: data.pickup ? 1 : 0,
      delivery: data.delivery ? 1 : 0,
      service: 1,
    };
    if (opr === 1) {
      request["id"] = data.id;
    }
    sendUpdatedDataToserver(request);
  };
  useImperativeHandle(ref, () => ({
    handleOpen(opr: any) {
      const loginData: any = useLocalStorage.getItem("loginData");
      if (opr === 1) {
        setOperation("edit");
      } else {
        //   resetform();
        setOperation("add");
      }
      if (loginData.workflow_type === 1) {
        loader.showLoader();
        getOtpVerificationSettings((data: any) => {
          updateVerificationSettingsForService(data, opr);
        });
        return;
      }
      getOtpVerificationSettings();

      setOpen(true);
    },
  }));
  const getOtpVerificationSettings = async (cb?: any) => {
    let header: any = {
      access_token: access_token,
    };
    let request: any = {};
    let reqData = {
      reqBody: request,
      headers: header,
    };
    await ApiService.get(extensionService.getOTPVerificationSettings(reqData))
      .then((res: any) => {
        let data = { ...otpVerificationSettings };
        if (res.data && res.data.length > 0) {
          data = res.data[0];
        }
        updateOtpVerificationSettings(data);
        if (cb) {
          cb(data);
        }
        //   setOpen(true);
      })
      .catch(() => {});
  };

  const resetform = () => {
    updateOtpVerificationSettings({
      pickup: false,
      delivery: false,
      service: 0,
      id: 0,
    });
  };

  const handleClose = () => {
    setOpen(false);
    resetform();
  };
  const sendUpdatedDataToserver = (request: any) => {
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    loader.showLoader();
    return ApiService[operation !== "edit" ? "post" : "put"](
      extensionService.updateOTPVerificationSettings(
        reqData,
        operation !== "edit" ? 0 : 1
      )
    )
      .then((res: any) => {
        loader.hideLoader();
        props.handleOTPVerification();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        handleClose();
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const submitForm = () => {
    if (!otpVerificationSettings.pickup && !otpVerificationSettings.delivery) {
      toaster.addToast({
        message: "Please select atleast one job type",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    loader.showLoader();
    const request: any = {
      pickup: otpVerificationSettings.pickup ? 1 : 0,
      delivery: otpVerificationSettings.delivery ? 1 : 0,
      service: otpVerificationSettings.service ? 1 : 0,
    };
    if (operation !== "add") {
      request["id"] = otpVerificationSettings["id"];
    }
    sendUpdatedDataToserver(request);
  };
  const handleJobTypeChange = (evt: any) => {
    // console.log(evt.target.checked);
    const temp: any = { ...otpVerificationSettings };
    temp[evt.target.name] = evt.target.checked;
    updateOtpVerificationSettings(temp);
  };
  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="modal-title">OTP Configuration</h2>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "left" }}>
                Where OTP verification should be performed?
                <div style={{ display: "grid" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={otpVerificationSettings.pickup}
                        onChange={handleJobTypeChange}
                        name="pickup"
                        color="primary"
                      />
                    }
                    label={t("Common:Pickup")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={otpVerificationSettings.delivery}
                        onChange={handleJobTypeChange}
                        name="delivery"
                        color="primary"
                      />
                    }
                    label={t("Common:Delivery")}
                  />
                </div>
              </Grid>
            </Grid>

            <div>
              <Btn
                className={classes.btn}
                type={"Submit"}
                btnText={
                  operation === "add" ? t("Common:Submit") : t("Common:Update")
                }
                onClick={submitForm}
              >
                {" "}
              </Btn>
              <Btn
                onClick={() => {
                  setOpen(false);
                }}
                color={"default"}
                type={"button"}
                btnText={t("Common:Cancel")}
              >
                {" "}
              </Btn>
            </div>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default OTPVerification;
