import { Backdrop, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import useLocalStorage from '../../../hooks/localStorage'
import Btn from '../../../components/Button/Button'
import "./CreateJobInfo.scss"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);
const CreateJobInfo = ({ activeJobStep, showInfoPopup, workflow }: any) => {
  const classes = useStyles();
  const [activeStep, setActiveStep]: any = useState("")
  const loginData = useLocalStorage.getItem("loginData")
  // const [showInfoPopup, setShowInfoPopup]: any = useState(false)

  const pickupInfo = "Fill pickup details like name, address, time etc.";
  const deliveryInfo = "Fill delivery details like name, address, time etc.";
  let serviceInfo = "Fill service details like name,address,time etc."
  const assignAgentInfo = "Assign an agent to your job.";
  // const ="Fill pickup details like name, address, time etc.";

  return (

    <div hidden={activeJobStep === 3}>
      <div className={"createjob-info_popup arrow-top"} hidden={!showInfoPopup}>
        <div className="CreateJobInfo">
          <Typography style={{ fontSize: '1.34rem', fontWeight: "bold" }}>Create Your First Job</Typography>

          <div style={{ pointerEvents: "none" }}>
            <FormControl style={{ margin: "1px 20px" }} component="fieldset">

              <RadioGroup aria-label="position" name="position" value={activeStep ? `${activeStep}` : activeJobStep && workflow === 1 ? `${activeJobStep + 1}` : `${activeJobStep}`}>
                {workflow != 1 ? <>
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label={<Typography className="CreateJobInfo__radioLabel">Enter Pickup Details</Typography>}

                  />
                  <div style={{ display: "flex" }}>
                    <div className="CreateJobInfo__dottedpath"></div>
                    {activeJobStep === 0 ? <div className="CreateJobInfo__info-div"><Typography className="CreateJobInfo__radioLabelInfo">{pickupInfo}</Typography></div>
                      : <></>}
                  </div>
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label={<Typography className="CreateJobInfo__radioLabel">Enter Delivery Details</Typography>}

                  />
                  <div style={{ display: "flex" }}>
                    <div className="CreateJobInfo__dottedpath"></div>
                    {activeJobStep === 1 ? <div className="CreateJobInfo__info-div"><Typography className="CreateJobInfo__radioLabelInfo">{deliveryInfo}</Typography></div>
                      : <></>}
                  </div>
                </> :
                  <>
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label={<Typography className="CreateJobInfo__radioLabel">Enter Service Details</Typography>}

                    />
                    <div style={{ display: "flex" }}>
                      <div className="CreateJobInfo__dottedpath"></div>
                      {activeJobStep === 0 ? <div className="CreateJobInfo__info-div"><Typography className="CreateJobInfo__radioLabelInfo">{serviceInfo}</Typography></div>
                        : <></>}
                    </div>
                  </>
                }
                <FormControlLabel value="2" control={<Radio color="primary" />} label={<Typography className="CreateJobInfo__radioLabel">Assign Agent</Typography>} />
                <div style={{ display: "flex" }}>
                  <div className="CreateJobInfo__dottedpath"></div>
                  {((activeJobStep === 2 && workflow === 0) || (activeJobStep === 1 && workflow === 1)) ? <div className="CreateJobInfo__info-div"><Typography className="CreateJobInfo__radioLabelInfo">{assignAgentInfo}</Typography></div>
                    : <></>}
                </div>
                <FormControlLabel value="3" control={<Radio color="primary" />} label={<Typography className="CreateJobInfo__radioLabel">Download Agent App</Typography>} />
                <div style={{ display: "flex" }}>
                  <div className="CreateJobInfo__dottedpath"></div>

                </div>
                <FormControlLabel value="4" control={<Radio color="primary" />} label={<Typography className="CreateJobInfo__radioLabel">Complete the job</Typography>} />
                <div style={{ display: "flex" }}>
                  <div className="CreateJobInfo__dottedpath" style={{ display: "none" }}></div>

                </div>
              </RadioGroup>
            </FormControl>

          </div>

        </div>
      </div>

    </div>

  )
}

export default CreateJobInfo