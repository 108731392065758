export enum JobStatusValueFromName {
  UNASSIGNED = 0,
  ASSIGNED = 1,
  ACCEPTED = 2,
  INPROGRESS = 3,
  ARRIVED = 4,
  COMPLETED = 5,
  FAILED = 6,
  CANCELLED = 7,
}
