import { Backdrop, Fade, makeStyles } from "@material-ui/core";
import React, {
  Fragment,
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from "react";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";
import Btn from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { useForm } from "react-hook-form";
import { ValidationService } from "../../utils/Validation";
import useToast from "../../components/Toast/hooks/useToast";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import { extensionService } from "./Extensions.endpoints";
import useLoader from "../../hooks/useLoader";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  textField: {
    marginTop: "4px!important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "500px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
    marginBottom: "-15px!important",
  },
}));
let extensionPrice: any = 0;
const CapacityManagement = forwardRef((props: any, ref: any) => {
  extensionPrice = props.price;
  const { t, i18n } = useTranslation(["Extension"]);
  const [open, setOpen] = React.useState(false);
  const [operation, setOperation]: any = useState("add");
  const toaster = useToast();
  const ApiService = useApiService();
  const loader: any = useLoader();
  const configFormDetails: any = useRef();
  const [step, updateStep] = useState(1);

  const [id, setId]: any = useState();
  const [defaultCapacity, setDefaultCapacity]: any = useState();
  const [appData]: any = useAppContext();
  const [configData, setConfigData]: any = useState();
  const { register, handleSubmit, reset, errors } = useForm();
  const obj = {
    unit: {
      required: ValidationService.requiredValidator(),
    },
    defaultCapacity: {
      required: ValidationService.requiredValidator(),
    },
  };
  const classes = useStyles();
  useImperativeHandle(ref, () => ({
    handleOpen(operation?: any, configData?: any) {
      updateStep(1);

      if (operation === 1) {
        setOperation("edit");
        if (configData) {
          resetConfigData(configData);
          setConfigData(configData);
        } else if (props.config) {
          resetConfigData(props.config);
          setConfigData(props.config);
        }
        setOpen(true);
      } else {
        resetForm();
        setOperation("add");
        setOpen(true);
      }
    },
  }));
  const resetForm = () => {
    reset({
      unit: "",
      default_capacity: "",
      price: "",
    });
  };
  const onConfirm = () => {
    submitCapacityManagementData(configFormDetails.current.value);
  };
  const resetConfigData = (data: any) => {
    setId(data.id);
    setDefaultCapacity(data.default_capacity);
    reset({
      unit: data.unit,
      defaultCapacity: data.default_capacity,
    });
  };
  const openConfirmation = (data: any) => {
    let updateValue: any = false;

    if (data.unit != configData.unit) {
      updateValue = true;
    }
    if (data.defaultCapacity != configData.default_capacity) {
      updateValue = true;
    }
    if (!updateValue) {
      toaster.addToast({
        message: "Please update a value",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    configFormDetails.current.value = data;
    updateStep(2);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const submitCapacityManagementData = (data: any) => {
    if (!data?.unit.trim()) {
      toaster.addToast({
        message: t("Team:Please enter valid Unit"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (!data?.defaultCapacity.trim()) {
      toaster.addToast({
        message: t("Team:Please enter valid Capacity"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    var defaultCapacit = parseInt(data.defaultCapacity);
    if (defaultCapacit < 0) {
      toaster.addToast({
        message: t("Team:Default Capacity can not be Negative"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    let capacityData: any = {};
    const headers = {
      access_token: appData.loginData.access_token,
    };
    if (operation != "edit") {
      capacityData = {
        unit: data.unit,
        default_capacity: Number(data.defaultCapacity),
        price: Number(extensionPrice),
      };
    } else {
      capacityData = {
        id: id,
        unit: data.unit,
        default_capacity: Number(data.defaultCapacity),
      };
    }

    const reqData = {
      reqBody: capacityData,
      headers: headers,
    };

    loader.showLoader();
    return ApiService[operation === "edit" ? "put" : "post"](
      extensionService.capacityManagement(reqData, operation === "edit" ? 1 : 0)
    )
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        handleClose();
        props.handleCapacityManagementActivation();
        props.fetchCapacityDetail(true);
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} ref={configFormDetails}>
            {step === 1 ? (
              <form
                onSubmit={handleSubmit(
                  operation === "edit"
                    ? openConfirmation
                    : submitCapacityManagementData
                )}
              >
                <h2 id="modal-title">
                  {(operation != "edit" ? "Add " : "Edit ") + "Details"}
                </h2>
                <Input
                  type="text"
                  id="unit"
                  name="unit"
                  refValue={register(obj.unit)}
                  errors={errors}
                  label={t("Extension:Unit") + "*"}
                ></Input>
                <Input
                  type="number"
                  id="defaultCapacity"
                  name="defaultCapacity"
                  refValue={register(obj.defaultCapacity)}
                  errors={errors}
                  label={t("Extension:Default Capacity") + "*"}
                ></Input>
                <div>
                  <Btn
                    className={classes.btn}
                    type={"Submit"}
                    // onClick={onConfirm}
                    btnText={t("Common:Submit")}
                  >
                    {" "}
                  </Btn>
                  <Btn
                    onClick={() => {
                      setOpen(false);
                    }}
                    color={"default"}
                    type={"button"}
                    btnText={t("Common:Cancel")}
                  >
                    {" "}
                  </Btn>
                </div>
              </form>
            ) : (
              <div>
                <h2 id="modal-title">{t("Extension:Update Configuration")}</h2>
                <p style={{ textAlign: "left" }}>
                  {" "}
                  {t(
                    "Extension:If you update the configuration Settings, your resources capacity will change. Do you still want to continue?"
                  )}
                </p>
                <div>
                  <Btn
                    className={classes.btn}
                    type={"button"}
                    onClick={onConfirm}
                    btnText={t("Common:Confirm")}
                  >
                    {" "}
                  </Btn>
                  <Btn
                    onClick={handleClose}
                    color={"default"}
                    type={"button"}
                    btnText={t("Common:Discard")}
                  >
                    {" "}
                  </Btn>
                </div>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default CapacityManagement;
