import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import Btn from "../../../components/Button/Button";
import DeleteRounded from "@material-ui/icons/DeleteRounded";
import React, { Suspense, useEffect, useRef, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AddCircleSharp } from "@material-ui/icons";
import Dropdown from "../../../components/Dropdown/Dropdown";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import { useAppContext } from "../../../AppContext/App.context";
import ScheduledPaymentEndPoints from "../ScheduledPaymentsEndPoints";
import useApiService from "../../../services/api.service";
import useToast from "../../../components/Toast/hooks/useToast";
import AlertDialog from "../../../components/Dialog/Dialog";
import useLoader from "../../../hooks/useLoader";
import { daysList } from "./ScheduledPaymentConstant";
import { scheduleOptionList } from "./ScheduledPaymentConstant";
import { weekList } from "./ScheduledPaymentConstant";

const useStyles = makeStyles((theme) => ({
  tabBody: {
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "0 17px 24px 0 rgba(0, 126, 191, 0.26)",
    margin: "1.25rem 1rem",
    padding: "1.4rem 1.8rem",

    minHeight: "calc(95vh - 215px)",
  },
  btn: {
    margin: theme.spacing(1),
  },
}));
let resourceArray: any;
const PaymentRules = () => {
  const classes = useStyles();
  const [tagsArray, setTagsArray]: any = useState([]);
  const [appData]: any = useAppContext();
  const access_token = appData.loginData.access_token;
  const deleteTaskRef: any = useRef();
  const ApiService = useApiService();
  const [customerTagsArray, setCustomerTagsArray]: any = useState([]);
  const [deleteData, setDeleteData]: any = useState();
  const [rowId, setRowId]: any = useState();
  const [isLoading, setIsLoading]: any = useState(false);
  const loader: any = useLoader();

  const toaster = useToast();

  const addPaymentRule = () => {
    let temp = [
      ...tagsArray,
      {
        payment_frequency: null,
        customer_tag_id: null,
      },
    ];
    setTagsArray(temp);
  };

  const onScheduleOptionChange = (event: any, row: any) => {
    let temp = [...tagsArray];
    temp[row].payment_frequency = event.target.value;

    setTagsArray(temp);
  };
  const getCustomerTags = async () => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams: any = {
      limit: 1000,
      skip: 0,
      validate_resource_tags: false,
      tag_type: 2,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      ScheduledPaymentEndPoints.getTags(reqData)
    );

    setCustomerTagsArray(res.data.data);
    return res.data.data;
  };
  const getExistingRules = async (tagList?: any) => {
    if (!tagList) {
      tagList = customerTagsArray;
    }
    let headers = {
      access_token: access_token,
    };
    let reqBody = {};
    let reqData = {
      reqBody: reqBody,
      headers: headers,
    };
    loader.showLoader();
    const res: any = await ApiService.get(
      ScheduledPaymentEndPoints.getExistingRules(reqData)
    );

    var i: any, j: any;
    for (i = 0; i < res.data.length; i++) {
      if (res.data[i].payment_frequency == 0) {
        delete res.data[i]["payment_date"];
        delete res.data[i]["payment_day"];
      } else if (res.data[i].payment_frequency == 1) {
        delete res.data[i]["payment_date"];
        delete res.data[i]["payment_day"];
      } else if (res.data[i].payment_frequency == 2) {
        delete res.data[i]["payment_date"];
      } else if (res.data[i].payment_frequency == 4) {
        delete res.data[i]["payment_day"];
      }
    }
    resourceArray = res.data;
    if (res.data.length == 0 && tagsArray.length == 0) {
      let temp = [
        ...tagsArray,
        {
          payment_frequency: null,
          customer_tag_id: null,
        },
      ];
      setTagsArray(temp);
    } else {
      setTagsArray(res.data);
    }
    setTimeout(() => {
      loader.hideLoader();
      setIsLoading(true);
    }, 50);
  };
  const deleteRule = async (data: any) => {
    let headers = {
      access_token: access_token,
    };
    let reqBody = {
      rule_id: data,
    };
    let reqData = {
      reqBody: reqBody,
      headers: headers,
    };
    const res: any = await ApiService.put(
      ScheduledPaymentEndPoints.deleteRule(reqData)
    )
      .then((res: any) => {
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "sucess",
        });
        // getExistingRules();
      })
      .catch((err) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  const TagsValidation = () => {};

  const handleSubmit = (data: any) => {
    let validator: any = false;

    let temp: any = [];
    var value: any;
    for (value = 0; value < data.length; value++) {
      if (data[value].tags?.length == 0) {
        toaster.addToast({
          message: "Rule in row " + "" + (value + 1) + " is incomplete.",
          timeout: 3000,
          type: "error",
        });
        validator = true;
        break;
      }
      if (data[value].tags == undefined) {
        toaster.addToast({
          message: "Rule in row " + "" + (value + 1) + " is incomplete.",
          timeout: 3000,
          type: "error",
        });
        validator = true;
        break;
      }
      if (data[value].payment_frequency == undefined) {
        toaster.addToast({
          message: "Please Add Schedule Type for Row : " + "" + (value + 1),
          timeout: 3000,
          type: "error",
        });
        validator = true;
        break;
      }
      if (data[value].payment_frequency == 2) {
        if (data[value].payment_day == undefined) {
          toaster.addToast({
            message: "Please Add Payment Day for Row : " + "" + (value + 1),
            timeout: 3000,
            type: "error",
          });
          validator = true;
          break;
        }
      }
      if (data[value].payment_frequency == 4) {
        if (data[value].payment_date == undefined) {
          toaster.addToast({
            message: "Please Add Payment Date for Row : " + "" + (value + 1),
            timeout: 3000,
            type: "error",
          });
          validator = true;
          break;
        }
      }
      let tempTagId: any = [];
      data[value].tags.map((element: any, index: any) => {
        tempTagId.push(element.tag_id);

        data[value].customer_tag_id = tempTagId;
      });
      temp[value] = tempTagId;
      if (data[value]?.id) delete data[value]?.id;
    }
    if (validator) return;
    var i, j: any;
    for (i = 0; i < temp.length; i++) {
      for (j = i + 1; j < temp.length; j++) {
        if (temp[i]?.length == temp[j]?.length) {
          temp[i].sort();
          temp[j].sort();
          if (temp[i].join() == temp[j].join()) {
            toaster.addToast({
              message:
                "You cannot have multiple rules for same tags Rows are :" +
                (i + 1) +
                " ," +
                (j + 1),
              timeout: 3000,
              type: "error",
            });
            return;
          }
        }
      }
    }

    data.map((index: any, value: any) => {
      delete data[value].tags;
    });
    setIsLoading(false);

    createCustomerRule(data);
  };

  const createCustomerRule = async (data: any) => {
    let headers = {
      access_token: access_token,
    };
    let reqBody = {
      rules: data,
    };
    let reqData = {
      reqBody: reqBody,
      headers: headers,
    };
    loader.showLoader();
    return ApiService.put(ScheduledPaymentEndPoints.createCustomerTag(reqData))
      .then((res: any) => {
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "sucess",
        });
        getExistingRules();
        setTimeout(() => {
          loader.hideLoader();
          setIsLoading(true);
        }, 1000);
      })
      .catch((err) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
        loader.hideLoader();
        setIsLoading(true);
      });
  };

  useEffect(() => {
    getTagsAndRules();
  }, []);
  const getTagsAndRules = async () => {
    const tagList = await getCustomerTags();
    await getExistingRules(tagList);
  };
  const onConfirm = () => {
    if (Object.keys(deleteData).includes("rule_id")) {
      deleteRule(deleteData.rule_id);
    }
    let temp = [...tagsArray];
    let removed: any;
    removed = temp.splice(rowId, 1);
    resourceArray.splice(rowId, 1);
    if (temp.length == 0) {
      let temp1 = [
        {
          payment_frequency: null,
          customer_tag_id: null,
        },
      ];
      setTagsArray(temp1);
    } else {
      resourceArray = temp;
      setTagsArray(temp);
    }
    deleteTaskRef.current.handleClose();
  };
  const onDeleteFunction = (data: any, row: any) => {
    deleteTaskRef.current.handleClickOpen();
    setDeleteData(data);
    setRowId(row);
  };
  const onDiscard = () => {
    deleteTaskRef.current.handleClose();
  };
  return (
    <>
      <Grid item xs={12} className={classes.tabBody}>
        {isLoading && (
          <Grid>
            {tagsArray.map((index: any, row: any) => (
              <>
                <Grid key={`${index}`} direction="row" container>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={customerTagsArray}
                    noOptionsText={"Add Tag from Tags Manager"}
                    filterSelectedOptions
                    size="medium"
                    // clearText={""}
                    getOptionSelected={(option, value) =>
                      option.tag_name === value.tag_name
                    }
                    getOptionLabel={(option: any) => option?.tag_name}
                    value={index.tags || []}
                    onChange={(event, value) => {
                      let temp = [...tagsArray];
                      temp[row].tags = value;
                      setTagsArray(temp);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="normal"
                        style={{ width: 300 }}
                        label="Customer Tags"
                      />
                    )}
                  />
                  <div
                    style={{
                      width: "200px",
                      marginTop: "16px",
                      marginLeft: "7px",
                    }}
                  >
                    <Dropdown
                      required={true}
                      labelName="Schedule"
                      onChange={(event: any) => {
                        onScheduleOptionChange(event, row);
                      }}
                      selectedValue={tagsArray[row]?.payment_frequency}
                      itemList={scheduleOptionList}
                      uniqueId="value"
                      label="label"
                    ></Dropdown>
                  </div>
                  {/* ----------------------------MONTHLY CALENDER ------------------------------- */}
                  {tagsArray[row].payment_frequency == 4 && (
                    <div
                      style={{
                        width: "180px",
                        marginTop: "16px",
                        marginLeft: "7px",
                      }}
                    >
                      <Dropdown
                        required={true}
                        labelName="Select Date"
                        itemList={daysList}
                        selectedValue={tagsArray[row]?.payment_date}
                        onChange={(event: any) => {
                          let temp = [...tagsArray];
                          temp[row].payment_date = event.target.value;
                          setTagsArray(temp);
                        }}
                        uniqueId="value"
                        label="label"
                      ></Dropdown>
                    </div>
                  )}

                  {/* ---------------------------------------------------------------------------- */}
                  {/* ----------------------------WEEKLY ----------------------------------------- */}
                  {tagsArray[row].payment_frequency == 2 && (
                    <div
                      style={{
                        width: "200px",
                        marginTop: "16px",
                        marginLeft: "7px",
                      }}
                    >
                      {" "}
                      <Dropdown
                        required={true}
                        labelName="Day"
                        itemList={weekList}
                        uniqueId="value"
                        label="label"
                        selectedValue={tagsArray[row]?.payment_day}
                        onChange={(event: any) => {
                          let temp = [...tagsArray];
                          temp[row].payment_day = event.target.value;
                          setTagsArray(temp);
                        }}
                      ></Dropdown>
                    </div>
                  )}

                  {/* ---------------------------------------------------------------------------- */}
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fff",
                        marginLeft: "4px",
                        marginTop: "25px",
                      }}
                      startIcon={<DeleteRounded />}
                      type="button"
                      onClick={() => {
                        let temp = [...tagsArray];
                        onDeleteFunction(temp[row], row);
                      }}
                    />
                  </div>
                </Grid>
              </>
            ))}
          </Grid>
        )}
        {isLoading && (
          <>
            <div style={{ marginTop: "12px" }}>
              <Btn
                type="submit"
                onClick={() => {
                  addPaymentRule();
                }}
                startIcon={<AddCircleSharp />}
                btnText={tagsArray.length == 0 ? "Add" : "Add More"}
              />
            </div>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="baseline"
              style={{ marginTop: "1.2rem", marginBottom: "1.2rem" }}
            >
              {tagsArray.length != 0 ? (
                <>
                  <Btn
                    onClick={() => {
                      handleSubmit(tagsArray);
                    }}
                    // disable={previousTagsArray == tagsArray ? true : false}
                    type="submit"
                    btnText="Submit"
                  />
                  <Btn
                    color={"default"}
                    className={classes.btn}
                    type={"button"}
                    onClick={() => {
                      if (resourceArray.length == 0) {
                        let temp = [
                          {
                            payment_frequency: null,
                            customer_tag_id: null,
                          },
                        ];
                        setTagsArray(temp);
                      } else {
                        setTagsArray(resourceArray);
                      }
                    }}
                    btnText="Cancel"
                  />
                </>
              ) : (
                ""
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Suspense fallback={""}>
        <AlertDialog
          ref={deleteTaskRef}
          description="Are you sure you want to delete this  Rule?"
          title="Delete Rule"
          confirm="Confirm"
          discard="Discard"
          onConfirm={onConfirm}
          onDiscard={onDiscard}
        ></AlertDialog>
      </Suspense>
    </>
  );
};
export default PaymentRules;
