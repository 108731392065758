import React, {
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
// import googleMutant from "../../utils/leaflet/Leaflet.GoogleMutant"
import "../../../containers/Dashboard/Dashboard.scss";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import TaskLocationMap from "./TaskLocationMap";
import Btn from "../../Button/Button";
import { makeStyles } from "@material-ui/core/styles";
import PageHeading from "../../PageHeading/PageHeading";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "500px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
  },
}));
const TaskLocationPopup = forwardRef((props: any, ref: any) => {
  const { t, i18n } = useTranslation(["TaskDetail"]);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [historyDetail, setHistoryDetail]: any = useState("");
  const [type, setType]: any = useState();

  useImperativeHandle(ref, () => ({
    handleOpen(history: any, type: any) {
     
      setOpen(true);
      setType(type);
      setHistoryDetail(history);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <PageHeading
              title={
                type
                  ? type === 1
                    ? t("TaskDetail:Image")
                    : t("TaskDetail:Signature")
                  : t("TaskDetail:Location on map")
              }
            ></PageHeading>
            {type ? (
              <div style={{ maxHeight: "300px" }}>
                <img
                  style={{
                    padding: "10px",
                    maxHeight: "280px",
                    maxWidth: "100%",
                  }}
                  src={historyDetail}
                  alt="user icon"
                ></img>
              </div>
            ) : (
              <TaskLocationMap taskHistoryDetail={historyDetail} />
            )}
            <Btn
              onClick={() => {
                setOpen(false);
              }}
              color={"default"}
              type={"button"}
              btnText={"Close"}
            >
              {" "}
            </Btn>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default TaskLocationPopup;
