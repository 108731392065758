import React, { createContext, useReducer, useContext } from "react";
import { IToast } from "../Toast/interfaces";

const ToastRightContext = createContext({});

const initialState: Array<IToast> = [];

const ToastRightContextProvider = ({ reducer, children }: any) => {
  return (
    <ToastRightContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </ToastRightContext.Provider>
  );
};

const useToastRightContext = () => useContext(ToastRightContext);

export { ToastRightContext, ToastRightContextProvider, useToastRightContext };
