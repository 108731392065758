import { IToast } from "../../Toast/interfaces";
import * as actionTypes from "./actionTypes";

export const addToastRight = (data: IToast) => {
  return {
    type: actionTypes.ADD_TOAST,
    data: { ...data },
  };
};

export const addToastRightSuccess = (data: IToast) => {
  return {
    type: actionTypes.ADD_TOAST_SUCCESS,
    data: { ...data },
  };
};

export const removeToastRight = (id: number | undefined) => {
  return {
    type: actionTypes.REMOVE_TOAST,
    id,
  };
};
