export const ImportExportService: any = {
  getFormat(reqData: any) {
    return {
      url: "import/layout/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  importJob(reqData: any) {
    return {
      url: "import/import/job",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  exportJob(reqData: any) {
    return {
      url: "export/jobs/export",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  exportAgents(reqData: any) {
    return {
      url: "export/agent/export",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  exportCustomers(reqData: any) {
    return {
      url: "export/customer/export",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  exportMerchant(reqData: any) {
    return {
      url: "export/analytics/merchant",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  exportJobAsPDF(reqData: any) {
    return {
      url: "export/jobs/export",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  exportResourceAnalytics(reqData: any) {
    return {
      url: "export/analytics/resource",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },

  createFormat(reqData: any, operation?: number) {
    let url = "import/layout/";
    switch (operation) {
      case 1:
        url += "update";
        break;
      default:
        url += "create";
        break;
    }
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deleteFormat(reqData: any) {
    return {
      url: "import/layout/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};
