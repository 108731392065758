import { Subject } from "rxjs";
let state = {};
const subject = new Subject();
// const abortController = new AbortController();

const headerDropdownService = {
  // init: () => subject.next(state),
  subscribe: (update: any) => subject.subscribe(update),
  unsubscribe: () => subject.unsubscribe(),
  emit: (drodownData: any) => {
    state = drodownData;
    subject.next(state);
  },
  setData: (d: any) => subject.next(d),
  clearData: () => subject.next(),
  getData: () => subject.asObservable(),
  state,
};

export default headerDropdownService;
