import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// const useStyles = makeStyles({
//     list: {
//       width: 250,
//     },
//     fullList: {
//       width: "auto",
//     },
//   });
const useStyles = makeStyles({
  circle: {
    width: 14,
    height: 14,
    borderRadius: 7, //half radius will make it cirlce,
    backgroundColor: "#D3D3D4",
  },
  count: {
    // color: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1.5px",
    margin: "4px",
    fontSize: "9px",
  },
});
const Badge = (props: any) => {
  const styles = useStyles();
  return (
    <div className={styles.circle} style={{ ...props.style }}>
      <div className={styles.count}>{props.count}</div>
    </div>
  );
};

export default Badge;
