import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router";
// import { useAppContext } from "./AppContext/App.context";
import useLocalStorage from "./hooks/localStorage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

import EditProfile from "./containers/EditProfile/EditProfile";
import redirect from "./utils/Redirect";
import { register } from "./serviceWorker";
import { useAppContext } from "./AppContext/App.context";
import ScheduledPayments from "./containers/ScheduledPayments/ScheduledPayments";
import PaymentRules from "./containers/ScheduledPayments/PaymentRules/PaymentRules";
import CustomerSubscriptionsList from "./containers/Settings/CustomerSubscriptions/CustomerSubscriptionsList";
import JobsListByRefrenceId from "./containers/Jobs/JobsList/JobsListRefrenceId";
import LoginViaAccessToken from "./components/LoginViaAccessToken/LoginViaAccessToken";
import PageNotFound from "./components/404/PageNotFound";
import Temp from "./components/404/Temp";
// import AgentWalletConfig from "./containers/Extensions/AgentWallet/AgentWalletConfig";
// import AgentReferral from "./components/AgentReferral";
// import GoDaddy from "./containers/Domains/GoDaddy/GoDaddy";
const Login = lazy(() => import("./containers/Login/Login"));
const DeleteAccount = lazy(() =>
  import("./containers/DeleteAccount/DeleteAccount")
);
const ResetPassword = lazy(() =>
  import("./containers/ResetPassword/ResetPassword")
);

const AgentReferral = lazy(() => import("./components/AgentReferral"));
const BillingInvoices = lazy(() =>
  import("./containers/Settings/Billing/Invoices")
);
const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
// eslint-disable-next-line
const whyDidYouRender = require("@welldone-software/why-did-you-render");
const GeneralSettings = lazy(() =>
  import("./containers/Settings/GeneralSettings/GeneralSettings")
);

const TeamList = lazy(() =>
  import("./containers/Settings/Teams/TeamList/TeamList")
);
const GeofenceList = lazy(() =>
  import("./containers/Settings/Geofence/GeofenceList/GeofenceList")
);
const AutoAllocation = lazy(() =>
  import("./containers/Settings/AutoAllocation/AutoAllocation")
);
const ResourceSchedule = lazy(() =>
  import("./containers/Settings/ResourceSchedule/ResourceSchedule")
);
const WebhookLogs = lazy(() =>
  import("./containers/Settings/WebhookLogs/WebhookLogs")
);
const CustomReport = lazy(() =>
  import("./containers/roberto-analytics/CustomReport")
);
const CustomerPromoListComponent = lazy(() =>
  import("./containers/Settings/CustomerPromo/CustomerPromoList")
);
const EatHappyNowReport = lazy(() =>
  import("./containers/Analytics/EatHappyNow/EatHappyNowReport")
);
const SupervisorList = lazy(() =>
  import("./containers/Settings/Supervisor/SupervisorList/SupervisorList")
);
const TagsManager = lazy(() =>
  import("./containers/Settings/TagsManager/TagsManager")
);
const CustomerList = lazy(() =>
  import("./containers/Customers/List/CustomerList")
);
const ExtensionsList = lazy(() =>
  import("./containers/Extensions/ExtensionList")
);
const extensionDetail = lazy(() =>
  import("./containers/Extensions/ExtensionDetail")
);
const AgentWalletReport = lazy(() =>
  import("./containers/Extensions/AgentWallet/AgentWalletReport")
);
const WalletTransactionHistory = lazy(() =>
  import("./containers/Extensions/AgentWallet/WalletTransactionHistory")
);
const AddTransaction = lazy(() =>
  import("./containers/Extensions/AgentWallet/AddTransaction")
);
const JobsScheduler = lazy(() =>
  import("./containers/JobsScheduler/JobsScheduler")
);
const OverviewAnalytics = lazy(() =>
  import("./containers/Analytics/OverviewAnalytics")
);
const JobAnalytics = lazy(() => import("./containers/Analytics/JobAnalytics"));
const ResourceAnalytics = lazy(() =>
  import("./containers/Analytics/ResourceAnalytics")
);
const CustomerAnalytics = lazy(() =>
  import("./containers/Analytics/CustomerAnalytics")
);
const RouteAnalytics = lazy(() =>
  import("./containers/Analytics/Custom/RouteAnalytics")
);
const merchantAnalytics = lazy(() =>
  import("./containers/Analytics/merchantAnalytics")
);
const tripAnalytics = lazy(() =>
  import("./containers/Analytics/tripAnalytics")
);
const RoleList = lazy(() =>
  import("./containers/Settings/Roles/RoleList/RoleList")
);
const ResourceApp = lazy(() =>
  import("./containers/Settings/ResourceAppSettings/ResourceAppSetting")
);
const RecurringList = lazy(() =>
  import("./containers/Settings/Recurring/RecurringList")
);
const CustomerApp = lazy(() =>
  import("./containers/Settings/CustomerApp/CustomerApp")
);

const RecurringTasks = lazy(() =>
  import("./containers/Settings/Recurring/RecurringTasks")
);
const ResourceList = lazy(() => import("./containers/Resources/List/List"));
const VehicleAndAssetList = lazy(() =>
  import("./containers/VehicleAndAsset/VehicleAndAssetList")
);
const AddResource = lazy(() =>
  import("./containers/Resources/AddResource/AddResource")
);
const AddVehicleAndAssets = lazy(() =>
  import("./containers/VehicleAndAsset/AddVehicleAndAssets")
);
const editResource = lazy(() =>
  import("./containers/Resources/AddResource/AddResource")
);
const editVehicleAsset = lazy(() =>
  import("./containers/VehicleAndAsset/AddVehicleAndAssets")
);
const AddGeofence = lazy(() =>
  import("./containers/Settings/Geofence/AddGeofence/AddGeofence")
);
const editGeofence = lazy(() =>
  import("./containers/Settings/Geofence/AddGeofence/AddGeofence")
);
const AddResourceSchedule = lazy(() =>
  import(
    "./containers/Settings/ResourceSchedule/AddResourceSchedule/AddResourceSchedule"
  )
);
const createTemplate = lazy(() =>
  import(
    "./containers/Settings/Templates/CreateTemplate/CreateTemplateContainer"
  )
);
const templateList = lazy(() =>
  import("./containers/Settings/Templates/TemplateList/TemplateList")
);
const Notifications = lazy(() =>
  import("./containers/Settings/Notifications/List/NotificationList")
);
const EditNotification = lazy(() =>
  import("./containers/Settings/Notifications/AddNotification/AddNotifications")
);
const AddNotification = lazy(() =>
  import("./containers/Settings/Notifications/AddNotification/AddNotifications")
);
const EarningPricing = lazy(() =>
  import("./containers/Settings/Earning-Pricing/ListView/EarningList")
);
const NLevelCatalog = lazy(() =>
  import("./containers/Settings/NLevelCatalog/NLevelCatalog")
);
const AddOffering = lazy(() =>
  import("./containers/Settings/NLevelCatalog/Offerings/AddOffering")
);
const BookingformList = lazy(() =>
  import("./containers/Settings/Booking Form/BookingformList")
);
const AddBookingform = lazy(() =>
  import("./containers/Settings/Booking Form/AddBookingTemplate")
);
const EditBookingform = lazy(() =>
  import("./containers/Settings/Booking Form/AddBookingTemplate")
);
const AddEarningPricing = lazy(() =>
  import("./containers/Settings/Earning-Pricing/AddEarningRule/AddEarningRule")
);

const AgentIncentiveList = lazy(() =>
  import("./containers/Settings/AgentIncentive/AgentIncentives")
);

const AddAgentIncentive = lazy(() =>
  import("./containers/Settings/AgentIncentive/AddNewIncentiveRule")
);

const EditEarningPricing = lazy(() =>
  import("./containers/Settings/Earning-Pricing/AddEarningRule/AddEarningRule")
);
const Billing = lazy(() => import("./containers/Settings/Billing/Billing"));
const AddRole = lazy(() =>
  import("./containers/Settings/Roles/AddRole/AddRole")
);
const editRole = lazy(() =>
  import("./containers/Settings/Roles/AddRole/AddRole")
);
const VerificationReport = lazy(() =>
  import("./containers/Settings/VerificationReport/VerificationReport")
);
const JobsList = lazy(() => import("./containers/Jobs/JobsList/JobsList"));
const routesList = lazy(() =>
  import("./containers/Routes/RoutesList/RoutesList")
);
const routesLogs = lazy(() =>
  import("./containers/Routes/RoutesList/RouteLogs")
);
const Onboarding = lazy(() =>
  import("./containers/OnboardingUser/OnboardingUser")
);
const ApiKeyComponent = lazy(() =>
  import("./containers/Settings/ApiKey/ApiKey")
);
const ChangeLanguage = lazy(() =>
  import("./containers/Settings/LanguageManager/ChangeLanguage/ChangeLanguage")
);
const EditLanguage = lazy(() =>
  import("./containers/Settings/LanguageManager/UpdateLanguage/UpdateLanguage")
);
const StripeHistory = lazy(() =>
  import("./containers/Extensions/StripeConnect/TransactionHistory")
);
const Broadcast = lazy(() =>
  import("./containers/Extensions/BroadCast/Broadcast")
);
const Chat = lazy(() => import("./containers/Chat/Dashboard/Dashboard"));

const AppRoutes: React.FC = () => {
  const loginData: any = useLocalStorage.getItem("loginData");
  const [appData]: any = useAppContext();
  const onboardStep =
    appData?.loginData?.onboard_step || loginData.onboard_step;
  return (
    <Suspense fallback={""}>
      <Switch>
        {useLocalStorage.getItem("isAuthenticated") &&
        onboardStep &&
        !window.location.pathname.includes("trial_expire") ? (
          (
            window.location.pathname === "/resources/add" && onboardStep === 4
              ? null
              : window.location.pathname != "/dashboard/" && onboardStep < 6
          ) ? (
            <Redirect to={"/dashboard/"}></Redirect>
          ) : null
        ) : null}
        <Route exact path="/login">
          {useLocalStorage.getItem("isAuthenticated") ? (
            <Redirect to={redirect(loginData, true) || "/dashboard"}></Redirect>
          ) : (
            <Login></Login>
          )}
        </Route>
        <Route exact path="/">
          <Redirect to="/login"></Redirect>
        </Route>
        <Route exact path="/resetpassword">
          <ResetPassword></ResetPassword>
        </Route>
        <Route exact path="/deleteaccount">
          <DeleteAccount></DeleteAccount>
        </Route>
        <Route exact path="/loginviaaccesstoken">
          <LoginViaAccessToken></LoginViaAccessToken>
        </Route>

        <Route exact path="/register">
          {window.origin.includes("networkon") ||
          window.origin.includes("localhost") ? (
            useLocalStorage.getItem("loginData") &&
            (onboardStep === 7 || onboardStep === null) ? (
              <Redirect to="/dashboard"></Redirect>
            ) : (
              <Onboarding />
            )
          ) : (
            <Redirect to="/login"></Redirect>
          )}
        </Route>
        <PrivateRoute
          exact
          path="/settings/general/"
          component={GeneralSettings}
        />
        <PrivateRoute exact path="/settings/teams/" component={TeamList} />
        <PrivateRoute exact path="/chat/" component={Chat} />
        <PrivateRoute
          exact
          path="/settings/supervisors/"
          component={SupervisorList}
        />
        <PrivateRoute
          exact
          path="/settings/geofence/"
          component={GeofenceList}
        />
        <PrivateRoute
          exact
          path="/settings/autoallocation/"
          component={AutoAllocation}
        />
        <PrivateRoute
          exact
          path="/settings/resourceschedule/"
          component={ResourceSchedule}
        />
        <PrivateRoute
          exact
          path="/settings/resourceschedule/add"
          component={AddResourceSchedule}
        />
        <PrivateRoute exact path="/customers/list" component={CustomerList} />

        <PrivateRoute
          exact
          path="/extensions/list"
          component={ExtensionsList}
        />
        <PrivateRoute
          exact
          path="/extensions/broadcast"
          component={Broadcast}
        />
        <PrivateRoute
          exact
          path={"/extensions/promos"}
          component={CustomerPromoListComponent}
        />

        <PrivateRoute
          exact
          path="/extensions/list/:extensionId"
          component={extensionDetail}
        />
        <PrivateRoute
          exact
          path="/extensions/agentwallet/report"
          component={AgentWalletReport}
        />
        <PrivateRoute
          exact
          path="/extensions/agentwallet/history/:resourceId"
          component={WalletTransactionHistory}
        />
        <PrivateRoute
          exact
          path="/extensions/AddTransaction"
          component={AddTransaction}
        />

        <PrivateRoute
          exact
          path="/analytics/overview"
          component={OverviewAnalytics}
        />
        <PrivateRoute exact path="/analytics/job" component={JobAnalytics} />
        <PrivateRoute
          exact
          path="/analytics/resource"
          component={ResourceAnalytics}
        />
        <PrivateRoute
          exact
          path="/analytics/customer"
          component={CustomerAnalytics}
        />
        <PrivateRoute
          exact
          path="/analytics/route-analytics"
          component={RouteAnalytics}
        />
        <PrivateRoute
          exact
          path="/analytics/merchantAnalytics"
          component={merchantAnalytics}
        />
        <PrivateRoute
          exact
          path="/analytics/tripAnalytics"
          component={tripAnalytics}
        />

        <PrivateRoute exact path="/scheduler/" component={JobsScheduler} />
        <PrivateRoute exact path="/resources/list" component={ResourceList} />

        <PrivateRoute exact path="/resources/add" component={AddResource} />
        <PrivateRoute
          exact
          path="/vehicle/add"
          component={AddVehicleAndAssets}
        />
        <PrivateRoute exact path="/asset/add" component={AddVehicleAndAssets} />

        <PrivateRoute
          exact
          path="/resources/edit/:resourceId"
          component={editResource}
        />
        <PrivateRoute
          exact
          path="/vehicle/edit/:id"
          component={editVehicleAsset}
        />
        <PrivateRoute
          exact
          path="/asset/edit/:id"
          component={editVehicleAsset}
        />
        <PrivateRoute
          exact
          path="/analytics/eathappynow/report"
          component={EatHappyNowReport}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/settings/geofence/add"
          component={AddGeofence}
        />
        <PrivateRoute exact path="/routes/list" component={routesList} />
        <PrivateRoute
          exact
          path="/settings/geofence/edit/:geofenceId"
          component={editGeofence}
        />
        <PrivateRoute
          exact
          path="/settings/templates/add/"
          component={createTemplate}
        />
        <PrivateRoute
          exact
          path="/settings/templates/edit/:templateId"
          component={createTemplate}
        />
        <PrivateRoute
          exact
          path={["/settings/templates", "/settings/templates/:type"]}
          component={templateList}
        />
        <PrivateRoute
          exact
          path={["/settings/notifications/webhookLogs"]}
          component={WebhookLogs}
        />
        <PrivateRoute
          exact
          path={["/settings/notifications", "/settings/notifications/:type"]}
          component={Notifications}
        />
        <PrivateRoute
          exact
          path="/settings/notifications/:type/add"
          component={AddNotification}
        />

        <PrivateRoute
          exact
          path={["/settings/billing", "/settings/billing/:trial_expire"]}
          component={Billing}
        />

        <PrivateRoute
          exact
          path={["/extensions/payments/:type"]}
          component={ScheduledPayments}
        />

        <PrivateRoute
          exact
          path={["/extensions/subscription/:type"]}
          component={CustomerSubscriptionsList}
        />

        <PrivateRoute
          exact
          path={["/extensions/agentIncentive/:type"]}
          component={AgentIncentiveList}
        />

        <PrivateRoute
          exact
          path={["/extensions/agentIncentives/add"]}
          component={AddAgentIncentive}
        />

        <PrivateRoute
          exact
          path={["/extensions/agentIncentive/edit/:id"]}
          component={AddAgentIncentive}
        />
        <PrivateRoute
          exact
          path="/settings/catalog"
          component={NLevelCatalog}
        />
        <PrivateRoute
          exact
          path="/settings/catalog/offering"
          component={AddOffering}
        />
        <PrivateRoute
          exact
          path={["/settings/enp/:type"]}
          component={EarningPricing}
        />
        <PrivateRoute
          exact
          path={["/settings/earning/add", "/settings/pricing/add"]}
          component={AddEarningPricing}
        />
        <PrivateRoute
          exact
          path={["/settings/earning/edit/:Id", "/settings/pricing/edit/:Id"]}
          component={EditEarningPricing}
        />
        <PrivateRoute
          exact
          path={["/vehicle/list", "/asset/list"]}
          component={VehicleAndAssetList}
        />
        <PrivateRoute
          exact
          path="/settings/bookingform"
          component={BookingformList}
        />
        <PrivateRoute
          exact
          path="/settings/verificationReport"
          component={VerificationReport}
        />
        <PrivateRoute
          exact
          path="/settings/bookingform/add"
          component={AddBookingform}
        />
        <PrivateRoute
          exact
          path="/settings/bookingform/edit/:Id"
          component={EditBookingform}
        />
        <PrivateRoute
          exact
          path="/settings/customerapp"
          component={CustomerApp}
        />
        <PrivateRoute
          exact
          path="/settings/notifications/:type/edit/:Id/:template?"
          component={EditNotification}
        />
        <PrivateRoute
          exact
          path="/extensions/stripe/history"
          component={StripeHistory}
        />
        <PrivateRoute exact path="/settings/role/add" component={AddRole} />
        <PrivateRoute
          exact
          path="/settings/role/edit/:roleId"
          component={editRole}
        />
        <PrivateRoute exact path="/settings/roles/" component={RoleList} />
        <PrivateRoute
          exact
          path="/settings/recurring/"
          component={RecurringList}
        />
        <PrivateRoute
          exact
          path="/settings/resourceapp"
          component={ResourceApp}
        />
        <PrivateRoute
          exact
          path="/settings/resourceapp/signup"
          component={ResourceApp}
        />
        <PrivateRoute exact path="/analytics/report" component={CustomReport} />
        <PrivateRoute
          exact
          path="/settings/recurring/:ruleId"
          component={RecurringTasks}
        />
        <PrivateRoute exact path="/jobs/list" component={JobsList} />
        <PrivateRoute
          exact
          path="/jobs/list/byreferenceid"
          component={JobsListByRefrenceId}
        />

        <PrivateRoute
          exact
          path="/settings/language/changelanguage"
          component={ChangeLanguage}
        />
        <PrivateRoute
          exact
          path="/extensions/agentreferral"
          component={AgentReferral}
        />
        <PrivateRoute
          exact
          path="/settings/language/edit/:languageId"
          component={EditLanguage}
        />
        <PrivateRoute
          exact
          path="/settings/apikey/"
          component={ApiKeyComponent}
        />
        <PrivateRoute
          exact
          path="/settings/tagsmanager/"
          component={TagsManager}
        />
        {/* <PrivateRoute
          exact
          path="/settings/scheduledpayments"
          component={ScheduledPayments}
        /> */}
        <PrivateRoute exact path="/editprofile" component={EditProfile} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/temp" component={Temp} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Suspense>
  );
};

//AppRoutes.whyDidYouRender = true;

export default AppRoutes;
