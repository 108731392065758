import React, { useEffect, useState, Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./CreateJob.scss";
import useStyles from "./CreateJobStyle";
import {
  AccordionDetails,
  Grid,
  AccordionSummary,
  Accordion,
  Typography,
  RadioGroup,
  Radio,
  Switch,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  RecurringTypesArray,
  repeatDays,
} from "../../constants/recurringTypes";
import Dropdown from "../Dropdown/Dropdown";
import DateTimePickerInput from "../TemplatesCommonMethods/DateTimePickerInput";
import DateTimeFormatter from "../../utils/DateFormatter";
import Input from "../Input/Input";
import useLocalStorage from "../../hooks/localStorage";

const CreateRecurringJob = (props?: any) => {
  const { t, i18n } = useTranslation(["Common", "Createjob"]);
  const loginData: any = useLocalStorage.getItem("loginData");

  const recurringTypesArray = RecurringTypesArray(t);
  const classes = useStyles();
  const [recurringName, setRecurringName] = useState(
    props.repeatObj.recurringName
  );
  const [isRecurringEnabled, setIsRecurringEnabled] = useState(
    props.repeatObj.isRecurringEnabled
  );
  const [selectedRecurringType, setSelectedRecurringType] = useState(
    props.repeatObj.cycle
  );
  const [selectedDays, setSelectedDays]: any = useState({
    "0": true,
    "1": true,
    "2": true,
    "3": true,
    "4": true,
    "5": true,
    "6": true,
  });
  const [scheduleEndCriteria, setScheduleEndCriteria] = useState(0);
  const scrollRef: any = useRef(null);
  const [endDate, setEndDate]: any = useState("");
  const [occurrences, setOccurrences] = useState();
  const [scrollValue, setScrollValue]: any = useState();
  const handleChangeRecurringToggle = (evt: any) => {
    if (evt && evt.target.checked) {
      props.openManuallyAccordion("accordion-recurring");
    } else {
      props.openManuallyAccordion("");
    }
    setIsRecurringEnabled(evt.target.checked);
    if (evt.target.checked) {
      setScrollValue(true);
    } else {
      setScrollValue(false);
    }
    updateRepeatObj({
      key: "isRecurringEnabled",
      value: evt.target.checked,
    });
  };
  const updateSelectedDays = (dayId: number) => {
    let newData = { ...selectedDays };
    newData[dayId] = newData[dayId] === true ? false : true;
    setSelectedDays(newData);
    updateRepeatObj({
      key: "cycle_def",
      value: newData,
    });

    // }}
  };
  const handleScheduleCriteriaChange = (evt: any) => {
    setScheduleEndCriteria(evt.target.value);
    updateRepeatObj({
      key: "scheduleEndCriteria",
      value: evt.target.value,
    });
  };
  const updateRepeatObj = (data: any) => {
    let tempData: any = props.repeatObj;
    tempData[data.key] = data.value;
    props.updateRepeatObj(tempData);
  };

  useEffect(() => {
    setSelectedRecurringType(props.repeatObj.cycle);
    if (props.repeatObj.cycle_def) {
      setSelectedDays(props.repeatObj.cycle_def);
    }
    if (!loginData.time_format) {
      if (!DateTimeFormatter.checkIfTimeInTz) {
        setEndDate(props.repeatObj.endDate);
      } else {
        let convertedEndDate = DateTimeFormatter.converDateTimeTo24HoursWithoutUTC(
          props.repeatObj.endDate
        );
        setEndDate(convertedEndDate);
      }
    } else {
      let convertedEndDate = DateTimeFormatter.converDateTimeTo24HoursWithoutUTC(
        props.repeatObj.endDate
      );
      setEndDate(convertedEndDate);
    }

    setScheduleEndCriteria(props.repeatObj.scheduleEndCriteria);
    setOccurrences(props.repeatObj.occurrences);
  }, [props.repeatObj, props.expanded]);
  const scrollToBottom = (scrollOffset?: any) => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    if (scrollValue) {
      setTimeout(() => {
        scrollToBottom();
      }, 200);
    }
  }, [scrollValue]);
  return (
    <>
      <div>
        <div ref={scrollRef}>{""}</div>

        <div style={{ marginBottom: "10px" }}>
          {t("Createjob:Do you want to create recurring jobs?")}
          <Switch
            onClick={(event) => event.stopPropagation()}
            checked={isRecurringEnabled}
            onChange={handleChangeRecurringToggle}
            value="checked"
            color="primary"
          />
        </div>

        {isRecurringEnabled ? (
          <>
            <div>
              {/* <Accordion
            className={classes.accordionWrapper}
            expanded={props.expanded === "accordion-recurring" ? true : false}
            onChange={() => {
              if (props.expanded !== "accordion-recurring") {
                handleChangeRecurringToggle({ target: { checked: true } });
                props.handleChange("accordion-recurring");
              } else {
                props.openManuallyAccordion("");
              }
            }}
          >
            <AccordionSummary
              className={classes.expanded}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            > */}
              <Typography className="CreateJob__form__task-accordion__recurring-type-heading">
                {t("Createjob:Schedule Job")}
              </Typography>
              {/* </AccordionSummary>
            <AccordionDetails className={classes.padding0}> */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="CreateJob__form__recurring__scheduleName">
                    <Input
                      type="text"
                      id={"recurring-name"}
                      name={"recurring-name"}
                      onchange={(evt: any) => {
                        setRecurringName(evt.target.value);
                        updateRepeatObj({
                          key: "recurringName",
                          value: evt.target.value,
                        });
                      }}
                      value={recurringName}
                      label={t("Createjob:Schedule Name") + "*"}
                    ></Input>
                  </div>
                </Grid>

                <Grid
                  xs={6}
                  className="CreateJob__form__recurring__scheduleCycle"
                >
                  <Dropdown
                    labelName={t("Createjob:Schedule Cycle")}
                    MenuItemMaxWidth="100%"
                    onChange={(event: any) => {
                      setSelectedRecurringType(event.target.value);
                      updateRepeatObj({
                        key: "cycle",
                        value: event.target.value,
                      });
                    }}
                    translationComponentName=""
                    selectedValue={selectedRecurringType}
                    itemList={recurringTypesArray}
                    uniqueId="value"
                    label="name"
                  ></Dropdown>
                </Grid>
                {selectedRecurringType === 1 ? (
                  <Grid item xs={6} className={"CreateJob__repeatOn"}>
                    <div
                      style={{ marginTop: 0 }}
                      className={"CreateJob__repeatOn__repeat-heading"}
                    >
                      {t("Createjob:Repeat On")} :
                    </div>

                    {repeatDays.map((day: any) => (
                      <span
                        onClick={() => {
                          updateSelectedDays(day?.id);
                        }}
                        className={
                          selectedDays[day?.id] === true
                            ? "CreateJob__repeatOn__repeat-days-selected"
                            : "CreateJob__repeatOn__repeat-days"
                        }
                        key={day?.id}
                      >
                        {day.name}
                      </span>
                      // <span className="CreateJob__repeat-days-selected" key={day.id}>{day.name} </span>
                    ))}
                  </Grid>
                ) : //    <MaterialAutocomplete
                //    isMultiple={true}
                //    freeSolo={false}
                //    disableClearable={false}
                //    disablePortal={true}
                //    size="medium"
                //    id={"repeatOn"}
                //    name={"repeatOn"}
                //    options={repeatDays}
                //    labelName="name"
                //    value={repeatDays}
                //    onChange={(evt: any, newValue: any) => {
                //     //  onTemplateChange({
                //     //    data: newValue,
                //     //    index: index,
                //     //    taskType: taskType,
                //     //  });
                //      props.setValue('repeatOn', newValue, true);
                //    }}
                //    filterSelectedOptions
                //    onKeyDown={() => {}}
                //    textFieldClassName={classes.tagInputField}
                //    textFieldLabel={"Template"}
                //    textFieldPlaceholder={"Template"}
                //    textFieldMargin={"normal"}
                //    textFieldFullWidth={true}
                //  />
                null}

                <Grid
                  className={"CreateJob__repeatEnd"}
                  // style={{ marginLeft: "10px" }}
                  item
                  xs={6}
                >
                  <div className={"CreateJob__repeatOn__repeat-heading"}>
                    {t("Createjob:Scheduling end criteria")} :
                  </div>
                  <RadioGroup
                    value={scheduleEndCriteria}
                    onChange={handleScheduleCriteriaChange}
                    row
                    aria-label="position"
                    style={{ marginLeft: "-12px" }}
                    name="position"
                  >
                    <Radio id="recurring-end-date" color="primary" value="0" />
                    <label
                      htmlFor="recurring-end-date"
                      className={"CreateJob__repeatOn__schedule-criteria-label"}
                    >
                      {t("Createjob:End date")}
                    </label>
                    <Radio
                      id="no-of-occurrences"
                      name="no-of-occurrences"
                      color="primary"
                      value="1"
                    />
                    <label
                      htmlFor="no-of-occurrences"
                      className={"CreateJob__repeatOn__schedule-criteria-label"}
                    >
                      {t("Createjob:No. of occurrences")}
                    </label>
                    {/* <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label="End date"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Ends after no of occurences"
                  /> */}
                  </RadioGroup>
                  <Grid item xs={12}>
                    {/* {endDate} */}
                    {scheduleEndCriteria == 0 ? (
                      <div style={{ marginTop: "6px" }}>
                        <DateTimePickerInput
                          label={t("Createjob:End date")}
                          type={2}
                          className={
                            "CreateJob__form__task-accordion__date-input"
                          }
                          showIcon={true}
                          disablePast={true}
                          value={endDate}
                          onChange={(value: any, format: any) => {
                            let convertedEndDate = DateTimeFormatter.converDateTimeTo24HoursWithoutUTC(
                              value
                            );
                            const newDate = new Date(value);
                            newDate.setHours(23);
                            newDate.setMinutes(59);
                            newDate.setSeconds(59);
                            setEndDate(
                              DateTimeFormatter.changeOnlyFormatInSameTimeZone(
                                newDate,
                                "YYYY-MM-DD"
                              )
                            );
                            updateRepeatObj({
                              key: "endDate",
                              value: newDate,
                            });
                          }}
                        ></DateTimePickerInput>
                      </div>
                    ) : (
                      <div
                        className={
                          "CreateJob__repeatOn__schedule-no-of-occurences"
                        }
                      >
                        <Input
                          type="text"
                          id="occurence"
                          value={occurrences}
                          name="occurrences"
                          label={t("Createjob:No. of occurrences")}
                          onchange={(evt: any) => {
                            setOccurrences(evt.target.value);
                            updateRepeatObj({
                              key: "occurrences",
                              value: evt.target.value,
                            });
                          }}
                          onkeypress={() => {}}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {/* </AccordionDetails>
          </Accordion> */}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default CreateRecurringJob;
