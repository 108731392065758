export enum TemplateDataTypeEnum {
  TEXT = 0,
  NUMBER = 1,
  DATE = 2,
  DATE_TIME = 3,
  IMAGE = 4,
  TIME = 5,
  DOCUMENT = 6,
  DROPDOWN = 7,
  CHECKLIST = 8,
  BARCODE = 9,
  RECORDING = 10,
  PHONENUMBER = 11,
  EMAIL = 12,
  Url = 13,
}
