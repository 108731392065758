export const userPermissionRoles: any = {
  "1": {
    name: "Read",
    permission: 1,
  },
  "2": {
    name: "Create",
    permission: 2,
  },
  "3": {
    name: "Update",
    permission: 3,
  },
  "4": {
    name: "Delete",
    permission: 4,
  },
};
