import React from "react";
import classes from "./backdrop.module.scss";

const Backdrop: React.FC<any> = (props) => {
  return (
    <div
      className={classes.backdrop}
      style={{
        backgroundColor: props.config?.color ? props.config?.color : "#ccc",
      }}
    ></div>
  );
};
export default Backdrop;
