import React, {
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
} from "react";
// import googleMutant from "../../utils/leaflet/Leaflet.GoogleMutant"
import "../../../containers/Dashboard/Dashboard.scss";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-material-ui-carousel";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "500px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
  },
  image: {
    padding: "10px",
    maxHeight: "300px",
    maxWidth: "300px",
  },
}));
const TemplateImages = forwardRef((props: any, ref: any) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [index, setIndex]: any = useState();
  var items = props.imageArray;

  useImperativeHandle(ref, () => ({
    handleOpen(index: any) {
      //initMap();
      setOpen(true);
      setIndex(index);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Carousel index={index || 0} autoPlay={false}>
              {items &&
                items.map((item: any) => (
                  <img
                    className={classes.image}
                    src={item}
                    alt="user icon"
                  ></img>
                ))}
            </Carousel>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default TemplateImages;
