import React, { useEffect, useState, Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../AppContext/App.context";
import { makeStyles } from "@material-ui/core/styles";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import Grid from "@material-ui/core/Grid";
import CommonEndpoints from "../../services/commonApi.service";
import useApiService from "../../services/api.service";
import userDefaultIcon from "../../assets/images/resources/user.png";
import Input from "../../components/Input/Input";
import { useForm } from "react-hook-form";
import useToast from "../../components/Toast/hooks/useToast";
import Phone from "../../components/phone-picker/phone-picker";
import { ValidationService } from "../../utils/Validation";
import EditProfileEndpoints from "./EditProfile.endpoints";
import useLoader from "../../hooks/useLoader";
import useLocalStorage from "../../utils/localStorage";
import Autocomplete from "../../components/Autocomplete/Autocomplete";
import Dropdown from "../../components/Dropdown/Dropdown";
import { BusinessUsecase } from "../../constants/businessUsecase";
import Btn from "../../components/Button/Button";
import DeleteAccount from "./DeleteAccount";
import ChangePassword from "./ChangePassword";
import headerDropdownService from "../../services/headerDropdown.service";
import "./EditProfile.scss";
import { AnyARecord } from "dns";
import ResetPassword from "../ResetPassword/ResetPassword";
import { TrendingUpTwoTone } from "@material-ui/icons";
import Timezone from "./Timezone";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "-25px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "auto",
  },
  basicinfo: {
    padding: "12px",
  },
  item: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "0px !important",
  },
  pageheading: {
    fontSize: "22px",
  },
  root: {
    width: "100%",
    marginLeft: "5%",
  },
  btn: {
    margin: theme.spacing(2),
  },
}));

const EditProfile = (props: any, ref: any) => {
  const { t, i18n } = useTranslation(["Profile", "Common"]);
  const [
    isShownUploadProfilePictureIcon,
    showUploadProfilePictureIcon,
  ] = useState(false);
  const businessUsecase = BusinessUsecase(t);
  // console.log(businessUsecase);
  const setAutocomplete: any = useRef();
  const [businessType, setBusinessType]: any = useState();
  const [selectedTimezone, setSelectedTimezone]: any = useState();
  // console.log(selectedTimezone,"selectedTimezone");
  const [mapList, setMapList]: any = useState([]);
  const [locationHelperText, setLocationHelperText]: any = useState("");
  const fileUpload: any = React.createRef();
  const [image, setImage]: any = useState(userDefaultIcon);
  const ApiService = useApiService();
  const [isLoading, setIsLoading] = React.useState(true);
  const [appData]: any = useAppContext();
  const [showDropdown, setShowDropdown]: any = useState(false);
  const { register, handleSubmit, errors, getValues, reset } = useForm({
    mode: "onChange",
  });

  const userId = appData.loginData.user_id;
  const access_token = appData.loginData.access_token;
  const classes = useStyles();
  const toaster = useToast();
  const dialCode: any = useRef<any>();
  const countryCode: any = useRef();
  const loader: any = useLoader();

  const [profiledata, setProfileData]: any = useState({
    name: "",
    phone: "",
    company_name: "",
    address: "",
    business_usecase: "",
    account_timezone: "",
  });

  const [open, setOpen] = React.useState(false);
  const [preSavedCountryCode, updatePreSavedCountryCode]: any = useState("");

  const localStorage = useLocalStorage;

  const details = appData.loginData;
  // // console.log("Details", details);
  // // console.log(access_token);
  // // console.log(details.name);
  // // console.log(details.email);

  useEffect(() => {
    getUserProfileDetails(details.email.toString());
    //clearFields();
  }, []);

  const clearFields = () => {
    reset({
      name: "",
      phone: "",
      email: details.email,
    });
  };

  const setPrefillProfile = (res: any) => {
    // console.log("prefill details", res[0]);

    if (res[0].user_image) {
      setImage(res[0].user_image);
    } else {
      setImage(userDefaultIcon);
    }

    reset({
      name: res[0].name,
      email: res[0].email,
      //country_code: countrycode,
      phone: res[0].phone_number,
      company_name: res[0].company_name !== null ? res[0].company_name : "",
      // account_timezone: res[0].account_timezone,
      //image:  res[0].user_image,
    });

    setSelectedTimezone(res[0].account_timezone);

    if (res[0].use_case !== 0 || res[0].usecase !== null) {
      setBusinessType(res[0].use_case);
    }

    if (res[0].address && res[0].latitude && res[0].longitude) {
      let updatedAddress: any = [];
      let temp: any = {};
      temp["address"] = res[0].address;
      temp["latitude"] = res[0].latitude || 0;
      temp["longitude"] = res[0].longitude || 0;
      // console.log([{ ...temp }]);
      // console.log(temp);
      updatedAddress.push(temp);
      // console.log(updatedAddress);
      setMapList([{ ...temp }]);
      if (setAutocomplete && setAutocomplete.current)
        setAutocomplete.current.onValueChange();
    }
    setIsLoading(false);

    updatePreSavedCountryCode(
      res[0].country_code
        ? res[0].country_code.substring(1)
        : preSavedCountryCode
    );
  };

  const getUserProfileDetails = async (userEmail: any) => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams = {
      email: userEmail,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      CommonEndpoints.getUserProfileDetails(reqData)
    );

    const profileDetail: any = res.data;
    // console.log("Details of get API", profileDetail);
    //setProfileData(profileDetail);
    // validation(profileDetail);
    setPrefillProfile(profileDetail);
    setProfileData(profileDetail);
  };

  // const triggerActionToHeader = (action: string) => {
  //   const imageforHeader = {
  //     type: "ProfileImage",
  //     data: { value: image },
  //   };

  //   headerDropdownService.emit(imageforHeader);
  //   //headerDropdownService.setData(dropdownData);
  //   // console.log(imageforHeader);
  // };

  const ImageHandler = async (ev: any) => {
    const formData = new FormData();
    // formData.append("access_token", appData.loginData.access_token);
    // formData.append("user_id", appData.loginData.user_id);
    formData.append("file", ev.target.files[0]);
    formData.append("entity", "resource");
    // formData.append("image_type", "undefined");
    // formData.append("user_type", appData.loginData.user_type);
    const res = await ApiService.postFormData(
      CommonEndpoints.uploadImage(formData)
    );
    // console.log(res);
    setImage(res.data.url);
  };

  const showFileUpload = () => {
    fileUpload.current.click();
  };

  const obj = {
    //user_image: {},
    name: {
      required: ValidationService.requiredValidator(),
    },
    phone: {
      required: ValidationService.requiredValidator(),
    },
    email: {},
    company_name: {},
    //address: {},
    //businessUsecase: {},
    // password: {
    //   pattern: ValidationService.passwordValidator(),
    // },
    // confirmpassword: {},
  };
  const fetchAddress = (arg: any) => {
    // console.log(arg);
    let temp: any = {};
    temp["address"] = arg.address || "";
    temp["latitude"] = arg.latLng?.latitude || 0;
    temp["longitude"] = arg.latLng?.longitude || 0;
    setMapList([{ ...temp }]);

    // console.log(temp);
    // console.log(mapList);
  };

  const handleChangeBasicInfo = (data: any) => {
    // console.log(profiledata);
    if (!data.name.trim()) {
      toaster.addToast({
        message: "Please enter valid name",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (profiledata[0].use_case && !businessType) {
      toaster.addToast({
        message: "Please select Business Type",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (profiledata[0].address && !mapList[0].address) {
      toaster.addToast({
        message: "Please enter valid address",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (profiledata[0].company_name && !data.company_name.trim()) {
      toaster.addToast({
        message: "Please enter valid company name",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    const request: any = {};
    request["user_id"] = userId;
    if (selectedTimezone) request["account_timezone"] = selectedTimezone;
    if (data.name.trim()) {
      request["name"] = data.name;
    }
    if (image !== userDefaultIcon) {
      request["user_image"] = image;
    }
    request["country_code"] = dialCode.current;
    request["phone_number"] = parseInt(data.phone);

    if (data.company_name.trim()) {
      request["company_name"] = data.company_name;
    }
    // if (data.account_timezone){
    //   request["account_timezone"]= selectedTimezone
    // }

    if (locationHelperText) {
      setLocationHelperText("Unable to find location");
      return false;
    }
    if (mapList && mapList[0] && mapList[0].address) {
      request["address"] = mapList[0].address.trim();
      request["latitude"] = parseFloat(mapList[0].latitude);
      request["longitude"] = parseFloat(mapList[0].longitude);
    }
    if (businessType) {
      request["use_case"] = businessType;
    }
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    loader.showLoader();
    return ApiService.put(EditProfileEndpoints.updateProfile(reqData))
      .then((res: any) => {
        loader.hideLoader();
        let loginData = appData.loginData;
        loginData.name = res.data.name;
        loginData.country_code = request["country_code"];
        loginData.email = res.data.email;
        loginData.latitude = res.data.latitude;
        loginData.longitude = res.data.longitude;

        loginData.access_token = res.data.access_token;
        loginData.user_image = image;
        localStorage.setItem("loginData", loginData);
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        let imageforHeader: any = { type: "ProfileImage", value: image };
        headerDropdownService.emit(imageforHeader);
        //getUserProfileDetails(details.email.toString(), appData.loginData.access_token)
        // triggerActionToHeader("profileIconUpdated");
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  const fetchDialCode = (dialcode: any, countrycode: any) => {
    dialCode.current = "+" + dialcode;
    countryCode.current = countrycode.toUpperCase();
  };

  const setTimeZone = () => {};

  return (
    <Fragment>
      <div className="EditProfile">
        <div className={classes.pageheading}>
          <div className="EditProfile__page-title">
            {t("Profile:Edit Profile")}
          </div>
        </div>
        <div className="EditProfile__profile-picture-container">
          <Grid
            className={classes.container}
            container
            style={{ marginTop: "50px" }}
          >
            <Grid className={classes.item} item xs={12}>
              <img
                src={image}
                alt="user icon"
                onMouseEnter={() => showUploadProfilePictureIcon(true)}
                onMouseLeave={() => showUploadProfilePictureIcon(false)}
              ></img>
              {isShownUploadProfilePictureIcon ? (
                <div
                  onClick={showFileUpload}
                  onMouseEnter={() => showUploadProfilePictureIcon(true)}
                  onMouseLeave={() => showUploadProfilePictureIcon(false)}
                  className="EditProfile__profile-picture-container__camera"
                >
                  <CameraAltOutlinedIcon color="inherit"></CameraAltOutlinedIcon>
                </div>
              ) : (
                ""
              )}
              <input
                accept="image/*"
                type="file"
                className="EditProfile__profile-picture-container__upload-input"
                onChange={ImageHandler}
                ref={fileUpload}
              ></input>
            </Grid>
          </Grid>
        </div>
      </div>
      {isLoading ? (
        <div></div>
      ) : (
        <div className={classes.root}>
          <Grid item xs={11}>
            <div className={classes.basicinfo}>
              <h2>{t("Profile:Basic Info")}</h2>

              <form
                className="changeBasicInfo"
                onSubmit={handleSubmit(handleChangeBasicInfo)}
              >
                <Grid
                  container
                  justify="center"
                  className={classes.container}
                  spacing={6}
                >
                  <Grid item xs={6}>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      refValue={register(obj.name)}
                      // required={true}
                      errors={errors}
                      label={t("Common:Name") + "*"}
                    ></Input>

                    <Input
                      disabled
                      type="text"
                      id="email"
                      name="email"
                      refValue={register(obj.email)}
                      // required={true}
                      errors={errors}
                      label={t("Common:Email") + "*"}
                    ></Input>
                    <Autocomplete
                      color={"#16449b"}
                      label={t("Common:Please enter address")}
                      name={"resourceAddress"}
                      ref={setAutocomplete}
                      value={mapList[0] ? mapList[0]["address"] : ""}
                      errors={errors}
                      triggerLocationValidate={(isValid: boolean) => {
                        if (!isValid) {
                          // errors[`task[${taskType}][${index}].location`]=true;
                          setLocationHelperText("Unable to find location");
                        } else {
                          // errors[`task[${taskType}][${index}].location`]=false;
                          setLocationHelperText(false);
                        }
                      }}
                      helperText={locationHelperText}
                      onAutocompleteLocationSelected={fetchAddress}
                    ></Autocomplete>
                  </Grid>

                  <Grid item xs={6}>
                    <Phone
                      hideLabel={true}
                      showPhone={true}
                      //  setDialCode={fetchDialCode}
                      setDialCode={(dialcode: any, countrycode: any) => {
                        fetchDialCode(dialcode, countrycode);
                      }}
                      name="phone"
                      id="phone"
                      label={"Mobile Number"}
                      refValue={register(obj.phone)}
                      placeholder={t("Common:Enter Mobile Number") + "*"}
                      disabled={false}
                      required={true}
                      errors={errors}
                      defaultPhone={preSavedCountryCode}
                    ></Phone>

                    <Input
                      type="text"
                      id="company_name"
                      name="company_name"
                      refValue={register(obj.company_name)}
                      // required={true}
                      errors={errors}
                      label={t("Profile:Company Name")}
                    ></Input>

                    {/* <Dropdown
                      // label={t("Profile:Business Usecase")}
                      labelName={t("Profile:Business Usecase")}
                      className={"business_usecase"}
                      showDropdown={showDropdown}
                      value={businessType}
                      onChange={(e: any) => {
                        setBusinessType(e.target.value);
                      }}
                      itemList={businessUsecase}
                      selectedValue={businessType}
                      uniqueId="use_case"
                      label="job_name"
                    ></Dropdown> */}

                    <Dropdown
                      labelName={t("Profile:Time Zone")}
                      className={"business_usecase"}
                      showDropdown={showDropdown}
                      name={"account_timezone"}
                      value={selectedTimezone}
                      onChange={(e: any) => {
                        setSelectedTimezone(e.target.value);
                      }}
                      itemList={Timezone}
                      selectedValue={selectedTimezone}
                      uniqueId="value"
                      label="name"
                    ></Dropdown>
                  </Grid>

                  <Grid container justify="center">
                    <Btn
                      className={classes.btn}
                      type={"Submit"}
                      btnText={t("Common:Submit")}
                    ></Btn>

                    <Btn
                      className={classes.btn}
                      onClick={() => {
                        //clearFields();
                        getUserProfileDetails(details.email.toString());
                        //setPrefillProfile(profiledata);
                      }}
                      color={"default"}
                      type={"button"}
                      btnText={t("Common:Cancel")}
                    ></Btn>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>

          <Grid item xs={11}>
            <ChangePassword></ChangePassword>
          </Grid>

          <Grid item xs={11}>
            <DeleteAccount></DeleteAccount>
          </Grid>
        </div>
      )}
    </Fragment>
  );
};

export default EditProfile;
