import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppContext } from "../../AppContext/App.context";
import { userPermissionRoles } from "../../constants/userPermissionRoles";
import useLoader from "../../hooks/useLoader";
import useApiService from "../../services/api.service";
import CommonEndpoints from "../../services/commonApi.service";
import useLocalStorage from "../../utils/localStorage";
import { updateGoogleAPIKey } from "../../utils/updateGoogleAPIKey";
import useToast from "../Toast/hooks/useToast";
import LoginDataViaAccessToken from "./LoginViaAccessToken.endpoints";
import * as actions from "../../AppContext/actions/actions";
import Redirect from "../../utils/Redirect";

const LoginViaAccessToken = () => {
  const { search } = useLocation();
  const accessToken = search?.slice(7);
  const toaster = useToast();
  const loader: any = useLoader();
  const ApiService = useApiService();
  const [, dispatch]: any = useAppContext();
  const history = useHistory();

  const getPermissions = async (loginData: any) => {
    if (!Object.keys(loginData).length) {
      return;
    }
    let headers = {
      access_token: accessToken,
    };
    let qureyParams = {
      role_id: loginData.role_id,
      limit: 1,
      skip: 0,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      CommonEndpoints.getRolesList(reqData)
    );
    const roleDetail: any = res.data.data[0];
    var TabPermission: any = {};
    let modpermsvalues: any = Object.values(roleDetail.modperms);
    if (modpermsvalues && modpermsvalues.length > 0) {
      modpermsvalues.map((mode: any) => {
        let moduleName: any =
          mode.module_alias.charAt(0).toUpperCase() +
          mode.module_alias.slice(1).replace(/_/g, "");
        for (var key in mode.permission) {
          TabPermission[`${userPermissionRoles[key].name}_${moduleName}`] =
            mode.permission[key].access_type;
        }
      });
    }
    useLocalStorage.setItem("Permission", TabPermission);
    return true;
  };

  const getActivatedExtension = async (loginData: any) => {
    let header: any = {
      access_token: loginData.access_token,
    };
    let request: any = {
      limit: 1000,
      skip: 0,
      user_id: loginData.user_id,
    };
    let reqData = {
      reqBody: request,
      headers: header,
    };
    let data: any = [];
    await ApiService.get(CommonEndpoints.activatedExtensionList(reqData))
      .then(function (response: any) {
        response.data.map((item: any) => {
          data.push(item.extension_id);
        });

        localStorage.setItem("activatedExtension", JSON.stringify(data));
      })
      .catch((err: any) => {});
    return true;
  };

  const getLoginDataViaAccessToken = async () => {
    const headers: any = {
      access_token: accessToken,
    };
    const reqData = {
      headers: headers,
    };

    loader.showLoader();

    return ApiService.post(LoginDataViaAccessToken.getLoginData(reqData))
      .then(async (res: any) => {
        res.data["access_token"] = accessToken;
        await updateGoogleAPIKey(res.data.google_api_key);
        await getPermissions(res.data);
        await getActivatedExtension(res.data);
        useLocalStorage.setItem("loginData", res.data);
        dispatch(actions.onLoginResponse(res.data));

        if (
          (res.data.onboard_step >= 3 && res.data.onboard_step <= 7) ||
          !res.data.onboard_step
        ) {
          localStorage.setItem("isAuthenticated", "1");
          Redirect(res.data);
        } else {
          history.push("/register");
        }
        loader.hideLoader();
      })

      .catch((err) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  useEffect(() => {
    getLoginDataViaAccessToken();
  }, []);

  return <div></div>;
};

export default LoginViaAccessToken;
