import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/";
import ClearIcon from "@material-ui/icons/Clear";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import useApiService from "../../services/api.service";
import CommonEndpoints from "../../services/commonApi.service";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
const useStyles = makeStyles((theme) => ({
  fieldName: {
    marginBottom: "10px",
    fontWeight: 600,
  },
  fieldUpload: {
    alignItems: "center",
    width: "40px",
    height: "40px",
    border: "1px dashed rgba(0, 0, 0, 0.87)",
    borderRadius: "100%",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
  },
  uploadedImageDiv: {
    position: "relative",
    // display: "flex!important",
    // alignItems: "center!important",

    "&:hover": {
      // backgroundColor:"red",
      // "&removeImage":{
      //     display:"block"
      // }
      // "& $removeImage": {
      //    display:"block"
      //   }
    },
  },

  inputFileUpload: {
    display: "none",
  },
  images: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "10px",
    flexDirection: "row",
    verticalAlign: "middle",
    alignItems: "center",
  },
  uploadedImage: {
    border: "1px solid rgba(0, 0, 0, 0.87)",
    borderRadius: "100%",
    width: "40px",
    height: "40px",
    marginRight: "10px",
  },
  uploadedDoc: {
    border: "1px solid rgba(0, 0, 0, 0.87)",
    borderRadius: "100%",
    width: "40px",
    height: "40px",
    marginRight: "10px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
  },
  removeImage: {
    position: "absolute",
    left: "25px",
    background: "#fff",
    cursor: "pointer",
    borderRadius: "100%",
  },
}));
const ImageInTemplateForm = (props: any) => {
  const classes = useStyles();
  const ApiService = useApiService();
  const fileUpload: any = useRef();

  const item = props.item;
  const [value, updateValue]: any = useState([]);
  useEffect(() => {
    if (typeof props.item.value === "string") {
      if (item.data_type === 10) {
        updateValue(props.item.value ? [props.item.value] : []);
      } else {
        updateValue(props.item.value);
      }
    } else if (typeof props.item.value === "object") {
      updateValue(props.item.value);
    } else {
      updateValue([]);
    }
  }, [props.value]);

  const ImageHandler = async (ev: any) => {
    const formData = new FormData();

    formData.append("file", ev.target.files[0]);
    formData.append("entity", "template");

    const res = await ApiService.postFormData(
      CommonEndpoints.uploadImage(formData)
    );

    let oldImageArray = [...value];

    if (item.data_type === 10) {
      oldImageArray = [res.data.url];
      updateValue([res.data.url]);
    } else {
      oldImageArray.push(res.data.url);
      updateValue([...value, res.data.url]);
    }
    props.onChange(oldImageArray);
    if (fileUpload.current) {
      fileUpload.current.value = "";
    }
  };
  const showFileUpload = () => {
    fileUpload.current.click();
  };
  const removeImage = (index: number) => {
    const oldImageArray = [...value];
    // value.splice(index, 1);
    oldImageArray.splice(index, 1);
    updateValue(oldImageArray);
    props.onChange(oldImageArray);
  };
  // const updateNewDataInView
  const RenderImage = (imgUrl: string, index: number) => {
    return (
      <span className={classes.uploadedImageDiv}>
        <ClearIcon
          className={classes.removeImage}
          onClick={() => removeImage(index)}
          fontSize="small"
        ></ClearIcon>
        <img
          alt="image"
          className={classes.uploadedImage}
          onClick={() => window.open(imgUrl)}
          src={imgUrl}
        />
      </span>
    );
  };
  const RenderDocument = (url: string, index: number) => {
    return (
      <span className={classes.uploadedImageDiv}>
        <ClearIcon
          className={classes.removeImage}
          onClick={() => removeImage(index)}
          fontSize="small"
        ></ClearIcon>
        <div className={classes.uploadedDoc} style={{ marginRight: "10px" }}>
          <InsertDriveFileIcon
            onClick={() => window.open(url)}
          ></InsertDriveFileIcon>
        </div>
      </span>
    );
  };
  const RenderRecording = (url: string, index: number) => {
    return (
      <span className={classes.uploadedImageDiv}>
        <ClearIcon
          className={classes.removeImage}
          onClick={() => removeImage(index)}
          fontSize="small"
        ></ClearIcon>
        <div className={classes.uploadedDoc} style={{ marginRight: "10px" }}>
          <MusicNoteIcon onClick={() => window.open(url)}></MusicNoteIcon>
        </div>
      </span>
    );
  };
  return (
    <div className="input__box-image">
      <div className={classes.fieldName}> {item.name}:</div>
      <div className={classes.images}>
        {/* {console.log(value.length,'sdsadsadasdasdsadasdasdadsadasdasdasdasdasdasdasd')} */}
        {value &&
          item.data_type === 10 &&
          value.length > 0 &&
          value.map((imgUrl: any, index: number) => {
            return imgUrl ? RenderRecording(imgUrl, index) : null;
          })}
        {value &&
          item.data_type === 4 &&
          value.length > 0 &&
          value.map((imgUrl: any, index: number) => {
            return imgUrl ? RenderImage(imgUrl, index) : null;
          })}
        {value &&
          item.data_type === 6 &&
          value.length > 0 &&
          value.map((url: any, index: number) => {
            return url ? RenderDocument(url, index) : null;
          })}
        {value.length === 1 && item.data_type === 10 ? (
          <></>
        ) : (
          <div className={classes.fieldUpload} onClick={showFileUpload}>
            <CloudUploadIcon></CloudUploadIcon>
            <input
              accept={props.accept || "image/*"}
              type="file"
              className={classes.inputFileUpload}
              onChange={ImageHandler}
              ref={fileUpload}
            ></input>
          </div>
        )}
      </div>
    </div>
  );
};
export default ImageInTemplateForm;
