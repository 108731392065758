import React, { useEffect, useState } from "react";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment-timezone";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
import { TemplateDataTypeEnum } from "../../enum/Template.enum";
import DateTimeFormatter, {
  defaultTimeForamt,
  defaultDateFormat,
  defaultDateTimeFormat,
} from "../../utils/DateFormatter";
import { themeColor } from "../../constants/themeColor";
import { useLocation } from "react-router";

const DateTimePickerInput = (props: any) => {
  const [value, updateValue]: any = useState("");
  const location = useLocation();
  useEffect(() => {
    if (!props.value) {
      updateValue(props.value);
    } else {
      let newValue: any = "";
      switch (props.type) {
        case TemplateDataTypeEnum.DATE:
        case TemplateDataTypeEnum.DATE_TIME:
          let dateSelected: any = new Date(
            typeof props.value === "string"
              ? props.value.replace(/-/g, "/")
              : props.value
          );
          if (
            Object.prototype.toString.call(dateSelected) === "[object Date]"
          ) {
            if (!isNaN(dateSelected)) newValue = new Date(dateSelected);
          }
          if (newValue === "Invalid Date" || (!newValue && props.value)) {
            if (props.value !== "Invalid date") {
              const [day, month, year] = props.value.includes("-")
                ? props.value.split("-")
                : props.value.includes("/");
              newValue = new Date(`${month}/${day}/${year}`);
            }
          }

          break;
        // case TemplateDataTypeEnum.DATE_TIME:
        //   newValue = new Date(props.value);
        //   break;
        case TemplateDataTypeEnum.TIME:
          newValue = new Date("01/01/1970 " + props.value);
          //  newValue.setHours
          break;
      }
      updateValue(newValue);
    }

    // updateValue(DateTimeFormatter.convertDateTimeToSpecificFormat(props.value,'',format));
  }, [props.value]);
  const valueChangeFromPicker = (data: any) => {
    let newValue = "";
    switch (props.type) {
      case TemplateDataTypeEnum.DATE:
        newValue = data.format(defaultDateFormat);
        break;
      case TemplateDataTypeEnum.DATE_TIME:
        newValue = data.format(defaultDateTimeFormat);
        break;
      case TemplateDataTypeEnum.TIME:
        newValue = data.format(defaultTimeForamt);
        break;
    }

    setTimeout(() => {
      updateValue(newValue);
    });
    props.onChange(newValue);
  };

  const renderPickers = () => {
    switch (props.type) {
      case TemplateDataTypeEnum.DATE:
        return (
          <DateTimePicker
            className={props.inputVariant}
            //clearable={props.showClear}
            name={props.name}
            label={props.label}
            showIcon={props.showIcon ? props.showIcon : false}
            value={value || null}
            highlightDates={props.highlightDates ? props.highlightDates : null}
            disablePast={props.disablePast || false}
            onChange={(data: any) => {
              setTimeout(() => {
                updateValue(data);
              });

              props.onChange(data, defaultDateFormat);
            }}
            onCalenderOpen={(newData: any) => {
              if (location.pathname === "/routes/list") {
                props.calenderOpen(newData);
              }
            }}
            updatedMonth={(newData: any) => {
              if (location.pathname === "/routes/list" && props.isMonthChange) {
                props.monthChange(newData);
              }
            }}
          ></DateTimePicker>
        );
      case TemplateDataTypeEnum.DATE_TIME:
        return (
          <DateTimePicker
            format={DateTimeFormatter.getDateTimeFormat()}
            name={props.name}
            label={props.label}
            value={value || null}
            showIcon={props.showIcon ? props.showIcon : false}
            showTimeSelect={true}
            onChange={(data: any) => {
              setTimeout(() => {
                updateValue(data);
              });

              props.onChange(data, defaultDateTimeFormat);
            }}
          />
        );

      case TemplateDataTypeEnum.TIME:
        return (
          <DateTimePicker
            format={DateTimeFormatter.getTimeFormat()}
            name={props.name}
            label={props.label}
            value={value || null}
            showTimeSelect={true}
            showIcon={props.showIcon ? props.showIcon : false}
            showTimeSelectOnly={true}
            onChange={(data: any) => {
              setTimeout(() => {
                updateValue(data);
              });

              props.onChange(data, defaultTimeForamt);
            }}
          />
        );
    }
  };
  return (
    <div
      className={
        props.className !== "without-border" ? "input__box-container" : ""
      }
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {renderPickers()}
      </MuiPickersUtilsProvider>
    </div>
  );
};
export default DateTimePickerInput;
