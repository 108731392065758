import useLocalStorage from "./localStorage";

const DistanceFormatter = {
  getDistanceFormat() {
    return useLocalStorage.getItem("loginData").distance_format ? "mi" : "km";
  },
  convertMetricToImperial(distanceInKM: any) {
    if (this.getDistanceFormat() === "km") {
      return distanceInKM;
    } else if (this.getDistanceFormat() === "mi") {
      return Math.round(distanceInKM * 0.62137 * 1000) / 1000;
    }
  },
  converImperailToMetric(distanceInMI: any) {
    if (this.getDistanceFormat() === "mi") {
      let a = distanceInMI * 1.6;
      return Math.round(distanceInMI * 1.609344 * 1000) / 1000;
    } else {
      let a = distanceInMI;
      return distanceInMI;
    }
  },
  converPricePerKM(pricePerMile: any) {
    if (this.getDistanceFormat() === "mi") {
      let a = pricePerMile * 1.60934;
      return Math.round(pricePerMile * 1.609344 * 1000) / 1000;
    } else {
      let a = pricePerMile;
      return pricePerMile;
    }
  },
  convertPricePerMI(pricePerKM: any) {
    if (this.getDistanceFormat() === "km") {
      return pricePerKM;
    } else {
      return Math.round(pricePerKM * 0.62137 * 1000) / 1000;
    }
  },
};

export default DistanceFormatter;
