import React from "react";

const PageNotFound = () => {
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        background: "white",
        zIndex: "9999",
        display: "flex",
        justifycontent: "center",
        flexDirection: "column",
        alignItems: "inherit",
      }}
    >
      <h1>404</h1>
      <h1>Page Not Found</h1>
    </div>
  );
};

export default PageNotFound;
