const EditProfileEndpoints = {
  updateProfile(reqData: any) {
    return {
      url: "auth/user/update",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  updateTeamCurrency(reqData: any) {
    return {
      url: "main/team/currency/update",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },

  deleteAccount(reqData: any) {
    return {
      url: "auth/user/delete",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default EditProfileEndpoints;
