export const headerList = (t: any) => {
  return [
    {
      key: "mapped_ids",
      option: t("ImportJobs:Connected IDs"),
    },
    {
      key: "name",
      option: t("ImportJobs:Customer Name"),
    },
    {
      key: "country_code",
      option: t("ImportJobs:Country Code*"),
      is_required: 1,
    },
    {
      key: "customer_phone",
      option: t("ImportJobs:Phone no*"),
      is_required: 1,
    },
    {
      key: "email",
      option: t("ImportJobs:Email ID"),
    },
    {
      key: "reference_id",
      option: t("ImportJobs:Reference ID"),
    },
    {
      key: "location",
      option: t("ImportJobs:Address Line 1*"),
      is_required: 1,
    },
    {
      key: "address_line2",
      option: t("ImportJobs:Address Line 2"),
    },
    {
      key: "city/town",
      option: t("ImportJobs:City/Town*"),
      is_required: 1,
    },
    {
      key: "postal_code",
      option: t("ImportJobs:Postal Code*"),
      is_required: 1,
    },
    {
      key: "state/province",
      option: t("ImportJobs:State/province*"),
      is_required: 1,
    },
    {
      key: "country",
      option: t("ImportJobs:Country*"),
      is_required: 1,
    },
    {
      key: "lat",
      option: t("ImportJobs:Latitude"),
    },
    {
      key: "lng",
      option: t("ImportJobs:Longitude"),
    },
    {
      key: "task_description",
      option: t("ImportJobs:Task Description"),
    },
    {
      key: "task_type",
      option: t("ImportJobs:Pickup(0) / Delivery (1)*"),
      is_required: 1,
    },
    {
      key: "local_datetime",
      option: t("ImportJobs:Job Time*"),
      is_required: 1,
    },
    {
      key: "service_time",
      option: t("ImportJobs:Service Time"),
      // is_required: 1,
    },
    {
      key: "use_geofence_check",
      option: t("ImportJobs:Geofence Check"),
    },
    {
      key: "tags",
      option: t("ImportJobs:Tags"),
    },
    {
      key: "barcode",
      option: t("ImportJobs:Barcode"),
    },
    {
      key: "team_id",
      option: t("ImportJobs:Team ID"),
    },
    {
      key: "resource_id",
      option: t("ImportJobs:Resource ID"),
    },
  ];
};
