import React from "react";

const Temp = () => {
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        background: "white",
        zIndex: "9999",
        display: "flex",
        justifycontent: "center",
        flexDirection: "column",
        alignItems: "inherit",
      }}
    ></div>
  );
};

export default Temp;
