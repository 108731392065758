import { useToastRightContext } from "../ToastRight.context";
import { IToast } from "../../Toast/interfaces";
import * as actions from "../actions/actions";

const useToastRight = () => {
  const [, dispatch]: any = useToastRightContext();
  const generateRandom = () => {
    return Math.floor(Math.random() * 10000);
  };
  return {
    addToastRight: (data: IToast) => {
      data.id = generateRandom();
      dispatch(actions.addToastRightSuccess(data));
      setTimeout(
        () => dispatch(actions.removeToastRight(data.id)),
        data.timeout
      );
    },
  };
};
export default useToastRight;
