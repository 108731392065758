import React, { useState, Fragment } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import headerDropdownService from "../../services/headerDropdown.service";
import { useTranslation } from "react-i18next";
import classes from "../../assets/css/HeaderDropdown.module.scss";
import ResourceDropdownIcon from "../../assets/images/header/resource_dropdown_icon.svg";

const JobTypeDropdown = (props: any) => {
  const { t, i18n } = useTranslation(["Header", "Common"]);
  const [selectedJobType, setJobType] = useState(-1);

  const onJobTypeChange = (event: any) => {
    setJobType(event.target.value);
    const dropdownData = {
      type: "jobType",
      data: { jobType: event.target.value },
    };
    headerDropdownService.emit(dropdownData);
    //props.userTypeChanged(event.target.value)
  };
  const JobTypeList = [
    { label: t("Common:All"), value: -1 },
    { label: t("Common:Pickup"), value: 0 },
    { label: t("Common:Delivery"), value: 1 },
    { label: t("Common:Service"), value: 2 },
  ];
  const renderJobType = () => {
    return (
      JobTypeList.length > 0 &&
      JobTypeList.map((team: any, index: any) => {
        return <MenuItem value={team.value}> {team.label}</MenuItem>;
      })
    );
  };
  return (
    <Fragment>
      {/* <img
        src={TeamDropdownIcon}
        alt="team drpdown"
        className={classes["dropdown-pre-icon"]}
      ></img> */}
      <div style={{ display: "flex" }} className={classes.dropdownOuter}>
        <img
          src={ResourceDropdownIcon}
          alt="team drpdown"
          className={classes["dropdown-pre-icon"]}
        ></img>
        <Select
          MenuProps={{
            disableScrollLock: true,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          disableUnderline={true}
          value={selectedJobType}
          onChange={onJobTypeChange}
        >
          {renderJobType()}
        </Select>
      </div>
    </Fragment>
  );
};
export default JobTypeDropdown;
