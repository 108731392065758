const templateMethodService = {
  getTemplateFieldsByTemplateId(templateId: any, templateList: any) {
    const totalTemplates = templateList.length;
    for (let i = 0; i < totalTemplates; i++) {
      if (templateList[i]["template_id"] === templateId) {
        return templateList[i]["data"];
      }
    }
  },
  parseTemplateDataForInputForm(templateData: any) {
    const totalFields = templateData.length;
    for (let i = 0; i < totalFields; i++) {
      templateData[i].value = templateData[i].resource_value
        ? templateData[i].resource_value
        : templateData[i].value;
      delete templateData[i].resource_value;
    }
    return templateData;
  },
  parseTempalteDataForVisible(templateData: any) {
    const totalFields = templateData.length;
    for (let i = 0; i < totalFields; i++) {
      templateData[i].value = templateData[i].resource_value
        ? templateData[i].resource_value
        : templateData[i].value;
      delete templateData[i].resource_value;
    }
    return templateData;
  },
};
export default templateMethodService;
