export const themeColor: any = {
  primary: {
    main: "var(--primary-theme)",
  },
  defaultFontColor: "#000000",
  whiteColor: "#ffffff",
  blackColor: "#000000",
  iconsColor: "#39475B",
  defaultTableColor: "#f4f7fc",
  defaultBackgroundColor: "#f4f7fc",
};
