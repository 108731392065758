export const daysList = [
  { label: "First of Every Month", value: 1 },
  { label: "Mid of Every Month", value: 3 },
  { label: "Last of Every Month", value: 2 },
];

export const scheduleOptionList = [
  { label: "Daily", value: 1 },
  { label: "Weekly", value: 2 },
  { label: "Monthly", value: 4 },
];

export const weekList = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];
