const CustomerSubscriptionEndpoints = {
  createPlan(reqData: any, operation: string) {
    const url =
      operation === "add"
        ? "payment/customerSubscription/create"
        : "payment/customerSubscription/update";
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getSubscriptionRules(reqData: any) {
    return {
      url: "payment/customerSubscription/get",

      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },

  removeSubscriptionRule(reqData: any) {
    return {
      url: "payment/customerSubscription/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default CustomerSubscriptionEndpoints;
