import React from "react";
import { useTranslation } from "react-i18next";
import "./TaskDetailTabs.scss";
import TemplateInfo from "./TemplateInfo";

const ProductDetail = (props: any) => {
  const taskDetail: any = props.taskDetail;
  const { t, i18n } = useTranslation(["TaskDetail", "Common"]);

  return (
    <>
      <div className={"task-detail-tabs__info__basic-detail"}>
        <div className={"task-detail-tabs__info__basic-detail__key-name"}>
          {t("TaskDetail:Cost")}
        </div>
        <div className={"task-detail-tabs__info__basic-detail__key-value"}>
          {taskDetail.cost || "NA"}
        </div>
      </div>
      <div className={"task-detail-tabs__info__basic-detail"}>
        <div className={"task-detail-tabs__info__basic-detail__key-name"}>
          {t("TaskDetail:Service Tax")}
        </div>
        <div className={"task-detail-tabs__info__basic-detail__key-value"}>
          {taskDetail.service_tax || "NA"}
        </div>
      </div>
      <div className={"task-detail-tabs__info__basic-detail"}>
        <div className={"task-detail-tabs__info__basic-detail__key-name"}>
          {t("TaskDetail:GST")}
        </div>
        <div className={"task-detail-tabs__info__basic-detail__key-value"}>
          {taskDetail.gst || "NA"}
        </div>
      </div>

      {taskDetail?.product_details.map((index: any) => {
        return (
          <>
            <div className={"task-detail-tabs__info__basic-detail"}>
              <div
                className={"task-detail-tabs__info__basic-detail__key-name"}
                style={{ fontWeight: "bold" }}
              >
                {t("TaskDetail:Product Name")}
              </div>
              <div
                className={"task-detail-tabs__info__basic-detail__key-value"}
              >
                {index.product_name || "-"}
                {Number(index.quantity) > 1 && index.product_name
                  ? `(x${index.quantity})`
                  : ``}
              </div>
            </div>

            <>
              {index?.template_data?.length > 0 && (
                <p style={{ fontWeight: 500, margin: "0" }}>Additional Info</p>
              )}
              {index?.template_data?.length > 0 ? (
                <TemplateInfo taskDetail={index}></TemplateInfo>
              ) : null}
            </>
          </>
        );
      })}
    </>
  );
};

export default ProductDetail;
