import { MenuItem, Select } from "@material-ui/core";
import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import { useAppContext } from "../../AppContext/App.context";
import ScheduledPaymentEndPoints from "../../containers/ScheduledPayments/ScheduledPaymentsEndPoints";
import useApiService from "../../services/api.service";
import headerDropdownService from "../../services/headerDropdown.service";
import TeamDropdownIcon from "../../assets/images/header/team_dropdown_icon.svg";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
const TagListDropdown = (props: any) => {
  const [appData, dispatch]: any = useAppContext();
  const [tagList, setTagList]: any = useState([]);
  const [selectedTagId, setTagId] = useState("");

  const access_token = appData.loginData.access_token;
  const ApiService = useApiService();

  const onTagChange = (event: any) => {
    setTagId(event.target.value);
    const dropdownData = {
      type: "tagList",
      data: { tag_id: event.target.value },
    };
    headerDropdownService.emit(dropdownData);
  };

  const getCustomerTags = async () => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams: any = {
      limit: 1000,
      skip: 0,
      validate_resource_tags: false,
      tag_type: 2,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      ScheduledPaymentEndPoints.getTags(reqData)
    );
    if (res.data.data.length) {
      setTagListDropDown(res.data.data);
    }
  };

  useEffect(() => {
    getCustomerTags();
  }, []);
  const setTagListDropDown = (data: any) => {
    let arr: any = data || [];
    arr.unshift({ tag_id: 0, tag_name: props.default });
    setTagList(arr);
    setTagId("0");
  };
  const renderTagList = () => {
    return (
      tagList.length > 0 &&
      tagList.map((tag: any, index: any) => {
        return <MenuItem value={tag.tag_id}> {tag.tag_name}</MenuItem>;
      })
    );
  };
  return (
    <Fragment>
      {tagList.length ? (
        <div
          style={{
            display: "flex",
            padding: "5px 16px 5px 19.6px",
            borderRadius: "25px",
            border: "solid 1px #dadada",
          }}
        >
          {/* <LocalOfferIcon/> */}
          <img
            src={TeamDropdownIcon}
            alt="team drpdown"
            style={{ marginRight: "10px", verticalAlign: "middle" }}
          ></img>
          <Select
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              getContentAnchorEl: null,
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            disableUnderline={true}
            value={selectedTagId}
            onChange={onTagChange}
          >
            {renderTagList()}
          </Select>
        </div>
      ) : (
        <div></div>
      )}
    </Fragment>
  );
};

export default TagListDropdown;
