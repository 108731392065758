import React, { useRef, useState } from "react";
import Input from "../Input/Input";
import DateTimePickerInput from "./DateTimePickerInput";
import ImageInTemplateForm from "./ImageInTemplateForm";
import { TemplateDataTypeEnum } from "../../enum/Template.enum";
import DateTimeFormatter from "../../utils/DateFormatter";
import MaterialAutocomplete from "../MaterialAutocomplete/MaterialAutocomplete";
// import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
// eslint-disable-next-line react/prop-types
import "./TemplateInput.scss";
import { Grid, Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Phone from "../phone-picker/phone-picker";
import { useForm } from "react-hook-form";
import useToast from "../Toast/hooks/useToast";

const TemplateFieldsInputForm = (props: any) => {
  const itemList = props.items;
  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    errors,
    setValue,
  } = useForm();
  const dialCode: any = useRef<any>();
  const countryCode: any = useRef();
  const toaster = useToast();

  const getLabelName = (labelName: any) => {
    switch (labelName) {
      case `Single Cabinets Hoods up to 48 wide and 50 high`:
        return "Number of Cabinets";
        break;
      case `Single Cabinets Hoods up to 48 wide and between 50-100 high`:
        return "Number of Cabinets";
        break;
      case `Single Cabinets Hoods up to 48 wide and over 100 high`:
        return "Number of Cabinets";
        break;
      case `List all three measurements of each cabinet over 100 high (separated by commas)`:
        return "Number of Cabinets";
        break;
      case `List all three measurements of each combo (separated by commas)`:
        return "Number of Cabinets";
        break;
      case `List all three measurements of each combo (separated by commas)`:
        return "Number of Cabinets";
        break;
      default:
        return labelName;
        break;
    }
  };

  const TextTypeField = (rowObj: any) => {
    const index = rowObj.index;
    const item = rowObj.item;
    const type = rowObj.type;
    return (
      <Input
        type={type === 1 ? "number" : "text"}
        id={`template[${index}].name`}
        name={`template[${index}].name`}
        onchange={(evt: any) => {
          props.handleTemplateFieldChange({
            name: item["name"],
            taskType: props.taskType,
            taskIndex: props.taskIndex,
            fieldIndex: index,
            value: evt.target.value.trim(),
          });
        }}
        value={item["value"]}
        // label={item["name"]}
        label={getLabelName(item["name"])}
      ></Input>
    );
  };

  const DateTimeField = (rowObj: any) => {
    const index = rowObj.index;
    const item = rowObj.item;

    return (
      <DateTimePickerInput
        label={item["name"]}
        type={item["data_type"]}
        name={`template[${index}].name`}
        value={item["value"]}
        onChange={(evt: any, format: string) => {
          props.handleTemplateFieldChange({
            name: item["name"],
            taskType: props.taskType,
            taskIndex: props.taskIndex,
            fieldIndex: index,
            value: evt
              ? DateTimeFormatter.changeOnlyFormatInSameTimeZone(evt, format)
              : "",
          });
          // item["value"] = DateTimeFormatter.changeOnlyFormatInSameTimeZone(evt,"HH:mm");
        }}
      ></DateTimePickerInput>
    );
  };
  const ImageTypeField = (rowObj: any) => {
    const index = rowObj.index;
    const item = rowObj.item;
    return (
      <ImageInTemplateForm
        item={item}
        onChange={(data: any) => {
          props.handleTemplateFieldChange({
            name: item["name"],
            taskType: props.taskType,
            taskIndex: props.taskIndex,
            fieldIndex: index,
            value: data,
          });
        }}
        accept={"image/*"}
      ></ImageInTemplateForm>
    );
  };
  const DocumentTypeField = (rowObj: any) => {
    const index = rowObj.index;
    const item = rowObj.item;
    return (
      <ImageInTemplateForm
        item={item}
        onChange={(data: any) => {
          props.handleTemplateFieldChange({
            name: item["name"],
            taskType: props.taskType,
            taskIndex: props.taskIndex,
            fieldIndex: index,
            value: data,
          });
        }}
        accept={
          "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"
        }
      ></ImageInTemplateForm>
    );
  };
  const RecordingTypeField = (rowObj: any) => {
    const index = rowObj.index;
    const item = rowObj.item;
    return (
      <ImageInTemplateForm
        item={item}
        onChange={(data: any) => {
          // debugger;
          props.handleTemplateFieldChange({
            name: item["name"],
            taskType: props.taskType,
            taskIndex: props.taskIndex,
            fieldIndex: index,
            value: data[0] || "",
          });
        }}
        accept={"audio/*"}
      ></ImageInTemplateForm>
    );
  };

  const ChecklistField = (rowObj: any) => {
    const index = rowObj.index;
    const item = rowObj.item;

    let [checkListData, setcheckListData] = useState(item.checklist || []);
    if (item?.value) {
      let tempArr = [...checkListData];
      item.checklist.map((element: any, index: number) => {
        if (item.value.includes(element.name)) {
          tempArr[index] = { ...element, is_checked: true };
        }
      });
      checkListData = tempArr;
    }
    const setCheckListData = (e: any, i: any) => {
      let tempData: any = [...checkListData];
      let tempObj: any = {
        name: e.target.name,
        is_checked: e.target.checked,
      };
      tempData[i] = tempObj;
      let checklistValue: any = [];
      tempData.map((element: any) => {
        if (element.is_checked) {
          checklistValue.push(element.name);
        }
      });

      setcheckListData(tempData);
      props.handleTemplateFieldChange({
        name: item["name"],
        taskType: props.taskType,
        taskIndex: props.taskIndex,
        fieldIndex: index,
        value: checklistValue,
        checklist: tempData,
      });
    };

    return (
      <>
        <p style={{ marginBottom: "0px", marginTop: "0px", fontWeight: 600 }}>
          {item.name}
        </p>
        <FormGroup>
          {checkListData.map((element: any, i: any) => (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name={element.name}
                    checked={element.is_checked}
                    onChange={(e: any) => {
                      setCheckListData(e, i);
                    }}
                  />
                }
                label={element.name}
              />
            </>
          ))}
        </FormGroup>
      </>
    );
  };

  const DropdownTypeField = (rowObj: any) => {
    const index = rowObj.index;
    const item = rowObj.item;
    let dropdownObject: { name: string; value: number }[] = [];
    item.ddlist &&
      item.ddlist.map((val: any, index: any) => {
        dropdownObject[index] = {
          name: val,
          value: index,
        };
      });
    const [dropdownSelected, setDropdownSelected]: any = useState(
      dropdownObject.find((e: any) => e.name === item.value) || null
    );
    return (
      <>
        <MaterialAutocomplete
          style={{ "margin-bottom": "10px" }}
          isMultiple={false}
          freeSolo={false}
          disabledClearable={true}
          desableportal={true}
          size="medium"
          id={"template-data-type" + +index}
          options={dropdownObject}
          labelName="name"
          value={dropdownSelected}
          onChange={(evt: any, newValue: any) => {
            if (newValue && newValue.name) {
              setDropdownSelected(newValue);
              props.handleTemplateFieldChange({
                name: item["name"],
                taskType: props.taskType,
                taskIndex: props.taskIndex,
                fieldIndex: index,
                value: newValue.name,
              });
            } else {
              // setDropdownSelected({ name: "", value: "" });
              setDropdownSelected(null);
              props.handleTemplateFieldChange({
                name: item["name"],
                taskType: props.taskType,
                taskIndex: props.taskIndex,
                fieldIndex: index,
                value: "",
              });
            }
          }}
          filterSelectedOptions
          onKeyDown={() => {}}
          textFieldLabel={item["name"]}
          textFieldMargin={"none"}
          textFieldClassName={""}
          textFieldFullWidth={true}
        />
      </>
    );
  };
  const fetchDialCode = (dialcode: any, countrycode: any) => {
    dialCode.current = "+" + dialcode;

    countryCode.current = countrycode.toUpperCase();
  };
  const renderTemplateItem = (item: any, index: number) => {
    switch (item.data_type) {
      case TemplateDataTypeEnum.TEXT:
      case TemplateDataTypeEnum.NUMBER:
        return (
          <TextTypeField index={index} type={item.data_type} item={item} />
        );
      case TemplateDataTypeEnum.Url:
        return (
          <TextTypeField index={index} type={item.data_type} item={item} />
        );
      case TemplateDataTypeEnum.DATE:
        return <DateTimeField index={index} item={item} />;
      case TemplateDataTypeEnum.DATE_TIME:
        return <DateTimeField index={index} item={item} />;
      case TemplateDataTypeEnum.IMAGE:
        // return <DocumentTypeField index={index} item={item} />;
        return <ImageTypeField index={index} item={item} />;
      case TemplateDataTypeEnum.TIME:
        return <DateTimeField index={index} item={item} />;
      case TemplateDataTypeEnum.DOCUMENT:
        return <DocumentTypeField index={index} item={item} />;
      case TemplateDataTypeEnum.DROPDOWN:
        return <DropdownTypeField index={index} item={item} />;
      case TemplateDataTypeEnum.CHECKLIST:
        return <ChecklistField index={index} item={item} />;
      case TemplateDataTypeEnum.BARCODE:
        return (
          <TextTypeField index={index} type={item.data_type} item={item} />
        );
      case TemplateDataTypeEnum.RECORDING:
        return (
          <RecordingTypeField index={index} type={item.data_type} item={item} />
        );
      case TemplateDataTypeEnum.EMAIL:
        return (
          <Input
            type="text"
            name={`template[${index}].email`}
            id={`template[${index}].email`}
            error={errors}
            onchange={(evt: any) => {
              props.handleTemplateFieldChange({
                name: item["name"],
                taskType: props.taskType,
                taskIndex: props.taskIndex,
                fieldIndex: index,
                value: evt.target.value.trim(),
              });
            }}
            value={item["value"]}
            label={item["name"]}
          ></Input>
        );
      case TemplateDataTypeEnum.PHONENUMBER:
        return (
          <Phone
            hideLabel={true}
            showPhone={true}
            setDialCode={(dialcode: any, countrycode: any) => {
              fetchDialCode(dialcode, countrycode);
              item["country_code"] = dialCode.current;
            }}
            name={`template[${index}].phone`}
            id={`template[${index}].phone`}
            label={item["name"]}
            placeholder={item["name"]}
            onchange={(evt: any) => {
              props.handleTemplateFieldChange({
                name: item["name"],
                taskType: props.taskType,
                taskIndex: props.taskIndex,
                fieldIndex: index,
                value: evt.target.value,
              });
            }}
            defaultPhone={item["country_code"] ? item["country_code"] : "+91"}
            phoneNumber={item["value"]}
          ></Phone>
          // "Phone Number"
        );
    }
  };
  const checkTemplateHiddenALl = (itemList: any) => {
    let hiddenAllFalse = true;
    for (let item of itemList) {
      if (item.customer_permission && item.customer_permission === 2) {
        hiddenAllFalse = false;
      } else {
        hiddenAllFalse = true;
        return true;
      }
    }
    if (hiddenAllFalse === false) {
      return false;
    } else return true;
  };
  return (
    <div
      className={`${checkTemplateHiddenALl(itemList) ? "template-data" : ""}`}
    >
      <Grid
        className="AddResource__mainDiv"
        container
        spacing={2}
        style={{ marginTop: 0 }}
      >
        {itemList && itemList.length > 0
          ? itemList.map((item: any, index: number) => {
              return (
                // <div className={props.className}>

                <Grid
                  key={index}
                  lg={props.itemWidthLg || 12}
                  xs={props.itemWidthXs || 12}
                  item
                >
                  {renderTemplateItem(item, index)}
                </Grid>

                // </div>
              );
            })
          : null}
      </Grid>
    </div>
  );
};
export default TemplateFieldsInputForm;
