import React, { useState, Fragment, useEffect } from "react";
import Select from "@material-ui/core/Select";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import headerDropdownService from "../../services/headerDropdown.service";
import { useTranslation } from "react-i18next";
import classes from "../../assets/css/HeaderDropdown.module.scss";
import ResourceDropdownIcon from "../../assets/images/header/resource_dropdown_icon.svg";
import Dropdown from "../Dropdown/Dropdown";
import { AgentDropdownList } from "../../enum/AgentVehicleAsset.enum";

const ResourceTypeDropdown = (props: any) => {
  const { t, i18n } = useTranslation(["Header", "Common"]);
  const [selectedUserType, setUserType]: any = useState();
  const location = useLocation();
  const history = useHistory();

  const onUserTypeChange = (dropdownValue: any) => {
    setUserType(dropdownValue);

    const dropdownData = {
      type: "resourceDropdownChange",
      data: { date: dropdownValue },
    };
    headerDropdownService.emit(dropdownData);

    switch (dropdownValue) {
      case 1:
        history.push("/resources/list");

        break;
      case 2:
        history.push("/vehicle/list");

        break;
      case 3:
        history.push("/asset/list");

        break;
      default:
        break;
      //props.userTypeChanged(event.target.value)
    }
  };
  const userTypeList = [
    { label: t("Common:Agents"), value: 1 },
    { label: t("Common:Vehicles"), value: 2 },
    { label: t("Common:Assets"), value: 3 },
  ];
  const updateResourceTypeDropdown = () => {
    if (location.pathname.includes("/resources/list")) {
      setUserType(1);
      console.log("location", location.pathname);
    } else if (location.pathname.includes("/vehicle/list")) {
      setUserType(2);
    } else if (location.pathname.includes("asset/list")) {
      setUserType(3);
    }
  };
  useEffect(() => {
    updateResourceTypeDropdown();
  }, [location.pathname]);

  return (
    <Fragment>
      {selectedUserType ? (
        <div style={{ minWidth: "200px", marginTop: "12px" }}>
          <Dropdown
            required={true}
            labelName={t("Common:Select Resource Type")}
            onChange={(event: any) => onUserTypeChange(event.target.value)}
            selectedValue={selectedUserType}
            itemList={AgentDropdownList}
            uniqueId="id"
            label="name"
          ></Dropdown>
        </div>
      ) : null}
    </Fragment>
  );
};
export default ResourceTypeDropdown;
